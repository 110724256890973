import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NotificationBlock from '@/containers/NotificationBlock';
import { RoundLink } from '@/components/UI/RoundLink';
import { getInvestingResults } from '@/store/actions/investActions';
import InvestingInfo from '../../InvestingInfo';
import InvHappyStartups from './happyStartups';
import { NOTIFICATIONS as NOTIFICATIONS_T } from '@/components/Notification/list';
import InviteFriends from '@/components/InviteFriends';
import { APP_LINKS } from '@/helpers/links';
import { WriteReview } from '@/components/WriteReview';
import { useCheckPlannedPayments } from '@/components/Notification/hooks/useCheckPlannedPayments';
import './index.scss';

const InvResult = ({ status }) => {
  const { t } = useTranslation('investing');
  const { t: tCommon } = useTranslation('common');
  const NOTIFICATIONS = NOTIFICATIONS_T(tCommon);
  const dispatch = useDispatch();
  const initialAutoInvestStatus = useRef(status.auto_invest);
  const { data: result, loading } = useSelector(
    state => state.investStore.investingResult,
  );
  const { hasPlannedPayment } = useCheckPlannedPayments();

  useEffect(() => {
    dispatch(getInvestingResults());
  }, []);

  if (loading) {
    return null;
  }

  if (!result) {
    return (
      <h1 className="f-42 f-500">{t('ir_could_not_get_investing_results')}</h1>
    );
  }

  const notifications = [];
  const hasLoans = !!result.total_invested;
  const notAllocatedAmount =
    Number(result.initially_allocated) - Number(result.total_invested);

  !hasLoans &&
    notifications.push(NOTIFICATIONS.investment_strategy_restrictive());

  initialAutoInvestStatus.current &&
    !!notAllocatedAmount &&
    notifications.push(
      NOTIFICATIONS.unallocated_cash_reinvested(notAllocatedAmount),
    );

  hasPlannedPayment && notifications.push(NOTIFICATIONS.october_weekends());

  return (
    <div className="inv-result">
      <h1 className="f-42 f-500">
        {hasLoans ? t('ir_congrats_investments') : t('ir_ouch_no_investments')}
      </h1>
      <NotificationBlock
        className="inv-result__notifications"
        notifications={notifications}
      />
      <InvestingInfo
        status={status}
        skipResultFetch
        hasLoans={hasLoans}
        className="inv-result__summary"
      />

      {hasLoans && (
        <RoundLink
          label={t('ir_see_details')}
          path={APP_LINKS.loans}
          fullWidth
          className="inv-result__button"
        />
      )}

      <InviteFriends analyticSource="results" />

      <WriteReview className="inv-result__trustpilot" />

      <InvHappyStartups />
    </div>
  );
};

export default InvResult;
