import { useTranslation } from 'react-i18next';
import { RoundButton } from '@/components/UI/RoundButton';
import s from './index.module.scss';

const ComplaintRemoveModal = ({ onDelete, onClose }) => {
  const { t } = useTranslation('modal');
  return (
    <div className={s.wrapper}>
      <h1 className="f-20 f-500">
        {t(
          'complaint_remove_modal.are_you_sure_you_want_to_delete_your_complaints_form',
        )}
      </h1>
      <div className={s.text}>
        {t('complaint_remove_modal.deleting_the_form_may_result')}
      </div>
      <div className={s.controls}>
        <RoundButton
          className={s.cancel}
          label={t('complaint_remove_modal.cancel')}
          onClick={onClose}
        />
        <RoundButton
          className={s.remove}
          label={t('complaint_remove_modal.delete')}
          onClick={onDelete}
        />
      </div>
    </div>
  );
};

export default ComplaintRemoveModal;
