import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SET_VERIFF_STATUS_CODE } from '@/store/types/profileTypes.js';
import { MODAL_TYPES } from '@/components/ModalProvider';
import { calculateCash } from '@/helpers/utils';
import { USER_STATUSES, VERIFF_CODES } from '@/helpers/constants';
import { setModals } from '@/store/actions/commonActions';
import { getVeriffStatus } from '@/store/actions/profileActions';
import { getUserLocation } from '@/store/actions/userActions';
import { AccountCreateStep } from './steps/accountCreate';
import { VerifyIdentityStep } from './steps/verifyIdentity';
import { WainUntilCheckedStep } from './steps/waitUntilChecked';
import { StartInvestingStep } from './steps/startInvesting';
import { AddCashStep } from './steps/addCash';
import s from './index.module.scss';

export const STEPS = {
  account_create: 'account_create',
  verify_identity: 'verify_identity',
  wait_until_checked: 'wait_until_checked',
  add_cash: 'add_cash',
  start_investing: 'start_investing',
};

const VerificationProcessWizard = ({ isActiveFR }) => {
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const intervalID = useRef(null);
  const [passed, setPassed] = useState([STEPS.account_create]);
  const { balance } = useSelector(state => state.dashboardStore);
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const investingStatus = useSelector(
    state => state.investStore.investingStatus.data,
  );
  const veriffStatusCode = useSelector(
    state => state.profileStore.veriffStatusCode,
  );

  useEffect(() => {
    dispatch(getUserLocation());
  }, []);

  useEffect(() => {
    checkCurrentStep();
  }, [profile?.id, veriffStatusCode]);

  const closeModal = () => {
    dispatch(setModals([]));
  };

  const checkCurrentStep = () => {
    if (!profile) return;

    if (profile.status === USER_STATUSES.new) {
      if (veriffStatusCode === VERIFF_CODES.verified) {
        return setPassed([
          STEPS.account_create,
          STEPS.verify_identity,
          STEPS.wait_until_checked,
        ]);
      }

      if (hash === '#veriff' && !profile.is_verified) {
        onVerifyIdentity();
      }

      return setPassed([STEPS.account_create, STEPS.verify_identity]);
    }

    const minInvestAmount = investingStatus?.min_investment_amount || 0;
    const cash = calculateCash(balance);

    if (profile.status === USER_STATUSES.verified) {
      if (cash >= minInvestAmount) {
        return setPassed(Object.keys(STEPS));
      }
      return setPassed([
        STEPS.account_create,
        STEPS.verify_identity,
        STEPS.wait_until_checked,
        STEPS.add_cash,
      ]);
    }
  };

  useEffect(() => {
    if (VERIFF_CODES.submitted === veriffStatusCode) {
      startPollingVeriffStatus();
    }
    return () => {
      clearInterval(intervalID.current);
    };
  }, [veriffStatusCode]);

  const startPollingVeriffStatus = () => {
    clearInterval(intervalID.current);
    intervalID.current = setInterval(() => {
      dispatch(getVeriffStatus()).then(res => {
        if (res && res.success && res.data.code === VERIFF_CODES.verified) {
          clearInterval(intervalID.current);
        }
      });
    }, 2000);
  };

  const onVerifyIdentity = useCallback(() => {
    ![VERIFF_CODES.submitted, VERIFF_CODES.verified].includes(
      veriffStatusCode,
    ) &&
      dispatch(
        setModals(
          {
            type: MODAL_TYPES.wizard_veriff,
            onBack: closeModal,
            onClose: closeModal,
            onSuccess: () => {
              setTimeout(() => {
                dispatch({
                  type: SET_VERIFF_STATUS_CODE,
                  code: VERIFF_CODES.submitted,
                });
              }, 2000);
              closeModal();
              setPassed([
                STEPS.account_create,
                STEPS.verify_identity,
                STEPS.wait_until_checked,
              ]);
            },
          },
          true,
        ),
      );
  }, [veriffStatusCode]);

  return (
    <div className={s.wrapper}>
      <ul className={s.list}>
        <AccountCreateStep passed={passed} />
        <VerifyIdentityStep
          passed={passed}
          onVerifyIdentity={onVerifyIdentity}
        />
        <WainUntilCheckedStep passed={passed} isActiveFR={isActiveFR} />
        <AddCashStep passed={passed} />
        <StartInvestingStep passed={passed} />
      </ul>
    </div>
  );
};

export default VerificationProcessWizard;
