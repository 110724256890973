import { useTranslation } from 'react-i18next';
import { prettyFloatMoney } from '@/helpers/utils';
import ReferralCard from '@/components/Cards/ReferralCard';
import './index.scss';

const ReferralList = ({ data }) => {
  const { t } = useTranslation('affiliate');
  return (
    <div className="referral_list">
      <div className="referral_list__summary">
        <div className="referral_list__summary_row">
          <div className="f-16 f-500">{t('referral_list.total')}</div>
          <div className="f-18 f-500">{data.total_count}</div>
        </div>

        <div className="referral_list__summary_row">
          <div className="f-16 f-500">{t('referral_list.comission')}</div>
          <div className="f-18 f-500">
            +{prettyFloatMoney(data.total_commission)}
          </div>
        </div>
      </div>

      <div className="referral_list__list">
        {data.list.map((item, idx) => (
          <ReferralCard
            key={idx}
            data={item}
            className="referral_list__list_item"
          />
        ))}
      </div>
    </div>
  );
};

export default ReferralList;
