import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { BasicLayout } from '@/layouts/BasicLayout';
import { SocialNetworks } from '@/containers/SocialNetworks';
import { STEPS } from '@/pages/AuthPage/data';
import Notify from '@/components/Notification';
import {
  clearTempUserData,
  getAuthData,
  saveTempToken,
  saveTempUserData,
} from '@/pages/AuthPage/utils';
import { setToken } from '@/store/actions/userActions';
import { APP_LINKS } from '@/helpers/links';
import { useReferrals } from '@/providers/referral';
import { getLastRoute, removeLastRoute, scrollTop } from '@/helpers/utils';
import { StepMain } from './components/StepMain';
import { StepEmailOtp } from './components/StepEmailOtp';
import { StepPhoneOtp } from './components/StepPhoneOtp';
import { StepReferral } from './components/StepReferral';
import { StepVeriff } from './components/StepVeriff';
import s from './index.module.scss';

const AuthPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentRefCode } = useReferrals();
  const { t: tKit } = useTranslation('kit');
  const [state, setState] = useState(getAuthData());
  const { token: mainToken } = useSelector(state => state.userStore);
  const [step, setStep] = useState(state?.data?.step || STEPS.main);

  useEffect(() => {
    if (mainToken?.refresh_token) {
      navigate(APP_LINKS.dashboard, { replace: true });
    }
    return () => {
      clearTempUserData();
    };
  }, []);

  useEffect(() => {
    scrollTop();
  }, [step]);

  const redirectLastRoute = () => {
    const lastRoute = getLastRoute();
    navigate(lastRoute || APP_LINKS.dashboard);
    removeLastRoute();
  };

  const onEmailSubmit = ({ email, temp_token, is_new_user }) => {
    const userData = { email, step: STEPS.otp_email, is_new_user };
    saveTempToken(temp_token);
    saveTempUserData(userData);
    setState({
      token: temp_token,
      data: userData,
    });
    setStep(STEPS.otp_email);
  };

  const onEmailOtpSubmit = response => {
    if (state.data) {
      // Registration flow
      if (state.data.is_new_user) {
        clearTempUserData();
        dispatch(setToken(response));
        return !currentRefCode
          ? setStep(STEPS.referral)
          : setStep(STEPS.veriff);
      }

      // Login flow
      if (response.temp_token) {
        saveTempUserData({ ...state.data, step: STEPS.phone });
        saveTempToken(response.temp_token);
        return setStep(STEPS.phone);
      }

      if (response.refresh_token) {
        dispatch(setToken(response));
        redirectLastRoute();
      }
    }
  };

  const onSocialSuccess = res => {
    if (res?.temp_token) {
      saveTempUserData({ ...state.data, step: STEPS.phone });
      saveTempToken(res.temp_token);
      return setStep(STEPS.phone);
    }

    if (res?.access_token) {
      dispatch(setToken(res));
      // Decode JWT to recognize if user registering or logging in
      const decodedData = jwtDecode(res.access_token);

      if (decodedData.user?.is_new_user) {
        return !currentRefCode
          ? setStep(STEPS.referral)
          : setStep(STEPS.veriff);
      }

      Notify.success({
        text: tKit('notify.welcome', {
          firstName: decodedData.user?.first_name,
          lastName: decodedData.user?.last_name,
        }),
      });

      redirectLastRoute();
    }
  };

  return (
    <BasicLayout>
      <div className="container" style={{ height: '100%' }}>
        <div className={classNames(s.auth)}>
          <div className={s.auth__inner}>
            {step === STEPS.main && (
              <StepMain onEmailSubmit={onEmailSubmit}>
                <SocialNetworks onSuccess={onSocialSuccess} />
              </StepMain>
            )}
            {step === STEPS.otp_email && (
              <StepEmailOtp
                onChangeEmail={() => setStep(STEPS.main)}
                onSuccess={onEmailOtpSubmit}
              />
            )}
            {step === STEPS.referral && (
              <StepReferral nextStep={() => setStep(STEPS.veriff)} />
            )}
            {step === STEPS.phone && <StepPhoneOtp />}
            {step === STEPS.veriff && <StepVeriff />}
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default AuthPage;
