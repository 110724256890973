import { useEffect, useState } from 'react';
import qs from 'qs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FounderDetailCard from '@/components/Cards/FounderDetailCard';
import { getFounderDetail } from '@/store/services/userServices';
import { Preloader } from '@/components/Preloader';
import { BackButton } from '@/components/UI/Buttons';
import { getFounderFundraisingStatus } from '@/store/actions/founderActions';
import './index.scss';

const FounderDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const fundraising = useSelector(
    state => state.founderStore.founderFundraisingStats.data,
  );
  const [state, setState] = useState({
    id,
    detail: null,
    loading: true,
  });

  const { detail, loading } = state;

  useEffect(() => {
    const { to } = qs.parse(location.search.replace('?', ''));
    to && navigate(location.pathname, { replace: true });
    getDetails(id);
  }, []);

  const getDetails = founderID => {
    setState(prevState => ({ ...prevState, loading: true }));
    dispatch(getFounderFundraisingStatus(id));
    getFounderDetail(founderID).then(res =>
      res && res.success
        ? setState(prevState => ({
            ...prevState,
            detail: res.data,
            loading: false,
          }))
        : setState(prevState => ({
            ...prevState,
            detail: null,
            loading: false,
          })),
    );
  };

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      id,
      loading: true,
    }));
    getDetails(id);
    const main = document.querySelector('main');
    main && main.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, [id]);

  const onBack = () => {
    // TODO RESTORE
    // if (
    //   this.redirect &&
    //   Object.keys(WIZARD_REDIRECTS).map(key =>
    //     WIZARD_REDIRECTS[key].includes(this.redirect)
    //   )
    // ) {
    //   return navigate(
    //     `/investing?to=${this.redirect}${
    //       this.strategy ? `&strategy=${this.strategy}` : ''
    //     }`
    //   );
    // }
    navigate(-1);
  };

  return (
    <div className="founder-detail-page">
      <div className="container-inner">
        <div className="founder-detail-page__back">
          <BackButton onClick={onBack} />
        </div>
        {loading ? (
          <Preloader className="founder-detail-page__preloader" />
        ) : (
          <FounderDetailCard founder={detail} fundraising={fundraising} />
        )}
      </div>
    </div>
  );
};

export default FounderDetailPage;
