import React from "react";
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import config from '@/config';

export function initSentry() {
    if (['production', 'test'].includes(config.release)) {
        Sentry.init({
            dsn: config.sentryDSN,
            environment: config.release,
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect: React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
            ],
            tracesSampleRate: 1.0,
        });
    }
}

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV6(
    createBrowserRouter,
);
