export const GET_USER_PROFILE = {
  REQUEST: 'GET_USER_PROFILE_REQUEST',
  SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  FAILURE: 'GET_USER_PROFILE_FAILURE',
};

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_VERIFF_STATUS_CODE = 'SET_VERIFF_STATUS_CODE';

export const UPDATE_USER_PROFILE = {
  REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
  SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  FAILURE: 'UPDATE_USER_PROFILE_FAILURE',
};

export const SET_BONUS_GROUP = 'SET_BONUS_GROUP';
