import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import moment from 'moment/min/moment-with-locales';

import { DayPicker } from 'react-day-picker';
import { de, el, enGB, es, nl, pt } from 'react-day-picker/locale';
import 'react-day-picker/style.css';

import { useOutsideClick } from '@/hooks/useOutsideClick';
import { InputDateTextField } from '@/components/UI/InputDateTextField';
import { DATE_FORMAT_DD_MM_YYYY } from '@/helpers/common';
import s from './index.module.scss';

const DATE_MASK = '99/99/9999';

const LOCALE_MAP = {
  de: de,
  el: el,
  en: enGB,
  es: es,
  nl: nl,
  pt: pt,
};

export const DatePicker = ({
  label,
  name,
  value,
  initialValue,
  onChange,
  onReset,
  error,
  className,
}) => {
  const { t, i18n } = useTranslation('kit');
  const [inputValue, setInputValue] = useState('');
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setShow(false), show);

  const handleInputChange = e => {
    const val = e.target.value;
    setInputValue(val);
    if (val.length === 10) {
      const date = val.split('/').reduce((acc, val, index) => {
        if (index === 0) {
          acc.day = Number(val);
        }
        if (index === 1) {
          acc.month = Number(val);
        }
        if (index === 2) {
          acc.year = Number(val);
        }
        return acc;
      }, {});
      onChange(
        moment(
          `${date.day}-${date.month}-${date.year}`,
          DATE_FORMAT_DD_MM_YYYY,
        ).toDate(),
      );
    }
  };

  useEffect(() => {
    value
      ? setInputValue(moment(value).format('DD/MM/YYYY'))
      : setInputValue('');
  }, [value]);

  const onFocus = e => {
    e.target.placeholder = 'dd/mm/yyyy';
    setShow(true);
  };

  const onBlur = e => {
    e.target.placeholder = ' ';
  };

  return (
    <div
      className={classnames(s.date_select, error && 'error', className)}
      ref={ref}
    >
      <InputDateTextField
        name={name}
        label={label}
        mask={DATE_MASK}
        value={inputValue}
        onChange={handleInputChange}
        onClear={() => {
          setInputValue('');
          setShow(false);
          onReset();
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <div
        className={classnames(s.date_select__calendar_wrap, show && 'active')}
      >
        <DayPicker
          mode="single"
          captionLayout="dropdown"
          className="calendar"
          selected={value || initialValue}
          locale={LOCALE_MAP[i18n.language]}
          onSelect={date => {
            setShow(false);
            onChange(date);
          }}
          footer={
            <div className={s.date_select__reset_wrap}>
              <button
                type="button"
                className={classnames(s.date_select__reset, 'f-14')}
                onClick={() => {
                  setShow(false);
                  onReset();
                }}
              >
                {t('form_date_reset')}
              </button>
            </div>
          }
        />
      </div>
    </div>
  );
};
