import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Analytics from '../../analyticsGA';
import { QuestionsList } from './list';
import { NewQuestion } from './new';
import s from './index.module.scss';

const ClientQuestionsModule = () => {
  const { t } = useTranslation('client_questions');
  useEffect(() => {
    Analytics.pageNavigation('Investors questions');
  }, []);

  return (
    <div className={s.wrapper}>
      <div className="container-inner">
        <h1 className="h1">{t('title')}</h1>
        <p className={classnames(s.description)}>
          {t('description')}
        </p>
        <QuestionsList />
        <NewQuestion />
      </div>
    </div>
  );
};

export default ClientQuestionsModule;
