import { useTranslation } from 'react-i18next';
import { RoundButton } from '@/components/UI/RoundButton';
import './index.scss';

const StandardConfirm = ({ onAccept, onDecline, onClose }) => {
  const { t } = useTranslation('modal');
  return (
    <div className="standard-confirm">
      <div className="standard-confirm__title f-42 f-500">
        {t('standard_confirm.are_you_sure')}
      </div>
      <div className="standard-confirm__buttons">
        <RoundButton
          type="button"
          label={t('standard_confirm.yes')}
          fullWidth
          fillBackground
          onClick={onAccept}
          className="swap-confirm__accept"
        />
        <RoundButton
          type="button"
          label={t('standard_confirm.no')}
          fullWidth
          className="swap-confirm__reject"
          onClick={() => {
            onDecline ? onDecline() : onClose();
          }}
        />
      </div>
    </div>
  );
};

export default StandardConfirm;
