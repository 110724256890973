import axios from '@/axios-api';
import Pathes from '@/helpers/pathes';
import Notify from '@/components/Notification';
import i18n from '@/i18n';

const { t } = i18n;

export const uploadPhoto = fileObj => {
  let file;
  if (fileObj instanceof FormData) {
    file = fileObj;
  } else {
    file = new FormData();
    file.append('file', fileObj);
  }

  return axios
    .post(Pathes.Common.photo, file)
    .then(response => {
      const { data, status } = response;
      if (status === 201) {
        return { data, success: true };
      }
      Notify.error({ text: t('kit:notify.not_upload_file') });
      throw new Error(t('kit:notify.not_upload_file'));
    })
    .catch(e => ({ error: e.message, success: false }));
};

export const uploadFile = fileObj => {
  let file;
  if (fileObj instanceof FormData) {
    file = fileObj;
  } else {
    file = new FormData();
    file.append('file', fileObj);
  }

  return axios
    .post(Pathes.Common.file, file)
    .then(
      response =>
        response &&
        response.status === 201 && { data: response.data, success: true },
    )
    .catch(() => Notify.error({ text: t('kit:notify.not_upload_file') }));
};
