import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { LOAN_GROUPS, LOAN_GROUPS_IDS } from '@/helpers/constants';
import s from './index.module.scss';

export const Filter = ({ group, onClick }) => {
  const { t } = useTranslation('loans');
  const { t: tConstants } = useTranslation('constants');
  const { loansByFundraisingID } = useSelector(state => state.loanStore);

  const loans = loansByFundraisingID.data || [];

  if (loans.length < 2) {
    return null;
  }

  const LOAN_GROUPS_T = LOAN_GROUPS(tConstants);
  return (
    <div className={s.wrapper}>
      <h2 className="f-26 f-500">{t('loan_info.more_details_about')}</h2>
      <ul className={s.list}>
        <li
          onClick={() => onClick()}
          className={classnames(s.item, !group && 'active')}
        >
          {t('loan_info.all_loan_groups')}
        </li>
        <li
          onClick={() => onClick(LOAN_GROUPS_IDS.conservative.id)}
          className={classnames(
            s.item,
            LOAN_GROUPS_IDS.conservative.id === group && 'active',
          )}
        >
          {LOAN_GROUPS_T.conservative.full_name}
        </li>
        <li
          onClick={() => onClick(LOAN_GROUPS_IDS.moderate.id)}
          className={classnames(
            s.item,
            LOAN_GROUPS_IDS.moderate.id === group && 'active',
          )}
        >
          {LOAN_GROUPS_T.moderate.full_name}
        </li>
      </ul>
    </div>
  );
};
