import { Trans, useTranslation } from 'react-i18next';
import s from './index.module.scss';

const EmailOtpCodeSentModal = ({ email }) => {
  const { t } = useTranslation('modal');
  return (
    <div className={s.wrapper}>
      <h1 className="f-18-26 f-500">
        {t('email_otp_modal.code_sent_to_your_email')}
      </h1>
      <div>
        <Trans
          ns="modal"
          i18nKey="email_otp_modal.new_code_sent"
          values={{ email }}
          components={{ span: <span className="f-500" /> }}
        />
      </div>
      <br />
      <div>{t('email_otp_modal.check_inbox_and_follow_instructions')}</div>
    </div>
  );
};

export default EmailOtpCodeSentModal;
