import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconTrash from '@/assets/icons/icon_trash_red_22.svg?react';
import IconEdit from '@/assets/icons/icon_edit_22.svg?react';
import { removeBankDetail } from '@/store/services/fundServices';
import { getBankDetailsList } from '@/store/actions/fundActions';
import { RoundLink } from '@/components/UI/RoundLink';
import { APP_LINKS } from '@/helpers/links';
import { Tooltip } from '@/kit/Tooltip';
import { setModals } from '@/store/actions/commonActions';
import { MODAL_TYPES } from '@/components/ModalProvider';
import Analytics from '@/analyticsGA';
import s from './index.module.scss';

const STATUSES_T = t => ({
  pending: t('statuses.pending'),
  approved: t('statuses.approved'),
  rejected: t('statuses.rejected'),
  deleted: t('statuses.deleted'),
});

export const BankDetailsTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('bank_details');
  const { bankDetailsList } = useSelector(state => state.fundStore);
  const isEmpty = !bankDetailsList?.length;
  const STATUSES = STATUSES_T(t);

  useEffect(() => {
    dispatch(getBankDetailsList());
  }, []);

  const onRemove = async ({ id }) => {
    dispatch(
      setModals({
        type: MODAL_TYPES.confirm_modal,
        onDelete: async () => {
          await removeBankDetail(id);
          dispatch(getBankDetailsList());
          dispatch(setModals([]));
        },
        title: t('confirm_delete_bank_account_title'),
        desc: t('confirm_delete_bank_account_desc'),
      }),
    );
  };

  return (
    <>
      <div className={s.wrapper}>
        <h1 className="f-26 f-500">{t('current_bank_accounts')}</h1>
        {isEmpty ? (
          <div className={classnames(s.empty, 'f-20')}>
            {t('no_bank_accounts')}
          </div>
        ) : (
          <div className={s.table__wrapper}>
            <div className={s.table__inner}>
              <div className={s.table}>
                <div className={s.table__thead}>
                  <div className={s.table__thead_col}>{t('bank_account')}</div>
                  <div className={s.table__thead_col}>{t('status')}</div>
                  <div className={s.table__thead_col}>{t('actions')}</div>
                </div>

                <div className={s.table__tbody}>
                  {bankDetailsList.map((item, index) => (
                    <div className={s.table__tbody_row} key={item.id || index}>
                      <div className={s.table__tbody_col}>
                        {item.account_number}
                      </div>
                      <div className={s.table__tbody_col}>
                        <div className={s.status}>
                          <span className={item.review_status}>
                            {STATUSES[item.review_status]}
                          </span>
                          {item.review_status === 'rejected' && (
                            <Tooltip
                              renderContent={() => (
                                <div
                                  className={classnames(
                                    s.status_tooltip,
                                    'f-16 f-400',
                                  )}
                                >
                                  {item.rejected_reason}
                                </div>
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div className={s.table__tbody_col}>
                        <div className={s.controls}>
                          <Link
                            to={APP_LINKS.bankAccountsEdit(item.id)}
                            className={classnames(
                              s.button,
                              s.button_edit,
                              !['rejected', 'pending'].includes(
                                item.review_status,
                              ) && s.button_hidden,
                            )}
                            disabled={
                              !['rejected', 'pending'].includes(
                                item.review_status,
                              )
                            }
                          >
                            <IconEdit />
                            <span>{t('edit')}</span>
                          </Link>
                          <button
                            type="button"
                            onClick={() => onRemove(item)}
                            className={classnames(s.button, s.button_remove)}
                          >
                            <IconTrash />
                            <span>{t('delete')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <RoundLink
        type="button"
        path={APP_LINKS.bankAccountsCreate}
        className={s.button_add}
        onClick={() => Analytics.withdrawAddNewBankAccount()}
        label={t('add_new_bank_account')}
      />
    </>
  );
};
