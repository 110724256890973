import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getUserProfile,
  getVeriffStatus,
} from '@/store/actions/profileActions';
import { getUserLocation, logoutUser } from '@/store/actions/userActions';
import { getInvestingStatus } from '@/store/actions/investActions';
import ProfileHeader from '@/containers/ProfileModule/ProfileHeader';
import { RoundButton } from '@/components/UI/RoundButton';
import {
  EMPTY_DASH,
  FUNDS_ORIGIN as FUNDS_ORIGIN_T,
  PLANNED_MONTHLY_INVESTMENT,
  USER_STATUSES,
  VERIFF_CODES,
} from '@/helpers/constants';
import { INVESTOR_TYPES as INVESTOR_TYPES_T } from '@/components/Forms/InvestorTypeForm';
import { VerifyForm, SOURCES } from '@/components/VerifyForm';
import { APP_LINKS } from '@/helpers/links';
import { MODAL_TYPES } from '@/components/ModalProvider';
import { setModals } from '@/store/actions/commonActions';
import WarningBlock from '@/components/UI/WarningBlock';
import { WarningIcon, SuccessIcon } from '@/components/UI/Icons';
import { SuspendResidenceWarning } from '@/components/Warnings/SuspendResidenceWarning';
import { sendVerificationCode } from '@/store/services/userServices';
import Analytics from '@/analyticsGA';
import { isPassedVeriff, isVerifiedPhoneNumber } from '@/helpers/user';
import { SET_VERIFF_STATUS_CODE } from '@/store/types/profileTypes.js';
import './index.scss';

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('profile');
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { profile, veriffStatusCode } = useSelector(
    state => state.profileStore,
  );
  const { data: user } = profile;

  const FUNDS_ORIGIN = FUNDS_ORIGIN_T(t);
  const INVESTOR_TYPES = INVESTOR_TYPES_T(t);

  const preSavedForms = useRef({});

  useEffect(() => {
    dispatch(getUserProfile()).then(() => {
      if (hash) {
        const el = document.getElementById(hash.replace('#', ''));
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
    dispatch(getUserLocation());
    dispatch(getInvestingStatus());
    dispatch(getVeriffStatus());
    Analytics.pageNavigation('Profile');
  }, []);

  useEffect(() => {
    const chat = document.getElementById('tidio-chat');
    if (chat) {
      chat.style.display = 'none';
    }
    return () => {
      if (chat) {
        chat.style.display = 'unset';
      }
    };
  }, []);

  const onLogout = () =>
    dispatch(logoutUser(APP_LINKS.profile.replace('/', '')));

  const onAvatarEdit = () =>
    dispatch(setModals({ type: MODAL_TYPES.profile_avatar }));

  const onProfileEdit = () =>
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_detail,
        preSaved: preSavedForms.current[MODAL_TYPES.profile_detail],
        onOutsideClick: formValues => {
          preSavedForms.current[MODAL_TYPES.profile_detail] = formValues;
        },
      }),
    );
  const onPersonalEdit = () =>
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_personal,
        preSaved: preSavedForms.current[MODAL_TYPES.profile_personal],
        onOutsideClick: formValues => {
          preSavedForms.current[MODAL_TYPES.profile_personal] = formValues;
        },
      }),
    );

  const onInvestorTypeEdit = () =>
    dispatch(
      setModals({
        type: MODAL_TYPES.investor_type,
        preSaved: preSavedForms.current[MODAL_TYPES.investor_type],
        onOutsideClick: formValues => {
          preSavedForms.current[MODAL_TYPES.investor_type] = formValues;
        },
      }),
    );

  const onPhoneVerify = async () => {
    await sendVerificationCode(user?.phone);
    dispatch(
      setModals({
        type: MODAL_TYPES.phone_verification,
      }),
    );
  };

  return (
    <div className="profile_page">
      <div className="container-inner">
        <ProfileHeader onPhotoChange={onAvatarEdit} />

        {user?.status === USER_STATUSES.suspended ? (
          <SuspendResidenceWarning className="profile_page__suspend" />
        ) : (
          !isPassedVeriff(user) && (
            <WarningBlock className="profile_page__warning" withBorder>
              <div className="f-16 f-400">
                {t('provide_details_to_start_investing')}
              </div>
            </WarningBlock>
          )
        )}

        <section className="profile_page__details">
          <div className="profile_page__section_header">
            <h2 className="f-26 f-500">{t('profile_details')}</h2>
          </div>

          <div className="profile_page__box">
            <div className="profile_page__box_col">
              <div className="profile_page__first_name">
                <div className="f-14 f-400">{t('first_name')}</div>
                <div className="f-16 f-400">
                  {user?.first_name || EMPTY_DASH}
                </div>
              </div>
              <div className="profile_page__last_name">
                <div className="f-14 f-400">{t('last_name')}</div>
                <div className="f-16 f-400">
                  {user?.last_name || EMPTY_DASH}
                </div>
              </div>
            </div>
            <div className="profile_page__box_col">
              <div className="profile_page__company">
                <div className="f-14 f-400">{t('company')}</div>
                <div className="f-16 f-400">
                  {user?.investor?.company_name || '-'}
                </div>
              </div>
              <div className="profile_page__position">
                <div className="f-14 f-400">{t('position')}</div>
                <div className="f-16 f-400">
                  {user?.investor?.position || EMPTY_DASH}
                </div>
              </div>
            </div>
            <div className="profile_page__box_col">
              {user && (
                <RoundButton
                  label={t('edit_profile_details')}
                  onClick={onProfileEdit}
                  fullWidth
                  className="profile_page__edit"
                />
              )}
            </div>
          </div>
        </section>

        <section className="profile_page__personal" id="phone_verification">
          <div className="profile_page__section_header">
            <h2 className="f-26 f-500">{t('personal_information')}</h2>
          </div>

          <div className="profile_page__box">
            <div className="profile_page__box_col">
              <div className="profile_page__phone">
                <div className="f-14 f-400">{t('phone_number')}</div>
                <div className="profile_page__phone_value">
                  <div className="f-16 f-400">{user?.phone || '-'}</div>
                  {isVerifiedPhoneNumber(user) && (
                    <SuccessIcon className="profile_page__phone_verified" />
                  )}
                  {user?.phone &&
                    !isVerifiedPhoneNumber(user) &&
                    !['RU', 'BY'].includes(user?.country_code) && (
                      <RoundButton
                        className="profile_page__phone_verify"
                        label={() => t('verify')}
                        fillBackground
                        onClick={onPhoneVerify}
                      />
                    )}
                </div>
              </div>
              <div className="profile_page__citizenship">
                <div className="f-14 f-400">{t('citizenship')}</div>
                <div className="f-16 f-400">
                  {user?.citizenship || EMPTY_DASH}
                </div>
              </div>
              <div className="profile_page__funds_origin">
                <div className="f-14 f-400">{t('origin_of_funds')}</div>
                <div className="f-16 f-400">
                  {FUNDS_ORIGIN[user?.investor?.origin_of_funds] || EMPTY_DASH}
                </div>
              </div>
              <div className="profile_page__investment">
                <div className="f-14 f-400">
                  {t('planned_monthly_investment')}
                </div>
                <div className="f-16 f-400">
                  {PLANNED_MONTHLY_INVESTMENT[
                    user?.investor?.monthly_investment
                  ] || EMPTY_DASH}
                </div>
              </div>
            </div>
            <div className="profile_page__box_col">
              <div className="profile_page__country">
                <div className="f-14 f-400">{t('residence_country')}</div>
                <div className="f-16 f-400">{user?.country || EMPTY_DASH}</div>
              </div>
              <div className="profile_page__city">
                <div className="f-14 f-400">{t('residence_city')}</div>
                <div className="f-16 f-400">{user?.city || EMPTY_DASH}</div>
              </div>
              <div className="profile_page__address">
                <div className="f-14 f-400">{t('residence_address')}</div>
                <div className="f-16 f-400">{user?.address || EMPTY_DASH}</div>
              </div>
              <div className="profile_page__postal">
                <div className="f-14 f-400">{t('residence_postal_code')}</div>
                <div className="f-16 f-400">
                  {user?.postal_code || EMPTY_DASH}
                </div>
              </div>
            </div>
            <div className="profile_page__box_col">
              {user && (
                <RoundButton
                  label={t('edit_personal_information')}
                  onClick={onPersonalEdit}
                  fullWidth
                  className="profile_page__edit"
                />
              )}
            </div>
          </div>
        </section>

        {user?.country_code === 'GB' && (
          <section className="profile_page__investor_type">
            <div className="profile_page__section_header">
              <h2 className="f-26 f-500">{t('type_of_investor')}</h2>
            </div>

            <div className="profile_page__investor_type_container">
              <div className="profile_page__investor_type_content">
                {user.investor.investor_type ? (
                  <>
                    <h2 style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                      {INVESTOR_TYPES[user.investor.investor_type].title}
                    </h2>
                    <p className="f-16 f-400">
                      {INVESTOR_TYPES[user.investor.investor_type].description}
                    </p>
                  </>
                ) : (
                  <div className="profile_page__investor_type_empty">
                    {t('select_your_type_of_investor')}
                  </div>
                )}
              </div>
              <div className="profile_page__investor_type_edit">
                <RoundButton
                  label={t('edit')}
                  fullWidth
                  onClick={onInvestorTypeEdit}
                  className="profile_page__edit"
                />
              </div>
            </div>
          </section>
        )}

        {user && user.status !== USER_STATUSES.suspended && (
          <section className="profile_page__veriff">
            <div className="profile_page__section_header">
              <h2 className="f-26 f-500">
                {veriffStatusCode === VERIFF_CODES.verified
                  ? t('identity_verification')
                  : t('verify_your_identity')}
              </h2>
              {veriffStatusCode !== VERIFF_CODES.verified && <WarningIcon />}
            </div>
            <div className="profile_page__box">
              <div className="profile_page__box_col">
                {veriffStatusCode === VERIFF_CODES.verified ? (
                  <div className="profile_page__veriff_status">
                    <div className="f-14 f-400">{t('status')}</div>
                    <div className="f-16 f-400">
                      {t('identity_verified_successfully')}
                    </div>
                  </div>
                ) : VERIFF_CODES.submitted === veriffStatusCode ? (
                  <div className="profile_page__veriff_status">
                    <div className="f-14 f-400">{t('status')}</div>
                    <div className="f-16 f-400">
                      {t('identity_being_verified')}
                    </div>
                  </div>
                ) : (
                  <div className="profile_page__veriff_form">
                    <VerifyForm
                      source={SOURCES.profile}
                      onSuccess={() => {
                        dispatch({
                          type: SET_VERIFF_STATUS_CODE,
                          code: VERIFF_CODES.submitted,
                        });
                        navigate(APP_LINKS.dashboard);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
        )}

        <section className="profile_page__buttons">
          <RoundButton
            label={t('log_out')}
            onClick={onLogout}
            fullWidth
            className="profile_page__logout"
          />
        </section>
      </div>
    </div>
  );
};

export default ProfilePage;
