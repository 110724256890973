import { useRef } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { InputTextField } from '@/components/UI/InputTextField';
import { PhoneNumberField } from '@/components/UI/PhoneNumberField';
import { CountrySelectField } from '@/components/UI/CountrySelectField';
import { RoundButton } from '@/components/UI/RoundButton';
import SelectField from '@/components/UI/SelectField';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { FUNDS_ORIGIN, PLANNED_MONTHLY_INVESTMENT } from '@/helpers/constants';
import './index.scss';

const VALIDATION_SCHEMA = t =>
  Yup.object({
    phone: Yup.number(),
    citizenship: Yup.string(),
    origin_of_funds: Yup.string(),
    planned_monthly_investment: Yup.string(),
    country: Yup.string(),
    city: Yup.string(),
    address: Yup.string().max(
      255,
      t('profile_contacts_form.address_exceeds_limit'),
    ),
    postal_code: Yup.string()
      .min(4, t('profile_contacts_form.invalid_postal_code'))
      .max(10, t('profile_contacts_form.invalid_postal_code')),
    is_valid_phone: Yup.bool().when('phone', {
      is: phone => !!phone,
      then: () =>
        Yup.bool()
          .oneOf([true], t('profile_contacts_form.invalid_phone_number'))
          .required(),
    }),
  });

const ProfileContactsForm = ({
  location,
  user,
  preSaved,
  onSubmit,
  onCancel,
  onOutsideClick,
}) => {
  const ref = useRef();
  const { t } = useTranslation('modal');

  const getInitialValues = (user, location) => ({
    phone: (user && user.phone) || '',
    is_valid_phone: true,
    citizenship: (user && user.citizenship) || '',
    citizenship_code: (user && user.citizenship_code) || '',
    origin_of_funds: (user && user.investor.origin_of_funds) || '',
    planned_monthly_investment:
      (user && user.investor.monthly_investment) || '',
    country: (user && user.country) || (location && location.country) || '',
    country_code:
      (user && user.country_code) || (location && location.countryCode) || '',
    city: (user && user.city) || (location && location.city) || '',
    address: (user && user.address) || '',
    postal_code: (user && user.postal_code) || '',
  });

  return (
    <Formik
      enableReinitialize
      initialValues={preSaved || getInitialValues(user, location)}
      validationSchema={VALIDATION_SCHEMA(t)}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => {
        useOutsideClick(
          ref,
          () => onOutsideClick && onOutsideClick(values),
          !!onOutsideClick,
        );

        return (
          <form
            className="profile_contacts_form"
            onSubmit={handleSubmit}
            ref={ref}
          >
            <h1 className="profile_contacts_form__title f-32 f-500">
              {t('profile_contacts_form.edit_personal_information')}
            </h1>
            <p className="profile_contacts_form__subTitle">
              {t('profile_contacts_form.confidential_information')}
            </p>
            <div className="profile_contacts_form__grid">
              <div className="profile_contacts_form__grid_left">
                <CountrySelectField
                  label={t('profile_contacts_form.citizenship')}
                  name="citizenship"
                  value={values.citizenship_code}
                  onChange={(country, code) => {
                    setFieldValue('citizenship', country);
                    setFieldValue('citizenship_code', code);
                  }}
                  error={
                    errors.citizenship &&
                    touched.citizenship &&
                    errors.citizenship
                  }
                  className="profile_contacts_form__field"
                />
                <PhoneNumberField
                  label={t('profile_contacts_form.phone_number')}
                  name="phone"
                  value={values.phone}
                  countryCode={values.country_code}
                  onChange={phone => setFieldValue('phone', phone)}
                  onClear={() => setFieldValue('phone', '')}
                  error={
                    (errors.phone || errors.is_valid_phone) &&
                    (touched.phone || touched.is_valid_phone) &&
                    (errors.phone || errors.is_valid_phone)
                  }
                  containerID="phone-input"
                  isValid={values.is_valid_phone}
                  formikBag={{ setFieldValue, touched }}
                  className="profile_contacts_form__field"
                />
              </div>
              <div className="profile_contacts_form__grid_right">
                <SelectField
                  label={t('profile_contacts_form.origin_of_funds')}
                  name="origin_of_funds"
                  value={values.origin_of_funds || ''}
                  onChange={handleChange}
                  error={
                    errors.origin_of_funds &&
                    touched.origin_of_funds &&
                    errors.origin_of_funds
                  }
                  className="profile_contacts_form__row"
                  options={Object.keys(FUNDS_ORIGIN).map(key => ({
                    label: FUNDS_ORIGIN[key],
                    value: key,
                  }))}
                />
                <SelectField
                  label={t('profile_contacts_form.planned_monthly_investment')}
                  name="planned_monthly_investment"
                  value={values.planned_monthly_investment || ''}
                  onChange={handleChange}
                  error={
                    errors.planned_monthly_investment &&
                    touched.planned_monthly_investment &&
                    errors.planned_monthly_investment
                  }
                  className="profile_contacts_form__row"
                  options={Object.keys(PLANNED_MONTHLY_INVESTMENT).map(key => ({
                    label: PLANNED_MONTHLY_INVESTMENT[key],
                    value: key,
                  }))}
                />
              </div>
            </div>

            <h2 className="profile_contacts_form__h2 f-20 f-500">
              {t('profile_contacts_form.residence_address')}
            </h2>
            <div className="profile_contacts_form__grid">
              <div className="profile_contacts_form__grid_left">
                <CountrySelectField
                  label={t('profile_contacts_form.residence_country')}
                  name="country"
                  value={values.country_code}
                  onChange={(country, code) => {
                    setFieldValue('country', country);
                    setFieldValue('country_code', code);
                  }}
                  error={errors.country && touched.country && errors.country}
                  className="profile_contacts_form__field"
                />
                <InputTextField
                  label={t('profile_contacts_form.residence_city')}
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  onClear={() => setFieldValue('city', '')}
                  error={errors.city && touched.city && errors.city}
                  className="profile_contacts_form__field"
                />
              </div>
              <div className="profile_contacts_form__grid_right">
                <InputTextField
                  label={t('profile_contacts_form.residence_address')}
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  onClear={() => setFieldValue('address', '')}
                  error={errors.address && touched.address && errors.address}
                  className="profile_contacts_form__field"
                />
                <InputTextField
                  label={t('profile_contacts_form.residence_postal_code')}
                  name="postal_code"
                  value={values.postal_code}
                  onChange={handleChange}
                  onClear={() => setFieldValue('postal_code', '')}
                  error={
                    errors.postal_code &&
                    touched.postal_code &&
                    errors.postal_code
                  }
                  className="profile_contacts_form__field"
                />
              </div>
            </div>

            <div className="profile_contacts_form__buttons">
              {onCancel && (
                <RoundButton
                  label={t('profile_contacts_form.cancel')}
                  type="button"
                  fullWidth
                  onClick={onCancel}
                  className="profile_contacts_form__button"
                />
              )}
              <RoundButton
                label={t('profile_contacts_form.save')}
                type="submit"
                onSubmit={handleSubmit}
                fillBackground
                fullWidth
                className="profile_contacts_form__button"
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProfileContactsForm;
