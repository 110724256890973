import { components } from 'react-select';
import s from './index.module.scss';

export const SingleValueCountry = props => {
  const { data: country } = props;
  return (
    <components.SingleValue {...props}>
      <div className={s.country}>
        <div className={s.country_flag}>
          <img src={country.flag} alt={country.label} />
        </div>
        <div className={s.country_name}>{country.label}</div>
      </div>
    </components.SingleValue>
  );
};
