import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import AffiliateSteps from '@/components/AffiliateSteps';
import { RoundButton } from '@/components/UI/RoundButton';
import { InputTextField } from '@/components/UI/InputTextField';
import ReferralsModule from '@/containers/ReferralsModule';
import MiniFaqs, { MINIFAQ_SOURCES } from '@/containers/MiniFaqs';
import {
  acceptPartnerTermsOfUse,
  getReferralCode,
  inviteReferralByEmail,
} from '@/store/services/referralService';
import config from '@/config';
import { APP_LINKS } from '@/helpers/links';
import ShareButtons from '@/components/ShareButtons';
import { setModals } from '@/store/actions/commonActions';
import { MODAL_TYPES } from '@/components/ModalProvider';
import { CopyRoundButton } from '@/components/UI/CopyRoundButton';
import { BonusInviteFriends } from '@/components/BonusInviteFriend';
import { MyLink } from '@/components/MyLink';
import { getUserProfile } from '@/store/actions/profileActions';
import { SuspendResidenceWarning } from '@/components/Warnings/SuspendResidenceWarning';
import { USER_STATUSES } from '@/helpers/constants';
import Analytics from '@/analyticsGA';
import './index.scss';

export const ACCEPT_PARTNER_TERMS_SOURCES = {
  copyButton: 1,
  sendEmail: 2,
  copyByMouse: 3,
  ctrlCopyPress: 4,
  shareBySocial: 5,
};

const AffiliatePage = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState(null);
  const profile = useSelector(state => state.profileStore.profile.data);
  const { referralCode } = useSelector(state => state.referralStore);
  const { t } = useTranslation('affiliate');
  const isActiveReferralBanner = !!referralCode.data?.is_referral_banner_active;

  useEffect(() => {
    dispatch(getUserProfile());
    getReferralCode().then(res => res && res.success && setCode(res.data));
    Analytics.pageNavigation('Invite Friends');
  }, []);

  if (!profile) {
    return null;
  }

  if (profile?.status === USER_STATUSES.suspended) {
    return (
      <div className="referral_program_page">
        <div className="container-inner">
          <h1 className="help-page__title f-42 f-500">
            {t('referral_program')}
          </h1>
          <SuspendResidenceWarning className="referral_program_page__suspend" />
        </div>
      </div>
    );
  }

  const onPreviewClick = (values, onSubmit, errors, inviteLink) => {
    dispatch(
      setModals({
        type: MODAL_TYPES.referral_email,
        values,
        errors,
        onSubmit,
        inviteLink,
      }),
    );
  };

  const onSendEmail = (values, formikBag) => {
    const payload = {
      email: values.email,
      first_name: values.name,
    };
    inviteReferralByEmail(payload).then(r => {
      if (r && r.success) {
        formikBag.resetForm();
        dispatch(setModals([]));
        acceptPartnerTermsOfUse({
          has_accepted: true,
          source: ACCEPT_PARTNER_TERMS_SOURCES.sendEmail,
          extra_data: {},
        });
      }
    });
  };

  const inviteLink = code && config.mainPromoDomain + '?ref=' + code.code;

  const sharedText = t('shared_text');

  return (
    <div className="referral_program_page">
      <div className="container-inner">
        <BonusInviteFriends />

        <h1 className="referral_program_page__title f-42 f-500">
          {t('referral_program')}
        </h1>
        <AffiliateSteps className="referral_program_page__steps" />

        <section className="referral_program_page__how">
          <h2 className="f-26 f-500">{t('how_it_works')}</h2>
          <div className="referral_program_page__how_desc">
            {isActiveReferralBanner ? (
              <div className="f-16-24">
                <ul>
                  <li>
                    1.{' '}
                    <span className="f-500">
                      {t('referral_banner_step_1_title')}
                    </span>
                    : {t('referral_banner_step_1_description')}{' '}
                    <MyLink
                      isExternal
                      href={APP_LINKS.docAffiliateProgramScramble2024}
                      underline
                    >
                      {t('referral_banner_learn_more')}
                    </MyLink>
                  </li>
                  <li>
                    2.{' '}
                    <span className="f-500">
                      {t('referral_banner_step_2_title')}
                    </span>
                    : {t('referral_banner_step_2_description')}{' '}
                    <MyLink
                      isExternal
                      href={
                        APP_LINKS.docAffiliateProgramAdditional5Scramble2024
                      }
                      underline
                    >
                      {t('referral_banner_learn_more')}
                    </MyLink>
                  </li>
                  <li>
                    3.{' '}
                    <span className="f-500">
                      {t('referral_banner_step_3_title')}
                    </span>
                    : {t('referral_banner_step_3_description')}{' '}
                    <MyLink
                      isExternal
                      href={APP_LINKS.docScrambleFiveEuroProgram}
                      underline
                    >
                      {t('referral_banner_learn_more')}
                    </MyLink>
                  </li>
                </ul>
                <div className="f-500">{t('referral_banner_total')}</div>
              </div>
            ) : (
              <p className="f-400">{t('how_it_works_description')}</p>
            )}
            <p className="f-400">{t('copy_link_text')}</p>
          </div>
        </section>

        {code && (
          <section className="referral_program_page__share">
            <h2 className="f-26 f-500">{t('share_your_link')}</h2>
            <div className="referral_program_page__share_link">
              <div className="referral_program_page__share_link_label f-14 f-400 c-grey">
                {t('copy_link_below')}
              </div>
              <div className="referral_program_page__share_link_content">
                <div className="referral_program_page__share_link_value f-16 f-500">
                  {inviteLink}
                </div>
                <CopyRoundButton
                  text={inviteLink}
                  label={t('copy_link')}
                  successLabel={t('link_copied')}
                  onClick={() => {
                    acceptPartnerTermsOfUse({
                      has_accepted: true,
                      source: ACCEPT_PARTNER_TERMS_SOURCES.copyButton,
                      extra_data: {},
                    });
                  }}
                />
              </div>
            </div>

            <div className="referral_program_page__share_methods">
              <Formik
                onSubmit={onSendEmail}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email(t('invalid_email'))
                    .max(50, t('invalid_email'))
                    .required(t('email_is_required')),
                })}
                initialValues={{
                  name: '',
                  email: '',
                }}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="referral_program_page__share_email"
                  >
                    <div>{t('invite_by_email')}</div>
                    <InputTextField
                      label={t('share_by_email_form_name_label')}
                      onChange={handleChange}
                      value={values.name}
                      name="name"
                      className="referral_program_page__share_email_input"
                    />
                    <InputTextField
                      label={t('share_by_email_form_email_label')}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={errors && errors.email}
                      className="referral_program_page__share_email_input"
                    />
                    <div className="referral_program_page__share_email_buttons">
                      <RoundButton
                        label={t('share_by_email_form_button_preview')}
                        onClick={() =>
                          onPreviewClick(
                            values,
                            handleSubmit,
                            errors,
                            inviteLink,
                          )
                        }
                      />
                      <RoundButton
                        type="submit"
                        fullWidth
                        fillBackground
                        label={
                          isSubmitting
                            ? t('share_by_email_form_button_submitting')
                            : t('share_by_email_form_button_submit')
                        }
                        disabled={
                          isSubmitting ||
                          !values.email ||
                          (errors && errors.email)
                        }
                        onSubmit={handleSubmit}
                      />
                    </div>
                  </form>
                )}
              </Formik>
              <div className="referral_program_page__share_social">
                <div>{t('or_share_link')}</div>
                <ShareButtons
                  url={inviteLink}
                  options={{
                    facebook: {
                      quote: sharedText,
                    },
                    twitter: {
                      title: sharedText,
                    },
                    linkedin: {
                      title: sharedText,
                      source: inviteLink,
                    },
                  }}
                  onClick={() => {
                    acceptPartnerTermsOfUse({
                      has_accepted: true,
                      source: ACCEPT_PARTNER_TERMS_SOURCES.shareBySocial,
                      extra_data: {},
                    });
                  }}
                  className="referral_program_page__share_social_icons"
                />
              </div>
            </div>

            <div className="referral_program_page__share_disclaimer f-16 f-400">
              <div>
                <Trans
                  t={t}
                  i18nKey="have_read_terms_and_conditions"
                  components={{
                    Link: (
                      <MyLink
                        href={APP_LINKS.docTermsAndConditions}
                        isExternal
                      />
                    ),
                  }}
                />
              </div>
            </div>
          </section>
        )}

        <section className="referral_program_page__referrals">
          <h2 className="f-26 f-500">{t('your_referrals')}</h2>
          <ReferralsModule />
        </section>

        <MiniFaqs source={MINIFAQ_SOURCES.affiliate} />
      </div>
    </div>
  );
};

export default AffiliatePage;
