import classnames from 'classnames';
import AppleLogin from 'react-apple-login';
import config from '@/config';
import { RoundButton } from '@/ui/RoundButton';
import IconApple from './assets/icon_apple.svg';
import s from './index.module.scss';

export const AppleButton = ({ label, redirectURL, onResponse }) => (
  <AppleLogin
    clientId={config.appleAppId}
    redirectURI={redirectURL}
    usePopup
    callback={onResponse}
    scope="email name"
    responseMode="query"
    render={renderProps => (
      <RoundButton
        className={classnames(s.button, 'hov')}
        onClick={() => {
          renderProps.onClick();
        }}
      >
        <img src={IconApple} width={24} height={24} alt="Apple" />
        <span className="f-16-24 f-400">{label}</span>
      </RoundButton>
    )}
  />
);
