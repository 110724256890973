import React, { useState } from 'react';
import moment from 'moment/min/moment-with-locales';

let intervalID = null;

export const CountdownHour = ({ endTime, onFinish, className, label }) => {
  const [text, setText] = useState(null);

  React.useEffect(() => {
    initCounter(endTime);
    return () => {
      clearInterval(intervalID);
    };
  }, [endTime]);

  const secondsToDhms = seconds => {
    seconds = Number(seconds);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    if (m <= 0 && s <= 0) {
      return '';
    }

    return `${m <= 0 ? '00' : m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`;
  };

  const initCounter = endTime => {
    clearInterval(intervalID);

    const date = moment(endTime);

    intervalID = setInterval(() => {
      const now = moment();
      const timeLeft = secondsToDhms(date.diff(now, 'seconds'));

      if (timeLeft) {
        setText(label ? label(timeLeft) : timeLeft);
      } else {
        setText(null);
        onFinish();
        clearInterval(intervalID);
      }
    }, 1000);
  };

  return <span className={className}>{text}</span>;
};
