import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tippy';
import { Tippy } from '@/kit/Tippy';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getLoanEarningsByFundraisingID } from '@/store/actions/loanActions';
import { prettyDate, prettyFloatMoney } from '@/helpers/utils';
import {
  EARNINGS_STATUSES as EARNINGS_STATUSES_T,
  LOAN_GROUPS as LOAN_GROUPS_T,
  LOAN_GROUPS_IDS,
} from '@/helpers/constants';
import { InfoIcon } from '@/components/UI/Icons';
import { LOAN_EARNINGS_STATUSES } from '@/helpers/loan';
import { DATE_FORMAT_DD_MMM_YYYY } from '@/helpers/common';
import s from './index.module.scss';

export const LoanEarningsSchedule = ({ fundraisingID }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('loans');
  const { t: tConstants } = useTranslation('constants');
  const EARNINGS_STATUSES = EARNINGS_STATUSES_T(tConstants);
  const LOAN_GROUPS = LOAN_GROUPS_T(tConstants);
  const [groupId, setGroupId] = useState(null);
  const { data: loans } = useSelector(
    state => state.loanStore.loansByFundraisingID,
  );
  const { data, loading } = useSelector(
    state => state.loanStore.loanEarningsByFundraisingID,
  );

  const { data: scheduleData } = useSelector(
    state => state.loanStore.repaymentScheduleByFundraisingID,
  );

  const isPaid = useMemo(
    () =>
      !!(
        scheduleData &&
        scheduleData.list.filter(s => s.status === 'paid').length ===
          scheduleData.list.length
      ),
    [scheduleData],
  );

  const { balance } = useSelector(state => state.dashboardStore);

  useEffect(() => {
    if (groupId) {
      dispatch(getLoanEarningsByFundraisingID(fundraisingID, groupId));
    }
  }, [fundraisingID, groupId]);

  useEffect(() => {
    if (loans?.length && !groupId) {
      setGroupId(loans[0].id);
    }
  }, [loans, groupId]);

  if (!loans?.length || !data) {
    return null;
  }

  const { total_earnings, list } = data;
  const flatFeePaid = loans.find(loan => loan.id === groupId)?.loans
    ?.flat_fee_paid;
  const earningDiff = total_earnings - flatFeePaid;

  return (
    <div className={s.wrapper}>
      <h2 className="f-26 f-500">{t('loan_info.earnings_schedule')}</h2>
      <div className="f-16-24" style={{ marginTop: '20px' }}>
        {t('loan_info.track_monthly_income')}
      </div>
      <div className={s.tabs}>
        {loans?.map(item => (
          <div
            key={item.id}
            onClick={() => setGroupId(item.id)}
            className={classnames(
              s.tabs_item,
              item.id === groupId && 'active',
              'f-20 f-400',
            )}
          >
            {LOAN_GROUPS[item.id].full_name}
          </div>
        ))}
      </div>

      {!loading &&
        (data?.list.length ? (
          <>
            <div className={s.table_wrapper}>
              <div className={s.table_inner}>
                <div className={s.table}>
                  <div className={classnames(s.thead)}>
                    <div className={s.thead_col}>{t('loan_info.due_date')}</div>
                    <div className={s.thead_col}>
                      {t('loan_info.loan_balance')}
                    </div>
                    <div className={s.thead_col}>
                      {t('loan_info.earnings_percent')}
                    </div>
                    <div className={s.thead_col}>
                      {t('loan_info.earnings_currency')}
                    </div>
                    <div className={s.thead_col}>{t('loan_info.status')}</div>
                  </div>

                  <div className={s.tbody}>
                    {list.map((loan, idx) => {
                      const isLast = idx === list.length - 1;
                      return (
                        <div
                          className={classnames(s.tbody_row, loan.status)}
                          key={idx}
                        >
                          <div className={s.tbody_col}>
                            {prettyDate(loan.deadline, DATE_FORMAT_DD_MMM_YYYY)}
                          </div>
                          <div className={s.tbody_col}>
                            {prettyFloatMoney(loan.loan_balance)}
                          </div>
                          <div className={s.tbody_col}>
                            {(LOAN_EARNINGS_STATUSES.accrued === loan.status ||
                              groupId === LOAN_GROUPS_IDS.moderate.id) && (
                              <span>{loan.earnings_percent}%</span>
                            )}

                            {groupId === LOAN_GROUPS_IDS.conservative.id &&
                              LOAN_EARNINGS_STATUSES.scheduled ===
                                loan.status && (
                                <b>
                                  {t('loan_info.up_to')} {loan.earnings_percent}
                                  %{' '}
                                  {balance && (
                                    <Tippy
                                      render={(ref, open, setOpen) => (
                                        <Tooltip
                                          open={open}
                                          theme="light"
                                          position="top"
                                          trigger="click"
                                          arrow
                                          interactive
                                          html={
                                            <div
                                              ref={ref}
                                              className="f-16 f-400"
                                            >
                                              {balance.invested >= 10000 ? (
                                                <span>
                                                  {t(
                                                    'loan_info.investment_balance_10000',
                                                  )}
                                                </span>
                                              ) : (
                                                <span>
                                                  {t(
                                                    'loan_info.invest_minimum_100',
                                                  )}
                                                </span>
                                              )}
                                            </div>
                                          }
                                        >
                                          <InfoIcon
                                            onClick={() => setOpen(true)}
                                            style={{
                                              cursor: 'pointer',
                                              position: 'relative',
                                              top: '2px',
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    />
                                  )}
                                </b>
                              )}
                          </div>
                          <div className={s.tbody_col}>
                            {isPaid && earningDiff && isLast
                              ? prettyFloatMoney(
                                  loan.earnings_currency - earningDiff,
                                )
                              : prettyFloatMoney(loan.earnings_currency)}
                          </div>
                          <div className={s.tbody_col}>
                            {EARNINGS_STATUSES[loan.status]?.label}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className={s.total}>
              <div className={s.total_label}>
                {t('loan_info.total_earnings')}
              </div>
              <div className={s.total_value}>
                {isPaid && earningDiff
                  ? prettyFloatMoney(flatFeePaid)
                  : prettyFloatMoney(total_earnings)}
              </div>
            </div>
          </>
        ) : (
          <div className={s.empty_text}>{t('loan_info.no_data')}</div>
        ))}
    </div>
  );
};
