export const useGoogleEvent = (
  eventObject,
  extraParams,
  onSuccess,
  onError,
) => {
  if (eventObject) {
    const { event, event_params } = eventObject;

    try {
      // window.gtag('event', event, event_params);
      const payload = {
        event,
        ...event_params,
        ...extraParams,
      };
      window.dataLayer?.push(payload);
      onSuccess && onSuccess(event, extraParams);
      console.info(
        `GA4 Event ${window.dataLayer ? '(Prod)' : '(Dev)'} --> `,
        payload,
      );
      return event;
    } catch (e) {
      onError && onError(e);
      console.error('GA4 Event Error:', e.message);
    }
  } else {
    console.error('Event not found');
  }
};
