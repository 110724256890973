import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Tooltip } from '@/kit/new/Tooltip';
import { LOAN_GROUPS } from '@/helpers/constants';
import { prettyFloatMoney } from '@/helpers/utils';
import s from './index.module.scss';

export const RoundDetailGroups = ({ loanGroups }) => {
  const { t } = useTranslation('round_history');
  const { t: tConstants } = useTranslation('constants');

  const getStatusLabel = status => {
    switch (status) {
      case 'no_payments':
        return t('status_no_payments');
      case 'scheduled':
        return t('status_scheduled');
      case 'completed':
        return t('status_completed');
      case 'in_progress':
        return t('status_in_progress');
      default:
        return status;
    }
  };
  const LOAN_GROUPS_T = LOAN_GROUPS(tConstants);

  return (
    <div className={s.wrapper}>
      {loanGroups?.map(group => {
        const remaining = group.invested - group.repaid_back;

        return (
          <div key={group.id} className={s.group}>
            <div className={s.group__title}>
              <span className="f-18-24">
                {LOAN_GROUPS_T[group.id].full_name}
              </span>
            </div>
            <div className={s.group__info}>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip id="investing" content={t('tooltip_investing')}>
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      {t('invested')}
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  {prettyFloatMoney(group.invested)}
                </div>
              </div>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip id="brands" content={t('tooltip_brands')}>
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      {t('brands')}
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  {group.startups_count}
                </div>
              </div>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip id="Investors" content={t('tooltip_investors')}>
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      {t('investors')}
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  {group.investors_count}
                </div>
              </div>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip
                    id="Repayment status"
                    content={t('tooltip_repayment_status')}
                  >
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      {t('repayment_status')}
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  <span>{getStatusLabel(group.repayment_status)}</span>
                </div>
              </div>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip id="Repaid back" content={t('tooltip_repaid_back')}>
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      {t('repaid_back')}
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  {prettyFloatMoney(group.repaid_back)}
                </div>
              </div>
              <div className={s.group__info_row}>
                <div className={s.group__info_label}>
                  <Tooltip
                    id="Investors earnings"
                    content={t('tooltip_investors_earnings')}
                  >
                    <span className={classnames(s.group__info_text, 'f-14')}>
                      {t('investors_earnings')}
                    </span>
                  </Tooltip>
                </div>
                <div className={classnames(s.group__info_value, 'f-14')}>
                  {prettyFloatMoney(group.investors_earnings)}
                </div>
              </div>
            </div>
            <div className={s.group__progress}>
              <div className={s.group__progress_header}>
                <div className="f-18-24">{t('repayment_progress')}</div>
                <div className="f-18-24">{group.repaid_back_percentage}%</div>
              </div>
              <div className={s.group__bar}>
                <div
                  className={s.group__bar_paid}
                  style={{ width: `${group.repaid_back_percentage}%` }}
                />
              </div>

              <div className={s.group__bar_stats}>
                <div className={s.group__bar_row}>
                  <div className={s.group__bar_circle} />
                  <div className="f-12">
                    {prettyFloatMoney(group.repaid_back)}
                  </div>
                  <div className="f-12">{t('paid')}</div>
                </div>
                <div className={s.group__bar_row}>
                  <div
                    className={classnames(
                      s.group__bar_circle,
                      s.group__bar_circle_remaining,
                    )}
                  />
                  <div className="f-12">{prettyFloatMoney(remaining)}</div>
                  <div className="f-12">{t('remaining')}</div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
