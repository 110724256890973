import React from 'react';
import classnames from 'classnames';
import parseUrl from 'parse-url';
import { LINK_TYPES } from '../../helpers/constants';
import { deepLink } from './linkGenerator';

const Linker = ({ type = LINK_TYPES.web, className, value, children }) => {
  let domain = '';
  const params = {
    target: '_blank',
    rel: 'noopener noreferrer',
  };

  if (type === LINK_TYPES.phone) {
    params.href = `tel:${value}`;
  }

  if (type === LINK_TYPES.web) {
    try {
      const url = parseUrl(value);
      domain = url.resource;
      params.href =
        url.protocol === 'file' ? `https://${url.href}` : deepLink(url);
    } catch (e) {
      console.error(e.message);
    }
  }

  if (type === LINK_TYPES.mail) {
    params.href = `mailto:${value}`;
  }

  return (
    <a {...params} className={classnames('linker', 'tl', className)}>
      {children || domain || value}
    </a>
  );
};

export default Linker;
