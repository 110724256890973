import { LOAN_GROUPS_IDS } from '@/helpers/constants';

export const TERMS = {
  [LOAN_GROUPS_IDS.conservative.id]: {
    id: 'conservative',
    title: 'Group A',
    seniority: 'Senior',
    investor_annual_rate: '12%',
    annual_base_return: 6.0,
    principal_payment_start_month: 7,
    info_term: '6 months',
    success_investor_fee: 4.0,
    is_active: true,
    downside_protection: 'Triple-secured',
    flat_fee: null,
    extra_flat_fee: null,
  },
  [LOAN_GROUPS_IDS.moderate.id]: {
    id: 'moderate',
    title: 'Group B',
    seniority: 'Junior',
    investor_annual_rate: 'Up to 30%',
    annual_base_return: 6.0,
    principal_payment_start_month: 12,
    info_term: '6 months',
    success_investor_fee: 17.33,
    is_active: true,
    downside_protection: 'Single-secured',
    flat_fee: null,
    extra_flat_fee: null,
  },
  [LOAN_GROUPS_IDS.growth.id]: {
    id: 'growth',
    title: 'Group C',
    seniority: '-',
    investor_annual_rate: '15.000',
    annual_base_return: 6.0,
    principal_payment_start_month: 7,
    info_term: null,
    success_investor_fee: 26.0,
    is_active: false,
    downside_protection: '-',
    flat_fee: null,
    extra_flat_fee: null,
  },
};
