import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dropdown from '@/components/Dropdown';
import NotificationBlock from '@/containers/NotificationBlock';
import { NOTIFICATIONS as NOTIFICATIONS_T } from '@/components/Notification/list';
import InviteFriends from '@/components/InviteFriends';
import { WriteReview } from '@/components/WriteReview';
import { InvestingProgressBar } from '@/components/InvestingProgressBar';
import { useCheckPlannedPayments } from '@/components/Notification/hooks/useCheckPlannedPayments';
import { scrollTop } from '@/helpers/utils';
import { isExpiredResetDate } from '@/helpers/date';
import { getPreAllocateInfo } from '@/store/actions/fundActions';
import { ResetInvesting } from '../components/ResetInvesting';
import { STRATEGIES } from '../InvSelectStrategy';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import InvestingSummary from '../../InvestingSummary';
import { BonusBanner } from '../../BonusProgram/banner';
import PriorityFoundersGroupedList from '../InvAllocateFunds/priority';
import s from '@/components/Forms/PaymentForm/index.module.scss';
import './index.scss';

const InvReady = ({ status, onReset, setStep }) => {
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('investing');
  const dispatch = useDispatch();
  const { fundraisingDates } = useSelector(state => state.dashboardStore);
  const { hasPlannedPayment } = useCheckPlannedPayments();
  const NOTIFICATIONS = NOTIFICATIONS_T(tCommon);

  useEffect(() => {
    dispatch(getPreAllocateInfo());
    scrollTop();
  }, []);

  const notifications = [];

  isExpiredResetDate(status.editable_until)
    ? notifications.push(NOTIFICATIONS.now_you_can_add_more_funds())
    : notifications.push(
        NOTIFICATIONS.you_can_modify_investing_until(status.editable_until),
      );

  notifications.push(NOTIFICATIONS.results_will_be_processed(status.end_date));

  hasPlannedPayment && notifications.push(NOTIFICATIONS.october_weekends());

  fundraisingDates?.id === 101 &&
    fundraisingDates?.is_active &&
    notifications.push(NOTIFICATIONS.regular_top_ups());

  return (
    <div className="inv-ready">
      <h1 className="f-42 f-500">{t('irs_your_investment_settings_saved')}</h1>
      <NotificationBlock
        className="inv-ready__notifications"
        notifications={notifications}
      />

      <BonusBanner className={s.bonus} />

      <InvestingProgressBar className="inv-ready__progress" />

      <h2 className="inv-ready__summary-header f-26 f-500">
        {t('irs_your_investment_settings')}
      </h2>

      <InvestingSummary
        isReadyStep
        status={status}
        onStrategyEdit={() => {
          setStep(1);
        }}
        onLoansEdit={() => {
          setStep(2);
        }}
        className="inv-ready__summary"
      />

      <InviteFriends analyticSource="setsaved" />

      <WriteReview className="inv-ready__trustpilot" />

      <div className="inv-ready__founders">
        {status.option === STRATEGIES.standard && (
          <Dropdown title={t('consumer_goods_brands_batch')}>
            <ParticipatingStartupsList />
          </Dropdown>
        )}
        {status.option === STRATEGIES.priority && (
          <PriorityFoundersGroupedList />
        )}
      </div>

      <ResetInvesting
        status={status}
        onReset={onReset}
        className="inv-ready__reset"
      />
    </div>
  );
};

export default InvReady;
