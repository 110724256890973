import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import { ACCOUNT_TYPES, LOAN_GROUPS_IDS } from '../helpers/constants';
import { useGetAllocationStatus } from './useGetAllocationStatus';
import { XIRR } from '../helpers/xirr';
import { round } from '../helpers/utils';

export const useCalculateEarnings = () => {
  const [state, setState] = useState({
    bonusAmount: null,
    increasedIncome: null,
    apr: null,
    totalAllocated: null,
    plannedEarning: null,
    earnings: null,
  });

  const { preAllocateInfo } = useSelector(state => state.fundStore);
  const { fundraisingDates } = useSelector(state => state.dashboardStore);
  const { bonusGroup } = useSelector(state => state.profileStore);
  const { data: status } = useSelector(
    state => state.investStore.investingStatus,
  );
  const { data } = useSelector(
    state => state.investStore.allocatedRepaymentSchedule,
  );
  const { totalAllocated } = useGetAllocationStatus();

  const accounts = useMemo(
    () => (preAllocateInfo.data ? preAllocateInfo.data.accounts : []),
    [preAllocateInfo.data],
  );

  useEffect(() => {
    if (bonusGroup && status && accounts) {
      const prevBonus = status?.bonus_amount || 0;
      const amount = accounts.reduce((total, account) => {
        if (account.type === ACCOUNT_TYPES.loan) {
          if (account.group === LOAN_GROUPS_IDS.moderate.id) return total;
          total += account.amount;
        }
        return total;
      }, 0);

      const bonusAmount =
        parseInt((amount - prevBonus) / bonusGroup.investment_amount) *
        bonusGroup.bonus_amount;
      setState(prev => ({ ...prev, bonusAmount }));
    }
  }, [bonusGroup, status?.bonus_amount, accounts]);

  const calculateAPR = () => {
    if (fundraisingDates && totalAllocated && data) {
      const dates = [
        moment(fundraisingDates.end_date).add('1', 'day').format('YYYY-MM-DD'),
      ];
      const values = [0 - totalAllocated];

      data.repayment_schedule.map(item => {
        dates.push(item.date);
        values.push(item.total);
      });

      if (data.increased_income) {
        dates.push(
          data.repayment_schedule[data.repayment_schedule.length - 1].date,
        );
        values.push(data.increased_income);
      }

      if (state.bonusAmount) {
        dates.push(
          data.repayment_schedule[data.repayment_schedule.length - 1].date,
        );
        values.push(state.bonusAmount);
      }
      return round(XIRR(values, dates) * 100, { roundUpper: true });
    }
    return null;
  };

  useEffect(() => {
    if ((fundraisingDates, totalAllocated, data)) {
      const apr = calculateAPR(fundraisingDates, totalAllocated, data);
      setState(prev => ({ ...prev, apr }));
    }
  }, [fundraisingDates, totalAllocated, data, state.bonusAmount]);

  useEffect(() => {
    if (data) {
      setState(prev => ({
        ...prev,
        earnings: data.total_earnings,
        plannedEarning: data.total_earnings + data.increased_income,
      }));
    }
  }, [data]);

  return state;
};
