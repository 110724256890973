import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPrioritySelections } from '@/store/services/investingServices';
import StartupCard from '@/components/Cards/StartupCard';
import { WIZARD_REDIRECTS } from '../index';

const PriorityFoundersList = ({ selectedStrategy, setSelections }) => {
  const { t } = useTranslation('investing');

  const [state, setState] = useState({
    page: 1,
    limit: 100,
    founders: null,
  });

  useEffect(() => {
    getFounders(setSelections);
  }, []);

  const getFounders = setSelections => {
    const { page, limit } = state;
    getPrioritySelections({ page, limit }).then(res => {
      if (res && res.success) {
        setState(prevState => ({
          ...prevState,
          founders: res.data,
        }));
        setSelections &&
          setSelections(
            res.data.list.filter(founder => founder.is_selected).map(f => f.id),
          );
      }
    });
  };

  const onFounderSelect = founder => {
    try {
      const { founders } = state;
      if (founders) {
        setState(prevState => {
          const updatedList = prevState.founders.list.map(user => {
            if (user.id === founder.id) {
              return {
                ...founder,
                is_selected: !founder.is_selected,
              };
            } else return user;
          });

          setSelections &&
            setSelections(
              updatedList.filter(founder => founder.is_selected).map(f => f.id),
            );
          return {
            ...prevState,
            founders: {
              ...prevState.founders,
              list: updatedList,
            },
          };
        });
      }
    } catch (e) {
      // Do nothing
    }
  };

  const { founders } = state;
  const isEmpty = !founders || !(founders && founders.total_count);

  return (
    <>
      <h2 className="f-26 f-500">{t('which_founders_do_you_like_the_most')}</h2>
      <p className="priority-founders-list__subtitle f-16 f-400">
        {t('select_founders_who_will_be_the_first_to_receive_your_cash')}
      </p>
      {isEmpty ? (
        <div className="priority-founders-list__empty f-16 f-400">
          {t('no_brands')}
        </div>
      ) : (
        <div className="priority-founders-list">
          {founders.list.map(founder => (
            <StartupCard
              key={founder.id}
              founders={founder.co_founders}
              company={founder.company}
              fundraisingStatus={founder.fundraising_status}
              isSelected={founder.is_selected}
              onSelect={() => onFounderSelect(founder)}
              founderRedirect={`/founders/${founder.id}?to=${
                WIZARD_REDIRECTS.strategy
              }${selectedStrategy ? `&strategy=${selectedStrategy}` : ''}`}
              className="priority-founders-list__item"
              active
            />
          ))}
        </div>
      )}
    </>
  );
};

export default PriorityFoundersList;
