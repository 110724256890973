import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { STEPS } from '../index';
import s from '../index.module.scss';

export const AccountCreateStep = ({ passed }) => {
  const { t } = useTranslation('dashboard');
  const active = passed.includes(STEPS.account_create);

  return (
    <li
      id={`vpw_${STEPS.account_create}`}
      className={classnames(s.list_item, active && s.list_item_active)}
    >
      <div className={s.list_item_left}>
        <span
          className={classnames(
            s.circle,
            active && s.circle_active,
            'f-26 f-400',
          )}
        >
          01
        </span>
      </div>
      <div className={s.list_item_right}>
        <div className={classnames(s.list_item_title, 'f-26 f-400')}>
          {t('wizard_step_1_title')}
        </div>
      </div>
    </li>
  );
};
