import { useMutation } from '@tanstack/react-query';
import { referralService } from '../../services/referralService';

export const useApplyReferralCode = () => {
  return useMutation({
    mutationFn: code =>
      referralService.apply(code).then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        throw new Error(data?.message);
      }),
  });
};
