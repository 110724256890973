import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { getInactiveFounders } from '@/store/actions/founderActions';
import { Preloader } from '@/components/Preloader';
import FounderCard from '@/components/Cards/FounderCard';

const FoundersAll = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    page: 1,
    limit: 30,
  });
  const { t } = useTranslation('brands');

  const founders = useSelector(state => state.founderStore.inactiveFounders);
  const { innerWidth } = useSelector(state => state.commonStore);

  useEffect(() => {
    getFounders();
  }, []);

  const getFounders = (extraParams, isNext) => {
    const { page, limit } = state;
    dispatch(
      getInactiveFounders({ page, limit, ...extraParams }, isNext),
    ).finally(() => setState(prevState => ({ ...prevState, loading: false })));
  };

  const getNext = totalPages => {
    if (state.page < totalPages) {
      const nextPage = state.page + 1;
      getFounders({ page: nextPage }, true);
      return setState(prevState => ({ ...prevState, page: nextPage }));
    }
  };

  const { page, loading } = state;
  const haveFounders = !!(founders && founders.list.length);

  return (
    <div className={classnames('founders-all')}>
      <h2 className="f-26 f-500">
        {t('founders.other.title')} {haveFounders && `(${founders.total_count})`}
      </h2>

      <p className={classnames('founders-all__desc', 'f-16 f-400')}>
        {t('founders.other.description')}
      </p>

      {loading ? (
        <Preloader className="founders-all__preloader" />
      ) : haveFounders ? (
        <>
          <InfiniteScroll
            dataLength={Number(founders?.list.length) || 0}
            next={() => getNext(founders.total_pages)}
            hasMore={!founders || (founders && page < founders.total_pages)}
            scrollableTarget={innerWidth >= 1380 && 'main'}
            scrollThreshold={0.5}
            className="founders-all__list"
            loader={null}
          >
            {founders?.list.map(founder => (
              <FounderCard key={founder.id} founder={founder} hideFR />
            ))}
          </InfiniteScroll>
        </>
      ) : (
        <div className="founders-all__empty">
          <span className="founders-all__empty-title f-16 f-400">
            {t('founders.other.no_founders')}
          </span>
        </div>
      )}
    </div>
  );
};

export default FoundersAll;
