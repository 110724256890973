import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { CONTACT_EMAIL } from '@/helpers/constants';
import WarningBlock from '@/components/UI/WarningBlock';
import s from './index.module.scss';

export const SuspendResidenceWarning = ({ className }) => {
  const { t } = useTranslation('warning');
  const profile = useSelector(state => state.profileStore.profile.data);

  return (
    <WarningBlock withBorder className={classnames(s.wrapper, className)}>
      {['RU', 'BY'].includes(profile?.country_code) ? (
        <div className={classnames(s.text, 'f-16 f-400')}>
          <div>{t('suspend_residence.ru_by.message_1')}</div>
          <div>
            {t('suspend_residence.ru_by.message_2')}
            <MyLink href={APP_LINKS.email} underline isExternal>
              {CONTACT_EMAIL}
            </MyLink>
            {t('suspend_residence.ru_by.message_3')}
            <ul className="scr-circle-list">
              <li>{t('suspend_residence.ru_by.list_item_1')}</li>
              <li>{t('suspend_residence.ru_by.list_item_2')}</li>
              <li>{t('suspend_residence.ru_by.list_item_3')}</li>
            </ul>
          </div>
        </div>
      ) : (
        <div className={classnames(s.text, 'f-16 f-400')}>
          {t('suspend_residence.default.message')}
          <MyLink href={`mailto:${CONTACT_EMAIL}`} isExternal underline>
            {CONTACT_EMAIL}
          </MyLink>
        </div>
      )}
    </WarningBlock>
  );
};
