import { useMutation } from '@tanstack/react-query';
import { collectDetailsUTM } from '@/helpers/utm';
import { getMessage } from '@/helpers/utils';
import Notify from '@/components/Notification';
import { authService } from '../../services/authService';

export const useGoogleAuth = () => {
  return useMutation({
    mutationFn: ({ googleRes, refCode }) => {
      const payload = {
        code: '',
        access_token: googleRes.access_token,
        ...collectDetailsUTM(),
      };

      if (refCode) {
        payload.referral_code = refCode;
      }

      return authService.google(payload).then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        const message = getMessage(data);
        message && Notify.error({ text: message });
      });
    },
  });
};
