import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ImageDesktop from '../../assets/images/how_choose_startups.svg';
import ImageMobile from '../../assets/images/how_choose_startups_m.svg';
import s from './index.module.scss';

export const ChooseStartups = () => {
  const { t } = useTranslation('investing');
  const { innerWidth } = useSelector(state => state.commonStore);
  return (
    <section className={s.wrapper}>
      <h2 className="f-26 f-500">{t('how_we_choose_brands')}</h2>
      <p className={classnames(s.desc, 'f-16 f-400')}>
        {t('every_round_we_bring_our_customers')}
      </p>
      {innerWidth < 700 ? (
        <img
          src={ImageMobile}
          alt={t('choose')}
          className={classnames(s.item_image, 'mobile')}
        />
      ) : (
        <img
          src={ImageDesktop}
          alt={t('choose')}
          className={classnames(s.item_image, 'desktop')}
        />
      )}
    </section>
  );
};
