import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FileName } from '@/kit/FileName';
import { getComplaintDetail } from '@/store/actions/complaintActions';
import { prettyDate } from '@/helpers/utils';
import { Preloader } from '@/components/Preloader';
import IconReply from '../../assets/icons/icon_reply_20.svg?react';
import s from './index.module.scss';

const ComplaintDetailModal = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('modal');
  const { data: detail, loading } = useSelector(
    state => state.complaintStore.detail,
  );

  useEffect(() => {
    dispatch(getComplaintDetail(id));
  }, []);

  return (
    <div className={s.wrapper}>
      {loading || !detail ? (
        <Preloader className={s.preloader} />
      ) : (
        <>
          <h1 className="f-26 f-500">
            {t('company_detail_form.complaint')} #{detail.id}
          </h1>
          <div className={s.content}>
            <div className={s.left}>
              <div className={s.group}>
                <div className={s.subtitle}>
                  {t('company_detail_form.round_number')}
                </div>
                <div className={s.value}>{detail.fundraising_name}</div>
              </div>
              <div className={s.group}>
                <div className={s.subtitle}>
                  {t('company_detail_form.created_date')}
                </div>
                <div className={s.value}>{prettyDate(detail.created_at)}</div>
              </div>
              <div className={s.group}>
                <div className={s.subtitle}>
                  {t('company_detail_form.uploaded_files')}
                </div>
                <div className={s.files}>
                  {detail.files.map(file => (
                    <FileName className={s.file} key={file.id} file={file} />
                  ))}
                </div>
              </div>
            </div>
            <div className={s.right}>
              <div className={s.complaint}>
                <div className={s.complaint_title}>
                  {t('company_detail_form.description')}
                </div>
                <div className={s.complaint_text}>{detail.description}</div>
              </div>
              {detail.answer && (
                <div className={s.reply}>
                  <IconReply className={s.reply_icon} />
                  <div className={s.reply_title}>
                    {t('company_detail_form.reply_from', {
                      from: detail.answer.from,
                    })}
                  </div>
                  <div className={s.reply_text}>{detail.answer.answer}</div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ComplaintDetailModal;
