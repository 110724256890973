import classnames from 'classnames';
import { FileName } from '../../../FileName';
import { FileUploadField } from '@/components/UI/FileUploadField';
import { ALLOWED_FILE_SIZES, ALL_FILE_FORMATS } from '@/helpers/common';
import { checkForValidFile } from '@/helpers/utils';
import { Tooltip } from '../../../Tooltip';
import s from './index.module.scss';

export const UploadFiles = ({
  name,
  label = 'Upload files',
  value = [],
  error,
  isRequired = false,
  multiple = true,
  accept = ALL_FILE_FORMATS,
  tooltip,
  onBlur,
  onChange,
  onError,
  onRemove,
}) => {
  return (
    <div className={s.wrapper}>
      <div className={classnames(s.label, 'f-16')}>
        {label} {isRequired && <span style={{ color: '#D80027' }}>*</span>}
        {tooltip && <Tooltip renderContent={() => tooltip} />}
      </div>

      <FileUploadField
        name={name}
        multiple={multiple}
        label="Upload up to 5 files"
        description="Upload file up to 10Mb"
        accept={accept}
        error={!!error}
        className={s.upload}
        onBlur={onBlur}
        onChange={e => {
          const files = Object.keys(e.target.files).map(
            idx => e.target.files[idx],
          );
          const validFiles = files.filter(file => {
            const { isValid, type, size } = checkForValidFile(
              file,
              accept,
              ALLOWED_FILE_SIZES['10mb'],
            );

            !isValid && size && onError('File size is too big');
            !isValid &&
              type &&
              onError('Incorrect file type. Upload file in PDF format.');

            return isValid;
          });
          onChange(validFiles);
        }}
      />

      {!!value.length && (
        <div className={s.files}>
          {value.map((file, index) => (
            <FileName key={index} file={file} onRemove={onRemove} />
          ))}
        </div>
      )}
    </div>
  );
};
