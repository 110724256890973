import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFlagURL, prettyMoney } from '../../../../helpers/utils';
import s from './index.module.scss';

export const RoundDetailCountries = ({ data }) => {
  const { t } = useTranslation('round_history');

  const countries = data ? [...data.countries] : [];
  const countryList = countries.splice(0, 5);

  if (!data?.total_participated_investors) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <div className="single_s">{t('top_active_countries')}</div>
      <div className={s.content}>
        <div className={s.countries}>
          {countryList.map((country, index) => (
            <PlaceCountry
              key={country.country_code}
              place={index}
              country={country}
            />
          ))}
        </div>
        <OtherCountries
          countries={countries}
          totalAmount={data.total_other_countries_amount}
        />
      </div>
    </div>
  );
};

const PlaceCountry = ({ place, country }) => (
  <div className={s.country__wrapper}>
    <div className={s.country__flag}>
      <img
        src={getFlagURL(country.country_code)}
        width={40}
        height={40}
        alt={country.country_name}
      />
      <div className={s.country__place}>0{place + 1}</div>
    </div>
    <div className={s.country__content}>
      <div className="text_m">{country.country_name}</div>
      <div className="single_s">{prettyMoney(country.allocated_amount)}</div>
    </div>
  </div>
);

const OtherCountries = ({ countries, totalAmount }) => {
  const { t } = useTranslation('round_history');

  return (countries.length ? (
    <div className={s.other}>
      <div className={s.other_flags}>
        {countries.map((country, index) => {
          if (countries.length === 3 || index <= 1) {
            return (
              <img
                key={country.country_code}
                src={getFlagURL(country.country_code)}
                width={40}
                height={40}
                alt={country.country_name}
              />
            );
          }
        })}
        {countries.length > 3 && (
          <span className={s.other_more}>+{countries.length - 2}</span>
        )}
      </div>
      <div className={s.other_info}>
        <div className="text_m">{t('other_countries')}</div>
        <div className="single_s">{prettyMoney(totalAmount)}</div>
      </div>
    </div>
  ) : null);
};
