import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { APP_LINKS } from '@/helpers/links';
import { prettyFloatMoney } from '@/helpers/utils';
import WarningBlock from '@/components/UI/WarningBlock';
import { RoundLink } from '@/components/UI/RoundLink';
import { roundStarts } from '@/helpers/date';
import s from './index.module.scss';

export const PaymentSuccess = ({ amount }) => {
  const { t } = useTranslation('withdraw');
  const { data: status } = useSelector(
    state => state.investStore.investingStatus,
  );

  return (
    <div className={s.wrapper}>
      <h1 className={classnames(s.title, 'f-26 f-500')}>
        {t('payment_successful')}
      </h1>
      <WarningBlock withBorder className={s.message} isSuccess>
        <div className="f-16 f-400">
          <Trans
            ns="withdraw"
            i18nKey="your_payment_of_was_successfully_transferred"
            values={{ amount: prettyFloatMoney(amount) }}
            components={{ span: <span className="f-500" /> }}
          />
        </div>
      </WarningBlock>
      <p className={classnames(s.desc, 'f-16 f-500')}>{t('congratulations')}</p>
      {status &&
        (status.is_active ? (
          <p className="f-16 f-400">{t('setting_adjustments')}</p>
        ) : (
          <p className="f-16 f-400">
            {t('round_starts', { date: roundStarts(status.start_date) })}
          </p>
        ))}

      <div className={s.buttons}>
        <RoundLink
          path={APP_LINKS.investing}
          label={t('go_to_investing')}
          fillBackground
          className={s.button}
        />
        <RoundLink
          path={APP_LINKS.addFunds}
          label={t('add_more_cash')}
          className={s.button}
        />
      </div>
    </div>
  );
};
