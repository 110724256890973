import moment from 'moment/min/moment-with-locales';
import { Trans, useTranslation } from 'react-i18next';
import { prettyFloatMoney } from '@/helpers/utils';
import { DATE_FORMAT_DD_MMM_YYYY } from '@/helpers/common';
import { REPAYMENT_STATUSES } from '../index';
import Tooltip from '@/components/Tooltip';
import { InfoIcon } from '@/components/UI/Icons';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { EMPTY_DASH } from '@/helpers/constants';
import './index.scss';

const RepScheduleList = ({ data }) => {
  const { t } = useTranslation('repayment_schedule');
  const REPAYMENT_STATUSES_T = REPAYMENT_STATUSES(t);

  return (
    <div className="rep_schedule_list">
      <div className="rep_schedule_list__list">
        {data.map((item, idx) => {
          const paidPercentage =
            (item.payment_received / item.total_amount) * 100;
          return (
            <div className="rep_schedule_list__list_item" key={idx}>
              <div className="loan_card__row">
                <div className="c-grey f-14 f-400">{t('due_date')}</div>
                <div className="f-18 f-500">
                  {moment(item.deadline).format(DATE_FORMAT_DD_MMM_YYYY)}
                </div>
              </div>
              <div className="loan_card__row">
                <div className="loan_card__tooltip">
                  <div className="c-grey f-14 f-400">{t('amount')}</div>
                  {item.is_extendable && (
                    <Tooltip
                      clickMode
                      icon={InfoIcon}
                      position="bottom center"
                      renderContent={() => (
                        <div className="rep_schedule_list__tooltip f-16 f-400">
                          <Trans
                            ns="repayment_schedule"
                            i18nKey="extendable_payment_info"
                            components={{
                              link: (
                                <MyLink
                                  href={APP_LINKS.helpLoanTerms()}
                                  underline
                                />
                              ),
                            }}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
                <div className="f-18 f-500">
                  {prettyFloatMoney(item.total_amount)}
                </div>
              </div>
              <div className="loan_card__row">
                <div className="c-grey f-14 f-400">{t('payment_date')}</div>
                <div className="f-18 f-500">
                  {' '}
                  {moment(item.deadline).format(DATE_FORMAT_DD_MMM_YYYY)}
                </div>
              </div>
              <div className="loan_card__row">
                <div className="c-grey f-14 f-400">{t('payment_received')}</div>
                <div
                  className="f-18 f-500"
                  style={{ color: REPAYMENT_STATUSES_T[item.status].color }}
                >
                  {item.payment_received
                    ? prettyFloatMoney(item.payment_received)
                    : EMPTY_DASH}
                </div>
              </div>
              <div className="loan_card__row">
                <div className="c-grey f-14 f-400">{t('status')}</div>
                <div
                  className="f-18 f-500"
                  style={{ color: REPAYMENT_STATUSES_T[item.status].color }}
                >
                  {REPAYMENT_STATUSES_T[item.status].label}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RepScheduleList;
