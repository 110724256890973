import classnames from 'classnames';
import IconSuccess from './assets/icon_success_18.svg';
import IconError from './assets/icon_danger_18.svg';
import s from './index.module.scss';

const TYPES = {
  success: 'success',
  error: 'error',
};

export const Message = ({ type, title, description, className }) => {
  return (
    <div className={classnames(s.block, type, className)}>
      {type === TYPES.error && (
        <img src={IconError} alt="icon" className={s.icon} />
      )}
      {type === TYPES.success && (
        <img src={IconSuccess} alt="icon" className={s.icon} />
      )}
      <div className={s.content}>
        <div className={s.title}>{title}</div>
        {description && <div className={s.desc}>{description}</div>}
      </div>
    </div>
  );
};
