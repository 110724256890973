import { useNavigate } from 'react-router-dom';
import { ComplaintForm } from '../../components/Forms/ComplaintForm';
import { APP_LINKS } from '../../helpers/links';
import { createComplaint } from '../../store/services/complaintServices';
import { uploadFile } from '../../store/services/commonServices';
import s from './index.module.scss';

const ComplaintAddPage = () => {
  const navigate = useNavigate();
  const onSubmit = async values => {
    const payload = {
      fundraising_id: values.fundraising.id,
      description: values.description,
      file_ids: [],
    };

    const files = await Promise.all(values.files.map(file => uploadFile(file)));
    payload.file_ids = files
      .filter(res => res.success)
      .map(file => file.data.id);

    const res = await createComplaint(payload);
    res?.success && navigate(APP_LINKS.complaints);
  };

  return (
    <div className={s.page}>
      <div className="container-inner">
        <h1 className="f-42 f-500">Investor complaints</h1>
        <ComplaintForm className={s.form} onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default ComplaintAddPage;
