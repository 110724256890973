'use client';

import { createContext, useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import {
  getAllReferralCodesFromCookie,
  updateCookieReferralCodes,
} from '@/helpers/referral';
import { checkReferralCode } from '@/store/services/referralService';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const ReferralContext = createContext({
  codes: [],
  currentRefCode: null,
});

const CODE_EXPIRE_DAYS = 30;

export const ReferralProvider = ({ children }) => {
  const location = useLocation();
  const [codes, setCodes] = useState(getAllReferralCodesFromCookie() || []);
  const lastChecked = useRef(); // Flag to avoid double-checking of the same code
  const { ref } = qs.parse(location.search.replace('?', ''));

  useEffect(() => {
    ref && saveReferralCode(ref);
  }, [ref]);

  const saveReferralCode = async code => {
    const existingIndex = codes.findIndex(c => c.id === code);
    if (existingIndex !== -1) {
      return; // do not save if code already exists
    }

    const res = await checkReferralCode(code);
    if (res.success) {
      const { is_valid, amount } = res.data;
      if (!is_valid) {
        console.error(`Referral code ${code} is invalid`);
        return;
      }

      lastChecked.current = code;
      const codeData = {
        id: code,
        expireDate: moment().add(CODE_EXPIRE_DAYS, 'days').toISOString(),
        amount,
        is_valid,
      };
      setCodes(prev => [...prev, codeData]);
    }
  };

  useEffect(() => {
    updateCookieReferralCodes(codes);
  }, [codes.length]);

  const lastCode = codes[codes.length - 1];

  useEffect(() => {
    lastCode && lastCode !== lastChecked.current && validateCode(lastCode);
  }, [lastCode]);

  const validateCode = async code => {
    const res = await checkReferralCode(code.id);
    if (res.success) {
      if (!res.data.is_valid || moment().isAfter(code.expireDate)) {
        // Remove expired or not-valid code from state
        setCodes(prev => prev.filter(c => c.id !== code.id));
      }
    }
  };

  const value = {
    refCodes: codes,
    currentRefCode: lastCode,
  };

  return (
    <ReferralContext.Provider value={value}>
      {children}
    </ReferralContext.Provider>
  );
};

export const useReferrals = () => {
  const referrals = useContext(ReferralContext);
  if (!referrals) {
    throw new Error('useReferrals must be used within a ReferralProvider');
  }
  return referrals;
};
