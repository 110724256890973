import { useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { CONTACT_EMAIL, CONTACT_PHONE } from '@/helpers/constants';
import { AppVersion } from '@/components/AppVersion';
import { PaymentMethodLogos } from '../../PaymentMethodLogos';
import { TrustpilotStars } from '../../Trustpilot/stars';
import { WhatsAppChatLink } from '../../WhatsAppChatLink';
import { SocialButtonsGrey } from '../../SocialButtonsGrey';
import './index.scss';

export const AuthorizedFooter = () => {
  const { t } = useTranslation('footer');
  return (
    <footer className="auth-footer">
      <div className="container-inner">
        <nav className="auth-footer__top">
          <div className="auth-footer__top-column">
            <h4 className="f-20 f-500">{t('company')}</h4>
            <ul>
              <li>
                <MyLink href={APP_LINKS.home} isExternal newWindow={false}>
                  {t('home')}
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.investing}>
                  {t('investment_round')}
                </MyLink>
              </li>
              <li>
                <MyLink
                  href={APP_LINKS.promoRoundsHistory}
                  isExternal
                  newWindow={false}
                >
                  {t('rounds_history')}
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.blog} isExternal newWindow={false}>
                  {t('blog')}
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.affiliate}>
                  {t('affiliate_programme')}
                </MyLink>
              </li>
            </ul>
          </div>
          <div className="auth-footer__top-column">
            <h4 className="f-20 f-500">{t('legal')}</h4>
            <ul>
              <li>
                <MyLink href={APP_LINKS.privacyPolicy} isExternal>
                  {t('privacy_policy')}
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.termOfUse} isExternal>
                  {t('terms_of_use')}
                </MyLink>
              </li>
              <li>
                <MyLink
                  href={APP_LINKS.docAssignmentAgreementGeneralTerms}
                  isExternal
                >
                  {t('assignment_agreement')}
                </MyLink>
              </li>
              <li>
                <MyLink
                  href={APP_LINKS.docAnnexToSubordinatedLoansAgreement}
                  isExternal
                >
                  {t('annex_guarantee_letter')}
                </MyLink>
              </li>
            </ul>
          </div>
          <div className="auth-footer__top-column">
            <h4 className="f-20 f-500">{t('help')}</h4>
            <ul>
              <li>
                <MyLink href={APP_LINKS.help}>{t('help')}</MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.helpLoanTerms()}>
                  {t('loan_terms')}
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.complaints}>
                  {t('investor_complaints')}
                </MyLink>
              </li>
            </ul>
          </div>
          <div className="auth-footer__top-column">
            <h4 className="f-20 f-500">{t('support')}</h4>
            <div className="auth-footer__contact">{t('contact_us')}</div>
            <WhatsAppChatLink className="auth-footer__whatsapp" />
            <div>
              <MyLink
                className="auth-footer__email"
                href={`mailto:${CONTACT_EMAIL}`}
                isExternal
              >
                {CONTACT_EMAIL}
              </MyLink>
            </div>
            <div>
              <MyLink
                className="auth-footer__phone"
                href={APP_LINKS.phone}
                isExternal
              >
                {CONTACT_PHONE}
              </MyLink>
            </div>
            <SocialButtonsGrey className="auth-footer__social" />
          </div>
        </nav>

        <div className="auth-footer__middle">
          <PaymentMethodLogos className="auth-footer__payments" />
          <TrustpilotStars className="auth-footer__trustpilot" />
        </div>

        <div className="auth-footer__copyright">
          <div className="f-12 f-400">
            {t('scramble_ou_all_rights_reserved', {
              year: new Date().getFullYear(),
            })}
          </div>
          <div className="f-12 f-400">{t('scramble_ou_registered')}</div>
          <AppVersion color="#191919" />
        </div>
      </div>
    </footer>
  );
};
