import { useGoogleEvent } from './google';
import { SEO_EVENTS } from './events';

const google = 'google';
export const SEO_SERVICES = [google];

export default class Analytics {
  static signUpWithGoogle = () =>
    registerEvent({ event: SEO_EVENTS.sign_up_with_google });
  static signUpWithApple = () =>
    registerEvent({ event: SEO_EVENTS.sign_up_with_apple });
  static signUpEmailInput = () =>
    registerEvent({ event: SEO_EVENTS.sign_up_email_input });
  static signUpPasswordInput = () =>
    registerEvent({ event: SEO_EVENTS.sign_up_password_input });
  static signUpRegister = () =>
    registerEvent({ event: SEO_EVENTS.sign_up_register });
  static publicHeaderSignIn = () =>
    registerEvent({ event: SEO_EVENTS.public_header_sign_in });
  static signInWithGoogle = () =>
    registerEvent({ event: SEO_EVENTS.sign_in_with_google });
  static signInWithApple = () =>
    registerEvent({ event: SEO_EVENTS.sign_in_with_apple });
  static signInEmailInput = () =>
    registerEvent({ event: SEO_EVENTS.sign_in_email_input });
  static signInPasswordInput = () =>
    registerEvent({ event: SEO_EVENTS.sign_in_password_input });
  static signInLogin = () => registerEvent({ event: SEO_EVENTS.sign_in_login });
  static signInForgotPassword = () =>
    registerEvent({ event: SEO_EVENTS.sign_in_forgot_pwd });
  static publicHeaderSignUp = () =>
    registerEvent({ event: SEO_EVENTS.public_header_sign_up });
  static signUpPersonalDataInput = () =>
    registerEvent({ event: SEO_EVENTS.sign_up_personal_data_submit });
  static signUpContactsDataInput = () =>
    registerEvent({ event: SEO_EVENTS.sign_up_contacts_data_submit });
  static signUpPhoneVerification = () =>
    registerEvent({ event: SEO_EVENTS.sign_up_phone_vrification });
  static veriffPass = () => registerEvent({ event: SEO_EVENTS.veriff_pass });
  static rootPageNavigation = linkName =>
    registerEvent({ event: SEO_EVENTS.root_page(linkName) });
  static pageNavigation = (pageName, pageType) =>
    registerEvent({ event: SEO_EVENTS.page_navigation(pageName, pageType) });
  static addCashAmount = variantName =>
    registerEvent({ event: SEO_EVENTS.add_cash_amount(variantName) });
  static addCashPaymentMethod = () =>
    registerEvent({ event: SEO_EVENTS.add_cash_payment_method });
  static addCashGoToNextPayment = () =>
    registerEvent({ event: SEO_EVENTS.add_cash_go_to_next_payment });
  static withdrawAddNewBankAccount = () =>
    registerEvent({ event: SEO_EVENTS.withdraw_add_new_bank_account });
  static withdrawWithdrawSubmit = () =>
    registerEvent({ event: SEO_EVENTS.withdraw_withdraw_submit });
  static investingJoinRound = (description, event) =>
    registerEvent({ event: SEO_EVENTS.investing_join(description, event) });
  static investingPreviewBrand = brandName =>
    registerEvent({ event: SEO_EVENTS.investing_preview_brand(brandName) });
  static investingStrategyStep = () =>
    registerEvent({ event: SEO_EVENTS.investing_strategy_step });
  static investingBackButton = () =>
    registerEvent({ event: SEO_EVENTS.investing_back_button });
  static investingAllocateStep = () =>
    registerEvent({ event: SEO_EVENTS.investing_allocate_step });
  static investingAllocateGroup = groupName =>
    registerEvent({ event: SEO_EVENTS.investing_allocate_group(groupName) });
  static investingReviewStep = () =>
    registerEvent({ event: SEO_EVENTS.investing_review_step });
  static investingReviewSaveSettings = () =>
    registerEvent({ event: SEO_EVENTS.investing_review_save_settings });
  static signInPage = () => registerEvent({ event: SEO_EVENTS.sign_in_page });
  static addCashPage = () => registerEvent({ event: SEO_EVENTS.add_cash_page });
  static investingPage = () =>
    registerEvent({ event: SEO_EVENTS.investing_page });
  static signUpPage = () => registerEvent({ event: SEO_EVENTS.sign_up_page });
  static authRefCodeStep = () =>
    registerEvent({ event: SEO_EVENTS.auth_refcode_page });
  static authRefCodeSkip = () =>
    registerEvent({ event: SEO_EVENTS.auth_refcode_page_skipped });
  static authRefCodeSubmit = () =>
    registerEvent({ event: SEO_EVENTS.auth_refcode_page_submitted });
}

export const registerEvent = ({
  services = SEO_SERVICES,
  event,
  extraParams = {},
  onSuccess,
  onError,
}) => {
  services.map(service => {
    switch (service) {
      case google:
        return useGoogleEvent(event, extraParams, onSuccess, onError);
      default:
        return console.error(
          `Warning. Could not load '${service}' tag manager service.`,
        );
    }
  });
};
