import classnames from 'classnames';
import { Avatar } from '@/components/UI/Avatar';
import './index.scss';

export const GroupedAvatars = ({
  data = [],
  count,
  size = 34,
  limit = 3,
  showCounter,
  onCounterClick,
  className,
}) => {
  const totalCount = count || data.length || 0;
  const sliceCount =
    (showCounter && totalCount > showCounter && limit - 1) || limit;

  return (
    <div
      className={classnames('grouped-avatars', className)}
      style={{ height: `${size}px` }}
    >
      {data.length
        ? data
            .slice(0, sliceCount)
            .map((item, index) => (
              <Avatar
                key={`${item.id}${index}`}
                src={item.src}
                alt={item.alt}
                nameInitials={item.nameInitials}
                size={size}
              />
            ))
        : new Array(limit)
            .fill('-')
            .map((item, idx) => <Avatar key={idx} size={size} />)}
      {showCounter && totalCount > showCounter && (
        <div
          className="grouped-avatars__counter f-18 f-500"
          style={{ width: size, height: size }}
          onClick={onCounterClick}
        >
          +{totalCount - (limit - 1)}
        </div>
      )}
    </div>
  );
};
