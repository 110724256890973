import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP_LINKS } from '@/helpers/links';
import { BankDetailForm } from '@/components/Forms/BankDetailForm';
import { uploadFile } from '@/store/services/commonServices';
import { createBankDetail } from '@/store/services/fundServices';
import Notify from '@/components/Notification';

export const BankDetailCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('bank_details');

  const onSubmit = async (values, { setSubmitting }) => {
    const payload = {
      country: values.country.label,
      country_code: values.country.code,
      beneficiary_name: values.beneficiary_name,
      account_number: values.account_number,
    };

    if (values.files[0]) {
      const file = values.files[0];
      if (file.id) {
        payload.bank_statement = file.id;
      } else {
        const result = await uploadFile(file);
        if (result?.success) {
          payload.bank_statement = result.data.id;
        }
      }
    }

    if (!values.in_sepa_zone) {
      payload.swift = values.swift;
      payload.beneficiary_address = values.beneficiary_address;
      payload.beneficiary_bank = values.beneficiary_bank;
      payload.beneficiary_bank_address = values.beneficiary_bank_address;
    }

    const res = await createBankDetail(payload);

    if (res?.success) {
      return navigate(APP_LINKS.bankAccounts);
    }

    Notify.error({
      text: res.message || t('please_check_your_data_and_try_again'),
    });

    setSubmitting(false);
  };

  return <BankDetailForm onSubmit={onSubmit} />;
};
