import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getNonPriorityFounders,
  getPriorityFounders,
} from '@/store/services/investingServices';
import StartupCard from '@/components/Cards/StartupCard';
import './index.scss';

const PriorityFoundersGroupedList = () => {
  const { t } = useTranslation('investing');
  const [state, setState] = useState({
    page: 1,
    limit: 500,
    priorityFounders: null,
    nonPriorityFounders: null,
  });

  useEffect(() => {
    const { page, limit } = state;
    getPriorityFounders({ page, limit }).then(
      res =>
        res &&
        res.success &&
        setState(prevState => ({
          ...prevState,
          priorityFounders: res.data,
        })),
    );
    getNonPriorityFounders({ page, limit }).then(
      res =>
        res &&
        res.success &&
        setState(prevState => ({
          ...prevState,
          nonPriorityFounders: res.data,
        })),
    );
  }, []);

  const { priorityFounders, nonPriorityFounders } = state;
  const isEmptyPriority =
    !priorityFounders || !(priorityFounders && priorityFounders.total_count);
  const isEmptyNonPriority =
    !nonPriorityFounders ||
    !(nonPriorityFounders && nonPriorityFounders.total_count);

  return (
    <div className="priority-founders-grouped-list__container">
      <h2 className="f-26 f-500">{t('priority_founders')}</h2>
      <p className="f-16 f-400">{t('priority_founders_description')}</p>
      {isEmptyPriority ? (
        <div className="priority-founders-grouped-list__empty f-16 f-400">
          {t('no_founders')}
        </div>
      ) : (
        <div className="priority-founders-grouped-list">
          {priorityFounders.list.map(founder => (
            <StartupCard
              key={founder.id}
              founders={founder.co_founders}
              company={founder.company}
              fundraisingStatus={founder.fundraising_status}
              className="priority-founders-grouped-list__item"
              active
            />
          ))}
        </div>
      )}
      {!isEmptyNonPriority && (
        <>
          <h2 className="f-26 f-500">{t('other_founders')}</h2>
          <p className="f-16 f-400">{t('other_founders_description')}</p>
          {isEmptyNonPriority ? (
            <div className="priority-founders-grouped-list__empty f-16 f-400">
              {t('no_founders')}
            </div>
          ) : (
            <div className="priority-founders-grouped-list">
              {nonPriorityFounders.list.map(founder => (
                <StartupCard
                  key={founder.id}
                  founders={founder.co_founders}
                  company={founder.company}
                  fundraisingStatus={founder.fundraising_status}
                  className="priority-founders-grouped-list__item"
                  active
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PriorityFoundersGroupedList;
