import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { BackButton } from '@/components/UI/Buttons';
import { canGoBack } from '@/helpers/utils';
import { APP_LINKS } from '@/helpers/links';
import { GROUP_DETAIL as GROUP_DETAIL_T } from './data';
import { TERMS } from './term';
import s from './index.module.scss';

export const GroupDetailVersion02 = ({ id }) => {
  const navigate = useNavigate();
  const { innerWidth } = useSelector(state => state.commonStore);
  const { t } = useTranslation('loan_terms');
  const GROUP_DETAIL = GROUP_DETAIL_T(t);
  const {
    title,
    termsSection,
    fundsUsageSection,
    fundsProtectionSection,
    repaymentSection,
  } = GROUP_DETAIL[id](TERMS, innerWidth);

  return (
    <div className={s.page}>
      <div className="container-inner">
        <BackButton
          onClick={() =>
            canGoBack() ? navigate(-1) : navigate(APP_LINKS.helpLoanTerms('v2'))
          }
        />
        <h1 className="f-42 f-500">{title}</h1>
        {termsSection}
        {fundsUsageSection}
        {fundsProtectionSection}
        {repaymentSection}
        <div className={classnames(s.page__fees, 'f-16-24 f-400')}>
          <Trans
            ns="loan_terms"
            i18nKey="v2.detail.no_fees_on_investor"
            components={{ b: <b /> }}
          />
        </div>
      </div>
    </div>
  );
};
