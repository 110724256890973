import s from './index.module.scss';

export const AppVersion = ({ color }) => {
  const version = import.meta.env.VITE_APP_VERSION;
  const release = import.meta.env.VITE_RELEASE;
  return (
    <div className={s.version} style={{ color }}>
      App version: {version}-{release?.charAt(0)}
    </div>
  );
};
