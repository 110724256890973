import classnames from 'classnames';
import s from './index.module.scss';

export const TextInput = ({ iconLeft, className, error, ...props }) => {
  return (
    <div className={classnames(s.wrapper, className)}>
      {iconLeft && <div className={s.icon_left}>{iconLeft}</div>}
      <input
        type={props.type || 'text'}
        className={classnames(
          'text_input',
          s.input,
          props.value && s.input__filled,
          error && s.input__error,
        )}
        style={{ paddingLeft: iconLeft ? '48px' : '12px' }}
        {...props}
      />
    </div>
  );
};
