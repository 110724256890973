import { Trans, useTranslation } from 'react-i18next';
import { prettyFloatMoney } from '@/helpers/utils';
import { ReferralFriendStatus } from '../index';
import Tooltip from '@/components/Tooltip';
import './index.scss';

const ReferralTable = ({ data }) => {
  const { t } = useTranslation('affiliate');
  return (
    <div className="referral_table__wrap">
      <div className="referral_table__inner">
        <div className="referral_table">
          <div className="referral_table__thead">
            <div className="referral_table__thead_col">
              <div className="referral_table__thead_col_tooltip">
                <span>{t('referral_table.user.title')}</span>
                <Tooltip
                  position="bottom left"
                  renderContent={() => (
                    <div className="referral_table__tooltip f-16  f-400">
                      {t('referral_table.user.tooltip')}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="referral_table__thead_col">
              <div className="referral_table__thead_col_tooltip">
                <span>{t('referral_table.user_status.title')}</span>
                <Tooltip
                  position="bottom left"
                  renderContent={() => (
                    <div className="referral_table__tooltip f-16  f-400">
                      {t('referral_table.user_status.tooltip')}
                      <div>
                        <Trans
                          t={t}
                          i18nKey="referral_table.user_status.tooltip_1"
                          components={{
                            Bold: <span className="f-500" />,
                          }}
                        />
                      </div>
                      <div>
                        <Trans
                          t={t}
                          i18nKey="referral_table.user_status.tooltip_2"
                          components={{
                            Bold: <span className="f-500" />,
                          }}
                        />
                      </div>
                      <div>
                        <Trans
                          t={t}
                          i18nKey="referral_table.user_status.tooltip_3"
                          components={{
                            Bold: <span className="f-500" />,
                          }}
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="referral_table__thead_col">
              <div className="referral_table__thead_col_tooltip">
                <span>{t('referral_table.days_left.title')}</span>
                <Tooltip
                  position="bottom left"
                  renderContent={() => (
                    <div className="referral_table__tooltip f-16  f-400">
                      {t('referral_table.days_left.tooltip')}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="referral_table__thead_col">
              <div className="referral_table__thead_col_tooltip">
                <span>{t('referral_table.reward.title')}</span>
                <Tooltip
                  position="bottom left"
                  renderContent={() => (
                    <div className="referral_table__tooltip f-16  f-400">
                      {t('referral_table.reward.tooltip')}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="referral_table__thead_col">
              <div className="referral_table__thead_col_tooltip">
                <span>{t('referral_table.rewards_status.title')}</span>
                <Tooltip
                  position="bottom left"
                  className="last"
                  renderContent={() => (
                    <div className="referral_table__tooltip f-16  f-400">
                      {t('referral_table.rewards_status.tooltip')}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="referral_table__tbody">
            <div className="referral_table__tbody_row_summary">
              <div className="referral_table__tbody_col">
                <p className="f-18 f-500">
                  <Trans
                    t={t}
                    i18nKey="referral_table.total"
                    components={{
                      Span: <span />,
                    }}
                    values={{ value: data.total_count }}
                  />
                </p>
              </div>
              <div className="referral_table__tbody_col">
                <span className="f-18 f-500">—</span>
              </div>
              <div className="referral_table__tbody_col">
                <span className="f-18 f-500">—</span>
              </div>
              <div className="referral_table__tbody_col tl">
                <span className="f-18 f-500">
                  +{prettyFloatMoney(data.total_commission)}
                </span>
              </div>
              <div className="referral_table__tbody_col tl">
                <span className="f-18 f-500">—</span>
              </div>
            </div>

            {data.list.map(item => (
              <div className="referral_table__tbody_row" key={item.investor_id}>
                <div className="referral_table__inner_row">
                  <div className="referral_table__tbody_col">
                    <span className="f-18 f-400">{item.investor_id}</span>
                  </div>
                  <div className="referral_table__tbody_col">
                    <span className="f-18 f-400">
                      <ReferralFriendStatus status={item.friend_status} />
                    </span>
                  </div>
                  <div className="referral_table__tbody_col">
                    <div className="f-18 f-400 tl">
                      {item.remaining_period
                        ? t('referral_table.days_count', {
                            count: item.remaining_period,
                          })
                        : '-'}
                    </div>
                  </div>
                  <div className="referral_table__tbody_col tl">
                    <span className="f-18 f-400">
                      {prettyFloatMoney(item.commission)}
                    </span>
                  </div>
                  <div className="referral_table__tbody_col">
                    <span className="f-18 f-400">{item.commission_status}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralTable;
