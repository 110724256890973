import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import {
  getFundraisingCountriesStats,
  getFundraisingStats,
} from '@/store/actions/investActions';
import { ProgressBar } from './bar';
import { ProgressCountries } from './countries';
import s from './index.module.scss';

export const InvestingProgressBar = ({ className }) => {
  const dispatch = useDispatch();
  const { fundraisingStats, fundraisingCountriesStats } = useSelector(
    state => state.investStore,
  );

  useEffect(() => {
    updateStats();
  }, []);

  useEffect(() => {
    window.addEventListener('focus', updateStats);
    return () => {
      window.removeEventListener('focus', updateStats);
    };
  }, []);

  const updateStats = () => {
    dispatch(getFundraisingStats());
    dispatch(getFundraisingCountriesStats());
  };

  return (
    <div className={classnames(s.wrapper, className)}>
      <ProgressBar
        current={fundraisingStats.currently_allocated}
        min={fundraisingStats.min_goal}
        max={fundraisingStats.total_goal}
        className={s.bar}
      />
      {fundraisingCountriesStats && (
        <ProgressCountries data={fundraisingCountriesStats} />
      )}
    </div>
  );
};
