import { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Veriff } from '@veriff/js-sdk';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import config from '@/config';
import AnalyticsGA from '@/analyticsGA';
import { APP_LINKS } from '@/helpers/links';
import { setUserVeriffSkipReason } from '@/store/services/userServices';
import { MyLink } from '@/components/MyLink';
import { useGetMe } from '@/hooks/useGetMe';
import './index.scss';

export const SOURCES = {
  registration: 'registration',
  wizard: 'wizard',
  profile: 'profile',
};

export const SKIP_REASON_TYPES = {
  skipped_later: 'skipped_later',
  skipped_dont_want: 'skipped_dont_want',
};

export const VerifyForm = ({ source, onSuccess, onClose }) => {
  const { t } = useTranslation('auth');
  const { user } = useGetMe();

  const toggleChat = hide => {
    const chat = document.getElementById('tidio-chat');
    if (chat) {
      chat.style.display = hide ? 'none' : 'unset';
    }
  };

  const initVeriff = () => {
    return Veriff({
      host: config.veriffHostURL,
      apiKey: config.veriffApiKey,
      parentId: 'veriff-root',
      onSession(err, response) {
        // If Veriff service ready, open the verification flow
        if (response?.verification) {
          toggleChat(true);

          createVeriffFrame({
            url: response.verification.url,
            onEvent: msg => {
              if (msg === MESSAGES.SUBMITTED) {
                AnalyticsGA.veriffPass();
                onSuccess && onSuccess({ response });
              }

              if (msg === MESSAGES.CANCELED) {
                onClose && onClose();
              }

              toggleChat(false);
            },
          });
        }
      },
    });
  };

  const veriff = useRef(initVeriff());

  useEffect(() => {
    if (veriff.current) {
      setVeriffParams();
      mountVeriff();
    }
  }, []);

  useEffect(() => {
    if (user && veriff.current) {
      setVeriffParams();
      mountVeriff();
    }
  }, [user?.first_name, user?.last_name]);

  const setVeriffParams = () => {
    veriff.current &&
      veriff.current.setParams({
        person: {
          givenName: user?.first_name || ' ',
          lastName: user?.last_name || ' ',
        },
        vendorData: user?.email || ' ',
      });
  };

  const mountVeriff = () => {
    veriff.current &&
      veriff.current.mount({
        submitBtnText: t('start_session'),
      });
  };

  return (
    <div className="verify_form">
      <div className="verify_form__top">
        {source === SOURCES.registration && (
          <h1 className="verify_form__title f-500">
            {t('verify_your_identity')}
          </h1>
        )}
        <div className="verify_form__text">
          <div>{t('safe_marketplace_message')}</div>
          <div>{t('start_verification_message')}</div>
          <ul className="verify_form__list">
            <li>{t('prepare_identity_document')}</li>
            <li>{t('check_camera')}</li>
            <li>{t('prepare_photo_and_selfie')}</li>
          </ul>
        </div>
        <div id="veriff-root" className="verify_form__form" />
        <div
          className={classnames(
            'verify_form__bottom',
            source === SOURCES.profile && 'full',
          )}
        >
          <div>{t('identity_verification_provided_by_veriff')}</div>
          <div>{t('media_storage_message')}</div>
        </div>
      </div>

      {source === SOURCES.registration && (
        <div className="verify_form__actions">
          <MyLink
            underline
            href={APP_LINKS.dashboard}
            onClick={() => {
              setUserVeriffSkipReason(SKIP_REASON_TYPES.skipped_later);
            }}
          >
            {t('complete_verification_later')}
          </MyLink>
          <MyLink
            underline
            href={APP_LINKS.dashboard}
            onClick={() => {
              setUserVeriffSkipReason(SKIP_REASON_TYPES.skipped_dont_want);
            }}
          >
            {t('dont_want_to_be_verified')}
          </MyLink>
        </div>
      )}
    </div>
  );
};
