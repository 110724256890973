import { useState } from 'react';
import moment from 'moment/min/moment-with-locales';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { prettyFloatMoney } from '@/helpers/utils';
import { DATE_FORMAT_DD_MMM_YYYY } from '@/helpers/common';
import { REPAYMENT_STATUSES } from '../index';
import { InfoIcon } from '@/components/UI/Icons';
import Tooltip from '@/components/Tooltip';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { EMPTY_DASH } from '@/helpers/constants';
import './index.scss';

const RepScheduleTable = ({ data }) => {
  const [activeRows, setActiveRows] = useState([]);
  const { t } = useTranslation('repayment_schedule');
  const REPAYMENT_STATUSES_T = REPAYMENT_STATUSES(t);

  const onToggle = idx => {
    activeRows.includes(idx)
      ? setActiveRows(activeRows.filter(i => i !== idx))
      : setActiveRows([...activeRows, idx]);
  };

  return (
    <div className="rep_schedule_table__wrap">
      <div className="rep_schedule_table__inner">
        <div className="rep_schedule_table">
          <div className="rep_schedule_table__thead">
            <div className="rep_schedule_table__thead_col">{t('due_date')}</div>
            <div
              className="rep_schedule_table__thead_col"
              style={{ textAlign: 'center' }}
            >
              {t('amount')}
            </div>
            <div className="rep_schedule_table__thead_col">
              {t('payment_received')}
            </div>
            <div className="rep_schedule_table__thead_col">
              {t('payment_date')}
            </div>
            <div className="rep_schedule_table__thead_col">{t('status')}</div>
          </div>

          <div className="rep_schedule_table__tbody">
            {data.map((item, idx) => {
              const paidPercentage =
                (item.payment_received / item.total_amount) * 100;

              return (
                <div className="rep_schedule_table__tbody_row" key={idx}>
                  <div
                    className={classnames(
                      'rep_schedule_table__tbody_col',
                      REPAYMENT_STATUSES_T[item.status].className,
                    )}
                  >
                    {moment(item.deadline).format(DATE_FORMAT_DD_MMM_YYYY)}
                  </div>
                  <div
                    className={classnames(
                      'rep_schedule_table__tbody_col',
                      REPAYMENT_STATUSES_T[item.status].className,
                    )}
                    onClick={() => onToggle(idx)}
                  >
                    <div className="scr_tree_view">
                      <div>{prettyFloatMoney(item.total_amount)}</div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <svg
                          width="7"
                          height="4"
                          viewBox="0 0 7 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginBottom: '3px',
                            transform: `rotate(${activeRows.includes(idx) ? '180deg' : '0'})`,
                          }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.89072 3.84L6.83832 0.946667C7.05389 0.72 7.05389 0.373333 6.83832 0.16C6.62275 -0.0533333 6.25898 -0.0533333 6.04341 0.16L3.5 2.66667L0.956587 0.16C0.727545 -0.0533333 0.377246 -0.0533333 0.161677 0.16C-0.0538922 0.373333 -0.0538922 0.72 0.161677 0.946667L3.09581 3.84C3.32485 4.05333 3.67515 4.05333 3.89072 3.84Z"
                            fill="black"
                          />
                        </svg>
                        {item.is_extendable && item.status !== 'paid' && (
                          <Tooltip
                            clickMode
                            icon={InfoIcon}
                            position="top center"
                            renderContent={() => (
                              <div className="rep_schedule_table__tooltip f-16 f-400">
                                <Trans
                                  ns="repayment_schedule"
                                  i18nKey="extendable_payment_tooltip"
                                  components={{
                                    link: (
                                      <MyLink
                                        href={APP_LINKS.helpLoanTerms()}
                                        underline
                                      />
                                    ),
                                  }}
                                />
                              </div>
                            )}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      className={classnames(
                        'rep_schedule_table__menu f-14 f-400',
                        activeRows.includes(idx) && 'active',
                      )}
                    >
                      {item.interest > 0 && (
                        <div className="scr_tree_view">
                          <div>{prettyFloatMoney(item.interest)}</div>
                          <div>{t('interest')}</div>
                        </div>
                      )}
                      {item.principal > 0 && (
                        <div className="scr_tree_view">
                          <div>{prettyFloatMoney(item.principal)}</div>
                          <div>{t('principal')}</div>
                        </div>
                      )}

                      {item.success_fee > 0 && (
                        <div className="scr_tree_view">
                          <div>{prettyFloatMoney(item.success_fee)}</div>
                          <div>{t('success_return')}</div>
                        </div>
                      )}
                      {item.flat_fee > 0 && (
                        <div className="scr_tree_view">
                          <div>{prettyFloatMoney(item.flat_fee)}</div>
                          <div>{t('flat_fee')}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={classnames(
                      'rep_schedule_table__tbody_col',
                      'tl',
                      REPAYMENT_STATUSES_T[item.status].className,
                    )}
                    style={{ color: REPAYMENT_STATUSES_T[item.status].color }}
                  >
                    {item.payment_received
                      ? prettyFloatMoney(item.payment_received)
                      : EMPTY_DASH}
                  </div>
                  <div
                    className={classnames(
                      'rep_schedule_table__tbody_col',
                      REPAYMENT_STATUSES_T[item.status].className,
                    )}
                  >
                    {item.payment_date
                      ? moment(item.payment_date).format(
                          DATE_FORMAT_DD_MMM_YYYY,
                        )
                      : EMPTY_DASH}
                  </div>
                  <div
                    className={classnames(
                      'rep_schedule_table__tbody_col',
                      'tl',
                      REPAYMENT_STATUSES_T[item.status].className,
                    )}
                    style={{ color: REPAYMENT_STATUSES_T[item.status].color }}
                  >
                    {REPAYMENT_STATUSES_T[item.status].label}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepScheduleTable;
