import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { getTermsOfUseStatus } from '@/store/services/userServices';
import { InformationBlock } from '@/kit/InformationBlock';
import { RoundButton } from '@/components/UI/RoundButton';
import { confirmTermOfUse } from '@/modals/TermsOfUseModal/action';
import { getUserProfile } from '@/store/actions/profileActions';
import { prettyFloatMoney } from '@/helpers/utils';
import s from './index.module.scss';

export const TermsAcceptWarning = ({ className }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('warning');
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(0);

  React.useEffect(() => {
    getTermsOfUseStatus().then(res => {
      if (
        res.success &&
        res.data.has_active_loans &&
        res.data.amount > 0 &&
        res.data.status === 'pending'
      ) {
        setAmount(res.data.amount);
        setShow(true);
      }
    });
  }, []);

  const onAccept = async has_accepted_terms => {
    const res = await confirmTermOfUse({ has_accepted_terms });
    if (res?.success) {
      setShow(false);
      dispatch(getUserProfile());
    }
  };

  return show ? (
    <InformationBlock
      type="warning"
      className={classnames(s.wrapper, className)}
      id="tou_warning"
    >
      <div className={s.content}>
        <h1 className="f-24 f-500">
          {t('term_accept.increase_your_investment_guarantees')}
        </h1>
        <div className={classnames(s.disclaimer, 'f-16')}>
          <Trans
            ns="warning"
            i18nKey="term_accept.disclaimer"
            values={{ amount: prettyFloatMoney(amount) }}
            components={{ span: <span className="f-500" /> }}
          />
        </div>
        <div className={s.buttons}>
          <RoundButton
            label={t('term_accept.i_agree')}
            fillBackground
            onClick={() => onAccept(true)}
          />
          <div className={s.decline} onClick={() => onAccept(false)}>
            {t('term_accept.i_disagree')}
          </div>
        </div>
      </div>
    </InformationBlock>
  ) : null;
};
