import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { useGetAllocationStatus } from '@/hooks/useGetAllocationStatus';
import { LOAN_GROUPS } from '@/helpers/constants';
import { Table } from './table';
import s from './index.module.scss';

export const RepaymentSchedule = () => {
  const { t } = useTranslation('investing');
  const { t: tConstants } = useTranslation('constants');
  const { data } = useSelector(
    state => state.investStore.allocatedRepaymentSchedule,
  );
  const { allocatedGroupA, allocatedGroupB } = useGetAllocationStatus();

  const LOAN_GROUPS_T = LOAN_GROUPS(tConstants);
  const getGroup = () => {
    if (allocatedGroupA && allocatedGroupB) {
      return `${LOAN_GROUPS_T.conservative.full_name} and ${LOAN_GROUPS_T.moderate.full_name}`;
    }
    if (allocatedGroupA) {
      return LOAN_GROUPS_T.conservative.full_name;
    }
    if (allocatedGroupB) {
      return LOAN_GROUPS_T.moderate.full_name;
    }
  };

  const groups = getGroup();

  if (!data) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <div className="f-16-24">
        {t('summary_repayment_schedule_may_be_changed')}
      </div>
      <div className={s.schedule} style={{ marginTop: '20px' }}>
        <div className={s.schedule_title}>
          {t('summary_loan_repayment_schedule', { groups })}
        </div>
        <Table data={data} />
      </div>
      <div className={classnames(s.hint, 'f-16 f-400')}>
        {t('summary_repayment_schedule_is_subject_to_changes')}
        <MyLink href={APP_LINKS.helpLoanTerms()} underline>
          {t('summary_loan_terms')}
        </MyLink>
        .
      </div>
    </div>
  );
};
