import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { RoundButton } from '@/components/UI/RoundButton';
import { CheckboxButton } from '@/kit/form/Checkbox';
import { updateQuestionnaire } from '@/store/services/questionnaireService';
import { setModals } from '@/store/actions/commonActions';
import { getUserProfile } from '@/store/actions/profileActions';
import s from './index.module.scss';

export const QuestionnaireDisclaimer = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('modal');
  const onSubmit = async values => {
    const payload = {
      has_accept_warning: values.accept,
    };
    const res = await updateQuestionnaire(payload);
    if (res?.success) {
      onSuccess && onSuccess();
      dispatch(setModals([]));
      dispatch(getUserProfile());
    }
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: { accept: false },
    onSubmit,
  });

  return (
    <form className={s.wrapper} onSubmit={handleSubmit}>
      <h1 className="f-24 f-500">
        {t('questionnaire_disclaimer.risk_notice')}
      </h1>
      <div className={classnames(s.disclaimer, 'f-16')}>
        <Trans
          ns="modal"
          i18nKey="questionnaire_disclaimer.we_have_qualified_you_as"
          components={{
            span: <span className="f-500" />,
            br: <br />,
          }}
        />
      </div>
      <div className={s.checkboxes}>
        <CheckboxButton
          value="accept"
          checked={values.accept}
          onChange={() => setFieldValue('accept', !values.accept)}
        >
          {t('questionnaire_disclaimer.i_have_received_and_understood')}
        </CheckboxButton>
      </div>
      <div className={s.buttons}>
        <RoundButton
          type="submit"
          label={t('questionnaire_disclaimer.acknowledge_and_continue')}
          onSubmit={handleSubmit}
          disabled={!values.accept}
          className={s.submit}
          fillBackground
        />
      </div>
    </form>
  );
};
