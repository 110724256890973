import classnames from 'classnames';
import './index.scss';

const NotificationBlock = ({ notifications, className }) => {
  if (!notifications || (notifications && !notifications.length)) {
    return null;
  }

  return (
    <div className={classnames(className)}>
      <ul className="notification-block__list">
        {notifications.map((render, index) => render(index))}
      </ul>
    </div>
  );
};

export default NotificationBlock;
