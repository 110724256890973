import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import WarningBlock from '@/components/UI/WarningBlock';
import s from './index.module.scss';

export const MinInvestAmountWarning = ({ className }) => {
  const { t } = useTranslation('warning');

  return (
    <WarningBlock withBorder className={classnames(s.wrapper, className)}>
      <div className={classnames('f-16 f-400')}>
        {t('minimum_investment_amount')}
      </div>
    </WarningBlock>
  );
};
