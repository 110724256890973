import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import { CONTACT_PHONE } from '@/helpers/constants';
import IconWhatsApp from '@/assets/icons/icon_whatsapp.svg?react';
import s from './index.module.scss';

export const WhatsAppChatLink = ({ className }) => {
  const phone = CONTACT_PHONE.replace('+', '').replaceAll(' ', '');
  const { t } = useTranslation('footer');

  return (
    <MyLink
      href={`https://wa.me/${phone}`}
      className={classnames(s.link, className)}
      isExternal
      newWindow
    >
      <IconWhatsApp />
      <span>{t('message_us_whatsapp')}</span>
    </MyLink>
  );
};
