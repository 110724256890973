import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getFlagURL, prettyMoney } from '@/helpers/utils';
import IconStar1 from './assets/star_1.svg?react';
import IconStar2 from './assets/star_2.svg?react';
import IconStar3 from './assets/star_3.svg?react';
import s from './index.module.scss';

const PLACE_IMAGES = [IconStar1, IconStar2, IconStar3];

export const ProgressCountries = ({ data }) => {
  const { t } = useTranslation('investing');
  const otherCountries = useMemo(() => data?.countries?.slice(3), [data]);

  if (!data.countries.length) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <div className={s.left}>
        <PlaceCountry place={0} country={data.countries[0]} />
        <PlaceCountry place={1} country={data.countries[1]} />
        <PlaceCountry place={2} country={data.countries[2]} />
      </div>
      <div className={s.right}>
        <PlaceOthers countries={otherCountries} />
        <div className={s.investors}>
          <div className={s.investors_label}>
            {t('progress_bar_total_investors')}
          </div>
          <div className={s.investors_total}>
            {data.total_participated_investors}
          </div>
        </div>
      </div>
    </div>
  );
};

const PlaceCountry = ({ place, country }) => {
  if (!country) {
    return null;
  }

  const Component = PLACE_IMAGES[place];
  return (
    <div className={s.place}>
      <div className={classnames(s.place_flag, s[`place_flag_${place}`])}>
        <div className={s.place_flag_image}>
          <img
            src={getFlagURL(country.country_code)}
            alt={country.country_code}
          />
        </div>
        <Component />
      </div>
      <div className={s.place_info}>
        <div className={s.place_name}>{country.country_name}</div>
        <div className={s.place_amount}>
          {prettyMoney(country.allocated_amount)}
        </div>
      </div>
    </div>
  );
};

const PlaceOthers = ({ countries }) => {
  const { t } = useTranslation('investing');
  const totalAmount = React.useMemo(
    () =>
      countries.reduce((acc, country) => {
        acc += country.allocated_amount;
        return acc;
      }, 0),
    [countries],
  );

  return (
    <div className={s.other}>
      <div className={s.other_flags}>
        {countries.map((country, index) => {
          if (countries.length === 3 || index <= 1) {
            return (
              <img
                key={country.country_code}
                src={getFlagURL(country.country_code)}
                alt={country.country_code}
              />
            );
          }
        })}
        {countries.length > 3 && (
          <span className={s.other_more}>+{countries.length - 2}</span>
        )}
      </div>
      <div className={s.other_info}>
        <div className={s.other_name}>{t('progress_bar_other_countries')}</div>
        <div className={s.other_value}>{prettyMoney(totalAmount)}</div>
      </div>
    </div>
  );
};
