import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getStartupDetail } from '@/store/actions/startupActions';
import StartupDetail from '@/containers/CompanyDetail';
import { Preloader } from '@/components/Preloader';
import { BackButton } from '@/components/UI/Buttons';
import { canGoBack } from '@/helpers/utils';
import { APP_LINKS } from '@/helpers/links';
import s from './index.module.scss';

const StartupDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { t } = useTranslation('brands');

  useEffect(() => {
    dispatch(getStartupDetail(slug));
  }, [slug]);

  const onBack = () => {
    canGoBack() ? navigate(-1) : navigate(APP_LINKS.startups);
  };

  const { startupDetail } = useSelector(state => state.startupStore);
  const { data: startup, loading } = startupDetail;

  return (
    <div className={s.wrapper}>
      <div className="container-inner">
        <div className={s.back}>
          <BackButton onClick={onBack} />
        </div>
        {loading ? (
          <Preloader className={s.preloader} />
        ) : startup ? (
          <StartupDetail />
        ) : (
          <div className={s.empty}>{t('startup_detil.check_connection')}</div>
        )}
      </div>
    </div>
  );
};

export default StartupDetailPage;
