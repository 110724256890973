import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RoundButton } from '@/components/UI/RoundButton';
import s from './index.module.scss';

const ConfirmModal = ({
  title,
  desc,
  confirmLabel,
  onDelete,
  onConfirm,
  onCancel,
  onClose,
}) => {
  const { t } = useTranslation('modal');
  return (
    <div className={s.wrapper}>
      <div className={classnames(s.title, 'f-20 f-500')}>{title}</div>
      <div className={classnames(s.desc, 'f-14 f-400')}>{desc}</div>
      <div className={s.controls}>
        <RoundButton
          className={classnames(s.button_cancel, 'hov')}
          type="button"
          label={t('confirm_modal.cancel')}
          onClick={onCancel || onClose}
        />
        {onDelete && (
          <RoundButton
            className={classnames(s.button_delete, 'hov')}
            type="button"
            label={t('confirm_modal.delete')}
            onClick={onDelete}
          />
        )}
        {onConfirm && (
          <RoundButton
            className={classnames(s.button_confirm, 'hov')}
            type="button"
            label={confirmLabel || t('confirm_modal.confirm')}
            onClick={onConfirm}
          />
        )}
      </div>
    </div>
  );
};

export default ConfirmModal;
