import moment from 'moment/min/moment-with-locales';
import nl2br from 'react-nl2br';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import { DATE_FORMAT_YYYY } from '@/helpers/common';
import Linker from '@/components/Linker';
import FounderCard from '@/components/Cards/FounderCard';
import s from './index.module.scss';

const StartupDetail = () => {
  const { t } = useTranslation('brands');
  const { startupDetail } = useSelector(state => state.startupStore);
  const { innerWidth } = useSelector(state => state.commonStore);
  const { data: startup } = startupDetail;

  return (
    <div className={classnames(s.wrapper)}>
      {startup.image && (
        <MyLink href={startup.site} className={s.mobile_logo} isExternal>
          <img
            src={startup.image.file}
            alt={startup.image.name || t('startup_detail.logo')}
          />
        </MyLink>
      )}
      <div className={s.top}>
        <div className={s.top_left}>
          <div className={s.top_header}>
            <h1 className={classnames(s.top_title, 'f-42 f-500')}>
              {startup.name}
            </h1>
            {!!startup?.fundraising_status?.goal &&
              getFundraisingBlock(
                startup.fundraising_status,
                innerWidth >= 900 && 'desktop',
              )}
          </div>
          <div className={classnames(s.info, 'f-16 f-400')}>
            {startup.sectors &&
              startup.sectors.map(sector => (
                <span key={sector.id}>{sector.name}</span>
              ))}
            {startup.country && <span>{t('startup_detail.country_based', { country: startup.country })}</span>}
            {startup.foundation_date && (
              <span>
                {t('startup_detail.started_in', { date: moment(startup.foundation_date).format(DATE_FORMAT_YYYY) })}
              </span>
            )}
          </div>
          {startup.site && (
            <Linker
              value={startup.site}
              className={classnames(s.site, 'f-16 f-400')}
            />
          )}

          {!!startup?.fundraising_status?.goal &&
            getFundraisingBlock(
              startup.fundraising_status,
              innerWidth < 900 && 'mobile',
            )}
        </div>
        <div className={s.top_right}>
          {startup.image &&
            (startup.site ? (
              <MyLink href={startup.site} className={s.logo} isExternal>
                <img
                  src={startup.image.file}
                  alt={startup.image.name || t('startup_detail.logo')}
                />
              </MyLink>
            ) : (
              <div className={s.logo}>
                <img
                  src={startup.image.file}
                  alt={startup.image.name || t('startup_detail.logo')}
                />
              </div>
            ))}
        </div>
      </div>

      <div className={s.founders}>
        <h2 className={classnames(s.founders_title, 'f-22 f-500')}>{t('startup_detail.founders')}</h2>
        <div className={s.founders_list}>
          {startup.co_founders.map(founder => (
            <FounderCard
              key={founder.id}
              founder={{
                ...founder,
                company: t('startup_detail.company_co_founder'),
              }}
              active={!!startup?.fundraising_status?.goal}
            />
          ))}
        </div>
      </div>

      {(startup.description || startup?.promo?.image) && (
        <div className={s.details}>
          <h2 className={classnames(s.founders_title, 'f-22 f-500')}>
            {t('startup_detail.details')}
          </h2>
          <p className={s.details_text}>{nl2br(startup.description)}</p>
          {startup?.promo?.image && (
            <div className="image">
              <MyLink href={startup.site || '#'} isExternal>
                <img
                  src={startup.promo.image.file}
                  alt={startup.promo.image.name}
                />
              </MyLink>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StartupDetail;
