import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import config from '@/config';
import { MyLink } from '@/components/MyLink';
import Dropdown from '@/components/Dropdown';
import { getAgreementsByFundraisingID } from '@/store/actions/loanActions';
import s from './index.module.scss';

export const FinanceAgreements = ({ fundraisingID, info, group }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('loans');
  const { agreementsByFundraisingID } = useSelector(state => state.loanStore);

  useEffect(() => {
    dispatch(getAgreementsByFundraisingID(fundraisingID, { group }));
  }, [fundraisingID, group]);

  const { data: agreements } = agreementsByFundraisingID;

  return (
    <div className={classnames(s.contracts, info.insurance_strategy)}>
      <Dropdown
        label={
          <div className={s.header}>
            <h2 className="f-26 f-500">
              {t('loan_info.financing_agreements')}
            </h2>
            {/* <RoundLink */}
            {/* label={t('loan_info.download_all_files')} */}
            {/* path={`${config.apiURL}fundraisings/${fundraisingID}/agreements/`} */}
            {/* className={s.download} */}
            {/* fillBackground */}
            {/* isExternal */}
            {/* /> */}
          </div>
        }
      >
        {fundraisingID === 92 ? (
          <div className={classnames(s.empty_message, 'f-16')}>
            {t('loan_info.files_being_processed')}
          </div>
        ) : agreements?.length ? (
          <div className={s.table}>
            <div className={s.thead}>
              <div className={s.thead_col}>{t('loan_info.loan_type')}</div>
              <div className={s.thead_col}>{t('loan_info.loan_number')}</div>
              <div className={s.thead_col}>
                {t('loan_info.download_agreements')}
              </div>
            </div>
            <div className={s.tbody}>
              {agreements.map(agreement => (
                <div className={s.tbody_row} key={agreement.id}>
                  <div className={s.tbody_col}>
                    {agreement.risk_group_title}
                  </div>
                  <div
                    className={s.tbody_col}
                  >{`${t('loan_info.loan')} #${agreement.id}`}</div>
                  <div className={s.tbody_col}>
                    <MyLink
                      href={`${config.apiURL}loans/${agreement.id}/assignments/`}
                      isExternal
                    >
                      {t('loan_info.download')}
                    </MyLink>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className={classnames(s.empty_message, 'f-16')}>
            {t('loan_info.no_agreements_yet')}
          </div>
        )}
      </Dropdown>
    </div>
  );
};
