import moment from 'moment/min/moment-with-locales';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RoundButton } from '@/components/UI/RoundButton';
import { DATE_FORMAT_DD_MMM_YYYY } from '@/helpers/common';
import { prettyFloatMoney } from '@/helpers/utils';
import { InfoIcon } from '@/components/UI/Icons';
import { setModals } from '@/store/actions/commonActions';
import { MODAL_TYPES } from '../../ModalProvider';
import s from './index.module.scss';

export const LoanCard = ({ round, onDetailsClick, className }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('kit');

  const {
    fundraising_name,
    fundraising_id,
    max_earnings,
    start_date,
    flat_fee_accrued,
    loan_amount,
    repayment_status,
    repaid_amount,
    earnings,
  } = round;

  const onTooltipClick = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.your_earnings,
        round,
      }),
    );
  };

  return (
    <div className={classnames(s.card, className)}>
      <div className={s.company}>
        <div className={classnames(s.company_name, 'f-18 f-500')}>
          {fundraising_name}
        </div>
      </div>

      <div className={s.info}>
        <div className={s.row}>
          <div className="c-grey f-14 f-400">
            {t('loan_card.origination_date')}
          </div>
          <div className="f-18 f-500">
            {moment(start_date).format(DATE_FORMAT_DD_MMM_YYYY)}
          </div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">{t('loan_card.loan_amount')}</div>
          <div className="f-18 f-500">{prettyFloatMoney(loan_amount)}</div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">
            {t('loan_card.repayment_progress')}
          </div>
          <div className="f-18 f-500">{parseInt(repayment_status)}%</div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">{t('loan_card.repayments')}</div>
          <div className="f-18 f-500">{prettyFloatMoney(repaid_amount)}</div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">
            {t('loan_card.your_earnings')}
          </div>
          <div className={classnames(s.earnings_block)}>
            {fundraising_id > 92 && repayment_status < 100 ? (
              <span className={classnames(s.earnings, 'f-18 f-500')}>
                {t('loan_card.out_of', {
                  earnings: prettyFloatMoney(earnings + flat_fee_accrued),
                  max_earnings: prettyFloatMoney(max_earnings),
                })}
              </span>
            ) : (
              <span className={classnames(s.earnings, 'f-18 f-500')}>
                + {prettyFloatMoney(earnings)}
              </span>
            )}

            {fundraising_id > 92 && repayment_status < 100 && (
              <InfoIcon onClick={onTooltipClick} style={{ minWidth: '20px' }} />
            )}
          </div>
        </div>
      </div>

      <RoundButton
        label={t('loan_card.more_details')}
        onClick={onDetailsClick}
        className={s.more}
        fullWidth
      />
    </div>
  );
};
