import { useState } from 'react';
import classnames from 'classnames';
import AvatarEditor from 'react-avatar-editor';
import { dataURItoBlob } from '@/helpers/utils';
import { RotateIcon, XIcon } from '@/components/UI/Icons';
import './index.scss';

export const cropAvatar = editorRef => {
  try {
    const canvasScaled = editorRef
      .getImageScaledToCanvas()
      .toDataURL('image/jpeg');
    const imageBlob = dataURItoBlob(canvasScaled);
    const formData = new FormData();
    formData.append(
      'file',
      imageBlob,
      `avatar_${new Date().toISOString()}.jpg`,
    );
    return formData;
  } catch (e) {
    console.warn('Could not resolve image');
    return null;
  }
};

export const AvatarEdit = props => {
  const [state, setState] = useState({
    scale: 1,
    rotate: 0,
  });

  const rotate = () => {
    setState(prev => ({ ...prev, rotate: prev.rotate + 90 }));
  };

  const {
    src,
    className,
    name,
    size = 250,
    setFieldValue,
    error,
    setEditorRef,
  } = props;

  return (
    <div
      className={classnames(
        'avatar-edit__container',
        error && 'avatar-edit__error',
        className,
      )}
    >
      <AvatarEditor
        ref={setEditorRef}
        image={src}
        width={size}
        height={size}
        border={0}
        color={[255, 255, 255, 0.6]} // RGBA
        scale={state.scale}
        rotate={state.rotate}
        className="avatar-edit"
      />
      <RotateIcon onClick={rotate} className="avatar-edit__rotate" />
      <button
        type="button"
        onClick={() => setFieldValue(name, '')}
        className="avatar-edit__remove"
      >
        <XIcon className="avatar-edit__remove-icon" />
      </button>
      <div />
    </div>
  );
};
