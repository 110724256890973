import classnames from 'classnames';
import s from './index.module.scss';

export const RoundButton = ({
  label,
  className,
  children,
  fullWidth,
  fillBackground,
  ...props
}) => {
  return (
    <button
      type={props.type || 'button'}
      className={classnames(
        s.button,
        fillBackground && s.button__filled,
        fullWidth && s.button__fullwidth,
        className,
      )}
      {...props}
    >
      {children || <span className={s.button__label}>{label}</span>}
    </button>
  );
};
