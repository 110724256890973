import { useRef } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { checkForValidFile } from '@/helpers/utils';
import { PHOTO_ALLOWED_FORMATS } from '@/helpers/common';
import { AvatarEdit } from '@/components/AvatarEdit';
import { Avatar } from '@/components/UI/Avatar';
import { RoundButton } from '@/components/UI/RoundButton';
import './index.scss';

const ProfileAvatarForm = ({
  photo,
  fullname,
  nameInitials,
  onSubmit,
  onRemove,
}) => {
  const { t } = useTranslation('modal');
  const editorRef = useRef(null);

  const setEditorRef = ref => {
    editorRef.current = ref;
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, formikBag) =>
        onSubmit(values, formikBag, editorRef.current)
      }
      initialValues={{
        file: null,
        photoSrc: photo && photo.file,
      }}
    >
      {({ values, handleSubmit, setFieldError, setFieldValue }) => (
        <form className="profile_avatar_form" onSubmit={handleSubmit}>
          <h1 className="profile_avatar_form__photo f-26 f-500">
            {t('profile_avatar_form.update_photo')}
          </h1>
          <div className="profile_avatar_form__content">
            {values.file ? (
              <AvatarEdit
                src={values.file}
                name="file"
                size={260}
                setFieldValue={setFieldValue}
                setEditorRef={setEditorRef}
                className="profile_avatar_form__editor"
              />
            ) : (
              <Avatar
                size={260}
                src={values.photoSrc}
                alt={fullname}
                nameInitials={nameInitials}
                className="profile_avatar_form__avatar"
              />
            )}
          </div>

          <div className="profile_avatar_form__controls">
            <label
              htmlFor="file"
              className="profile_avatar_form__upload f-16 f-500"
            >
              {t('profile_avatar_form.change_photo')}
            </label>
            <input
              className="profile_avatar_form__input"
              type="file"
              name="file"
              id="file"
              onChange={e => {
                const file = e.target.files[0];
                const { isValid, type, size } = checkForValidFile(
                  file,
                  PHOTO_ALLOWED_FORMATS,
                  12582912,
                );
                !isValid &&
                  size &&
                  setFieldError(
                    'file',
                    t('profile_avatar_form.file_size_too_big'),
                  );
                !isValid &&
                  type &&
                  setFieldError(
                    'file',
                    t('profile_avatar_form.incorrect_file_type'),
                  );
                isValid && setFieldValue('file', file);
              }}
            />

            {photo && (
              <RoundButton
                label={t('profile_avatar_form.remove_photo')}
                className="profile_avatar_form__remove"
                type="button"
                onClick={onRemove}
                fullWidth
              />
            )}

            {values.file && (
              <RoundButton
                label={t('profile_avatar_form.save')}
                fillBackground
                fullWidth
                type="submit"
                className="profile_avatar_form__save"
                onSubmit={handleSubmit}
              />
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ProfileAvatarForm;
