import { APP_LINKS } from '@/helpers/links';

export const TERMS = t => [
  {
    is_active: true,
    id: 'conservative',
    title: t('v3.data.group_a'),
    investor_annual_rate: t('v3.data.up_to_12_4_percent'),
    term: t('v3.data.6_months'),
    flat_fee: t('v3.data.up_to_1_percent_per_month'),
    flat_fee_repayment: t('v3.data.at_the_end_of_the_term'),
    downside_protection: t('v3.data.triple_secured'),
    details_link: APP_LINKS.helpLoanTermsGroup('v3-group-a'),
  },
  {
    is_active: true,
    id: 'moderate',
    title: t('v3.data.group_b'),
    investor_annual_rate: t('v3.data.up_to_25_percent'),
    term: t('v3.data.6_months'),
    flat_fee: t('v3.data.9_percent_for_6_months'),
    flat_fee_repayment: t('v3.data.at_the_end_of_the_term'),
    downside_protection: t('v3.data.single_secured'),
    details_link: APP_LINKS.helpLoanTermsGroup('v3-group-b'),
  },
  {
    is_active: false,
    id: 'growth',
    title: t('v3.data.group_c'),
    investor_annual_rate: t('v3.data.dash'),
    term: t('v3.data.dash'),
    flat_fee: t('v3.data.dash'),
    flat_fee_repayment: t('v3.data.dash'),
    downside_protection: t('v3.data.dash'),
    details_link: APP_LINKS.helpLoanTermsGroup('v3-group-c'),
  },
];
