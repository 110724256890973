import Select from 'react-select';

export const ReactSelectField = ({ selected, onChange, options, ...props }) => (
  <Select
    defaultValue={selected}
    onChange={onChange}
    options={options}
    {...props}
  />
);
