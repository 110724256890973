import React, { useEffect, useState } from 'react';
import moment from 'moment/min/moment-with-locales';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RoundButton } from '@/components/UI/RoundButton';
import {
  getWithdrawHistory,
  getWithdrawInfo,
} from '@/store/actions/fundActions';
import { DATE_FORMAT_DD_MM_YYYY } from '@/helpers/common';
import { prettyFloatMoney } from '@/helpers/utils';
import { cancelWithdrawRequest } from '@/store/services/fundServices';
import { getBalance } from '@/store/actions/dashboardActions';
import './index.scss';

const REQUEST_STATUSES = t => ({
  new: { id: 'new', title: t('sent') },
  in_process: { id: 'in_process', title: t('in_process') },
  completed: { id: 'completed', title: t('processed') },
  cancelled: { id: 'cancelled', title: t('cancelled') },
});

const WithdrawHistory = ({ resetTime, className }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('withdraw');
  const { withdrawHistory } = useSelector(state => state.fundStore);
  const [state, setState] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    dispatch(getWithdrawHistory(state));
  }, []);

  useEffect(() => {
    setState({ page: 1, limit: 10 });
    dispatch(getWithdrawHistory({ page: 1, limit: 10 }));
  }, [resetTime]);

  const getNext = totalPages => {
    if (state.page < totalPages) {
      const nextPage = state.page + 1;
      dispatch(
        getWithdrawHistory(
          {
            ...state,
            page: nextPage,
          },
          true,
        ),
      );

      return setState({ ...state, page: nextPage });
    }
  };

  const { page } = state;
  const { data } = withdrawHistory;

  if (!data || !data?.total_count) {
    return null;
  }

  return (
    <div className={classnames('withdraw_history', className)}>
      <h2 className="f-26 f-500">{t('withdrawals_history')}</h2>
      <ul className="withdraw_history__list">
        {data.list.map(request => (
          <RequestItem request={request} key={request.id} />
        ))}
      </ul>
      {data && page < data.total_pages && (
        <RoundButton
          type="button"
          label={t('show_more')}
          className="withdraw_history__more"
          onClick={() => getNext(data.total_pages)}
        />
      )}
    </div>
  );
};

export default WithdrawHistory;

const RequestItem = ({ request }) => {
  const [status, setStatus] = React.useState(request.status);
  const { t } = useTranslation('withdraw');
  const dispatch = useDispatch();
  const REQUEST_STATUSES_T = REQUEST_STATUSES(t);

  const handleCancel = () => {
    cancelWithdrawRequest(request.id).then(res => {
      if (res && res.success) {
        setStatus(REQUEST_STATUSES_T.cancelled.id);
        dispatch(getBalance());
        dispatch(getWithdrawInfo(true));
      }
    });
  };

  return (
    <li className="withdraw_history__list_item">
      <span>{moment(request.created_at).format(DATE_FORMAT_DD_MM_YYYY)}</span>
      <span>
        <Trans
          ns="withdraw"
          i18nKey="transfer_to_the_account"
          values={{
            amount: prettyFloatMoney(request.amount),
            accType: request.external_account.account_type,
            accNumber: request.external_account.account_number,
            status: REQUEST_STATUSES_T[status].title,
          }}
          components={{
            span: <span style={{ textTransform: 'capitalize' }} />,
          }}
        />{' '}
        {status === REQUEST_STATUSES_T.new.id && (
          <button
            type="button"
            className="withdraw_history__cancel"
            onClick={handleCancel}
          >
            {t('cancel')}
          </button>
        )}
      </span>
    </li>
  );
};
