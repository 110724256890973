import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLongNext } from '../Icons';
import './index.scss';

export const ModalCloseButton = ({ ...other }) => (
  <button type="button" {...other}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99926 9.41609L14.2902 15.7072C14.6811 16.0982 15.3163 16.097 15.7063 15.7072C16.097 15.3163 16.0972 14.6821 15.706 14.2911L9.41537 8.00023L15.7063 1.70932C16.097 1.31837 16.097 0.68392 15.7063 0.293212C15.3153 -0.0977374 14.6811 -0.0977374 14.2902 0.293212L7.99926 6.58412L1.70835 0.293212C1.3174 -0.0977374 0.683197 -0.0977374 0.292489 0.293212C0.0970138 0.488687 0.000482931 0.744011 0.000724258 1.00078C2.77177e-07 1.25804 0.0970138 1.51384 0.292489 1.70932L6.5834 8.00023L0.293212 14.2902C0.0977376 14.4859 -0.000240882 14.7422 4.44708e-07 14.9985C0.000483098 15.255 0.0977377 15.511 0.292971 15.7065C0.683679 16.0972 1.31813 16.0975 1.70908 15.7065L7.99926 9.41609Z"
        fill="#9E98A3"
      />
    </svg>
  </button>
);

export const BackButton = ({ to, onClick, className, ...props }) => {
  const { t } = useTranslation();
  const classNames = classnames('back-button', className);
  const content = (
    <>
      <ArrowLongNext />
      <span className="f-16 f-500">{t('buttons.back')}</span>
    </>
  );
  return to ? (
    <Link to={to} {...props} className={classNames}>
      {content}
    </Link>
  ) : (
    <button onClick={onClick} {...props} className={classNames}>
      {content}
    </button>
  );
};
