import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { VerifyForm, SOURCES } from '@/components/VerifyForm';
import { SET_VERIFF_STATUS_CODE } from '@/store/types/profileTypes';
import { VERIFF_CODES } from '@/helpers/constants';
import { APP_LINKS } from '@/helpers/links';
import s from './index.module.scss';

export const StepVeriff = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className={s.wrapper}>
      <VerifyForm
        source={SOURCES.registration}
        onSuccess={() => {
          setTimeout(() => {
            dispatch({
              type: SET_VERIFF_STATUS_CODE,
              code: VERIFF_CODES.submitted,
            });
            navigate(APP_LINKS.dashboard);
          }, 2000);
        }}
      />
    </div>
  );
};
