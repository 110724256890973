import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RoundLink } from '@/components/UI/RoundLink';
import { APP_LINKS } from '@/helpers/links';
import s from '../index.module.scss';
import { STEPS } from '../index';

export const AddCashStep = ({ passed }) => {
  const { t } = useTranslation('dashboard');
  const active = passed.includes(STEPS.add_cash);

  return (
    <li
      id={`vpw_${STEPS.add_cash}`}
      className={classnames(s.list_item, active && s.list_item_active)}
    >
      <div className={s.list_item_left}>
        <span
          className={classnames(
            s.circle,
            active && s.circle_active,
            'f-26 f-400',
          )}
        >
          04
        </span>
      </div>
      <div className={s.list_item_right}>
        <div className={classnames(s.list_item_title, 'f-26 f-400')}>
          {t('wizard_step_4_add_cash')}
        </div>
        <div
          className={classnames(
            s.list_item_content,
            active &&
              !passed.includes(STEPS.start_investing) &&
              s.list_item_content_active,
            'f-16-24 f-400',
          )}
        >
          <div className={s.list_item_content_inner}>
            <div className="f-16 f-400">
              {t('wizard_step_4_start_investing_info')}
            </div>
            <div className="f-16 f-400" style={{ marginTop: '8px' }}>
              {t('wizard_step_4_notify_email')}
            </div>
            <RoundLink
              label={t('wizard_step_4_add_cash')}
              fillBackground
              fullWidth
              path={APP_LINKS.addFunds}
              className={s.button}
            />
          </div>
        </div>
      </div>
    </li>
  );
};
