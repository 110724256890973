import { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RoundButton } from '../RoundButton';
import Notify from '../../Notification';
import './index.scss';

export const CopyRoundButton = ({ text, label, successLabel, onClick }) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation('kit');

  const onSuccess = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const onFailure = () => {
    Notify.error({ text: t('notify.could_not_copy') });
  };

  return (
    <RoundButton
      label={copied ? successLabel : label}
      fillBackground
      className={classnames('copy_round_button', copied && 'copied')}
      onClick={() => {
        navigator.clipboard.writeText(text).then(onSuccess, onFailure);
        onClick && onClick();
      }}
    />
  );
};
