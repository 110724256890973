import axios from '@/axios-api';
import Notify from '@/components/Notification';
import Pathes from '@/helpers/pathes';
import { getMessage, getQuery } from '@/helpers/utils';
import i18n from '@/i18n';
import {
  CREATE_WITHDRAW_REQUEST,
  GET_ALLOCATE_INFO,
  GET_PRE_ALLOCATE_INFO,
  GET_WITHDRAW_HISTORY,
  GET_WITHDRAW_INFO,
  SET_BANK_DETAILS_LIST,
  SET_PENDING_TRANSACTIONS,
  UPDATE_REINVEST_STATUS,
} from '../types/fundTypes';

const { t } = i18n;

export const getAllocateInfoRequest = () => ({
  type: GET_ALLOCATE_INFO.REQUEST,
});
export const getAllocateInfoSuccess = payload => ({
  type: GET_ALLOCATE_INFO.SUCCESS,
  payload,
});
export const getAllocateInfoFailure = error => ({
  type: GET_ALLOCATE_INFO.FAILURE,
  error,
});

export const getPreAllocateInfoRequest = () => ({
  type: GET_PRE_ALLOCATE_INFO.REQUEST,
});
export const getPreAllocateInfoSuccess = payload => ({
  type: GET_PRE_ALLOCATE_INFO.SUCCESS,
  payload,
});
export const getPreAllocateInfoFailure = error => ({
  type: GET_PRE_ALLOCATE_INFO.FAILURE,
  error,
});

export const updateReinvestStatusRequest = () => ({
  type: UPDATE_REINVEST_STATUS.REQUEST,
});
export const updateReinvestStatusSuccess = payload => ({
  type: UPDATE_REINVEST_STATUS.SUCCESS,
  payload,
});
export const updateReinvestStatusFailure = error => ({
  type: UPDATE_REINVEST_STATUS.FAILURE,
  error,
});

export const getWithdrawInfoRequest = () => ({
  type: GET_WITHDRAW_INFO.REQUEST,
});
export const getWithdrawInfoSuccess = payload => ({
  type: GET_WITHDRAW_INFO.SUCCESS,
  payload,
});
export const getWithdrawInfoFailure = error => ({
  type: GET_WITHDRAW_INFO.FAILURE,
  error,
});

export const getWithdrawHistoryRequest = () => ({
  type: GET_WITHDRAW_HISTORY.REQUEST,
});
export const getWithdrawHistorySuccess = payload => ({
  type: GET_WITHDRAW_HISTORY.SUCCESS,
  payload,
});
export const getWithdrawHistoryFailure = error => ({
  type: GET_WITHDRAW_HISTORY.FAILURE,
  error,
});

export const setBankDetailsList = payload => ({
  type: SET_BANK_DETAILS_LIST,
  payload,
});

export const getAllocateInfo = () => {
  return dispatch => {
    dispatch(getAllocateInfoRequest());
    return axios
      .get(Pathes.Funds.allocateInfo)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getAllocateInfoSuccess(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getAllocateInfoFailure(e.message)));
  };
};

export const getPreAllocateInfo = () => {
  return dispatch => {
    dispatch(getPreAllocateInfoRequest());
    return axios
      .get(Pathes.Funds.preAllocateInfo)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getPreAllocateInfoSuccess(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getPreAllocateInfoFailure(e.message)));
  };
};

export const updatePreAllocateInfo = payload => {
  return dispatch => {
    return axios
      .post(Pathes.Funds.preAllocateInfo, payload)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getPreAllocateInfoSuccess(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => {
        Notify.error({ text: e.message });
        return { data: null, success: false };
      });
  };
};

export const allocateFunds = payload => {
  return dispatch => {
    return axios
      .post(Pathes.Funds.allocateFunds, payload)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);

        if (status === 200) {
          Notify.success({ text: t('kit:notify.allocate_fund_success') });
          dispatch(getAllocateInfoSuccess(data));
          return { data, success: true };
        }

        if (status === 422 || status === 406) {
          return { error: message, success: false };
        }

        throw new Error(message);
      })
      .catch(e => {
        Notify.error({ text: e.message });
        return { success: false };
      });
  };
};

export const updateReinvestStatus = (value, disableNotification) => {
  return dispatch => {
    dispatch(updateReinvestStatusRequest());
    return axios
      .put(Pathes.Funds.reinvestStatus, { reinvest: value })
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          !disableNotification &&
            Notify.success({ text: t('kit:notify.reinvest_status_changed') });
          dispatch(updateReinvestStatusSuccess(data));
          return { ...data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(updateReinvestStatusFailure(e.message)));
  };
};

export const getWithdrawInfo = skipLoading => {
  return dispatch => {
    !skipLoading && dispatch(getWithdrawInfoRequest());
    return axios
      .get(Pathes.Funds.withdrawInfo)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getWithdrawInfoSuccess(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getWithdrawInfoFailure(e.message)));
  };
};

export const createWithdrawRequest = payload => {
  return (dispatch, getState) => {
    return axios
      .post(Pathes.Funds.withdrawReq, payload)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const current = getState().fundStore.withdrawInfo.data;
          current &&
            dispatch({
              type: CREATE_WITHDRAW_REQUEST,
              data: { ...current, cash: data.cash },
            });
          Notify.success({ text: message });
          return { ...data, success: true };
        }

        if (status === 400) {
          Notify.error({ text: message });
        }

        throw new Error(message);
      })
      .catch(e => ({ error: e.message }));
  };
};

export const getWithdrawHistory = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getWithdrawHistoryRequest());
    return axios
      .get(Pathes.Funds.withdrawHistory + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().fundStore.withdrawHistory.data;
          if (!isNext || !prevData) {
            dispatch(getWithdrawHistorySuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getWithdrawHistorySuccess(updatedData));
          return { data: updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getWithdrawHistoryFailure(e.message)));
  };
};

export const getPendingTransactions = () => {
  return dispatch => {
    return axios
      .get(Pathes.Funds.pendingTransactions)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch({
            type: SET_PENDING_TRANSACTIONS,
            payload: data,
          });
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch({
          type: SET_PENDING_TRANSACTIONS,
          payload: null,
        });
        return { success: false, message: e.message };
      });
  };
};

// Get bank details list
export const getBankDetailsList = () => {
  return dispatch => {
    return axios
      .get(Pathes.Funds.bankDetails)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(setBankDetailsList(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => ({ success: false, error: e.message }));
  };
};
