import { useTranslation } from 'react-i18next';
import { RoundButton } from '@/components/UI/RoundButton';
import config from '@/config';
import './index.scss';

const ReferralEmailModal = ({ values, onSubmit, errors, inviteLink }) => {
  const { name } = values;
  const { t } = useTranslation('modal');
  const link = `${config.apiURL}invitation_email_preview/?first_name=${name || t('ref_email_modal.hey')}&invitation_link=${inviteLink}`;
  return (
    <div className="referral_email_modal">
      <div className="referral_email_modal__inner">
        <iframe
          title="affiliate_email_modal"
          id="affiliate_email_modal"
          src={link}
        />
      </div>

      <RoundButton
        label={t('ref_email_modal.send_email')}
        fillBackground
        fullWidth
        className="referral_email_modal__send"
        disabled={!values.email || (errors && errors.email)}
        onClick={onSubmit}
      />
    </div>
  );
};

export default ReferralEmailModal;
