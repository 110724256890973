import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { prettyDate } from '@/helpers/utils';
import { AverageTime } from '@/components/AverageTime';
import s from './index.module.scss';

export const ArticleCard = ({ article, className }) => {
  const { t } = useTranslation('blog');

  return (
    <MyLink
      href={APP_LINKS.blogDetail(article.slug)}
      className={classnames(s.wrapper, className)}
      data-post-id={article.id}
    >
      <div className={s.image}>
        <img
          src={article.main_image.replace('test-investor', 'investor')}
          alt={article.title}
          loading="lazy"
          width={365}
          height={240}
        />
      </div>

      <div className={s.content}>
        <div className={classnames('single_xs', 'f-500', s.meta)}>
          <div>{t('investment_tips')}</div>
          <div>{t('brands')}</div>
        </div>
        <div
          className={classnames('single_s', 'f-500', s.title)}
          title={article.title}
        >
          {article.title}
        </div>
        <div className={classnames('single_xs', 'f-500', s.info)}>
          <div>{prettyDate(article.created_at)}</div>
          <AverageTime text={article.reading_time} />
        </div>
      </div>
    </MyLink>
  );
};
