const BASE_KEYS = {
  referral: 'referral',
  auth: 'auth',
  user: 'user',
};

export const QUERY_KEYS = {
  referral: {
    validation: `${BASE_KEYS.referral}_validation`,
  },
  auth: {
    email_otp_status: `${BASE_KEYS.auth}_email_otp_status`,
  },
  user: {
    profile: `${BASE_KEYS.user}_profile`,
  },
};
