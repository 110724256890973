import React from 'react';
import classnames from 'classnames';
import s from './index.module.scss';

export const FileUploadField = ({
  name,
  label,
  description,
  onChange,
  onBlur,
  error,
  multiple = true,
  accept,
  className,
}) => (
  <label
    htmlFor={name}
    className={classnames(
      s.statement_upload__label,
      error && s.statement_upload__label_error,
      className,
    )}
  >
    <div className={s.statement_upload__centered}>
      {label && (
        <div className={classnames(s.statement_upload__centered_title, 'f-16')}>
          {label}
        </div>
      )}
      {typeof error === 'string' ? (
        <div className={classnames(s.statement_upload__centered_error, 'f-12')}>
          {error}
        </div>
      ) : (
        <div className={classnames(s.statement_upload__centered_desc, 'f-12')}>
          {description || 'Upload file up to 12Mb'}
        </div>
      )}
    </div>
    <input
      type="file"
      multiple={multiple}
      name={name}
      id={name}
      onChange={onChange}
      onBlur={onBlur}
      className={s.statement_upload__input}
      accept={accept && accept.join(', ')}
    />
  </label>
);
