import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { AppRoutes } from '@/routes';
import store from '@/store/configureStore';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as serviceWorker from './serviceWorker';
import './i18n';

const queryClient = new QueryClient();

createRoot(document.getElementById('root')).render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <RouterProvider router={AppRoutes} />
    </Provider>
  </QueryClientProvider>,
);

serviceWorker.unregister();
