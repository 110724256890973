import { VeriffForm } from './form';

const WizardVeriffModal = ({ id, onSuccess, onBack, onClose }) => {
  const handleSuccess = veriffProps => {
    onSuccess && onSuccess(veriffProps);
  };
  return (
    <VeriffForm
      id={id}
      onBack={onBack}
      onSuccess={handleSuccess}
      onClose={onClose}
    />
  );
};

export default WizardVeriffModal;
