import { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getNameInitials } from '@/helpers/utils';
import { TextareaField } from '@/components/UI/TextareaField';
import { Avatar } from '@/components/UI/Avatar';
import { RoundButton } from '@/components/UI/RoundButton';
import { useGetMe } from '@/hooks/useGetMe';
import s from './index.module.scss';

export const WriteMessage = ({ className, onCancel, onReply }) => {
  const { t } = useTranslation('client_questions');
  const [text, setText] = useState('');
  const { user } = useGetMe();

  if (!user) {
    return null;
  }

  return (
    <div className={classnames(s.message, className)}>
      <div className={s.message_content}>
        <Avatar
          size={50}
          src={user.photo?.file}
          alt={user.first_name}
          nameInitials={getNameInitials(user.first_name, user.last_name)}
          className="write_message_avatar"
        />
        <div className={s.message_input}>
          <TextareaField
            placeholder={t('card.write_message.type_here')}
            value={text}
            onChange={e => setText(e.target.value)}
            className={classnames(s.message_textarea, 'write_message_input')}
          />
        </div>
      </div>

      <div className={classnames(s.message_controls, 'write_message_controls')}>
        <RoundButton
          label={() => t('card.write_message.cancel')}
          onClick={onCancel}
        />
        <RoundButton
          label={() => t('card.write_message.reply')}
          fillBackground
          onClick={() => onReply(text)}
        />
      </div>
    </div>
  );
};
