import { useTranslation } from "react-i18next"
import { components } from 'react-select';
import { ReactSelectField } from '../../../components/UI/ReactSelectField';
import Checkbox from '../../../components/UI/Checkbox';
import ClearIcon from '../../../assets/images/icon-clear.svg?react';

const customStyles = {
  multiValue: provided => ({
    ...provided,
    display: 'none',
  }),
  control: provided => ({
    ...provided,
    borderBottom: '1px solid #d8d6da',
    borderRadius: 0,
    borderColor: 'transparent',
    boxShadow: 'none',
    '&:hover': 'border',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '2px 8px 2px 0',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#9E98A3',
  }),
  option: provided => ({
    ...provided,
    background: 'transparent',
    color: '#000000',
  }),
};

const ValueContainer = ({ children, ...props }) => {
  const { t } = useTranslation('reports');
  const values = props.getValue();
  return (
    <components.ValueContainer {...props}>
      {children}
      {!!values.length && (
        <span className="f-16 c-grey">
          {t('activity_selected_of', { value: values.length, total: props.options.length })}
        </span>
      )}
    </components.ValueContainer>
  );
};

const Option = props => (
  <components.Option {...props}>
    <Checkbox
      name="activityType"
      isChecked={props.isSelected}
      disabled
      render={() => <span className="f-16">{props.label}</span>}
      className="activity_filters__select_checkbox"
    />
  </components.Option>
);

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <ClearIcon style={{ cursor: 'pointer' }} />
  </components.ClearIndicator>
);

const ActivityTypeSelect = props => (
  <ReactSelectField
    {...props}
    styles={customStyles}
    components={{ ValueContainer, ClearIndicator, Option }}
    isMulti
    closeMenuOnSelect={false}
    isSearchable={false}
    hideSelectedOptions={false}
  />
);

export default ActivityTypeSelect;
