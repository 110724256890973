import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackButton from '@/components/BackButton';
import { canGoBack } from '@/helpers/utils';
import { APP_LINKS } from '@/helpers/links';
import { GROUP_DETAIL as GROUP_DETAIL_T } from './data';
import s from './index.module.scss';

export const GroupDetailVersion01 = ({ id }) => {
  const navigate = useNavigate();
  const { innerWidth } = useSelector(state => state.commonStore);
  const { t } = useTranslation('loan_terms');
  const GROUP_DETAIL = GROUP_DETAIL_T(t);
  const { title, termsSection, protectionSection, repaymentSection } =
    GROUP_DETAIL[id](innerWidth);

  return (
    <div className={s.page}>
      <div className="container-inner">
        <BackButton
          onClick={() =>
            canGoBack() ? navigate(-1) : navigate(APP_LINKS.helpLoanTerms('v1'))
          }
        />
        <h1 className="f-42 f-500">{title}</h1>
        {termsSection}
        {protectionSection}
        {repaymentSection}
      </div>
    </div>
  );
};
