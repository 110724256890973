import { Preloader } from '@/components/Preloader';
import './index.scss';

export const ProcessingLoader = ({ loading }) => {
  return (
    loading && (
      <div className="processing_loader">
        <div className="processing_content">
          <div className="processing_text f-20 f-400">
            Processing, please wait
          </div>
          <Preloader />
        </div>
      </div>
    )
  );
};
