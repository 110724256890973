import { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import momentTZ from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { secondsToDhms } from '@/helpers/utils';
import { RoundButton } from '@/components/UI/RoundButton';
import NotificationBlock from '../../NotificationBlock';
import { MyLink } from '@/components/MyLink';
import Dropdown from '@/components/Dropdown';
import {
  LOAN_GROUPS_IDS,
  TIMEZONE_ESTONIA,
  USER_STATUSES,
} from '@/helpers/constants';
import { NOTIFICATIONS as NOTIFICATIONS_T } from '@/components/Notification/list';
import { APP_LINKS } from '@/helpers/links';
import WarningBlock from '@/components/UI/WarningBlock';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import { SuspendResidenceWarning } from '@/components/Warnings/SuspendResidenceWarning';
import { ChooseStartups } from '@/components/ChooseStartups';
import { MinInvestAmountWarning } from '@/components/Warnings/MinInvestAmountWarning';
import { InvestingProgressBar } from '@/components/InvestingProgressBar';
import { BonusBanner } from '../../BonusProgram/banner';
import { isSubmittedVeriff } from '@/helpers/user';
import { LoanTerms } from '@/components/LoanTerms';
import { useCheckPlannedPayments } from '@/components/Notification/hooks/useCheckPlannedPayments';
import './index.scss';

const InvPreRound = ({ status }) => {
  const { t } = useTranslation('investing');
  const { t: tCommon } = useTranslation('common');
  const [groupID, setGroupID] = useState(LOAN_GROUPS_IDS.conservative.id); // long_term | single_loan
  const { veriffStatusCode } = useSelector(state => state.profileStore);
  const { fundraisingDates } = useSelector(state => state.dashboardStore);
  const profile = useSelector(state => state.profileStore.profile.data);
  const { hasPlannedPayment } = useCheckPlannedPayments();

  const NOTIFICATIONS = NOTIFICATIONS_T(tCommon);

  const humanizeDate = value => {
    if (value) {
      const now = moment();
      const date = moment(value);
      const timeLeft = secondsToDhms(date.diff(now, 'seconds'));
      return timeLeft && t('investing_will_be_available_in', { timeLeft });
    }
  };

  const onCalculate = groupID => {
    setGroupID(groupID || LOAN_GROUPS_IDS.conservative.id);
    const el = document.getElementById('calculator');
    el && el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  if (!profile) {
    return null;
  }

  const notifications = [NOTIFICATIONS.next_round_starts(status.start_date)];

  // TODO SD-188 not finished
  // const notifications = [NOTIFICATIONS.round_ended_earlier()];
  hasPlannedPayment && notifications.push(NOTIFICATIONS.october_weekends());

  fundraisingDates?.id === 101 &&
    fundraisingDates?.is_active &&
    notifications.push(NOTIFICATIONS.regular_top_ups());

  return (
    <div className="inv-pre-round">
      <h1 className="f-42 f-500">{t('ipr_the_round_starts_soon')}</h1>

      {profile?.country_code === 'US' &&
        status?.min_investment_amount === 1000 && (
          <MinInvestAmountWarning className="inv-pre-round__warning-header" />
        )}

      {profile?.status === USER_STATUSES.suspended ? (
        <SuspendResidenceWarning className="inv-pre-round__suspend_top" />
      ) : profile.status !== USER_STATUSES.verified ? (
        <WarningBlock withBorder className="inv-pre-round__warning-header">
          {isSubmittedVeriff(veriffStatusCode) ? (
            <div className="f-16 f-400">
              {t('investing_available_only_verified_investors')}
            </div>
          ) : (
            <div className="f-16 f-400">
              {t('complete_verification_process_to_start_investing')}{' '}
              <MyLink href={APP_LINKS.dashboard} className="c-grey">
                {t('complete_verification')} &nbsp;→
              </MyLink>
            </div>
          )}
        </WarningBlock>
      ) : (
        <>
          <NotificationBlock
            className="inv-pre-round__notifications"
            notifications={notifications}
          />

          <BonusBanner className="inv-pre-round__bonus" />
        </>
      )}

      <InvestingProgressBar className="inv-pre-round__progress" />

      <Dropdown
        title={t('consumer_goods_brands_batch')}
        className="inv-pre-round__founders"
        defaultShow
        disableToggle
      >
        <ParticipatingStartupsList />
      </Dropdown>

      <LoanTerms onCalculate={onCalculate} />

      {/* <LoanCalculator */}
      {/*   groupID={groupID} */}
      {/*   setGroupID={setGroupID} */}
      {/*   status={status} */}
      {/*   onChange={type => */}
      {/*     type === CALCULATOR_TYPES.long_term && setGroupID(null) */}
      {/*   } */}
      {/* /> */}

      <ChooseStartups />

      <div className="inv-pre-round__bottom">
        {profile?.status === USER_STATUSES.suspended ? (
          <SuspendResidenceWarning className="inv-pre-round__suspend_bottom" />
        ) : (
          profile.status !== USER_STATUSES.verified && (
            <WarningBlock className="inv-pre-round__warning-note">
              {isSubmittedVeriff(veriffStatusCode) ? (
                <div className="f-16 f-400">
                  {t('investing_available_only_verified_investors')}
                </div>
              ) : (
                <div className="f-16 f-400">
                  {t('complete_verification_process_to_start_investing')}{' '}
                  <MyLink href={APP_LINKS.dashboard} className="c-grey">
                    {t('complete_verification')} &nbsp;→
                  </MyLink>
                </div>
              )}
            </WarningBlock>
          )
        )}
        <RoundButton
          type="button"
          label={
            humanizeDate(
              momentTZ.tz(status.start_date, TIMEZONE_ESTONIA).add(12, 'hours'),
            ) || t('wait_a_little_investing_will_start_soon')
          }
          className="inv-pre-round__button"
          fillBackground
          fullWidth
          disabled
        />
      </div>
    </div>
  );
};

export default InvPreRound;
