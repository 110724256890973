import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PhoneVerificationForm from '@/components/Forms/PhoneVerificationForm';
import { validatePhoneNumber } from '@/store/services/userServices';
import { getUserProfile } from '@/store/actions/profileActions';
import s from './index.module.scss';

const PhoneVerificationModal = ({ onClose, onBack, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('modal');
  const profile = useSelector(state => state.profileStore.profile.data);

  const onSubmit = async (values, { setErrors }) => {
    if (values.code.length < 6) {
      return setErrors({
        code: t('phone_verify_modal.verification_code_required'),
      });
    }

    const res = await validatePhoneNumber({
      phone_number: phone,
      code: values.code.join(''),
    });

    if (res && res.success) {
      dispatch(getUserProfile());
      onSuccess ? onSuccess() : onClose();
    } else {
      setErrors({ code: res.message });
    }
  };

  if (!profile) {
    return null;
  }

  const { phone } = profile;

  return (
    <PhoneVerificationForm
      phone={phone}
      onSubmit={onSubmit}
      className={s.wrapper}
      defaultSubmit
      onBack={onBack}
    />
  );
};

export default PhoneVerificationModal;
