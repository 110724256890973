import classnames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RadioButton from '@/components/UI/RadioButton';
import { RoundButton } from '@/components/UI/RoundButton';
import { RoundLink } from '@/components/UI/RoundLink';
import { APP_LINKS } from '@/helpers/links';
import s from './index.module.scss';

export const INVESTOR_TYPES = t => ({
  restricted_investor: {
    title: t('investor_types.restricted_investor_title'),
    description: t('investor_types.restricted_investor_description'),
  },
  advised_investor: {
    title: t('investor_types.advised_investor_title'),
    description: t('investor_types.advised_investor_description'),
  },
  sophisticated_investor: {
    title: t('investor_types.sophisticated_investor_title'),
    description: t('investor_types.sophisticated_investor_description'),
  },
  high_net_worth_investor: {
    title: t('investor_types.high_net_worth_investor_title'),
    description: t('investor_types.high_net_worth_investor_description'),
  },
});

const VALIDATION_SCHEMA = Yup.object({
  investor_type: Yup.string(),
});

const InvestorTypeForm = ({ initialValues, onSubmit, onCancel, onBack }) => {
  const location = useLocation();
  const { t } = useTranslation('profile');
  const profile = useSelector(state => state.profileStore.profile.data);
  const isSignUp = location.pathname === APP_LINKS.signUp;
  const isWizard = location.pathname === APP_LINKS.dashboard;
  const INVESTOR_TYPES_T = INVESTOR_TYPES(t);

  return (
    <Formik
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      initialValues={
        initialValues || {
          investor_type: profile?.investor?.investor_type || '',
        }
      }
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <form className="" onSubmit={handleSubmit}>
          <h1 className={classnames(s.title, 'f-32 f-500')}>
            {t('select_your_type_of_investor')}
          </h1>
          <p className={classnames(s.subtitle, 'f-16 f-400')}>
            {t('in_accordance_with_applicable_legislation')}
          </p>

          <div className={s.radio_buttons}>
            {Object.keys(INVESTOR_TYPES_T).map(key => (
              <RadioButton
                key={key}
                value={key}
                name="investor_type"
                checked={values.investor_type === key}
                className={s.radio_item}
                onChange={() => setFieldValue('investor_type', key)}
              >
                <h5 className="f-20 f-500">{INVESTOR_TYPES_T[key].title}</h5>
                <p className="f-16 f-400">
                  {INVESTOR_TYPES_T[key].description}
                </p>
              </RadioButton>
            ))}
          </div>

          {isSignUp ? (
            <div className={s.signup_buttons}>
              <RoundButton
                label={t('continue')}
                type="submit"
                onSubmit={handleSubmit}
                fillBackground
                fullWidth
                withArrow
                className={s.signup_button}
              />
              <RoundLink
                label={t('continue_later')}
                path={APP_LINKS.dashboard}
                className={s.signup_link}
              />
            </div>
          ) : (
            <div className={s.buttons}>
              {onCancel && (
                <RoundButton
                  label={t(onBack ? 'back' : 'cancel')}
                  type="button"
                  fullWidth
                  onClick={() =>
                    (onBack && onBack(values)) || (onCancel && onCancel())
                  }
                  className={s.button}
                />
              )}
              <RoundButton
                label={t(isWizard ? 'continue' : 'save')}
                type="submit"
                onSubmit={handleSubmit}
                fillBackground
                fullWidth
                className={s.button}
                style={{ maxWidth: isWizard ? '325px' : '150px' }}
              />
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default InvestorTypeForm;
