import { useEffect } from 'react';
import classnames from 'classnames';
import ReactCodeInput from 'react-verification-code-input-2';
import { OtpReader } from '../../OtpReader';
import s from './index.module.scss';

export const OTP_LIMIT = 6;

export const VerificationCodeInput = ({
  onChange,
  onComplete,
  values,
  error,
  className,
}) => {
  useEffect(() => {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.setAttribute('inputmode', 'numeric');
      input.setAttribute('autocomplete', 'one-time-code');
    });
  }, []);

  return (
    <OtpReader
      onChange={code => {
        onChange(code);
        code.length === OTP_LIMIT && onComplete();
      }}
    >
      <div className={s.wrapper}>
        <ReactCodeInput
          type="number"
          fields={OTP_LIMIT}
          onChange={onChange}
          onComplete={onComplete}
          loading={false}
          values={values}
          fieldWidth={44}
          autoFocus
          className={classnames(
            s.vcode,
            'verification_code',
            error ? 'error' : 'success',
            className,
          )}
        />
      </div>
    </OtpReader>
  );
};
