import { Navigate, useParams } from 'react-router-dom';
import { LOAN_GROUPS_IDS } from '@/helpers/constants';
import { APP_LINKS } from '@/helpers/links';
import { GroupDetailVersion01 } from './versions/v1';
import { GroupDetailVersion02 } from './versions/v2';
import { GroupDetailVersion03 } from './versions/v3';

const LoanTermsGroupDetailPage = () => {
  const { group } = useParams();

  switch (group) {
    case 'v1-group-a':
      return <GroupDetailVersion01 id={LOAN_GROUPS_IDS.conservative.id} />;
    case 'v1-group-b':
      return <GroupDetailVersion01 id={LOAN_GROUPS_IDS.moderate.id} />;
    case 'v1-group-c':
      return <Navigate to={APP_LINKS.helpLoanTerms('v1')} replace />;
    case 'v2-group-a':
      return <GroupDetailVersion02 id={LOAN_GROUPS_IDS.conservative.id} />;
    case 'v2-group-b':
      return <GroupDetailVersion02 id={LOAN_GROUPS_IDS.moderate.id} />;
    case 'v2-group-c':
      return <Navigate to={APP_LINKS.helpLoanTerms('v2')} replace />;
    case 'v3-group-a':
      return <GroupDetailVersion03 id={LOAN_GROUPS_IDS.conservative.id} />;
    case 'v3-group-b':
      return <GroupDetailVersion03 id={LOAN_GROUPS_IDS.moderate.id} />;
    case 'v3-group-c':
      return <Navigate to={APP_LINKS.helpLoanTerms('v3')} replace />;
    default:
      return <Navigate to={APP_LINKS.helpLoanTerms()} replace />;
  }
};

export default LoanTermsGroupDetailPage;
