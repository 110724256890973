import classnames from 'classnames';
import TooltipIcon from '../../../assets/icons/icon_tooltip.svg?react';
import { SpecTriangleIcon } from '../triangle';
import s from './index.module.scss';

export const Tooltip = ({ children, className, onClick }) => (
  <div className={classnames(s.tooltip, 'b_tooltip', className)}>
    <TooltipIcon
      className={classnames(s.tooltip__icon, 'b_tooltip__icon')}
      onClick={onClick}
    />
    <SpecTriangleIcon
      className={classnames(s.tooltip__triangle, 'b_tooltip__triangle')}
    />
    <div className={classnames(s.tooltip__content, 'b_tooltip__content')}>
      {children}
    </div>
  </div>
);
