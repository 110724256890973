import { Trans } from 'react-i18next';
import { prettyDate, prettyFloatMoney } from '@/helpers/utils';
import s from './index.module.scss';

const YourEarningsModal = ({ round }) => {
  const { max_earnings, flat_fee_accrued, earnings, end_date } = round;

  return (
    <div className={s.wrapper}>
      <div className="f-16 f-400">
        <Trans
          ns="modal"
          i18nKey="your_earnings_modal.two_key_values_to_track"
          components={{ br: <br />, span: <span className="f-500" /> }}
          values={{
            earnings: prettyFloatMoney(earnings + flat_fee_accrued),
            maxEarning: prettyFloatMoney(max_earnings),
            endDate: prettyDate(end_date, 'DD.MM.YYYY'),
          }}
        />
      </div>
    </div>
  );
};

export default YourEarningsModal;
