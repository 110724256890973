import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import { Control } from '../../../../kit/form/select/components/control';
import { getParticipatingFundraisingList } from '../../../../store/services/complaintServices';
import s from './index.module.scss';

export const FundraisingSelect = ({ selected, onChange, error, ...props }) => {
  const [options, setOptions] = useState([]);
  const getFundraisingList = async () => {
    const res = await getParticipatingFundraisingList();
    res?.success &&
      setOptions(
        res.data.map(item => ({
          ...item,
          label: item.fundraising_name,
          value: item.id,
        })),
      );
  };

  useEffect(() => {
    getFundraisingList();
  }, []);

  return (
    <div>
      <Select
        value={selected}
        onChange={onChange}
        name="fundraising"
        classNamePrefix="scr_select"
        className={classnames(
          s.fundraising_select,
          error && s.fundraising_select__error,
        )}
        options={options}
        components={{ Control }}
        placeholder="Select round"
        isSearchable
        {...props}
      />
      {error && <div className={s.error_message}>{error}</div>}
    </div>
  );
};
