export const ClipboardIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3 15.3H19.75C19.5246 15.3 19.3084 15.3896 19.149 15.549C18.9896 15.7084 18.9 15.9246 18.9 16.15C18.9 16.3754 18.9896 16.5916 19.149 16.751C19.3084 16.9104 19.5246 17 19.75 17H22.3C22.7509 17 23.1833 16.8209 23.5021 16.5021C23.8209 16.1833 24 15.7509 24 15.3V1.7C24 1.24913 23.8209 0.81673 23.5021 0.497918C23.1833 0.179107 22.7509 0 22.3 0H8.7C8.24913 0 7.81673 0.179107 7.49792 0.497918C7.17911 0.81673 7 1.24913 7 1.7V4.25C7 4.47543 7.08955 4.69163 7.24896 4.85104C7.40836 5.01045 7.62457 5.1 7.85 5.1C8.07543 5.1 8.29164 5.01045 8.45104 4.85104C8.61045 4.69163 8.7 4.47543 8.7 4.25V1.7H22.3V15.3ZM15.3 7H1.7C1.24913 7 0.81673 7.17911 0.497919 7.49792C0.179106 7.81673 0 8.24913 0 8.7V22.3C0 22.7509 0.179106 23.1833 0.497919 23.5021C0.81673 23.8209 1.24913 24 1.7 24H15.3C15.7509 24 16.1833 23.8209 16.5021 23.5021C16.8209 23.1833 17 22.7509 17 22.3V8.7C17 8.24913 16.8209 7.81673 16.5021 7.49792C16.1833 7.17911 15.7509 7 15.3 7ZM15.3 8.7V22.3H1.7V8.7H15.3Z"
      fill="#9E98A3"
    />
  </svg>
);
