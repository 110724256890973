import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';

export const useQueryParams = (params, setParams) => {
  const navigate = useNavigate();
  const location = useLocation();

  const parsedParams = useMemo(() => {
    return qs.stringify(params);
  }, [params]);

  useEffect(() => {
    updateQuery(parsedParams);
  }, [parsedParams]);

  useEffect(() => {
    const current = location.search.replace('?', '');
    if (parsedParams !== current) {
      const newParams = qs.parse(current);
      setParams(
        Object.keys(params).reduce((acc, key) => {
          acc[key] = Number(newParams[key]) || params[key];
          return acc;
        }, {})
      );
    }
  }, [location.search]);

  const updateQuery = parsedParams => {
    if (params) {
      if (!location.search) {
        return navigate(`${location.pathname}?${parsedParams}`, { replace: true });
      }
      navigate(`${location.pathname}?${parsedParams}`);
    }
  };

  return { params, parsedParams };
};
