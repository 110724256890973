import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RoundButton } from '@/components/UI/RoundButton';
import AccountSelect from '@/components/UI/AccountSelect';
import { MONEY_REGEX } from '@/helpers/common';
import { prettyFloatMoney } from '@/helpers/utils';
import { MoneyField } from '@/components/UI/MoneyField';
import { MyLink } from '@/components/MyLink';
import { CONTACT_EMAIL } from '@/helpers/constants';
import { RoundLink } from '@/components/UI/RoundLink';
import { APP_LINKS } from '@/helpers/links';
import './index.scss';

const VALIDATION_SCHEMA = t =>
  Yup.object().shape({
    account: Yup.object().shape({
      id: Yup.number().required(),
    }),
    amount: Yup.string()
      .matches(MONEY_REGEX, t('enter_valid_amount'))
      .required(t('please_enter_amount')),
  });

const WithdrawForm = ({ balance, accounts, onSubmit, children }) => {
  const { t } = useTranslation('withdraw');
  return (
    <Formik
      enableReinitialize
      validationSchema={VALIDATION_SCHEMA(t)}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      initialValues={{
        cash: balance,
        amount: '',
        account: (accounts && accounts[0]) || null,
      }}
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="withdraw-form">
          <p className="withdraw-form__from f-16">{t('from_available_cash')}</p>
          <p className="withdraw-form__from-amount f-20 f-500">
            {prettyFloatMoney(balance)}
          </p>
          <p className="withdraw-form__to f-16">{t('to_bank_account')}</p>
          <AccountSelect
            value={values.account}
            options={accounts || []}
            onSelect={option => setFieldValue('account', option)}
            className="withdraw-form__select"
            error={errors.account && touched.account && errors.account}
          />

          <div className="withdraw-form__add_bank_account">
            <RoundLink
              path={APP_LINKS.bankAccountsCreate}
              label={t('add_new_bank_account')}
            />
          </div>

          <MoneyField
            label={t('amount')}
            name="amount"
            onChange={(_, value) => setFieldValue('amount', value)}
            value={values.amount}
            onClear={() => setFieldValue('amount', '')}
            className="withdraw-form__amount"
            error={errors.amount && touched.amount && errors.amount}
          />
          <p className="f-16 withdraw-form__commission">{t('no_commission')}</p>
          <RoundButton
            type="submit"
            label={t('withdraw')}
            onSubmit={handleSubmit}
            fillBackground
            className="withdraw-form__submit hov"
          />

          {children}

          <div className="withdraw-form__support f-16">
            <p>{t('scramble_transfers_money_in_eur')}</p>
            <p>{t('currency_conversion_notice')}</p>{' '}
            {t('withdrawal_processing_notice')}{' '}
            <MyLink href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</MyLink>.
          </div>
        </form>
      )}
    </Formik>
  );
};

export default WithdrawForm;
