import moment from 'moment/min/moment-with-locales';
import { Tooltip } from 'react-tippy';
import { Trans, useTranslation } from 'react-i18next';
import { Tippy } from '@/kit/Tippy';
import classnames from 'classnames';
import ProgressBar from '@/components/ProgressBar';
import { prettyDate, prettyFloatMoney } from '@/helpers/utils';
import { DATE_FORMAT_DD_MMM_YYYY } from '@/helpers/common';
import { InfoIcon } from '@/components/UI/Icons';
import s from './index.module.scss';

export const LoansTable = ({ data, onLoanOpen }) => {
  const { t } = useTranslation('loans');
  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <div className={s.table}>
          <div className={s.thead}>
            <div className={s.thead_col}>{t('round')}</div>
            <div className={s.thead_col}>{t('origination_date')}</div>
            <div className={s.thead_col}>{t('loan_amount')}</div>
            <div className={s.thead_col}>{t('repayment_progress')}</div>
            <div className={s.thead_col}>{t('repaid')}</div>
            <div className={s.thead_col}>{t('your_earnings')}</div>
          </div>

          <div className={s.tbody}>
            <div className={s.tbody_row_summary}>
              <div className={s.tbody_col}>
                <p className="f-18 f-500">
                  {t('total')}:{' '}
                  <span>
                    {data.total_count === 1
                      ? t('one_round')
                      : t('multiple_rounds', { count: data.total_count })}
                  </span>
                </p>
              </div>
              <div className={s.tbody_col}>
                <span className="f-18 f-500">—</span>
              </div>
              <div className={classnames(s.tbody_col, 'tl')}>
                <span className="f-18 f-500">
                  {prettyFloatMoney(data.total_amount)}
                </span>
              </div>
              <div className={s.tbody_col}>
                <ProgressBar
                  value={data.repayment_average}
                  text={t('average_repayment', {
                    percentage: data ? data.repayment_average : 0,
                  })}
                />
              </div>
              <div className={classnames(s.tbody_col, 'tl')}>
                <span className="f-18 f-500">
                  {prettyFloatMoney(data.total_repayments)}
                </span>
              </div>
              <div className={s.tbody_col}>
                <span className="f-18 f-500">
                  + {prettyFloatMoney(data.total_earnings + data.total_accrued)}
                </span>
              </div>
            </div>

            {data.list.map(round => {
              const {
                fundraising_name,
                fundraising_id,
                start_date,
                end_date,
                max_earnings,
                loan_amount,
                repayment_status,
                repaid_amount,
                earnings,
                flat_fee_accrued,
              } = round;

              return (
                <div
                  className={classnames(s.tbody_row, 'hov')}
                  key={fundraising_name}
                  onClick={() => onLoanOpen(round)}
                >
                  <div className={s.tbody_col}>
                    <span className="f-16 f-400">{fundraising_name}</span>
                  </div>
                  <div className={s.tbody_col}>
                    <span className="f-16 f-400">
                      {moment(start_date).format(DATE_FORMAT_DD_MMM_YYYY)}
                    </span>
                  </div>
                  <div className={classnames(s.tbody_col, s.loan)}>
                    <span className="f-16 f-400 tl">
                      {prettyFloatMoney(loan_amount)}
                    </span>
                  </div>
                  <div className={s.tbody_col}>
                    <ProgressBar value={repayment_status} />
                  </div>
                  <div className="loans_table__tbody_col tl">
                    <span className="f-16 f-400">
                      {prettyFloatMoney(repaid_amount)}
                    </span>
                  </div>
                  <div className={classnames(s.tbody_col)}>
                    <div
                      className={classnames(s.earnings_content, 'f-16 f-400')}
                    >
                      {fundraising_id > 92 && repayment_status < 100 ? (
                        <span className={classnames(s.earnings, 'f-16 f-400')}>
                          {t('out_of', {
                            earnings: prettyFloatMoney(
                              earnings + flat_fee_accrued,
                            ),
                            max_earnings: prettyFloatMoney(max_earnings),
                          })}
                        </span>
                      ) : (
                        <span className={classnames(s.earnings, 'f-16 f-400')}>
                          + {prettyFloatMoney(earnings)}
                        </span>
                      )}

                      {fundraising_id > 92 && repayment_status < 100 && (
                        <Tippy
                          render={(ref, open, setOpen) => (
                            <Tooltip
                              theme="light"
                              position="left"
                              open={open}
                              trigger="click"
                              arrow
                              html={
                                <div
                                  className="f-16 f-400"
                                  style={{ maxWidth: '350px' }}
                                  ref={ref}
                                >
                                  <Trans
                                    ns="loans"
                                    i18nKey="tooltip_two_key_values"
                                    values={{
                                      earnings: prettyFloatMoney(
                                        earnings + flat_fee_accrued,
                                      ),
                                      max_earnings:
                                        prettyFloatMoney(max_earnings),
                                      end_date: prettyDate(
                                        end_date,
                                        'DD.MM.YYYY',
                                      ),
                                    }}
                                    components={{
                                      span: <span className="f-500" />,
                                      br: <br />,
                                    }}
                                  />
                                </div>
                              }
                            >
                              <InfoIcon
                                style={{ position: 'relative', top: '2px' }}
                                onClick={e => {
                                  e.stopPropagation();
                                  setOpen(true);
                                }}
                              />
                            </Tooltip>
                          )}
                        ></Tippy>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
