import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { getFullname, getNameInitials } from '@/helpers/utils';
import UserIcon from '@/components/UI/Icons/UserIcon';
import { Avatar } from '@/components/UI/Avatar';
import { useGetMe } from '@/hooks/useGetMe';
import './index.scss';

const MenuUser = ({ className, showName = true }) => {
  const { user } = useGetMe();
  const isParticipating = useSelector(
    state => state.investStore.isParticipating,
  );

  if (!user) {
    return null;
  }

  return (
    <MyLink
      href={APP_LINKS.profile}
      className={classnames('menu_user', className)}
    >
      <div
        className={classnames('menu_user__avatar', isParticipating && 'active')}
      >
        <Avatar
          size={32}
          key={user.photo?.file}
          src={user.photo?.file}
          alt={getFullname(user.first_name, user.last_name)}
          nameInitials={getNameInitials(user.first_name, user.last_name)}
          errorRender={() => (
            <UserIcon
              className={classnames(
                'menu_user__icon',
                isParticipating && 'active',
              )}
              strokeColor={isParticipating && '#FFD200'}
            />
          )}
        />
      </div>
      {showName && (
        <div className="menu_user__name f-16 f-500 tl">
          {getFullname(user.first_name, user.last_name, 'Profile')}
        </div>
      )}
    </MyLink>
  );
};

export default MenuUser;
