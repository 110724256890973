import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { userService } from '../../services/userService';

export const useGetProfile = token => {
  return useQuery({
    queryKey: [QUERY_KEYS.user.profile, token?.refresh_token],
    queryFn: () =>
      userService.profile().then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
      }),
    enabled: !!token,
  });
};
