import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { LOAN_GROUPS } from '@/helpers/constants';
import { TERM_TOOLTIPS } from './tooltips';
import s from './mobile.module.scss';

export const MobileTermView = () => {
  const { t } = useTranslation('investing');
  const { t: tLoanTerms } = useTranslation('loan_terms');
  const { t: tConstants } = useTranslation('constants');

  const TERM_TOOLTIPS_T = TERM_TOOLTIPS(tLoanTerms);

  const LOAN_GROUPS_T = LOAN_GROUPS(tConstants);
  const GROUPS = [LOAN_GROUPS_T.conservative, LOAN_GROUPS_T.moderate];

  return (
    <div className={s.wrapper}>
      <div className={s.controls}>
        {GROUPS.map((group, index) => (
          <h3 className={classnames(s.name, 'f-24 f-500')} key={index}>
            {group.full_name}
          </h3>
        ))}
      </div>

      <div className={s.info}>
        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>
              {t('inv_loan_terms_investor_earnings')}
            </div>
            {TERM_TOOLTIPS_T.earnings({ className: s.m_earning })}
          </div>

          <div className={s.line}>
            <span>{GROUPS[0].investor_earnings}</span>
            <span>{GROUPS[1].investor_earnings}</span>
          </div>
        </div>

        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>
              {t('inv_loan_terms_net_annual_return')}
            </div>
            {TERM_TOOLTIPS_T.net_annual_return({ className: s.m_return })}
          </div>
          <div className={s.line}>
            <span>{GROUPS[0].target_annual_return}</span>
            <span>{GROUPS[1].target_annual_return}</span>
          </div>
        </div>

        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>{t('inv_loan_terms_term')}</div>
            {TERM_TOOLTIPS_T.term({ className: s.m_term })}
          </div>
          <div className={s.line}>
            <span>{t('inv_loan_terms_6_months')}</span>
            <span>{t('inv_loan_terms_6_months')}</span>
          </div>
        </div>

        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>{t('inv_loan_terms_repayments')}</div>
            {TERM_TOOLTIPS_T.repayments({ className: s.m_repayments })}
          </div>
          <div className={s.line}>
            <span>{GROUPS[0].repayment_type}</span>
            <span>{GROUPS[1].repayment_type}</span>
          </div>
        </div>

        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>
              {t('inv_loan_terms_skin_in_the_game')}
            </div>
            {TERM_TOOLTIPS_T.skin({ className: s.m_skin })}
          </div>
          <div className={s.line}>
            <span>{GROUPS[0].skin_in_the_game}</span>
            <span>{GROUPS[1].skin_in_the_game}</span>
          </div>
        </div>

        <div className={s.row}>
          <div className={s.header}>
            <div className={s.label}>{t('inv_loan_terms_protection')}</div>
          </div>
          <div className={s.line}>
            <span>{GROUPS[0].protection}</span>
            <span>{GROUPS[1].protection}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
