import { useState } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { setModals } from '@/store/actions/commonActions';
import { RoundButton } from '@/components/UI/RoundButton';
import { getUserProfile } from '@/store/actions/profileActions';
import { prettyFloatMoney } from '@/helpers/utils';
import { confirmTermOfUse } from './action';
import s from './index.module.scss';

const TermsOfUseModal = ({ amount, onSuccess, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('modal');
  const [showMore, setShowMore] = useState(false);

  const onAccept = async () => {
    const res = await confirmTermOfUse({ has_accepted_terms: true });
    if (res?.success) {
      onSuccess && onSuccess();
      dispatch(setModals([]));
      dispatch(getUserProfile());
      const warningEl = document.querySelector('#tou_warning');
      if (warningEl) {
        warningEl.style.display = 'none';
      }
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.form}>
        <h1 className="f-24 f-500">
          {t('tou_modal.increase_your_investment_guarantees')}
        </h1>
        <div className={classnames(s.disclaimer, 'f-16')}>
          {t('tou_modal.dear_investor')} <br />
          {t('tou_modal.scramble_is_entering_the_first_phase')}
          <br />
          <br />
          {t('tou_modal.if_you_do_not_authorize')}{' '}
          {showMore ? (
            <span>{t('tou_modal.your_funds_will_be_repaid_as_usual')}</span>
          ) : (
            <span
              onClick={() => setShowMore(true)}
              style={{
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {t('tou_modal.read_more')}
            </span>
          )}
          <br />
          <br />
          <Trans
            ns="modal"
            i18nKey="tou_modal.you_can_currently_assign"
            values={{ amount: prettyFloatMoney(amount) }}
            components={{
              span: <span className="f-500" />,
            }}
          />
        </div>

        <div className={s.buttons}>
          <RoundButton
            type="button"
            label={() => (
              <span style={{ color: '#000000' }}>{t('tou_modal.i_agree')}</span>
            )}
            onClick={onAccept}
            className={s.submit}
            fillBackground
          />
          <div onClick={() => onClose()}>{t('tou_modal.ask_me_later')}</div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUseModal;
