import { useEffect, useMemo, useRef, useState } from 'react';
import Decimal from 'decimal.js-light';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { prettyFloatMoney } from '@/helpers/utils';
import { getLoanEarningsByFundraisingID } from '@/store/services/loanServices';
import { LOAN_EARNINGS_STATUSES } from '@/helpers/loan';
import { EMPTY_DASH, LOAN_GROUPS as LOAN_GROUPS_T } from '@/helpers/constants';
import s from './index.module.scss';

export const LoanDetails = ({ fundraisingID }) => {
  const { t } = useTranslation('loans');
  const { t: tConstants } = useTranslation('constants');
  const LOAN_GROUPS = LOAN_GROUPS_T(tConstants);
  const mounted = useRef(true);
  const [accrued, setAccrued] = useState({
    moderate: null,
    conservative: null,
  });

  const { data: loans } = useSelector(
    state => state.loanStore.loansByFundraisingID,
  );

  const { data } = useSelector(
    state => state.loanStore.repaymentScheduleByFundraisingID,
  );

  const isPaid = useMemo(
    () =>
      !!(
        data &&
        data.list.filter(s => s.status === 'paid').length === data.list.length
      ),
    [data],
  );

  useEffect(() => {
    if (loans?.length) {
      loans.map(loan => {
        getLoanEarningsByFundraisingID(fundraisingID, loan.id).then(res => {
          const accruedSum = res.data?.list.reduce((acc, item) => {
            if (item.status === LOAN_EARNINGS_STATUSES.accrued) {
              acc = Decimal(item.earnings_currency).add(acc).toFixed(2);
            }
            return acc;
          }, 0);
          mounted.current &&
            setAccrued(prev => ({ ...prev, [loan.id]: accruedSum }));
        });
      });
    }
    () => {
      mounted.current = false;
    };
  }, [loans?.length]);

  if (!loans?.length) {
    return null;
  }

  return (
    <div className={s.details}>
      <h2 className="f-26 f-500">{t('loan_info.loan_details')}</h2>
      <div className={s.table_wrapper}>
        <div className={s.table_inner}>
          <div className={s.table}>
            <div className={classnames(s.thead)}>
              <div className={s.thead_col}>{t('loan_info.loan_group')}</div>
              <div className={s.thead_col}>{t('loan_info.loan_amount')}</div>
              <div className={s.thead_col}>
                {t('loan_info.outstanding_loan')}
              </div>
              <div className={s.thead_col}>
                {t('loan_info.flat_fee_accrued')}
              </div>
              <div className={s.thead_col}>{t('loan_info.flat_fee_paid')}</div>
            </div>

            <div className={s.tbody}>
              {loans.map((loan, idx) => (
                <div className={classnames(s.tbody_row)} key={idx}>
                  <div className={s.tbody_col}>
                    {LOAN_GROUPS[loan.id].full_name}
                  </div>
                  <div className={s.tbody_col}>
                    {prettyFloatMoney(loan.loans.total_loan_amount)}
                  </div>
                  <div className={s.tbody_col}>
                    {prettyFloatMoney(loan.loans.total_remain_amount)}
                  </div>
                  <div className={s.tbody_col}>
                    {isPaid
                      ? '€0'
                      : accrued[loan.id] === null
                        ? EMPTY_DASH
                        : `€${accrued[loan.id]}`}
                  </div>
                  <div className={s.tbody_col}>
                    {prettyFloatMoney(loan.loans.flat_fee_paid)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
