import { useTranslation } from 'react-i18next';
import moment from 'moment/min/moment-with-locales';
import { DATE_FORMAT_DD_MMM_YYYY } from '@/helpers/common';
import { prettyFloatMoney } from '@/helpers/utils';
import s from './index.module.scss';

export const ExampleList = ({ data }) => {
  const { t } = useTranslation('loan_terms');
  return (
    <div className={s.example_list}>
      <div className={s.example_list__list}>
        {data.list.map((item, idx) => (
          <div className={s.example_list__card} key={idx}>
            <div className={s.example_list__card_info}>
              <div>
                №{idx + 1}. {moment(item.date).format(DATE_FORMAT_DD_MMM_YYYY)}
              </div>

              <div className={s.example_list__card__row}>
                <div className="c-grey f-14 f-400">
                  {t('v1.detail.list_interest')}
                </div>
                <div className="f-16 f-500">
                  {prettyFloatMoney(item.interest)}
                </div>
              </div>

              <div className={s.example_list__card__row}>
                <div className="c-grey f-14 f-400">
                  {t('v1.detail.list_success_charge')}
                </div>
                <div className="f-16 f-500">
                  {item.success_charge
                    ? prettyFloatMoney(item.success_charge)
                    : '-'}
                </div>
              </div>

              <div className={s.example_list__card__row}>
                <div className="c-grey f-14 f-400">
                  {t('v1.detail.list_principal')}
                </div>
                <div className="f-16 f-500">
                  {item.principal ? prettyFloatMoney(item.principal) : '-'}
                </div>
              </div>

              <div className={s.example_list__card__row}>
                <div className="c-grey f-14 f-400">
                  {t('v1.detail.list_total')}
                </div>
                <div className="f-18 f-500">{prettyFloatMoney(item.total)}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={s.example_list__summary}>
        <div className={s.example_list__summary_row}>
          <div className="f-16 f-500">{t('v1.detail.list_total')}</div>
          <div className="f-18 f-500">{prettyFloatMoney(data.total)}</div>
        </div>

        <div className={s.example_list__summary_row}>
          <div className="f-16 f-500">{t('v1.detail.list_interest')}</div>
          <div className="f-18 f-500">
            {prettyFloatMoney(data.total_interest)}
          </div>
        </div>

        <div className={s.example_list__summary_row}>
          <div className="f-16 f-500">{t('v1.detail.list_success_charge')}</div>
          <div className="f-18 f-500">
            {prettyFloatMoney(data.total_success_charge)}
          </div>
        </div>

        <div className={s.example_list__summary_row}>
          <div className="f-16 f-500">{t('v1.detail.list_principal')}</div>
          <div className="f-18 f-500">
            {prettyFloatMoney(data.total_principal)}
          </div>
        </div>
      </div>
    </div>
  );
};
