import { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TopHeader } from '@/components/TopHeader';
import { getBalance } from '@/store/actions/dashboardActions';
import { getTermsOfUseStatus } from '@/store/services/userServices';
import { DesktopNavigation } from '@/components/Navigation/DesktopNavigation';
import { MobileNavigation } from '@/components/Navigation/MobileNavigation';
import { AuthorizedFooter } from '@/components/Footer/AuthorisedFooter';
import { logoutUser } from '@/store/actions/userActions';
import { getLastRoute } from '@/helpers/utils';
import Analytics from '@/analyticsGA';
import { setModals } from '@/store/actions/commonActions';
import { getReferralCode } from '@/store/actions/referralActions';
import { MODAL_TYPES } from '@/components/ModalProvider';
import { TermsAcceptWarning } from '@/components/Warnings/TermsAcceptWarning';
import { IncreasedIncomeBanner } from '@/containers/banners/IncreasedIncomeBanner';
import { useVeriffNotification } from '@/hooks/useVeriffNotification';
import { setUserToCookie } from '@/helpers/user';
import { APP_LINKS } from '@/helpers/links';
import './index.scss';

export const AppLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const intervalID = useRef(null);

  const { token } = useSelector(state => state.userStore);
  const { innerWidth } = useSelector(state => state.commonStore);

  useVeriffNotification();

  useEffect(() => {
    if (!token?.refresh_token) {
      return dispatch(logoutUser());
    }

    const lastRoute = getLastRoute();
    Analytics.rootPageNavigation(lastRoute);

    getTermsOfUseStatus().then(res => {
      if (res.success) {
        res.data.has_active_loans &&
          res.data.amount > 0 &&
          res.data.status === 'pending' &&
          dispatch(
            setModals({
              type: MODAL_TYPES.terms_of_use,
              amount: res.data.amount,
              onOutsideClick: true,
              disableCloseIcon: true,
            }),
          );
      }
    });

    // Getting updated balance every minute
    dispatch(getBalance());
    clearInterval(intervalID.current);
    intervalID.current = setInterval(
      () => token && !document.hidden && dispatch(getBalance()),
      60000,
    );

    getReferralCode();

    return () => {
      clearInterval(intervalID.current);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === APP_LINKS.root && token) {
      navigate(APP_LINKS.dashboard, { replace: true });
    }
  }, [token, location.pathname, navigate]);

  useEffect(() => {
    if (!token) {
      navigate(APP_LINKS.auth, { replace: true });
    }
    setUserToCookie(token?.access_token);
  }, [token]);

  return (
    <div className="root_page">
      {innerWidth >= 1380 ? <DesktopNavigation /> : <MobileNavigation />}
      <main id="main">
        <div className="root_page__content">
          <TopHeader />
          <div className="container-inner">
            <TermsAcceptWarning className="root_page__warning" />
          </div>
          <div className="container-inner">
            <IncreasedIncomeBanner />
          </div>
          <Outlet />
        </div>
        {innerWidth >= 1380 && <AuthorizedFooter />}
      </main>
      {innerWidth < 1380 && <AuthorizedFooter />}
    </div>
  );
};
