import {
  AdditionalFiveForFriendBanner,
  FiveForHundredBanner,
} from '../Banners/YellowBanner';

export const AFFILIATE_STEPS = t => [
  {
    label: t('steps_invite_label'),
    desc: t('steps_invite_desc'),
  },
  {
    label: t('steps_receive_label'),
    desc: t('steps_receive_desc'),
    banner: <FiveForHundredBanner />,
  },
  {
    label: t('steps_earn_label'),
    desc: t('steps_earn_desc'),
    banner: <AdditionalFiveForFriendBanner />,
  },
];
