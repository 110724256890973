import { useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getParticipatingStartups } from '@/store/actions/startupActions';
import { StartupCard } from '@/components/Cards/StartupCard/v3';
import { APP_LINKS } from '@/helpers/links';
import s from './index.module.scss';

export const StartupsActive = () => {
  const dispatch = useDispatch();
  const { participatingStartups: startups } = useSelector(
    state => state.startupStore,
  );
  const { t } = useTranslation('brands');

  useEffect(() => {
    dispatch(getParticipatingStartups({ page: 1, limit: 100 }));
  }, []);

  const hasStartups = !!startups?.length;

  if (!hasStartups) {
    return null;
  }

  return (
    <div className={s.startups_active}>
      <h2 className="f-26 f-500">
        {t('startups.active.title', { value: startups.length })}
      </h2>
      <p className={classnames(s.startups_active__desc, 'f-16 f-400')}>
        {t('startups.active.description')}
      </p>
      {hasStartups ? (
        <div className={s.startups_active__list}>
          {startups.map(startup => (
            <StartupCard
              key={startup.id}
              startup={startup}
              href={APP_LINKS.startupDetail(startup.slug)}
              className={s.startups_active__card}
            />
          ))}
        </div>
      ) : (
        <div className={s.startups_active__empty}>
          <span
            className={classnames(s.startups_active__empty_title, 'f-16 f-400')}
          >
            {t('startups.active.no_brands')}
          </span>
        </div>
      )}
    </div>
  );
};
