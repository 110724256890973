import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { setModals } from '@/store/actions/commonActions';
import { MODAL_TYPES } from '@/components/ModalProvider';
import s from './index.module.scss';

export const LanguageSwitcher = ({ className }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.language_select,
      }),
    );
  };

  return (
    <div className={classnames(s.switcher, className)} onClick={onClick}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="lang_sphere"
      >
        <path
          d="M10.0013 18.7541C11.6493 18.7538 13.2603 18.2649 14.6305 17.3491C16.0007 16.4333 17.0686 15.1318 17.6992 13.6092C18.3298 12.0865 18.4947 10.4111 18.1731 8.79467C17.8515 7.17828 17.0579 5.69354 15.8925 4.52818C14.7272 3.36282 13.2424 2.56918 11.626 2.24759C10.0096 1.92601 8.3342 2.09092 6.81153 2.72149C5.28887 3.35206 3.98737 4.41996 3.07159 5.79017C2.15581 7.16038 1.66688 8.77136 1.66663 10.4194C1.66645 11.514 1.88192 12.5979 2.30071 13.6092C2.71951 14.6204 3.33343 15.5393 4.1074 16.3133C4.88138 17.0873 5.80025 17.7012 6.81153 18.12C7.82282 18.5388 8.9067 18.7542 10.0013 18.7541V18.7541Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.08484 13.0337H17.6796"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.08484 7.80518H17.6796"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.20278 2.28223C6.70707 7.67888 6.92727 13.4072 8.83285 18.6731"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7971 2.28223C12.4505 4.6308 12.7803 7.05754 12.7775 9.4953C12.7841 12.6245 12.2426 15.7307 11.1775 18.6731"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
