import classnames from 'classnames';
import { Trans } from 'react-i18next';
import Tooltip from '@/components/Tooltip';
import { MyLink } from '@/components/MyLink';
import Dropdown from '@/components/Dropdown';
import { LOAN_GROUPS_IDS } from '@/helpers/constants';
import { LOAN_GROUPS_V1 } from '../../../LoanTermsPage/versions/v1/data';
import ProtectADesktop from './assets/protection_group_a_desktop.svg';
import ProtectAMobile from './assets/protection_group_a_mobile.svg';
import ProtectBDesktop from './assets/protection_group_b_desktop.svg';
import ProtectBMobile from './assets/protection_group_b_mobile.svg';
import { GroupAExample, GroupBExample } from './tableData';
import { ExampleList } from './ExampleList';
import { ExampleTable } from './ExampleTable';
import s from './index.module.scss';

export const GROUP_DETAIL = t => ({
  [LOAN_GROUPS_IDS.conservative.id]: innerWidth => ({
    title: LOAN_GROUPS_V1[0].title,
    termsSection: (
      <section className={s.page__terms}>
        <h2 className="f-24 f-500">{t('v1.detail.loan_terms')}</h2>
        <div className={s.page__first_row}>
          <div className={s.page__annual_return}>
            <div className={s.page__annual_return_header}>
              <div
                className={classnames(
                  s.page__annual_return_title,
                  'f-16 f-400',
                )}
              >
                {t('v1.detail.target_annual_return')}
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className="f-16 f-400">
                    {t('v1.detail.target_annual_return_calculations')}
                  </div>
                )}
              />
            </div>
            <div
              className={classnames(s.page__annual_return_value, 'f-24 f-500')}
            >
              {LOAN_GROUPS_V1[0].rate}
            </div>
          </div>

          <div className={s.page__term}>
            <div className={classnames(s.page__term_title, 'f-16 f-400')}>
              {t('v1.detail.term')}
            </div>
            <div className={classnames(s.page__term_value, 'f-24 f-500')}>
              {LOAN_GROUPS_V1[0].repaymentPeriod}
            </div>
          </div>
        </div>
        <div className={s.page__second_row}>
          <div
            className={classnames(s.page__nominal_return_title, 'f-16 f-400')}
          >
            {t('v1.detail.nominal_return')}
          </div>
          <div className={s.page__nominal_return}>
            <div className={s.page__nominal_return_item}>
              <div className="f-24 f-500">{t('v1.detail.annual_interest')}</div>
              <div className="f-16 f-400">{t('v1.detail.paid_monthly')}</div>
            </div>
            <div className={s.page__nominal_return_item}>
              <div className="f-24 f-500">{t('v1.detail.success_return')}</div>
              <div className="f-16 f-400">
                {t('v1.detail.paid_upon_repayment')}
              </div>
            </div>
          </div>
        </div>
      </section>
    ),
    protectionSection: (
      <section className={s.page__protection}>
        <h2 className="f-24 f-500">
          {t('v1.detail.triple_secured_protection')}
        </h2>
        <p className="f-16 f-400">{t('v1.detail.loans_group_a')}</p>
        <div className={s.page__protection_content}>
          {[
            {
              label: t('v1.detail.co_founder_guarantees'),
              content: (
                <div className="f-16 f-400">
                  <Trans
                    ns="loan_terms"
                    i18nKey="co_founder_guarantees_content"
                    components={{ link: <MyLink href="#" /> }}
                  />
                </div>
              ),
            },
            {
              label: t('v1.detail.mutual_security_deposit'),
              content: (
                <div className="f-16 f-400">
                  <Trans
                    ns="loan_terms"
                    i18nKey="v1.detail.mutual_security_deposit_content"
                    components={{ link: <MyLink href="#" /> }}
                  />
                </div>
              ),
            },
            {
              label: t('v1.detail.first_loss_capital'),
              content: (
                <div className="f-16 f-400">
                  <Trans
                    ns="loan_terms"
                    i18nKey="v1.detail.first_loss_capital_content"
                    components={{ link: <MyLink href="#" /> }}
                  />
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`0${idx + 1}`}
              disableToggle={innerWidth > 992}
              defaultShow={innerWidth > 992}
            >
              {item.content}
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className={s.page__repayments}>
        <h2 className="f-24 f-500">{t('v1.detail.loan_repayments')}</h2>
        <p className="f-16 f-400" style={{ whiteSpace: 'pre-line' }}>
          <Trans
            ns="loan_terms"
            i18nKey="v1.detail.monthly_repayments"
            components={{ link: <MyLink href="#" /> }}
          />
        </p>
        <img
          src={innerWidth > 650 ? ProtectADesktop : ProtectAMobile}
          alt="Group A"
          className={s.page__repayments_image}
        />
        <div className={s.page__repayments_description}>
          <ul className="scr-circle-list">
            <li>
              <div className="f-16 f-500">
                {t('v1.detail.from_1st_to_6th_months')}
              </div>
              <div className="f-16 f-400">{t('v1.detail.first_6_months')}</div>
            </li>
            <li>
              <div className="f-16 f-500">
                {t('v1.detail.from_7th_to_12th_months')}
              </div>
              <div className="f-16 f-400">{t('v1.detail.months_7_to_12')}</div>
            </li>
          </ul>
        </div>
        <div className={classnames(s.page__repayments_note1, 'f-16 f-400')}>
          <Trans
            ns="loan_terms"
            i18nKey="v1.detail.late_charges"
            components={{ span: <span className="f-500" /> }}
          />
        </div>
        <div className={classnames(s.page__repayments_note2, 'f-16 f-400')}>
          <Trans
            ns="loan_terms"
            i18nKey="v1.detail.no_fees_on_investor_capital"
            components={{ span: <span className="f-500" /> }}
          />
        </div>
        <Dropdown
          label={
            <span className="f-20 f-400">
              {t('v1.detail.loan_repayments_example')}
            </span>
          }
          className={s.page__example}
          defaultShow={false}
        >
          <>
            <div className={classnames(s.page__example_subtitle, 'f-16 f-400')}>
              <Trans
                ns="loan_terms"
                i18nKey="v1.detail.example_investment"
                components={{ span: <span className="f-500" /> }}
              />
            </div>

            <div className={s.page__example_summary}>
              <div className={s.page__example_summary_left}>
                <div className="f-16">
                  <div>{t('v1.detail.loan_amount')}</div>
                  <div className="f-600">
                    <b>€1’000</b>
                  </div>
                </div>
                <div className="f-16">
                  <div>{t('v1.detail.original_date')}</div>
                  <div className="f-600">
                    <b>05.01.2022</b>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.page__example_table}>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupAExample} />
              ) : (
                <ExampleTable data={GroupAExample} />
              )}
            </div>
          </>
        </Dropdown>
      </section>
    ),
  }),

  [LOAN_GROUPS_IDS.moderate.id]: innerWidth => ({
    title: LOAN_GROUPS_V1[1].title,
    termsSection: (
      <section className={s.page__terms}>
        <h2 className="f-24 f-500">{t('v1.detail.loan_terms')}</h2>
        <div className={s.page__first_row}>
          <div className={s.page__annual_return}>
            <div className={s.page__annual_return_header}>
              <div
                className={classnames(
                  s.page__annual_return_title,
                  'f-16 f-400',
                )}
              >
                {t('v1.detail.target_annual_return')}
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className="loan-group-card__tooltip f-16 f-400">
                    {t('v1.detail.target_annual_return_calculations')}
                  </div>
                )}
              />
            </div>
            <div
              className={classnames(s.page__annual_return_value, 'f-24 f-500')}
            >
              {LOAN_GROUPS_V1[1].rate}
            </div>
          </div>

          <div className={s.page__term}>
            <div className={classnames(s.page__term_title, 'f-16 f-400')}>
              {t('v1.detail.term')}
            </div>
            <div className={classnames(s.page__term_value, 'f-24 f-500')}>
              {LOAN_GROUPS_V1[1].repaymentPeriod}
            </div>
          </div>
        </div>
        <div className={s.page__second_row}>
          <div
            className={classnames(s.page__nominal_return_title, 'f-16 f-400')}
          >
            {t('v1.detail.nominal_return')}
          </div>
          <div className={s.page__nominal_return}>
            <div className={s.page__nominal_return_item}>
              <div className="f-24 f-500">{t('v1.detail.annual_interest')}</div>
              <div className="f-16 f-400">{t('v1.detail.paid_monthly')}</div>
            </div>
            <div className={s.page__nominal_return_item}>
              <div className="f-24 f-500">
                {t('v1.detail.success_return_moderate')}
              </div>
              <div className="f-16 f-400">
                {t('v1.detail.paid_from_7th_to_12th_months')}
              </div>
            </div>
          </div>
        </div>
      </section>
    ),
    protectionSection: (
      <section className={s.page__protection}>
        <h2 className="f-24 f-500">
          {t('v1.detail.double_secured_protection')}
        </h2>
        <p className="f-16 f-400">{t('v1.detail.loans_group_b')}</p>
        <div className={s.page__protection_content}>
          {[
            {
              label: t('v1.detail.co_founder_guarantees'),
              content: (
                <div className="f-16 f-400">
                  <Trans
                    ns="loan_terms"
                    i18nKey="v1.detail.co_founder_guarantees_content"
                    components={{ link: <MyLink href="#" /> }}
                  />
                </div>
              ),
            },
            {
              label: t('v1.detail.mutual_security_deposit'),
              content: (
                <div className="f-16 f-400">
                  <Trans
                    ns="loan_terms"
                    i18nKey="v1.detail.mutual_security_deposit_content"
                    components={{ link: <MyLink href="#" /> }}
                  />
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`${idx + 1}`}
              disableToggle={innerWidth > 992}
              defaultShow={innerWidth > 992}
            >
              {item.content}
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className={s.page__repayments}>
        <h2 className="f-24 f-500">{t('v1.detail.loan_repayments')}</h2>
        <p className="f-16 f-400" style={{ whiteSpace: 'pre-line' }}>
          <Trans
            ns="loan_terms"
            i18nKey="v1.detail.monthly_repayments"
            components={{
              span: <span className="f-500" />,
            }}
          />
        </p>
        <img
          src={innerWidth > 650 ? ProtectBDesktop : ProtectBMobile}
          alt="Group B"
          className={s.page__repayments_image}
        />
        <div className={s.page__repayments_description}>
          <ul className="scr-circle-list">
            <li>
              <div className="f-16 f-500">
                {t('v1.detail.from_1st_to_6th_months')}
              </div>
              <div className="f-16 f-400">{t('v1.detail.first_6_months')}</div>
            </li>
            <li>
              <div className="f-16 f-500">
                {t('v1.detail.from_7th_to_11th_months')}
              </div>
              <div className="f-16 f-400">{t('v1.detail.months_7_to_11')}</div>
            </li>
            <li>
              <div className="f-16 f-500">{t('v1.detail.at_12th_month')}</div>
              <div className="f-16 f-400">
                {t('v1.detail.at_12th_month_content')}
              </div>
            </li>
          </ul>
        </div>
        <div className={classnames(s.page__repayments_note1, 'f-16 f-400')}>
          <Trans
            ns="loan_terms"
            i18nKey="v1.detail.late_charges"
            components={{ span: <span className="f-500" /> }}
          />
        </div>
        <div className={classnames(s.page__repayments_note2, 'f-16 f-400')}>
          <Trans
            ns="loan_terms"
            i18nKey="v1.detail.no_fees_on_investor_capital"
            components={{
              span: <span className="f-500" />,
            }}
          />
        </div>
        <Dropdown
          label={
            <span className="f-20 f-400">
              {t('v1.detail.loan_repayments_example')}
            </span>
          }
          className={s.page__example}
          defaultShow={false}
        >
          <>
            <div className={classnames(s.page__example_subtitle, 'f-16 f-400')}>
              <Trans
                ns="loan_terms"
                i18nKey="v1.detail.example_investment_moderate"
                components={{
                  span: <span className="f-500" />,
                }}
              />
            </div>

            <div className={s.page__example_summary}>
              <div className={s.page__example_summary_left}>
                <div className="f-16">
                  <div>{t('v1.detail.loan_amount')}</div>
                  <div className="f-600">
                    <b>€1’000</b>
                  </div>
                </div>
                <div className="f-16">
                  <div>{t('v1.detail.original_date')}</div>
                  <div className="f-600">
                    <b>05.01.2022</b>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.page__example_results}>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupBExample} />
              ) : (
                <ExampleTable data={GroupBExample} />
              )}
            </div>
          </>
        </Dropdown>
      </section>
    ),
  }),
});
