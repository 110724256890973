import classnames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputTextField } from '@/components/UI/InputTextField';
import { BankAccountNumberField } from '@/kit/form/input/BankAccountNumberField';
import { BankStatementUploadField } from '@/kit/form/upload/BankStatementUploadField';
import { SepaZoneCountrySelect } from './SepaZoneCountrySelect';
import { RoundButton } from '@/components/UI/RoundButton';
import WarningBlock from '@/components/UI/WarningBlock';
import { RoundLink } from '@/components/UI/RoundLink';
import { getFlagURL } from '@/helpers/utils';
import { APP_LINKS } from '@/helpers/links';
import s from './index.module.scss';

const VALIDATION_SCHEMA = t =>
  Yup.object({
    country: Yup.mixed().required(t('country_is_required')),
    beneficiary_name: Yup.string().required(t('beneficiary_name_is_required')),
    in_sepa_zone: Yup.boolean(),
    is_valid_sepa: Yup.bool().oneOf([true], t('valid_iban_is_required')),
    account_number: Yup.string().required(t('iban_is_required')),
    files: Yup.array()
      .min(1, t('bank_statement_required_field'))
      .required(t('bank_statement_required_field')),
    swift: Yup.string().when('in_sepa_zone', {
      is: isSepa => isSepa !== true,
      then: () => Yup.string().required(t('swift_bic_code_is_required')),
    }),
    beneficiary_address: Yup.string().when('in_sepa_zone', {
      is: isSepa => isSepa !== true,
      then: () => Yup.string().required(t('address_is_required')),
    }),
    beneficiary_bank: Yup.string().when('in_sepa_zone', {
      is: isSepa => isSepa !== true,
      then: () => Yup.string().required(t('bank_name_is_required')),
    }),
    beneficiary_bank_address: Yup.string().when('in_sepa_zone', {
      is: isSepa => isSepa !== true,
      then: () => Yup.string().required(t('bank_address_is_required')),
    }),
  });

export const BankDetailForm = ({ data, onSubmit }) => {
  const { t } = useTranslation('bank_details');

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={VALIDATION_SCHEMA(t)}
      initialValues={{
        id: data?.id,
        country: data
          ? {
              value: data.country_code,
              code: data.country_code,
              label: data.country,
              flag: getFlagURL(data.country_code),
            }
          : null,
        in_sepa_zone: !!data?.is_sepa_zone,
        is_valid_sepa: false,
        files: data?.bank_statement ? [data.bank_statement] : [],
        beneficiary_name: data?.beneficiary_name || '',
        account_number: data?.account_number || '',
        bank_statement: data?.bank_statement?.id,
        swift: data?.swift || '',
        beneficiary_address: data?.beneficiary_address || '',
        beneficiary_bank: data?.beneficiary_bank || '',
        beneficiary_bank_address: data?.beneficiary_bank_address || '',
      }}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        setValues,
        handleBlur,
        handleChange,
        setFieldError,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className={s.form}>
          <h1 className="f-26 f-500">{t('bank_account_info')}</h1>
          {data?.review_status === 'rejected' && (
            <div className={classnames(s.reason, 'f-14')}>
              {data.rejected_reason}
            </div>
          )}
          <div className={s.inner}>
            <SepaZoneCountrySelect
              value={values.country}
              onChange={data => setValues({ ...values, ...data })}
              label={t('select_your_bank_country')}
              error={errors.country && dirty && errors.country}
            />

            <InputTextField
              onChange={handleChange}
              name="beneficiary_name"
              label={t('beneficiary_name')}
              value={values.beneficiary_name}
              onBlur={handleBlur}
              error={
                errors.beneficiary_name &&
                touched.beneficiary_name &&
                errors.beneficiary_name
              }
            />

            {!values.in_sepa_zone && (
              <>
                <InputTextField
                  onChange={handleChange}
                  name="swift"
                  label={t('swift_bic_code')}
                  value={values.swift}
                  onBlur={handleBlur}
                  error={errors.swift && touched.swift && errors.swift}
                />

                <InputTextField
                  onChange={handleChange}
                  name="beneficiary_address"
                  label={t('address')}
                  value={values.beneficiary_address}
                  onBlur={handleBlur}
                  error={
                    errors.beneficiary_address &&
                    touched.beneficiary_address &&
                    errors.beneficiary_address
                  }
                />

                <InputTextField
                  onChange={handleChange}
                  name="beneficiary_bank"
                  label={t('bank_name')}
                  value={values.beneficiary_bank}
                  onBlur={handleBlur}
                  error={
                    errors.beneficiary_bank &&
                    touched.beneficiary_bank &&
                    errors.beneficiary_bank
                  }
                />

                <InputTextField
                  onChange={handleChange}
                  name="beneficiary_bank_address"
                  label={t('bank_address')}
                  value={values.beneficiary_bank_address}
                  onBlur={handleBlur}
                  error={
                    errors.beneficiary_bank_address &&
                    touched.beneficiary_bank_address &&
                    errors.beneficiary_bank_address
                  }
                />
              </>
            )}

            <BankAccountNumberField
              onChange={handleChange}
              name="account_number"
              value={values.account_number}
              showSepa={values.in_sepa_zone}
              label={t('bank_account_number_iban')}
              countryCode={values.country?.code}
              error={errors.account_number || values.is_valid_sepa}
              onValidate={({ is_valid }) =>
                setFieldValue(
                  'is_valid_sepa',
                  is_valid
                    ? ''
                    : t('incorrect_iban_format_for_selected_country'),
                )
              }
            />
            <BankStatementUploadField
              name="files"
              value={values.files}
              error={errors.files && touched.files && errors.files}
              onBlur={handleBlur}
              onChange={files => setFieldValue('files', files)}
              onError={message => setFieldError('files', message)}
              onRemove={() => setFieldValue('files', [])}
            />
          </div>

          {values.account_number && !values.in_sepa_zone && (
            <WarningBlock withBorder className={s.warning}>
              <div className={classnames('f-16 f-400')}>
                {t('non_sepa_zone_warning')}
              </div>
            </WarningBlock>
          )}

          <div className={s.controls}>
            <RoundLink
              label={t('back')}
              className="hov"
              path={APP_LINKS.bankAccounts}
            />
            <RoundButton
              type="submit"
              label={data ? t('save') : t('submit')}
              className="hov"
              disabled={!dirty || Object.keys(errors).length}
              fillBackground
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
