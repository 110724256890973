import classnames from 'classnames';
import { Tooltip } from 'react-tippy';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tippy } from '@/kit/Tippy';
import { prettyDate, prettyFloatMoney, round } from '@/helpers/utils';
import { CURRENCY, LOAN_GROUPS_IDS } from '@/helpers/constants';
import { DATE_FORMAT_DD_MM_YYYY } from '@/helpers/common';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { InfoIcon } from '@/components/UI/Icons';
import s from './index.module.scss';

export const Table = props => {
  const { t } = useTranslation('investing');
  const { innerWidth } = useSelector(state => state.commonStore);
  const { allocatedAmount, tab: groupId } = props;
  const repayment = props.data.repayment_schedule[0].principal;

  const calculateA = (allocated, repayment, percent) => {
    let current = allocated;

    return props.data.repayment_schedule.map((i, index) => {
      const item = {
        date: i.date,
        earnings_percent: percent,
        loan_balance: null,
        earnings: null,
        earnings_base: null,
      };

      if (index === 0) {
        item.loan_balance = current * percent;
      } else {
        current -= repayment;
        item.loan_balance = current;
      }

      item.earnings = round(item.loan_balance * 0.01, { roundUpper: true });
      item.earnings_base = item.loan_balance * 0.0075;

      return item;
    });
  };

  const calculateB = (allocated, percent) => {
    const lastMonth =
      props.data.repayment_schedule[props.data.repayment_schedule.length - 1];

    return lastMonth
      ? [
          {
            date: lastMonth.date,
            earnings_percent: percent,
            loan_balance: allocatedAmount,
            earnings: round((allocatedAmount * 9) / 100, { roundUpper: true }),
            earnings_base: null,
          },
        ]
      : [];
  };

  const data =
    groupId === LOAN_GROUPS_IDS.conservative.id
      ? calculateA(allocatedAmount, repayment, 1)
      : calculateB(allocatedAmount, 9);

  const { total_earnings, total_earnings_base } = data.reduce(
    (acc, item) => {
      acc.total_loan_balance += item.loan_balance;
      acc.total_earnings += item.earnings;
      acc.total_earnings_base += item.earnings_base;
      return acc;
    },
    { total_loan_balance: 0, total_earnings: 0, total_earnings_base: 0 },
  );

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.inner}>
          <div className={s.table}>
            <div className={s.table__thead}>
              <div className={s.table__thead_col}>{t('summary_due_date')}</div>
              <div className={s.table__thead_col}>
                {t('summary_loan_balance')}
              </div>
              <div className={classnames(s.table__thead_col, s.table__hide)}>
                {' '}
                {t('summary_earnings_percent')}{' '}
              </div>
              <div className={s.table__thead_col}>
                {t('summary_earnings_currency', { currency: CURRENCY })}
              </div>
            </div>

            <div className={s.table__tbody}>
              {data.map((item, index) => (
                <div key={index} className={classnames(s.table__tbody_row)}>
                  <div className={s.table__tbody_col}>
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {prettyDate(item.date, DATE_FORMAT_DD_MM_YYYY)}
                    </span>
                  </div>
                  <div className={s.table__tbody_col}>
                    {prettyFloatMoney(item.loan_balance)}
                  </div>
                  <div
                    className={classnames(s.table__tbody_col, s.table__hide)}
                  >
                    {groupId === LOAN_GROUPS_IDS.conservative.id
                      ? t('summary_up_to_earnings_percent', {
                          percent: item.earnings_percent,
                        })
                      : `${item.earnings_percent}%`}
                  </div>
                  <div className={s.table__tbody_col}>
                    {prettyFloatMoney(item.earnings)}{' '}
                    {innerWidth < 700 && (
                      <>
                        <span
                          style={{ display: 'inline-block', width: '5px' }}
                        />
                        <Tippy
                          render={(ref, open, setOpen) => (
                            <Tooltip
                              open={open}
                              theme="light"
                              trigger="click"
                              arrow
                              interactive
                              html={
                                <div
                                  ref={ref}
                                  className="f-16 f-400"
                                  style={{ maxWidth: '350px' }}
                                >
                                  {groupId === LOAN_GROUPS_IDS.conservative.id
                                    ? t('summary_earnings_percent_up_to_1')
                                    : t('summary_earnings_percent_9')}
                                </div>
                              }
                            >
                              <InfoIcon
                                style={{
                                  position: 'relative',
                                  top: '4px',
                                  cursor: 'pointer',
                                }}
                                onClick={e => {
                                  e.stopPropagation();
                                  setOpen(true);
                                }}
                              />
                            </Tooltip>
                          )}
                        />
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={classnames(s.result, 'f-16-24')}>
        <div className="f-500">{t('summary_total_earnings')}:</div>
        <div className={s.table__tbody_col}>
          {groupId === LOAN_GROUPS_IDS.conservative.id && (
            <span style={{ color: '#9E98A3' }}>{t('summary_up_to')}</span>
          )}{' '}
          <span className="f-500">
            {prettyFloatMoney(
              groupId === LOAN_GROUPS_IDS.conservative.id
                ? total_earnings_base + props.data.increased_income
                : total_earnings,
              { roundUpper: true },
            )}
          </span>
        </div>
      </div>

      {groupId === LOAN_GROUPS_IDS.conservative.id && (
        <div className={classnames(s.hint, 'f-16-24')}>
          <Trans
            ns="investing"
            i18nKey="summary_note_basic_return"
            values={{
              amount: prettyFloatMoney(total_earnings_base, {
                roundUpper: true,
              }),
              additionalAmount: prettyFloatMoney(props.data.increased_income),
            }}
            components={{
              span: <span />,
              helpLink: <MyLink href={APP_LINKS.helpLoanTerms()} underline />,
            }}
          />
        </div>
      )}
    </>
  );
};
