import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP_LINKS } from '@/helpers/links';
import s from './index.module.scss';

const TABS = t => [
  {
    label: t('tabs.brands'),
    path: APP_LINKS.startups,
  },
  {
    label: t('tabs.founders'),
    path: APP_LINKS.founders,
  },
];

export const BrandTabs = () => {
  const { t } = useTranslation('brands');
  return (
    <div className={s.links}>
      {TABS(t).map(tab => (
        <NavLink
          key={tab.path}
          to={tab.path}
          className={({ isActive }) =>
            [s.link, isActive ? s.link_selected : ''].join(' ')
          }
        >
          {tab.label}
        </NavLink>
      ))}
    </div>
  );
};
