import { Trans, useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { useReferrals } from '@/providers/referral';
import { useUserAuth } from '@/query/queries/auth/useUserAuth';
import { RefferalCode } from './referral';
import { EmailForm } from './form';
import s from './index.module.scss';

export const StepMain = ({ children, onEmailSubmit }) => {
  const { currentRefCode } = useReferrals();
  const { t } = useTranslation('auth');
  const { mutateAsync: authenticate } = useUserAuth();

  const onSubmit = async ({ email }, formik) => {
    const payload = {
      email,
      referral_code: currentRefCode?.id || null,
    };
    const res = await authenticate(payload);

    if (res?.temp_token) {
      return onEmailSubmit({ email, ...res });
    }
    formik.setSubmitting(false);
  };

  return (
    <div className={s.wrapper}>
      <h1 className="f-42 f-500">{t('main_step.title')}</h1>
      <div className={s.content}>
        <EmailForm onSubmit={onSubmit} />
        <div className={s.divider} />
        {children}
        {currentRefCode && (
          <>
            <div className={s.divider} />
            <RefferalCode code={currentRefCode} />
          </>
        )}
        <div className={s.divider} />
        <div className={s.notes}>
          <Trans
            ns="auth"
            i18nKey="by_proceeding_with_registration"
            components={{
              span: <span className="f-500" />,
              linkTerms: (
                <MyLink href={APP_LINKS.termOfUse} isExternal underline />
              ),
              linkPolicy: (
                <MyLink href={APP_LINKS.privacyPolicy} isExternal underline />
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};
