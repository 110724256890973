import { Trans } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/bottom';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { LOAN_GROUPS_IDS } from '@/helpers/constants';

export const TERM_TOOLTIPS = t => ({
  earnings: ({ className }) => (
    <Tooltip className={className}>
      <div className="t_earning">{t('tooltip_earnings')}</div>
    </Tooltip>
  ),
  net_annual_return: ({ className }) => (
    <Tooltip className={className}>
      <div className="t_return">{t('tooltip_net_annual_return')}</div>
    </Tooltip>
  ),
  term: ({ className }) => (
    <Tooltip className={className}>
      <div className="t_term">
        {t('tooltip_term')}
        <MyLink href={APP_LINKS.helpLoanTerms()} underline>
          {t('tooltip_scramble_loan_terms')}
        </MyLink>
      </div>
    </Tooltip>
  ),
  repayments: ({ className }) => (
    <Tooltip className={className}>
      <div className="t_repayments" style={{ whiteSpace: 'pre-line' }}>
        <Trans
          ns="loan_terms"
          i18nKey="tooltip_repayments"
          components={{ span: <span style={{ fontWeight: '500' }} /> }}
        />
      </div>
    </Tooltip>
  ),
  skin: ({ className }) => (
    <Tooltip className={className}>
      <div className="t_skin">{t('tooltip_skin')}</div>
    </Tooltip>
  ),
  protection: ({ group, className }) => (
    <Tooltip className={className}>
      <div className="t_protection">
        {group === LOAN_GROUPS_IDS.conservative.id && (
          <ul className="scr-circle-list">
            <li>{t('tooltip_protection_conservative_1')}</li>
            <li>{t('tooltip_protection_conservative_2')}</li>
            <li>{t('tooltip_protection_conservative_3')}</li>
          </ul>
        )}

        {group === LOAN_GROUPS_IDS.moderate.id && (
          <ul className="scr-circle-list">
            <li>{t('tooltip_protection_moderate_1')}</li>
          </ul>
        )}
      </div>
    </Tooltip>
  ),
});
