import axios from '@/axios-api';
import Pathes from '@/helpers/pathes';
import { getMessage } from '@/helpers/utils';
import Notify from '@/components/Notification';
import i18n from '@/i18n';

const { t } = i18n;

// Link generation
export const getReferralCode = () => {
  return axios
    .get(Pathes.Referrals.getCode)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Invite referral
export const inviteReferralByEmail = payload => {
  return axios
    .post(Pathes.Referrals.emailSend, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: t('kit:notify.email_sent') });
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Accept or Decline Terms Of use
export const acceptPartnerTermsOfUse = payload => {
  return axios
    .post(Pathes.Referrals.acceptPartnerTermsOfUse, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Check referral code validity
export const checkReferralCodeValidity = code => {
  return axios
    .get(Pathes.Referrals.checkRefCodeValidity(code))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Check user referred status
export const checkUserReferralCodeStatus = () => {
  return axios
    .get(Pathes.Referrals.checkRefCode)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Activate user referral code
export const applyUserReferralCode = code => {
  return axios
    .post(Pathes.Referrals.applyRefCode, { code })
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        data.is_activated &&
          Notify.success({ text: t('kit:notify.ref_code_applied') });
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ error: e.message, success: false }));
};

// Check for valid code
export const checkReferralCode = code => {
  return axios
    .get(Pathes.Referrals.check(code))
    .then(response => {
      const { status, data } = response;
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(t('kit:notify.something_wrong'));
    })
    .catch(e => {
      return { error: e.message, success: false };
    });
};
