import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getRepaymentSchedule } from '@/store/actions/loanActions';
import { Preloader } from '@/components/Preloader';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import Checkbox from '@/components/UI/Checkbox';
import { SuspendResidenceWarning } from '@/components/Warnings/SuspendResidenceWarning';
import { getUserProfile } from '@/store/actions/profileActions';
import { USER_STATUSES } from '@/helpers/constants';
import RepScheduleTable from './table';
import RepScheduleList from './list';
import './index.scss';

export const REPAYMENT_STATUSES = t => ({
  scheduled: { label: t('scheduled'), color: '#000000', className: '' },
  paid: { label: t('paid'), color: '#9E98A3', className: 'c-grey' },
  paid_overdue: {
    label: t('paid_after_due_date'),
    color: '#9E98A3',
    className: 'c-grey',
  },
  paid_partially: {
    label: t('partially_paid'),
    color: '#000000',
    className: '',
  },
  paid_partially_late: {
    label: t('late_partially_paid'),
    color: '#F93C10',
    className: 'c-red',
  },
  late: { label: t('late'), color: '#F93C10', className: 'c-red' },
});

const RepaymentScheduleModule = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('repayment_schedule');
  const [excludePaid, setExcludePaid] = useState(true);
  const { repaymentSchedule } = useSelector(state => state.loanStore);
  const { innerWidth } = useSelector(state => state.commonStore);
  const profile = useSelector(state => state.profileStore.profile.data);

  useEffect(() => {
    dispatch(getRepaymentSchedule({ exclude_paid: excludePaid ? 1 : 0 }));
  }, [excludePaid]);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  const { data, loading } = repaymentSchedule;
  const isEmpty = !data?.list.length;

  if (profile?.status === USER_STATUSES.suspended && isEmpty) {
    return (
      <div className="repayment_schedule_module">
        <SuspendResidenceWarning />
      </div>
    );
  }

  return (
    <div className="repayment_schedule_module">
      {profile?.status === USER_STATUSES.suspended && (
        <SuspendResidenceWarning className="repayment_schedule_module__warning" />
      )}

      {!isEmpty && data?.show_extend_disclaimer && (
        <div className="repayment_schedule_module__disclaimer f-16-24 f-400">
          {t('repayment_schedule_disclaimer')}{' '}
          <MyLink href={APP_LINKS.helpLoanTerms()} underline>
            {t('loan_terms')}
          </MyLink>
          .
        </div>
      )}

      <div className="repayment_schedule_module__controls">
        <Checkbox
          name="excludePaid"
          isChecked={excludePaid}
          onChange={() => setExcludePaid(!excludePaid)}
          className="repayment_schedule_module__exclude"
          render={() => (
            <span className={classnames('f-16')}>
              {t('hide_paid_payments')}
            </span>
          )}
        />
      </div>

      {isEmpty && !loading && (
        <div className="repayment_schedule_module__empty f-16">
          {t('no_records_yet')}
        </div>
      )}

      {loading ? (
        <Preloader className="repayment_schedule_module__preloader" />
      ) : (
        !isEmpty &&
        (innerWidth < 992 ? (
          <RepScheduleList data={data.list} />
        ) : (
          <RepScheduleTable data={data.list} />
        ))
      )}
    </div>
  );
};

export default RepaymentScheduleModule;
