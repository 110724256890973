import React from 'react';
import classnames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { CYRILLIC_REGEX } from '../../../helpers/common';
import s from './index.module.scss';

export const TextareaField = ({
  label,
  name,
  value,
  minRows = 3,
  onChange,
  error,
  limit,
  placeholder = ' ',
  disabled,
  allowCyrillic,
  className,
}) => {
  const handleOnChange = e => {
    if (!allowCyrillic && CYRILLIC_REGEX.test(e.target.value)) {
      return;
    }
    onChange(e);
  };

  return (
    <div className={classnames(s.wrapper, className)}>
      <div className={classnames(s.textarea_group, 'textarea_group')}>
        <TextareaAutosize
          name={name}
          value={value}
          onChange={handleOnChange}
          minRows={minRows}
          placeholder={placeholder}
          disabled={disabled}
          className={classnames(
            s.textarea_input,
            value && s.textarea_input_filled,
            error && s.textarea_input_error,
            'textarea',
          )}
        />
        <label className={s.textarea_label} htmlFor={name}>
          {label}
        </label>
        {!disabled && limit && (
          <div className={classnames(s.textarea_limit)}>
            <span
              className={classnames(
                s.textarea_limit_start,
                value.length > limit && s.textarea_limit_start_error,
              )}
            >
              {value.length}
            </span>
            /<span className={s.textarea_limit_end}>{limit}</span>
          </div>
        )}
      </div>
      {error && (
        <div className={classnames(s.textarea_error_text)}>{error}</div>
      )}
    </div>
  );
};
