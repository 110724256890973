import { BasicLayout } from '@/layouts/BasicLayout';
import { RoundLink } from '@/components/UI/RoundLink/index';
import { APP_LINKS } from '@/helpers/links';
import s from './index.module.scss';

const PageNotFound = () => (
  <BasicLayout>
    <div className="container">
      <div className={s.content}>
        <div className={[s.text, 'f-26-32'].join(' ')}>
          404 - Page Not Found
        </div>
        <RoundLink
          path={APP_LINKS.dashboard}
          fillBackground
          label="Refresh page"
        />
      </div>
    </div>
  </BasicLayout>
);

export default PageNotFound;
