import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { prettyFloatMoney } from '@/helpers/utils';
import s from './index.module.scss';

export const IncreasedIncomeWarning = ({ amount, className }) => {
  const { t } = useTranslation('loan_terms');
  return (
    <div className={classnames(s.wrapper, className)}>
      {amount ? (
        <>
          <div className="f-16-24 f-500">
            {t('v3.warning.want_more', {
              amount: `+${prettyFloatMoney(amount)}`,
            })}
          </div>
          <div className="f-16-24 f-400">
            {t('v3.warning.get_increased_income')}
          </div>
          <div className="f-16-24 f-400">
            {t('v3.warning.increased_income_condition')}
          </div>
        </>
      ) : (
        <>
          <div className="f-16-24 f-500">
            {t('v3.warning.get_increased_income')}
          </div>
          <div className="f-16-24 f-400">
            {t('v3.warning.increased_income_condition')}
          </div>
        </>
      )}
    </div>
  );
};
