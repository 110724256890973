import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { getBlogBanners } from '@/store/actions/blogActions';
import { Slider } from './slider';
import s from './index.module.scss';

export const BlogBanner = ({ className }) => {
  const dispatch = useDispatch();
  const slides = useSelector(state => state.blogStore.blogBanners);

  useEffect(() => {
    dispatch(getBlogBanners());
  }, []);

  if (!slides?.length) {
    return null;
  }

  return (
    <div className={classnames(className, s.wrapper)}>
      <Slider slides={slides} />
    </div>
  );
};
