import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { FileName } from '@/kit/FileName';
import { FileUploadField } from '@/components/UI/FileUploadField';
import {
  ALLOWED_FILE_SIZES,
  BANK_STATEMENT_ALLOWED_FORMATS,
} from '@/helpers/common';
import { checkForValidFile } from '@/helpers/utils';
import { Tooltip } from '@/kit/Tooltip';
import s from './index.module.scss';

export const BankStatementUploadField = ({
  name,
  value = [],
  error,
  onBlur,
  onChange,
  onError,
  onRemove,
}) => {
  const { t } = useTranslation('bank_details');
  return (
    <div className={s.wrapper}>
      <div className={classnames(s.label, 'f-16')}>
        {t('upload_field.bank_statement')} *
        <Tooltip
          renderContent={() => (
            <div className={s.tooltip_text}>
              {t('upload_field.tooltip_text')}
            </div>
          )}
        />
      </div>
      {value.length ? (
        <div className={s.files}>
          {value.map((file, index) => (
            <FileName key={index} file={file} onRemove={onRemove} />
          ))}
        </div>
      ) : (
        <FileUploadField
          name={name}
          multiple={false}
          label={t('upload_field.upload_document_label')}
          description={t('upload_field.upload_file_description')}
          accept={BANK_STATEMENT_ALLOWED_FORMATS}
          error={!!error}
          className={s.upload}
          onBlur={onBlur}
          onChange={e => {
            const files = Object.keys(e.target.files).map(
              idx => e.target.files[idx],
            );
            const validFiles = files.filter(file => {
              const { isValid, type, size } = checkForValidFile(
                file,
                BANK_STATEMENT_ALLOWED_FORMATS,
                ALLOWED_FILE_SIZES['1mb'],
              );

              !isValid && size && onError(t('upload_field.file_size_error'));
              !isValid && type && onError(t('upload_field.file_type_error'));
              return isValid;
            });
            onChange(validFiles);
          }}
        />
      )}
    </div>
  );
};
