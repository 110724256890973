import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BasicLayout } from '@/layouts/BasicLayout';
import { RoundButton } from '@/components/UI/RoundButton';
import { Preloader } from '@/components/Preloader';
import { toggleSubscriptionEmails } from '@/store/services/userServices';
import './index.scss';

const UnsubscribePage = () => {
  const [loading, setLoading] = useState(true);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    handleSubscribe();
  }, []);

  const handleSubscribe = async () => {
    const res = await toggleSubscriptionEmails(token, unsubscribed);
    if (res && res.success) {
      setUnsubscribed(!unsubscribed);
    }
    setLoading(false);
  };

  return (
    <BasicLayout>
      <div className="container">
        <div className="unsubscribe-page">
          {loading ? (
            <Preloader className="unsubscribe-page__preloader" />
          ) : (
            <>
              {unsubscribed ? (
                <>
                  <h1 className="unsubscribe-page__title f-32 f-500">
                    Scramble notifications
                  </h1>
                  <p className="unsubscribe-page__message f-16 f-400">
                    You have successfully unsubscribed from the mailing list.
                  </p>
                  <p className="unsubscribe-page__note f-16 f-400">
                    You will no longer receive information about services,
                    offers, news and updates which are available to you as an
                    investor, including critical updates which can affect your
                    investments. If you have changed your mind, please click the
                    button below to renew your subscription.
                  </p>
                  <RoundButton
                    label="Subscribe again"
                    type="button"
                    fillBackground
                    fullWidth
                    onClick={handleSubscribe}
                    className="unsubscribe-page__button"
                  />
                </>
              ) : (
                <>
                  <h1 className="unsubscribe-page__title f-32 f-500">
                    Scramble notifications
                  </h1>
                  <p className="unsubscribe-page__message f-16 f-400">
                    You have subscribed to the mailing list.
                  </p>
                  <RoundButton
                    label="Unsubscribe"
                    type="button"
                    fillBackground
                    fullWidth
                    onClick={handleSubscribe}
                    className="unsubscribe-page__button"
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </BasicLayout>
  );
};

export default UnsubscribePage;
