import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader } from '@/components/Preloader';
import { RoundSummary } from './components/summary';
import { RoundDetail } from './components/detail';
import {
  getAllRounds,
  getRoundsSummary,
} from '@/store/actions/roundHistoryActions';
import s from './index.module.scss';

const RoundHistory = () => {
  const { t } = useTranslation('round_history');
  const dispatch = useDispatch();
  const { data: allRounds } = useSelector(
    state => state.roundHistoryStore.allRounds,
  );
  const { loading } = useSelector(
    state => state.roundHistoryStore.pastRoundStats,
  );
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    dispatch(getRoundsSummary());
    !allRounds && dispatch(getAllRounds());
  }, []);

  useEffect(() => {
    const rootEl = document.getElementById('main');
    const footerEl = document.querySelector('.auth-footer');

    if (rootEl) {
      rootEl.style.background = '#F3F2F4';
    }

    if (footerEl) {
      footerEl.style.marginTop = 0;
    }

    return () => {
      if (rootEl) {
        rootEl.style.background = 'unset';
      }

      if (footerEl) {
        footerEl.style.marginTop = '50px';
      }
    };
  }, []);

  useEffect(() => {
    if (!selected && allRounds) {
      setSelected(allRounds[allRounds.length - 1]);
    }
  }, [selected, allRounds]);

  return (
    <div className="container-inner">
      <div className={s.page}>
        <div className={s.header}>
          <h2 className="f-42 f-500">{t('round_history_title')}</h2>
          <p className={classnames(s.header__right, 'f-16-24', 'f-400')}>
            {t('round_history_description')}
          </p>
        </div>
        <RoundSummary />
        {!loading && !selected ? (
          <Preloader className={s.preloader} />
        ) : (
          selected && (
            <RoundDetail selected={selected} setSelected={setSelected} />
          )
        )}
      </div>
    </div>
  );
};

export default RoundHistory;
