import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AFFILIATE_STEPS } from '../data';
import s from './index.module.scss';

export const AffiliateStepsDesktop = props => {
  const { t } = useTranslation('affiliate');
  const { referralCode } = useSelector(state => state.referralStore);
  const isActiveReferralBanner = !!referralCode.data?.is_referral_banner_active;

  return (
    <div className={classnames(s.wrapper, props.className)}>
      <div className={s.cards}>
        {AFFILIATE_STEPS(t).map((item, index) => (
          <React.Fragment key={index}>
            <div className={s.item} key={index}>
              <div className={s.header}>
                <div className={s.number}>0{index + 1}</div>
                <div className="f-18 f-500">{item.label}</div>
              </div>
              <div className={classnames(s.desc, 'f-16 f-400')}>
                {item.desc}
              </div>
              {isActiveReferralBanner && item.banner}
            </div>
            <div className={s.divider} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
