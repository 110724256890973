import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  getQuestionnaireList,
  submitQuestionnaire,
} from '@/store/services/questionnaireService';
import { RadioButton } from '@/kit/form/RadioButton';
import { RoundButton } from '@/components/UI/RoundButton';
import s from './index.module.scss';

export const QuestionnaireForm = ({ updateStatus }) => {
  const { t } = useTranslation('modal');
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    getQuestionnaireList().then(res => res.success && setQuestions(res.data));
  }, []);

  const onSubmit = async values => {
    const payload = Object.keys(values).reduce(
      (acc, key) => {
        acc.questions_and_answers.push({
          question_id: Number(key),
          answer_ids: [Number(values[key])],
        });
        return acc;
      },
      { questions_and_answers: [] },
    );
    const res = await submitQuestionnaire(payload);
    if (res?.success) {
      updateStatus();
    }
  };

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {},
    onSubmit,
  });

  return (
    <form className={s.wrapper} onSubmit={handleSubmit}>
      <h1 className="f-24 f-500">
        {t('questionnaire_form.before_making_investment')}
      </h1>
      <div className={s.questions}>
        {questions.map((item, index) => (
          <div className={s.question} key={item.id}>
            <div className="f-16 f-500">
              {index + 1}. {item.text}
            </div>
            <div className={s.answers}>
              {item.answers.map(answer => (
                <RadioButton
                  key={answer.id}
                  value={answer.id}
                  name={`${item.id}`}
                  onChange={handleChange}
                >
                  {answer.text}
                </RadioButton>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={s.buttons}>
        <RoundButton
          type="submit"
          label={t('questionnaire_form.submit')}
          onSubmit={handleSubmit}
          disabled={questions.length !== Object.keys(values).length}
          className={s.submit}
          fillBackground
        />
      </div>
    </form>
  );
};
