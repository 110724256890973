import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { getVeriffStatus } from '@/store/actions/profileActions';
import { setModals } from '@/store/actions/commonActions';
import { MODAL_TYPES } from '@/components/ModalProvider';
import { VERIFF_CODES } from '@/helpers/constants';
import { SET_VERIFF_STATUS_CODE } from '@/store/types/profileTypes.js';

export const SESSION_NOTIFICATION_KEY = 'veriffNotificationTime'; // Ключ для sessionStorage
export const LOCAL_STORAGE_VISIT_KEY = 'user-has-visit'; // Ключ для localStorage
const NOTIFICATION_INTERVAL = 1440 * 60 * 1000; // Интервал в миллисекундах (например, 1440 минут)

// Функция для проверки, нужно ли показывать уведомление
function shouldShowNotification() {
  const lastNotificationTime = sessionStorage.getItem(SESSION_NOTIFICATION_KEY);

  if (!lastNotificationTime) {
    // Если данных нет, значит пользователь впервые зашел в сессию
    return true;
  }

  const lastTime = new Date(lastNotificationTime);
  const currentTime = new Date();

  // Проверяем, прошло ли заданное время с момента последнего уведомления
  return currentTime - lastTime > NOTIFICATION_INTERVAL;
}

// Функция для обновления времени показа уведомления
function updateNotificationTime() {
  sessionStorage.setItem(SESSION_NOTIFICATION_KEY, new Date().toISOString());
}

export const useVeriffNotification = () => {
  const userVisited = useRef(localStorage.getItem(LOCAL_STORAGE_VISIT_KEY));
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.profileStore);
  const veriffStatusCode = useSelector(
    state => state.profileStore.veriffStatusCode,
  );
  const [veriffCode, setVeriffCode] = useState();

  useEffect(() => {
    if (!userVisited.current && profile.data?.email) {
      localStorage.setItem(LOCAL_STORAGE_VISIT_KEY, profile.data.email);
    }
  }, [profile.data?.email]);

  useEffect(() => {
    if (profile.data && !profile.data.is_verified) {
      dispatch(getVeriffStatus()).then(res => {
        if (res && res.success) {
          setVeriffCode(res.data.code);
        }
      });
    }
  }, [profile.data?.is_verified]);

  const closeModal = () => {
    dispatch(setModals([]));
  };

  const onVerifyIdentity = () => {
    ![VERIFF_CODES.submitted, VERIFF_CODES.verified].includes(
      veriffStatusCode,
    ) &&
      dispatch(
        setModals(
          {
            type: MODAL_TYPES.wizard_veriff,
            id: 'veriff-notify',
            onBack: closeModal,
            onClose: closeModal,
            onSuccess: () => {
              setTimeout(() => {
                dispatch({
                  type: SET_VERIFF_STATUS_CODE,
                  code: VERIFF_CODES.submitted,
                });
              }, 2000);
              closeModal();
            },
          },
          true,
        ),
      );
  };

  useEffect(() => {
    if (
      userVisited.current &&
      profile.data?.email === userVisited.current &&
      profile.data?.is_verified === false &&
      typeof veriffCode === 'number' &&
      ![VERIFF_CODES.verified, VERIFF_CODES.submitted].includes(veriffCode)
    ) {
      if (shouldShowNotification()) {
        onVerifyIdentity();
        updateNotificationTime();
      }
    }
  }, [profile.data?.is_verified, veriffCode]);
};
