import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextareaField } from '@/components/UI/TextareaField';
import { RoundButton } from '@/components/UI/RoundButton';
import {
  createNewQuestion,
  getQuestionList,
} from '@/store/actions/questionActions';
import Notify from '@/components/Notification';
import { USER_STATUSES } from '@/helpers/constants';
import { RoundLink } from '@/components/UI/RoundLink';
import { APP_LINKS } from '@/helpers/links';
import { useGetMe } from '@/hooks/useGetMe';
import s from './index.module.scss';

const LIMIT = 300;

export const NewQuestion = () => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const { user } = useGetMe();
  const { t } = useTranslation('client_questions');

  const createMessage = async () => {
    const payload = {
      reply_to: null,
      comment_text: text,
    };

    const res = await dispatch(createNewQuestion(payload));
    if (res?.success) {
      Notify.success({ text: t('new.question_submitted') });
      dispatch(getQuestionList({ page: 1, limit: 1000 }));
      setText('');
    }
  };

  return (
    <div className={s.wrapper}>
      <h2 className="h2">{t('new.ask_your_question')}</h2>

      {user?.status === USER_STATUSES.verified ? (
        <>
          <div className={s.description}>{t('new.type_your_question')}</div>
          <TextareaField
            value={text}
            onChange={e =>
              e.target.value.length <= LIMIT && setText(e.target.value)
            }
            placeholder={t('new.message_placeholder')}
            className={s.textarea}
          />
          <div className={s.textarea_limit}>
            {t('new.characters_limit', { count: LIMIT - text.length })}
          </div>
          <RoundButton
            label={() => t('new.submit_button')}
            fullWidth
            fillBackground
            onClick={createMessage}
            className={s.link}
          />
        </>
      ) : (
        <>
          <div className={s.description}>{t('new.verify_yourself_text')}</div>
          <RoundLink
            label={t('new.verify_yourself_button')}
            fullWidth
            fillBackground
            className={s.button}
            path={APP_LINKS.profile}
          />
        </>
      )}
    </div>
  );
};
