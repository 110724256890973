import { useTranslation } from 'react-i18next';
import { APP_LINKS } from '@/helpers/links';
import { RoundLink } from '@/components/UI/RoundLink';
import { ArticleCard } from '../../BlogPage/components/ArticleCard';
import s from './index.module.scss';

export const LatestArticles = ({ posts }) => {
  const { t } = useTranslation('blog');
  return (
    <div className={s.wrapper}>
      <h2 className="f-32 f-500">{t('detail.latest_posts')}</h2>
      <div className={s.cards}>
        {posts.slice(0, 3).map(article => (
          <ArticleCard key={article.id} article={article} className={s.card} />
        ))}
      </div>
      <div className={s.controls}>
        <RoundLink
          label={t('detail.full_list_of_articles')}
          type="btn_black_border"
          variant="black"
          path={APP_LINKS.blog}
        />
      </div>
    </div>
  );
};
