import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import ShareButtons from '@/components/ShareButtons';
import { CopyRoundButton } from '@/components/UI/CopyRoundButton';
import config from '@/config';
import { ACCEPT_PARTNER_TERMS_SOURCES } from '@/pages/AffiliatePage';
import { BonusInviteFriends } from '../BonusInviteFriend';
import { APP_LINKS } from '@/helpers/links';
import {
  acceptPartnerTermsOfUse,
  getReferralCode,
} from '@/store/services/referralService';
import s from './index.module.scss';

const InviteFriends = () => {
  const { t } = useTranslation('investing');
  const [code, setCode] = useState(null);

  useEffect(() => {
    getReferralCode().then(res => res && res.success && setCode(res.data));
  }, []);

  if (!code) {
    return null;
  }

  const sharedText = t('friends_shared_text');
  const inviteLink = code && config.mainPromoDomain + '?ref=' + code.code;

  return (
    <div className={s.wrapper}>
      <h2 className="f-26 f-500">{t('friends_invite_your_friends')}</h2>

      <div className={s.block}>
        {code?.is_referral_banner_active && <BonusInviteFriends />}

        <div className={s.desc}>
          {t('friends_invite_description')}{' '}
          <MyLink href={APP_LINKS.affiliate} underline>
            {t('friends_more_details')}
          </MyLink>
        </div>

        <div className={s.social}>
          <div>{t('friends_share_via_social')}</div>
          <ShareButtons
            url={inviteLink}
            options={{
              facebook: { quote: sharedText },
              twitter: { title: sharedText },
              linkedin: { title: sharedText, source: inviteLink },
            }}
            onClick={() => {
              acceptPartnerTermsOfUse({
                has_accepted: true,
                source: ACCEPT_PARTNER_TERMS_SOURCES.shareBySocial,
                extra_data: {},
              });
            }}
            className={s.social_buttons}
          />
        </div>

        <div className={s.share}>
          <div>{t('friends_copy_link')}</div>
          <div className={s.share_content}>
            <div className={classnames(s.share_value, 'f-16 f-500')}>
              {inviteLink}
            </div>
            <CopyRoundButton
              text={inviteLink}
              label={t('friends_copy_link_label')}
              successLabel={t('friends_link_copied')}
              onClick={() => {
                acceptPartnerTermsOfUse({
                  has_accepted: true,
                  source: ACCEPT_PARTNER_TERMS_SOURCES.copyButton,
                  extra_data: {},
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteFriends;
