import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { authService } from '../../services/authService';

export const useGetEmailOtpStatus = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.auth.email_otp_status],
    queryFn: () =>
      authService.emailOtpStatus().then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
      }),
  });
};
