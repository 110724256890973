import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { STEPS } from '../index';
import s from '../index.module.scss';

export const WainUntilCheckedStep = ({ passed, isActiveFR }) => {
  const { t } = useTranslation('dashboard');
  const active = passed.includes(STEPS.wait_until_checked);

  return (
    <li
      id={`vpw_${STEPS.wait_until_checked}`}
      className={classnames(s.list_item, active && s.list_item_active)}
    >
      <div className={s.list_item_left}>
        <span
          className={classnames(
            s.circle,
            active && s.circle_active,
            'f-26 f-400',
          )}
        >
          03
        </span>
      </div>
      <div className={s.list_item_right}>
        <div className={classnames(s.list_item_title, 'f-26 f-400')}>
          {t('wizard_step_3_wait_until_checked')}
        </div>
        <div
          className={classnames(
            s.list_item_content,
            active &&
              !passed.includes(STEPS.add_cash) &&
              s.list_item_content_active,
            'f-16-24 f-400',
          )}
        >
          <div className={s.list_item_content_inner}>
            <div className="f-16 f-400">
              {t('wizard_step_3_checking_profile_info')}
            </div>
            <div className="f-16 f-400" style={{ marginTop: '8px' }}>
              {t('wizard_step_3_notify_by_email')}
            </div>
            {isActiveFR ? (
              <div className="f-16 f-400" style={{ marginTop: '8px' }}>
                <Trans
                  ns="dashboard"
                  i18nKey="wizard_step_3_message_1"
                  components={{
                    LinkInvesting: (
                      <MyLink href={APP_LINKS.investing} underline />
                    ),
                    LinkLoanTerms: (
                      <MyLink href={APP_LINKS.helpLoanTerms()} underline />
                    ),
                  }}
                />
              </div>
            ) : (
              <div className="f-16 f-400">
                <Trans
                  ns="dashboard"
                  i18nKey="wizard_step_3_message_2"
                  components={{
                    LinkBrands: <MyLink href={APP_LINKS.startups} underline />,
                    LinkLoanTerms: (
                      <MyLink href={APP_LINKS.helpLoanTerms()} underline />
                    ),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};
