import Cookies from 'js-cookie';
import config from '@/config';

export const COOKIE_PARENT_DOMAIN =
  config.release === 'development' ? 'localhost' : 'scrambleup.com';

const COOKIE_LANG_KEY = 'preferredLanguage';
const COOKIE_DETECT_KEY = 'detectLanguage';

export const saveUserLocale = locale => {
  Cookies.remove(COOKIE_LANG_KEY);
  Cookies.set(COOKIE_LANG_KEY, locale, {
    expires: 365 * 100,
    domain: COOKIE_PARENT_DOMAIN,
  });
};

export const getUserLocaleDetection = () =>
  Cookies.get(COOKIE_DETECT_KEY, {
    domain: COOKIE_PARENT_DOMAIN,
  });

export const setUserLocaleDetection = () =>
  Cookies.set(COOKIE_DETECT_KEY, '1', {
    expires: 365 * 100,
    domain: COOKIE_PARENT_DOMAIN,
  });
