import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TrustpilotReviewButton } from '../Trustpilot/review';
import s from './index.module.scss';

export const WriteReview = ({ className }) => {
  const { t } = useTranslation('investing');
  return (
    <div className={classnames(s.wrapper, className)}>
      <h2 className="f-26 f-500">{t('write_a_review_about_scramble')}</h2>
      <div className={s.desc}>
        {t('help_other_users_by_sharing_your_experience_with_scramble')}
        <div>
          <TrustpilotReviewButton className={s.button} />
        </div>
      </div>
    </div>
  );
};
