import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { APP_LINKS } from '@/helpers/links';

const BrandsRootPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === APP_LINKS.brandsRoot) {
      navigate(APP_LINKS.startups, { replace: true });
    }
  }, [location.pathname, navigate]);

  return <Outlet />;
};

export default BrandsRootPage;
