import axios from '@/axios-api';
import Pathes from '@/helpers/pathes';
import { getMessage } from '@/helpers/utils';
import i18n from '@/i18n';
import {
  GET_USER_PROFILE,
  SET_BONUS_GROUP,
  SET_USER_PROFILE,
  SET_VERIFF_STATUS_CODE,
  UPDATE_USER_PROFILE,
} from '../types/profileTypes';
import Notify from '@/components/Notification';
import { updateUser } from './userActions';

const { t } = i18n;

const getUserProfileRequest = () => ({ type: GET_USER_PROFILE.REQUEST });
const getUserProfileSuccess = payload => ({
  type: GET_USER_PROFILE.SUCCESS,
  payload,
});
const getUserProfileFailure = error => ({
  type: GET_USER_PROFILE.FAILURE,
  error,
});

const updateUserProfileRequest = () => ({ type: UPDATE_USER_PROFILE.REQUEST });
const updateUserProfileSuccess = payload => ({
  type: UPDATE_USER_PROFILE.SUCCESS,
  payload,
});
const updateUserProfileFailure = error => ({
  type: UPDATE_USER_PROFILE.FAILURE,
  error,
});

const setBonusGroup = payload => ({
  type: SET_BONUS_GROUP,
  payload,
});

export const getUserProfile = () => {
  return dispatch => {
    dispatch(getUserProfileRequest());
    return axios
      .get(Pathes.User.profile)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(updateUser(data));
          dispatch(getUserProfileSuccess(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getUserProfileFailure(e.message)));
  };
};

export const setUserProfile = profile => {
  return dispatch => {
    dispatch({ type: SET_USER_PROFILE, profile });
  };
};

export const updateUserProfile = data => {
  return dispatch => {
    dispatch(updateUserProfileRequest());
    return axios
      .put(Pathes.User.profile, data)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(updateUserProfileSuccess(data));
          Notify.success({ text: t('kit:notify.profile_updated') });
          return { ...data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(updateUserProfileFailure(e.message)));
  };
};

// Veriff status
export const getVeriffStatus = () => {
  return (dispatch, getState) => {
    const code = getState().profileStore.veriffStatusCode;
    return axios
      .get(Pathes.User.veriffStatus)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          code !== data.code &&
            dispatch({ type: SET_VERIFF_STATUS_CODE, code: data.code });
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(() => {
        return { success: false };
      });
  };
};

export const updateProfileVisibility = payload => {
  return (dispatch, getState) => {
    const profile = getState().profileStore.profile.data;
    return axios
      .post(Pathes.User.profileVisibility, payload)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          profile &&
            dispatch(
              setUserProfile({ ...profile, is_visible: data.is_visible }),
            );
          Notify.success({ text: t('kit:notify.profile_updated') });
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(e => {
        Notify.error({ text: t('kit:notify.profile_update_failed') });
        return { error: e.message, success: false };
      });
  };
};

export const getBonusGroup = () => {
  return dispatch => {
    return axios
      .get(Pathes.User.bonusGroup)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(setBonusGroup(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(() => {
        dispatch(setBonusGroup(null));
        return { success: false };
      });
  };
};
