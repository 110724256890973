import { useTranslation } from 'react-i18next';
import NotificationBlock from '@/containers/NotificationBlock';
import { NOTIFICATIONS as NOTIFICATIONS_T } from '@/components/Notification/list';
import InviteFriends from '@/components/InviteFriends';
import { useCheckPlannedPayments } from '@/components/Notification/hooks/useCheckPlannedPayments';
import InvestingInfo from '@/containers/InvestingInfo';
import './index.scss';

const InvHold = ({ status }) => {
  const { t } = useTranslation('investing');
  const { t: tCommon } = useTranslation('common');
  const { hasPlannedPayment } = useCheckPlannedPayments();
  const NOTIFICATIONS = NOTIFICATIONS_T(tCommon);

  const notifications = [NOTIFICATIONS.result_being_processed()];
  hasPlannedPayment && notifications.push(NOTIFICATIONS.october_weekends());

  return (
    <div className="inv-hold">
      <h1 className="f-42 f-500">{t('ih_get_ready_for_the_results')}</h1>
      <NotificationBlock
        className="inv-hold__notifications"
        notifications={notifications}
      />
      <InvestingInfo
        status={status}
        skipResultFetch
        className="inv-hold__summary"
      />
      <InviteFriends analyticSource="onhold" />
    </div>
  );
};

export default InvHold;
