import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Avatar } from '@/components/UI/Avatar';
import {
  getFullname,
  getLoansShortNames,
  getNameInitials,
  prettyFloatMoney,
  shortenDigit,
} from '@/helpers/utils';
import { RoundButton } from '@/components/UI/RoundButton';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import './index.scss';

const FounderCard = ({
  founder,
  active,
  onSelect,
  onClick,
  to,
  hideFR,
  className,
}) => {
  const { t } = useTranslation();

  if (!founder) {
    return null;
  }
  const fullname = getFullname(founder.first_name, founder.last_name);
  const company =
    founder.company &&
    (typeof founder.company === 'string'
      ? founder.company
      : founder.company.name);

  return (
    <MyLink
      href={to || `${APP_LINKS.founderDetail(founder.id)}`}
      onClick={onClick}
      className={classnames('founder-card', className)}
    >
      <div className="founder-card__inner">
        <div className="founder-card__top">
          <div className="founder-card__avatar">
            <Avatar
              src={founder.photo && founder.photo.file}
              nameInitials={getNameInitials(
                founder.first_name,
                founder.last_name,
              )}
              alt={fullname}
              size={160}
              active={active}
            />
            {!hideFR && founder.received && (
              <div className="founder-card__received f-12 f-400 tl">
                <span>{t('founder_card.received')}:</span> {prettyFloatMoney(founder.received)}
              </div>
            )}
          </div>
          <h6 className="founder-card__name f-18 f-500">{fullname}</h6>
          <p className="founder-card__company f-14 tl">{company || '-'}</p>
          {!hideFR && founder.fundraising_status && (
            <div className="founder-card__middle">
              <div className="founder-card__goal f-12 f-400">
                <span>{t('founder_card.goal')}: </span>
                <span>{shortenDigit(founder.fundraising_status.goal)}</span>
              </div>
              <div className="founder-card__loans f-12 f-400">
                <span>{t('founder_card.loans')}: </span>
                <span>
                  {getLoansShortNames(founder.fundraising_status.loan_groups)}
                </span>
              </div>
            </div>
          )}
          {founder.themes && !!founder.themes.length && (
            <ul className="founder-card__themes f-14">
              {founder.themes.map(theme => (
                <li key={theme.id} className="founder-card__theme tl">
                  {theme.title}
                </li>
              ))}
            </ul>
          )}
        </div>

        {onSelect && (
          <RoundButton
            label={founder.is_selected ? t('founder_card.selected') : t('founder_card.select')}
            type="button"
            fullWidth
            fillBackground={founder.is_selected}
            onClick={e => {
              e.preventDefault();
              onSelect(e);
            }}
            className="founder-card__select"
          />
        )}
      </div>
    </MyLink>
  );
};

export default FounderCard;
