import classnames from 'classnames';
import './index.scss';

const MobileStepper = ({ active, options, className }) => (
  <div className={classnames('mobile_stepper__wrap', className)}>
    <ul className={classnames('mobile_stepper', `line-${active}`)}>
      {options.map((item, index) => {
        const step = index + 1;
        return (
          <li
            key={item.label}
            className={classnames(
              'mobile_stepper__step',
              active >= step && 'active',
            )}
          >
            <span className="mobile_stepper__number f-26 f-500">
              {step <= 9 ? `0${step}` : step}
            </span>
          </li>
        );
      })}
    </ul>
    {options[active - 1] && (
      <div
        className={classnames(
          'mobile_stepper__title',
          'f-16 f-400',
          `title-${active}`,
        )}
      >
        {options[active - 1].label}
      </div>
    )}
  </div>
);

export default MobileStepper;
