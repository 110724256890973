import { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { APP_LINKS } from '@/helpers/links';
import { RoundLink } from '@/components/UI/RoundLink';
import { getComplaints } from '@/store/actions/complaintActions';
import { prettyDate } from '@/helpers/utils';
import { removeComplaint } from '@/store/services/complaintServices';
import { setModals } from '@/store/actions/commonActions';
import { MODAL_TYPES } from '@/components/ModalProvider';
import Notify from '@/components/Notification';
import IconTrash from '../../assets/icons/icon_trash_red_22.svg?react';
import s from './index.module.scss';

const ComplaintsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('compliant');
  const { t: tKit } = useTranslation('kit');
  const { data: complaintList } = useSelector(
    state => state.complaintStore.complaintList,
  );
  const isEmpty = !complaintList?.list.length;

  useEffect(() => {
    dispatch(getComplaints());
  }, []);

  const onRemove = id => {
    dispatch(
      setModals({
        type: MODAL_TYPES.complaint_remove,
        onDelete: async () => {
          const res = await removeComplaint(id);
          if (res?.success) {
            dispatch(getComplaints());
            dispatch(setModals([]));
            Notify.success({ text: tKit('notify.removed_success') });
          }
        },
      }),
    );
  };

  const onComplaintClick = id => {
    dispatch(setModals({ type: MODAL_TYPES.complaint_detail, id }));
  };

  return (
    <div className={s.page}>
      <div className="container-inner">
        <div className={s.wrapper}>
          <h1 className="f-42 f-500">
            {t('compliant_page.investor_complaints')}
          </h1>
          {isEmpty ? (
            <div className={classnames(s.empty, 'f-20')}>
              {t('compliant_page.no_complaints')}
            </div>
          ) : (
            <div className={s.content}>
              <div className={s.table__wrapper}>
                <div className={s.table__inner}>
                  <div className={s.table}>
                    <div className={s.table__thead}>
                      <div className={s.table__thead_col}>
                        {t('compliant_page.round_number')}
                      </div>
                      <div className={s.table__thead_col}>
                        {t('compliant_page.status')}
                      </div>
                      <div className={s.table__thead_col}>
                        {t('compliant_page.created_date')}
                      </div>
                      <div className={s.table__thead_col}>
                        {t('compliant_page.actions')}
                      </div>
                    </div>

                    <div className={s.table__tbody}>
                      {complaintList.list.map(item => (
                        <div
                          className={classnames(s.table__tbody_row, 'hov')}
                          key={item.id}
                          onClick={() => onComplaintClick(item.id)}
                        >
                          <div className={s.table__tbody_col}>
                            {item.fundraising_name}
                          </div>
                          <div className={s.table__tbody_col}>
                            <div className={s.status}>
                              <span
                                className={item.review_status}
                                style={{
                                  textTransform: 'capitalize',
                                  color: '#9E98A3',
                                }}
                              >
                                {item.status}
                              </span>
                            </div>
                          </div>
                          <div className={s.table__tbody_col}>
                            {prettyDate(item.created_at)}
                          </div>
                          <div className={s.table__tbody_col}>
                            <div className={s.controls}>
                              <button
                                type="button"
                                onClick={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  onRemove(item.id);
                                }}
                                className={classnames(
                                  s.button,
                                  s.button_remove,
                                )}
                              >
                                <IconTrash />
                                <span>{t('compliant_page.delete')}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <RoundLink
          path={APP_LINKS.complaintCreate}
          className={s.button_add}
          label={t('compliant_page.add_new_one')}
        />
      </div>
    </div>
  );
};
export default ComplaintsPage;
