import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import CopyButton from '@/components/UI/CopyButton';
import { padNumber } from '@/helpers/utils';
import { CONTACT_EMAIL } from '@/helpers/constants';
import './index.scss';

import IconWarning from '../../../assets/icons/icon_warning_black_16.svg?react';

const BANK_REQUISITES = {
  iban: 'EE947700771006351972',
  visibleIban: 'EE94 7700 7710 0635 1972',
  bankSwiftCode: 'LHVBEE22',
  beneficiaryName: 'Scramble OU',
  beneficiaryBankName: 'AS LHV Pank',
  bankAddress: 'Tartu mnt 2, 10145 Tallinn',
};

export const BankRequisite = ({ userID, className }) => {
  const { t } = useTranslation('funds');

  return (
    <div className={classnames('bank_requisite__bank-details', className)}>
      <h3 className="f-20 f-500">{t('bank_requisites.bank_details')}</h3>
      <ul className="bank_requisite__bank-details-list">
        <li className="bank_requisite__bank-details-list-item">
          <div className="bank_requisite__bank-details-list-title">
            {t('bank_requisites.beneficiary_name')}
          </div>
          <div className="bank_requisite__bank-details-list-value">
            {BANK_REQUISITES.beneficiaryName}
          </div>
          <CopyButton text={BANK_REQUISITES.beneficiaryName} />
        </li>

        <li className="bank_requisite__bank-details-list-item">
          <div className="bank_requisite__bank-details-list-title">
            {t('bank_requisites.beneficiary_bank_account_number_iban')}
          </div>
          <div className="bank_requisite__bank-details-list-value">
            {BANK_REQUISITES.visibleIban}
          </div>
          <CopyButton text={BANK_REQUISITES.iban} />
        </li>

        <li className="bank_requisite__bank-details-list-item">
          <div className="bank_requisite__bank-details-list-title">
            {t('bank_requisites.payment_details')}
          </div>
          <div className="bank_requisite__bank-details-list-value">
            {padNumber(userID)}
          </div>
          <div className="bank_requisite__bank-details-list-note f-12 f-400">
            <IconWarning />{' '}
            {t('bank_requisites.include_this_so_we_can_find_your_payment')}
          </div>
          <CopyButton text={padNumber(userID)} />
          <span
            className="bank_requisite__bank-details-list-required hidable f-14 f-400"
            id="hide"
          >
            {t('bank_requisites.required')}
          </span>
        </li>

        <li className="bank_requisite__bank-details-list-item">
          <div className="bank_requisite__bank-details-list-title">
            {t('bank_requisites.beneficiary_bank_swift_bic_code')}
          </div>
          <div className="bank_requisite__bank-details-list-value">
            {BANK_REQUISITES.bankSwiftCode}
          </div>
          <CopyButton text={BANK_REQUISITES.bankSwiftCode} />
        </li>

        <li className="bank_requisite__bank-details-list-item">
          <div className="bank_requisite__bank-details-list-title">
            {t('bank_requisites.beneficiary_bank_name')}
          </div>
          <div className="bank_requisite__bank-details-list-value">
            {BANK_REQUISITES.beneficiaryBankName}
          </div>
          <CopyButton text={BANK_REQUISITES.beneficiaryBankName} />
        </li>

        <li className="bank_requisite__bank-details-list-item">
          <div className="bank_requisite__bank-details-list-title">
            {t('bank_requisites.beneficiary_bank_address')}
          </div>
          <div className="bank_requisite__bank-details-list-value">
            {BANK_REQUISITES.bankAddress}
          </div>
          <CopyButton text={BANK_REQUISITES.bankAddress} />
        </li>

        <li className="bank_requisite__bank-details-list-item">
          <div className="bank_requisite__bank-details-list-title">
            {t('bank_requisites.company_email')}
          </div>
          <div className="bank_requisite__bank-details-list-value">
            {CONTACT_EMAIL}
          </div>
          <CopyButton text={CONTACT_EMAIL} />
        </li>
      </ul>
    </div>
  );
};
