import React, { useRef } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { prettyMoneyHybrid } from '@/helpers/utils';
import { SpecTriangleIcon } from '@/components/UI/Icons';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import s from './index.module.scss';

export const IncomeBlock = ({ earnings, className = 'income-block' }) => {
  const ref = useRef();
  const { t } = useTranslation('dashboard');
  const [show, setShow] = React.useState(false);
  useOutsideClick(ref, () => setShow(false), show);

  const allTimeEarnings =
    earnings && earnings.earned + earnings.earned_bonuses + earnings.accrued;

  return (
    <div className={classnames('income_block', s.income_block, className)}>
      <p className="f-16">{t('income_annual_rate')}</p>
      <p className={classnames(s.income_block__rate, 'f-36 f-500')}>
        {earnings?.rate ? `${earnings.rate}%` : '—'}
      </p>
      <p className={classnames(s.income_block__label, 'f-16')}>
        {t('income_all_time_invested')}
      </p>
      <p className="f-500 f-26">
        {prettyMoneyHybrid(earnings && earnings.invested)}
      </p>
      <p className={classnames(s.income_block__label, 'f-16')}>
        {t('income_all_time_earned')}
      </p>
      <div
        ref={ref}
        className={s.income_block__cash}
        onClick={() => setShow(!show)}
      >
        <div className={s.income_block__cash_inner}>
          <p
            className={classnames(s.income_block__white, 'f-500 f-26 tl')}
            style={{ cursor: 'pointer' }}
          >
            {prettyMoneyHybrid(
              allTimeEarnings >= 10000
                ? parseInt(allTimeEarnings)
                : allTimeEarnings,
            )}
          </p>
          {show && earnings?.earned_bonuses > 0 && (
            <div
              className={s.income_block__popup}
              onClick={e => e.stopPropagation()}
            >
              <SpecTriangleIcon className={s.income_block__popup_triangle} />
              <div className={s.income_block__popup_list}>
                <div className={s.income_block__popup_row}>
                  <div className="f-16 f-500">
                    {t('income_repaid_earnings')}
                  </div>
                  <div className="f-26 f-500 tl">
                    {prettyMoneyHybrid(
                      earnings.earned >= 10000
                        ? parseInt(earnings.earned)
                        : earnings.earned,
                    )}
                  </div>
                </div>
                <div className={s.income_block__popup_row}>
                  <div className="f-16 f-500">
                    {t('income_accrued_earnings')}
                  </div>
                  <div className="f-26 f-500 tl">
                    {prettyMoneyHybrid(
                      earnings.accrued >= 10000
                        ? parseInt(earnings.accrued)
                        : earnings.accrued,
                    )}
                  </div>
                </div>
                <div className={s.income_block__popup_row}>
                  <div className="f-16 f-500">{t('income_bonus_earnings')}</div>
                  <div className="f-26 f-500 tl">
                    {prettyMoneyHybrid(
                      earnings.earned_bonuses >= 10000
                        ? parseInt(earnings.earned_bonuses)
                        : earnings.earned_bonuses,
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
