import React from 'react';
import classnames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FundraisingSelect } from './FundraisingSelect';
import { RoundButton } from '../../UI/RoundButton';
import { RoundLink } from '../../UI/RoundLink';
import { APP_LINKS } from '../../../helpers/links';
import { TextareaField } from '../../UI/TextareaField';
import { UploadFiles } from '../../../kit/form/upload/UploadFiles';
import s from './index.module.scss';

const VALIDATION_SCHEMA = Yup.object({
  fundraising: Yup.mixed().required('Fundraising is required'),
  description: Yup.string().max(300).required('Description is required'),
  files: Yup.array().max(5, 'File limit exceeded'),
});

export const ComplaintForm = ({ data, className, onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={VALIDATION_SCHEMA}
    initialValues={{
      fundraising: null,
      description: '',
      files: data?.bank_statement ? [data.bank_statement] : [],
    }}
  >
    {({
      values,
      errors,
      touched,
      dirty,
      handleBlur,
      handleChange,
      setFieldError,
      handleSubmit,
      setFieldValue,
    }) => (
      <form onSubmit={handleSubmit} className={classnames(s.form, className)}>
        <h1 className="f-26 f-500">Complaint info</h1>
        <div className={s.inner}>
          <div className={s.group}>
            <div className={s.label}>
              Which round are you complaining about? <span>*</span>
            </div>
            <FundraisingSelect
              selected={values.fundraising}
              error={
                errors.fundraising && touched.fundraising && errors.fundraising
              }
              onChange={fundraising =>
                setFieldValue('fundraising', fundraising)
              }
            />
          </div>
          <div className={s.group}>
            <div className={s.label}>
              Can you describe your complaint? <span>*</span>
            </div>
            <div className={s.label_desc}>
              Provide details such as names, dates, amounts, type of securities,
              description of financial harm or abuse, etc.
            </div>
            <TextareaField
              name="description"
              value={values.description}
              allowCyrillic
              error={
                errors.description && touched.description && errors.description
              }
              onChange={handleChange}
            />
            {300 - values.description.length >= 0 ? (
              <div className={s.counter}>
                {300 - values.description.length} characters left
              </div>
            ) : (
              <div className={s.counter} style={{ color: '#D80027' }}>
                Limit exceeded
              </div>
            )}
          </div>
          <UploadFiles
            name="files"
            isRequired={false}
            value={values.files}
            tooltip={
              <div style={{ maxWidth: '300px' }}>
                Attach documents, photos, or other files relevant to your
                complaint. This helps us better understand and address your
                concerns.
              </div>
            }
            error={
              (errors.files && touched.files && errors.files) ||
              (values.files.length > 0 &&
                values.files.length > 5 &&
                'File limit exceeded')
            }
            onBlur={handleBlur}
            onChange={files =>
              setFieldValue('files', [...values.files, ...files])
            }
            onError={message => setFieldError('files', message)}
            onRemove={file =>
              setFieldValue(
                'files',
                values.files.filter(f => f.name !== file.name),
              )
            }
          />
        </div>
        <div className={s.controls}>
          <RoundLink label="Back" className="hov" path={APP_LINKS.complaints} />
          <RoundButton
            type="submit"
            label={data ? 'Save' : 'Submit'}
            className="hov"
            disabled={!dirty || Object.keys(errors).length}
            fillBackground
          />
        </div>
      </form>
    )}
  </Formik>
);
