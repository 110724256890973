import { createRef, useEffect, useState } from 'react';
import { Header } from '@/containers/Header';
import PromoFooter from '@/components/Footer/PromoFooter/PromoFooter';
import MobFooter from '@/components/Footer/MobFooter/MobFooter';
import './index.scss';

export const BasicLayout = ({ children }) => {
  const [mobileFooter, setMobileFooter] = useState(false);
  const promoFooterRef = createRef();

  useEffect(() => {
    if (promoFooterRef.current.offsetWidth <= 1024) {
      setMobileFooter(true);
    } else {
      setMobileFooter(false);
    }
  }, []);

  return (
    <div className="basic-layout" ref={promoFooterRef}>
      <Header />
      <main>{children}</main>
      {mobileFooter ? <MobFooter /> : <PromoFooter />}
    </div>
  );
};
