import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as qs from 'qs';
import { useTranslation } from 'react-i18next';
import { getBalance } from '@/store/actions/dashboardActions';
import Stepper from '../Stepper';
import { ProcessingLoader } from '@/components/ProcessingLoader';
import { Preloader } from '@/components/Preloader';
import InvStartProcess from './InvStartProcess';
import {
  getIncreasedIncomeStatus,
  getInvestingStatus,
  resetInvesting,
  setInvestingStatus,
} from '@/store/actions/investActions';
import InvSelectStrategy, { STRATEGIES } from './InvSelectStrategy';
import InvAllocateFunds from './InvAllocateFunds';
import InvPreRound from './InvPreRound';
import InvReady from './InvReady';
import InvResult from './InvResult';
import InvHold from './InvHold';
import InvReview from './InvReview';
import {
  getAllocateInfo,
  getPreAllocateInfo,
} from '@/store/actions/fundActions';
import {
  getUserProfile,
  getVeriffStatus,
} from '@/store/actions/profileActions';
import MiniFaqs, { MINIFAQ_SOURCES } from '../MiniFaqs';
import AnalyticsGA from '@/analyticsGA';
import { scrollTop } from '@/helpers/utils';
import MobileStepper from '../MobileStepper';
import { isVerifiedByAdmin } from '@/helpers/user';
import './index.scss';

export const WIZARD_REDIRECTS = {
  strategy: 'strategy-selection',
  allocate: 'funds-allocation',
};

const STEPS_T = t => [
  { label: t('select_strategy') },
  { label: t('allocate_cash') },
  { label: t('review_the_settings') },
];

const InvestingModule = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    step: -1,
    loading: true,
    processing: false,
  });

  const status = useSelector(state => state.investStore.investingStatus.data);
  const profile = useSelector(state => state.profileStore.profile.data);
  const { innerWidth } = useSelector(state => state.commonStore);

  const { preAllocateInfo } = useSelector(state => state.fundStore);
  const { t } = useTranslation('investing');
  const STEPS = STEPS_T(t);

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getVeriffStatus());
    dispatch(getPreAllocateInfo());
    dispatch(getInvestingStatus()).then(res => {
      if (res && res.success) {
        let step = res.data.step;
        if (res.data.step < 4) {
          const { to, strategy } = qs.parse(location.search.replace('?', ''));
          if (
            strategy &&
            (strategy === STRATEGIES.priority ||
              strategy === STRATEGIES.standard)
          ) {
            dispatch(setInvestingStatus({ ...res.data, option: strategy }));
          }
          to === WIZARD_REDIRECTS.strategy && (step = 1);
          to === WIZARD_REDIRECTS.allocate &&
            res.data.option !== STRATEGIES.not_selected &&
            (step = 2);
          to && navigate(location.pathname, { replace: true });
        }
        setState(prevState => ({ ...prevState, step, loading: false }));
      } else {
        setState(prevState => ({ ...prevState, loading: false }));
      }
    });
    AnalyticsGA.pageNavigation('Investing');
    AnalyticsGA.investingPage();
  }, []);

  useEffect(() => {
    scrollTop();
  }, [state.step]);

  const setStep = step => {
    setState(prevState => ({ ...prevState, step }));
  };

  const setProcessing = status => {
    setState(prevState => ({ ...prevState, processing: !!status }));
  };

  const onResetInvesting = () => {
    setProcessing(true);
    dispatch(resetInvesting())
      .then(res => {
        if (res && res.success) {
          dispatch(getBalance());
          dispatch(getAllocateInfo());
          dispatch(getIncreasedIncomeStatus());
          setState(prevState => ({
            ...prevState,
            step: res.data.step,
          }));
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const { step, loading, processing } = state;

  const enableWizard = isVerifiedByAdmin(profile);

  return (
    <div className="investing-module">
      <div className="container-inner">
        {loading && <Preloader className="investing-module__preloader" />}
        {!loading && status && (
          <>
            {step > 0 &&
              step < 4 &&
              !(step === 3 && preAllocateInfo.data?.has_confirmed) &&
              (innerWidth < 992 ? (
                <MobileStepper
                  active={step}
                  options={STEPS}
                  className="investing-module__progressbar"
                />
              ) : (
                <Stepper
                  active={step}
                  options={STEPS}
                  className="investing-module__progressbar"
                />
              ))}
            {step === -1 && <InvPreRound status={status} />}
            {step === 0 && (
              <InvStartProcess
                status={status}
                setStep={setStep}
                disabled={!enableWizard}
              />
            )}
            {step === 1 && (
              <InvSelectStrategy
                status={status}
                setStep={setStep}
                setProcessing={setProcessing}
                disabled={!enableWizard}
              />
            )}
            {step === 2 && (
              <InvAllocateFunds
                status={status}
                setStep={setStep}
                setProcessing={setProcessing}
                disabled={!enableWizard}
                onReset={onResetInvesting}
              />
            )}
            {step === 3 &&
              (preAllocateInfo.data?.has_confirmed ? (
                <InvReady
                  status={status}
                  setStep={setStep}
                  onReset={onResetInvesting}
                />
              ) : (
                <InvReview
                  status={status}
                  setStep={setStep}
                  onReset={onResetInvesting}
                  setProcessing={setProcessing}
                />
              ))}
            {step === 4 && <InvHold status={status} />}
            {step === 5 && <InvResult status={status} />}
          </>
        )}

        <MiniFaqs source={MINIFAQ_SOURCES.investing} step={step} />
      </div>
      <ProcessingLoader loading={processing} />
    </div>
  );
};

export default InvestingModule;
