import React from 'react';
import { useOutsideClick } from '@/hooks/useOutsideClick';

export const Tippy = ({ render }) => {
  const ref = React.useRef();
  const [open, setOpen] = React.useState(false);
  useOutsideClick(ref, () => setOpen(false), open);

  return render(ref, open, setOpen);
};
