import { useEffect } from 'react';
import { Veriff } from '@veriff/js-sdk';
import { useTranslation } from 'react-i18next';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { RoundButton } from '@/components/UI/RoundButton';
import { useGetMe } from '@/hooks/useGetMe';
import config from '@/config';
import './index.scss';

export const VeriffForm = ({ id, backLabel, onSuccess, onClose, onBack }) => {
  const { user } = useGetMe();
  const { t } = useTranslation('auth');

  useEffect(() => {
    const veriff = Veriff({
      host: config.veriffHostURL,
      apiKey: config.veriffApiKey,
      parentId: id || 'veriff-root',
      onSession(error, response) {
        createVeriffFrame({
          url: response?.verification?.url,
          onEvent: msg => {
            if (msg === MESSAGES.SUBMITTED) {
              onSuccess && onSuccess({ response, error, msg });
            }
            if (msg === MESSAGES.CANCELED) {
              onClose && onClose();
            }
          },
        });
      },
    });

    veriff.setParams({
      person: {
        givenName: user.first_name || ' ',
        lastName: user.last_name || ' ',
      },
      vendorData: user.email || ' ',
    });
    veriff.mount({
      submitBtnText: t('start_verification'),
    });
  }, []);

  return (
    <div className="veriff-form">
      <h1 className="veriff-form__title f-32 f-500">
        {t('verify_your_identity')}
      </h1>
      <p className="veriff-form__desc f-16 f-400">
        {t('safe_marketplace_message')}
      </p>
      <p className="veriff-form__desc f-16 f-400">
        {t('start_verification_message')}
      </p>
      <ul className="veriff-form__list f-16 f-400">
        <li>{t('prepare_identity_document')}</li>
        <li>{t('check_camera')}</li>
        <li>{t('prepare_photo_and_selfie')}</li>
      </ul>

      <div className="veriff-form__information">
        <p className="f-14 f-400" style={{ marginTop: '10px' }}>
          {t('identity_verification_provided_by')}{' '}
          <a href="https://www.veriff.com/" className="link-u" target="__blank">
            Veriff
          </a>
          .
        </p>

        <p className="veriff-support f-14 f-400">
          {t('media_storage_message')}
        </p>
      </div>
      <div className="veriff-form__bottom">
        <RoundButton
          label={backLabel || t('back')}
          type="button"
          onClick={onBack}
          className="veriff-form__back"
        />
        <div id={id || 'veriff-root'} />
      </div>

      <p className="veriff-form__veriff-description">
        <a title="Veriff" href="https://www.veriff.com/" target="_blank">
          Veriff
        </a>{' '}
        {t('veriff_identity_provider')}
      </p>
    </div>
  );
};
