import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { authService } from '../../services/authService';

export const useResendEmailOtpCode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () =>
      authService.useResendEmailOtpCode().then(response => {
        const { status, data } = response;
        queryClient.invalidateQueries([QUERY_KEYS.auth.email_otp_status]);
        if (status === 200) {
          return { success: true, data };
        }
        return { error: data?.errors?.[0] };
      }),
  });
};
