import { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { prettyFloatMoney } from '../../helpers/utils';
import IconStar from '../../assets/icons/icon_bonus_start.svg?react';
import { ACCOUNT_TYPES, LOAN_GROUPS_IDS } from '../../helpers/constants';
import { getBonusGroup } from '../../store/actions/profileActions';
import s from './index.module.scss';

export const BonusFirstInvestHint = ({ className }) => {
  const { preAllocateInfo } = useSelector(state => state.fundStore);
  const dispatch = useDispatch();
  const accounts = useMemo(
    () => (preAllocateInfo.data ? preAllocateInfo.data.accounts : []),
    [preAllocateInfo.data],
  );

  useEffect(() => {
    dispatch(getBonusGroup());
  }, []);

  const { data: status } = useSelector(
    state => state.investStore.investingStatus,
  );
  const { bonusGroup } = useSelector(state => state.profileStore);

  if (!status) {
    return null;
  }

  const bonusAmount = useMemo(() => {
    if (!bonusGroup) {
      return 0;
    }

    const prevBonus = status?.bonus_amount || 0;
    const amount = accounts.reduce((total, account) => {
      if (account.type === ACCOUNT_TYPES.loan) {
        if (account.group === LOAN_GROUPS_IDS.moderate.id) return total;
        total += account.amount;
      }
      return total;
    }, 0);
    return (
      parseInt((amount - prevBonus) / bonusGroup.investment_amount) *
      bonusGroup.bonus_amount
    );
  }, [accounts, status, bonusGroup]);

  if (!bonusAmount) {
    return null;
  }

  return (
    <div className={classnames(s.container, 'f-16 f-400', className)}>
      <span className="f-22">+</span>
      <IconStar />
      <span className={s.amount}>
        <span>{prettyFloatMoney(bonusAmount)}</span> <span>bonus</span>
      </span>
      <span className={s.text}>
        You will get for this investment after the end of the round
      </span>
    </div>
  );
};
