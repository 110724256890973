import InputMask from 'react-input-mask';
import classnames from 'classnames';
import ClearIcon from '@/assets/images/icon-clear.svg?react';
import './index.scss';

export const InputDateTextField = ({
  label,
  name,
  onChange,
  onClear,
  className,
  error,
  value,
  mask,
  ...other
}) => (
  <div
    className={classnames(
      'input_date_text_field',
      error && 'input_date_text_field_error',
      className,
    )}
  >
    <div className="input_date_text_field__input-group">
      <InputMask
        mask={mask}
        maskChar=""
        placeholder=" "
        value={value}
        onChange={onChange}
        className={classnames(
          'input_date_text_field__input',
          value && 'input_date_text_field__input-filled',
        )}
        {...other}
      />
      <label className="input_date_text_field__label" htmlFor={name}>
        {label}
      </label>
      {onClear && (
        <ClearIcon
          onClick={onClear}
          className={classnames(
            'input_date_text_field__clear',
            !value && 'input_date_text_field__clear-hidden',
          )}
        />
      )}
    </div>
    <div
      className={classnames(
        'input_date_text_field__error',
        error && 'input_date_text_field__error_visible',
      )}
    >
      {error}
    </div>
  </div>
);
