import { useMutation } from '@tanstack/react-query';
import { collectDetailsUTM } from '@/helpers/utm';
import { getMessage } from '@/helpers/utils';
import Notify from '@/components/Notification';
import { authService } from '../../services/authService';

export const useAppleAuth = () => {
  return useMutation({
    mutationFn: ({ appleRes }) => {
      const payload = {
        id_token: appleRes.authorization.id_token,
        code: appleRes.authorization.code,
        ...collectDetailsUTM(),
      };

      return authService.apple(payload).then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        const message = getMessage(data);
        message && Notify.error({ text: message });
      });
    },
  });
};
