import { Suspense, useEffect } from 'react';
import qs from 'qs';
import Modal from 'react-modal';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment/min/moment-with-locales';
import { setInnerWidth } from '@/store/actions/commonActions';
import { ModalProvider } from '@/components/ModalProvider';
import { ReferralProvider } from '@/providers/referral';
import { removeLastRoute, saveLastRoute, scrollTop } from '@/helpers/utils';
import { EMAIL_REGEX } from '@/helpers/common';
import { saveUTMtoCookie } from '@/helpers/utm';
import { ToastContainer } from 'react-toastify';
import { initYup } from '@/yup-config';
import { initSentry } from '@/sentry';
import config from '@/config';
import { LOCALES } from '@/i18n';
import {
  getUserLocaleDetection,
  saveUserLocale,
  setUserLocaleDetection,
} from '@/helpers/locale';

import 'normalize.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tippy/dist/tippy.css';

import '@/assets/styles/typography.scss';
import '@/index.scss';
import '@/assets/styles/themes/default.scss';

Modal.setAppElement('#root');

initSentry();
initYup();

export const AppProvider = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const hasDetected = getUserLocaleDetection();
    const currentLocale = i18n.language;

    if (!hasDetected && navigator) {
      const detectedLang =
        navigator.language?.split('-')[0] ||
        (navigator.languages && navigator.languages[0]);

      if (LOCALES.includes(detectedLang) && detectedLang !== currentLocale) {
        setUserLocaleDetection();
        saveUserLocale(detectedLang);
        window.location.reload();
      }
    }
  }, []);

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    dispatch(setInnerWidth(window.innerWidth));
    document.querySelector(':root').style.setProperty('--vh', `${vh}px`);
  };

  const collectUTM = pathname => {
    const { utm_medium, utm_source, utm_campaign, click_id, ref } = qs.parse(
      pathname.search.replace('?', ''),
    );
    if (utm_medium || utm_source || utm_campaign || click_id) {
      saveUTMtoCookie({ utm_medium, utm_source, utm_campaign, click_id, ref });
    }
  };

  useEffect(() => {
    const { email } = qs.parse(location.search.replace('?', ''));
    if (email) {
      if (EMAIL_REGEX.test(email)) {
        sessionStorage.setItem('existingEmail', email);
      }
    }
  }, []);

  useEffect(() => {
    saveLastRoute(location);
    collectUTM(location);
  }, [location]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      removeLastRoute();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    scrollTop();
  }, [location.pathname]);

  useEffect(() => {
    window.document.body.insertAdjacentHTML(
      'beforeend',
      `<div id="build" class="${config.release}">${config.release}</div>`,
    );
  }, []);

  return (
    <>
      <ReferralProvider>
        <Suspense fallback={<div>loading....</div>}>
          <Outlet />
        </Suspense>
        <ModalProvider />
        <ToastContainer />
      </ReferralProvider>
    </>
  );
};
