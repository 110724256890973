import { useEffect } from 'react';
import moment from 'moment/min/moment-with-locales';
import classnames from 'classnames';
import nl2br from 'react-nl2br';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Linker from '@/components/Linker';
import { MyLink } from '@/components/MyLink';
import { DATE_FORMAT_YYYY } from '@/helpers/common';
import FounderCard from '@/components/Cards/FounderCard';
import { shortenDigit } from '@/helpers/utils';
import { getCompanyInfo } from '@/store/actions/companyActions';
import { Preloader } from '@/components/Preloader';
import './index.scss';

const CompanyInfoModal = ({ companyID }) => {
  const innerWidth = useSelector(state => state.commonStore.innerWidth);
  const companyInfo = useSelector(state => state.companyStore.companyInfo);
  const dispatch = useDispatch();
  const { t } = useTranslation('modal');

  useEffect(() => {
    dispatch(getCompanyInfo(companyID));
  }, [companyID, dispatch]);

  const getFundraisingBlock = (fundraisingStatus, className) => (
    <div className={classnames('company_info_modal__fundraising', className)}>
      <div className="company_info_modal__top_goal dfc f-12 f-400">
        <span className="c-grey">{t('company_modal.goal')}:&nbsp;</span>
        <span className="c-black">{shortenDigit(fundraisingStatus.goal)}</span>
      </div>
    </div>
  );

  const { data, loading } = companyInfo;

  return (
    <div className="company_info_modal">
      {loading ? (
        <Preloader className="company_info_modal__preloader" />
      ) : data ? (
        <>
          {data.image && (
            <MyLink
              href={data.site}
              className="company_info_modal__mobile_logo"
              isExternal
            >
              <img
                src={data.image.file}
                alt={data.image.name || t('company_modal.startup_logo')}
              />
            </MyLink>
          )}
          <div className="company_info_modal__top">
            <div className="company_info_modal__top_left">
              <div className="company_info_modal__top_header">
                <h1 className="company_info_modal__top_title f-42 f-500">
                  {data.name}
                </h1>
                {!!data?.fundraising_status?.goal &&
                  getFundraisingBlock(
                    data.fundraising_status,
                    innerWidth >= 900 && 'desktop',
                  )}
              </div>
              <div className="company_info_modal__info f-16 f-400">
                {data.sectors &&
                  data.sectors.map(sector => (
                    <span key={sector.id}>{sector.name}</span>
                  ))}
                {data.country && (
                  <span>
                    {data.country}&nbsp;{t('company_modal.based')}
                  </span>
                )}
                {data.foundation_date && (
                  <span>
                    {t('company_modal.started_in', {
                      date: moment(data.foundation_date).format(
                        DATE_FORMAT_YYYY,
                      ),
                    })}
                  </span>
                )}
              </div>
              {data.site && (
                <Linker
                  value={data.site}
                  className="company_info_modal__site f-16 f-400"
                />
              )}

              {!!data?.fundraising_status?.goal &&
                getFundraisingBlock(
                  data.fundraising_status,
                  innerWidth < 900 && 'mobile',
                )}
            </div>
            <div className="company_info_modal__top_right">
              {data.image &&
                (data.site ? (
                  <MyLink
                    href={data.site}
                    className="company_info_modal__logo"
                    isExternal
                  >
                    <img
                      src={data.image.file}
                      alt={data.image.name || t('company_modal.startup_logo')}
                    />
                  </MyLink>
                ) : (
                  <div className="company_info_modal__logo">
                    <img
                      src={data.image.file}
                      alt={data.image.name || t('company_modal.startup_logo')}
                    />
                  </div>
                ))}
            </div>
          </div>

          <div className="company_info_modal__founders">
            <h2 className="company_info_modal__founders_title f-22 f-500">
              {t('company_modal.founders')}
            </h2>
            <div className="company_info_modal__founders_list">
              {data.co_founders.map(founder => (
                <FounderCard
                  key={founder.id}
                  active={founder.is_confirmed}
                  founder={{
                    ...founder,
                    company: t('company_modal.co_founder'),
                  }}
                />
              ))}
            </div>
          </div>

          {(data.description || data?.promo?.image) && (
            <div className="company_info_modal__details">
              <h2 className="company_info_modal__founders_title f-22 f-500">
                {t('company_modal.company_details')}
              </h2>
              <p className="company_info_modal__details_text">
                {nl2br(data.description)}
              </p>
              {data?.promo?.image && (
                <div className="company_info_modal__image">
                  <MyLink href={data.site || '#'} isExternal>
                    <img
                      src={data.promo.image.file}
                      alt={data.promo.image.name}
                    />
                  </MyLink>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div>{t('company_modal.error_message')}</div>
      )}
    </div>
  );
};

export default CompanyInfoModal;
