import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NotificationBlock from '../../NotificationBlock';
import { RoundButton } from '@/components/UI/RoundButton';
import { getFundraisingStats } from '@/store/actions/investActions';
import Dropdown from '@/components/Dropdown';
import { LOAN_GROUPS_IDS, USER_STATUSES } from '@/helpers/constants';
import AnalyticsGA from '@/analyticsGA';
import { NOTIFICATIONS as NOTIFICATIONS_T } from '@/components/Notification/list';
import { APP_LINKS } from '@/helpers/links';
import { MyLink } from '@/components/MyLink';
import WarningBlock from '@/components/UI/WarningBlock';
import { getUserProfile } from '@/store/actions/profileActions';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import { isSubmittedVeriff } from '@/helpers/user';
import { LoanTerms } from '@/components/LoanTerms';
import { ChooseStartups } from '@/components/ChooseStartups';
import { MinInvestAmountWarning } from '@/components/Warnings/MinInvestAmountWarning';
import { SuspendResidenceWarning } from '@/components/Warnings/SuspendResidenceWarning';
import { InvestingProgressBar } from '@/components/InvestingProgressBar';
import './index.scss';
import { useCheckPlannedPayments } from '@/components/Notification/hooks/useCheckPlannedPayments';
import { BonusBanner } from '../../BonusProgram/banner';
import s from '@/components/LoanCalculator/index.module.scss';

const InvStartProcess = ({ status, setStep, disabled }) => {
  const dispatch = useDispatch();
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('investing');
  const [groupID, setGroupID] = useState(LOAN_GROUPS_IDS.conservative.id);
  const { fundraisingStats } = useSelector(state => state.investStore);
  const { veriffStatusCode } = useSelector(state => state.profileStore);
  const { fundraisingDates } = useSelector(state => state.dashboardStore);
  const profile = useSelector(state => state.profileStore.profile.data);
  const { hasPlannedPayment } = useCheckPlannedPayments();

  const NOTIFICATIONS = NOTIFICATIONS_T(tCommon);

  useEffect(() => {
    dispatch(getFundraisingStats());
    dispatch(getUserProfile());
  }, []);

  const onJoinRound = () => {
    setStep(1);
  };

  const onCalculate = groupID => {
    setGroupID(groupID || LOAN_GROUPS_IDS.conservative.id);
    const el = document.getElementById('calculator');
    el && el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const hasReachedGoal =
    fundraisingStats.currently_allocated >= fundraisingStats.total_goal;
  const notifications = [];

  const joinDisabled =
    disabled || hasReachedGoal || profile?.status === USER_STATUSES.suspended;

  !hasReachedGoal &&
    notifications.push(NOTIFICATIONS.loan_disbursement_starts(status.end_date));
  // hasReachedGoal
  //   ? notifications.push(NOTIFICATIONS.founders_reached_goal())
  //   : notifications.push(
  //       NOTIFICATIONS.loan_disbursement_starts(status.end_date)
  //     );

  hasPlannedPayment && notifications.push(NOTIFICATIONS.october_weekends());

  fundraisingDates?.id === 101 &&
    fundraisingDates?.is_active &&
    notifications.push(NOTIFICATIONS.regular_top_ups());

  return (
    <div className="inv-start-process">
      <h1 className="f-42 f-500">
        {hasReachedGoal
          ? t('isp_round_expected_to_be_enlarged')
          : t('isp_participate_in_the_round')}
      </h1>

      {profile?.country_code === 'US' &&
        status?.min_investment_amount === 1000 && (
          <MinInvestAmountWarning className="inv-start-process__warning-header" />
        )}

      {profile &&
        (profile.status === USER_STATUSES.verified ? (
          <NotificationBlock
            className="inv-start-process__notifications"
            notifications={notifications}
          />
        ) : profile?.status === USER_STATUSES.suspended ? (
          <SuspendResidenceWarning className="inv-start-process__suspend_top" />
        ) : (
          <WarningBlock
            className="inv-start-process__warning-header"
            withBorder
          >
            {isSubmittedVeriff(veriffStatusCode) ? (
              <div className="f-16 f-400">
                {t('investing_available_only_verified_investors')}
              </div>
            ) : (
              <div className="f-16 f-400">
                {t('complete_verification_process_to_start_investing')}{' '}
                <MyLink href={APP_LINKS.dashboard} className="c-grey">
                  {t('complete_verification')} &nbsp;→
                </MyLink>
              </div>
            )}
          </WarningBlock>
        ))}

      <BonusBanner className={s.bonus} />

      {status.is_active && (
        <InvestingProgressBar className="inv-start-process__progress" />
      )}

      <div className="inv-start-process__buttons">
        <RoundButton
          type="button"
          label={t('join_the_round')}
          className="inv-start-process__start-btn"
          onClick={() => {
            AnalyticsGA.investingJoinRound('header');
            onJoinRound();
          }}
          fillBackground
          disabled={joinDisabled}
        />
      </div>

      <Dropdown
        title={t('consumer_goods_brands_batch')}
        className="inv-start-process__founders"
        defaultShow
        disableToggle
      >
        <ParticipatingStartupsList
          onMoreDetails={startup => {
            AnalyticsGA.investingPreviewBrand(startup?.name);
          }}
        />
      </Dropdown>

      <RoundButton
        type="button"
        label={t('join_the_round')}
        className="inv-start-process__founders_button"
        onClick={() => {
          AnalyticsGA.investingJoinRound('under_brands');
          onJoinRound();
        }}
        fillBackground
        fullWidth
        disabled={joinDisabled}
      />

      <LoanTerms onCalculate={onCalculate} />
      <ChooseStartups />
      <div className="inv-start-process__bottom">
        {profile?.status === USER_STATUSES.suspended ? (
          <SuspendResidenceWarning className="inv-start-process__suspend_bottom" />
        ) : (
          profile?.status !== USER_STATUSES.verified && (
            <WarningBlock className="inv-start-process__warning-note">
              {isSubmittedVeriff(veriffStatusCode) ? (
                <div className="f-16 f-400">
                  {t('investing_available_only_verified_investors')}
                </div>
              ) : (
                <div className="f-16 f-400">
                  {t('complete_verification_process_to_start_investing')}{' '}
                  <MyLink href={APP_LINKS.dashboard} className="c-grey">
                    {t('complete_verification')} &nbsp;→
                  </MyLink>
                </div>
              )}
            </WarningBlock>
          )
        )}

        <RoundButton
          type="button"
          label={t('join_the_round')}
          onClick={() => {
            AnalyticsGA.investingJoinRound('footer');
            onJoinRound();
          }}
          fillBackground
          fullWidth
          disabled={joinDisabled}
        />
      </div>
    </div>
  );
};

export default InvStartProcess;
