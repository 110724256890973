import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { prettyFloatMoney } from '@/helpers/utils';
import { ReferralFriendStatus } from '@/containers/ReferralsModule';
import './index.scss';

const ReferralCard = ({ data, className }) => {
  const { t } = useTranslation('affiliate');
  return (
    <div className={classnames('referral_card', className)}>
      <div className="referral_card__info">
        <div className="referral_card__row">
          <div className="c-grey f-14 f-400">{t('referral_card.user')}</div>
          <div className="f-18 f-500">{data.investor_id}</div>
        </div>

        <div className="referral_card__row">
          <div className="c-grey f-14 f-400">{t('referral_card.user_status')}</div>
          <div className="f-18 f-500">
            <ReferralFriendStatus status={data.friend_status} />
          </div>
        </div>

        <div className="referral_card__row">
          <div className="c-grey f-14 f-400">{t('referral_card.days_left')}</div>
          <div className="f-18 f-500">
            {data.remaining_period ? t('referral_card.days_left_count', { count: data.remaining_period }) : '-'}
          </div>
        </div>

        <div className="referral_card__row">
          <div className="c-grey f-14 f-400">{t('referral_card.reward')}</div>
          <div className="f-18 f-500">{prettyFloatMoney(data.commission)}</div>
        </div>

        <div className="referral_card__row">
          <div className="c-grey f-14 f-400">{t('referral_card.rewards_status')}</div>
          <div className="f-18 f-500">{data.commission_status || '—'}</div>
        </div>
      </div>
    </div>
  );
};

export default ReferralCard;
