import axios from '@/axios-api';
import Pathes from '@/helpers/pathes';
import { getMessage } from '@/helpers/utils';
import Notify from '@/components/Notification';
import i18n from '@/i18n';

const { t } = i18n;

export const cancelWithdrawRequest = reqID => {
  return axios
    .delete(Pathes.Funds.withdrawRequest(reqID))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Create bank details
export const createBankDetail = payload => {
  return axios
    .post(Pathes.Funds.bankDetails, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: t('kit:notify.bank_details_submit') });
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Update bank details
export const updateBankDetail = (id, payload) => {
  return axios
    .put(Pathes.Funds.bankDetailByID(id), payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: t('kit:notify.bank_details_update') });
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Check if country is in SEPA zone
export const checkCountryForSepa = countryCode => {
  return axios
    .get(Pathes.Funds.countryInSepa(countryCode))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Validate iban
export const checkIBAN = payload => {
  return axios
    .post(Pathes.Funds.ibanCheck, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Delete bank details
export const removeBankDetail = id => {
  return axios
    .delete(Pathes.Funds.bankDetailByID(id))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: t('kit:notify.bank_details_removed') });
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Get bank detail
export const getBankDetailById = id => {
  return axios
    .get(Pathes.Funds.bankDetailByID(id))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};
