import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { OPTIONS } from './options';
import s from './index.module.scss';

export const PaymentMethodLogos = ({ className }) => {
  const { t } = useTranslation('footer');
  return (
    <div className={classnames(s.wrapper, className)}>
      <div className={s.title}>{t('we_accept_payments')}</div>
      <div className={s.inner}>
        {OPTIONS.map(({ title, icon, withBorder, style }, idx) => (
          <div
            className={classnames(s.option, withBorder && s.option_border)}
            key={idx}
            style={style}
          >
            <img src={icon} alt={title} className={s.image} />
          </div>
        ))}
      </div>
    </div>
  );
};
