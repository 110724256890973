import { METADATA } from '@/helpers/metadata';
import {
  GET_USER_PROFILE,
  SET_BONUS_GROUP,
  SET_USER_PROFILE,
  SET_VERIFF_STATUS_CODE,
  UPDATE_USER_PROFILE,
} from '../types/profileTypes';

const initialState = {
  profile: { ...METADATA.default, data: null },
  veriffStatusCode: null,
  bonusGroup: null,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE.REQUEST:
    case UPDATE_USER_PROFILE.REQUEST:
      return { ...state, profile: { ...state.profile, ...METADATA.request } };
    case GET_USER_PROFILE.SUCCESS:
    case UPDATE_USER_PROFILE.SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_USER_PROFILE.FAILURE:
    case UPDATE_USER_PROFILE.FAILURE:
      return {
        ...state,
        profile: { ...state.profile, ...METADATA.error, error: action.error },
      };
    case SET_USER_PROFILE:
      return { ...state, profile: { ...state.profile, data: action.profile } };
    case SET_VERIFF_STATUS_CODE:
      return { ...state, veriffStatusCode: action.code };
    case SET_BONUS_GROUP:
      return { ...state, bonusGroup: action.payload };

    default:
      return state;
  }
};

export default profileReducer;
