import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { MyLink } from '@/components/MyLink';
import { roundStarts } from '@/helpers/date';
import { APP_LINKS } from '@/helpers/links';
import { RoundLink } from '@/components/UI/RoundLink';
import { STEPS } from '../index';
import s from '../index.module.scss';

export const StartInvestingStep = ({ passed }) => {
  const { t } = useTranslation('dashboard');
  const { fundraisingDates } = useSelector(state => state.dashboardStore);
  const fundraisingRunning = !!fundraisingDates?.is_active;
  const active = passed.includes(STEPS.start_investing);

  return (
    <li
      id={`vpw_${STEPS.start_investing}`}
      className={classnames(s.list_item, active && s.list_item_active)}
    >
      <div className={s.list_item_left}>
        <span
          className={classnames(
            s.circle,
            active && s.circle_active,
            'f-26 f-400',
          )}
        >
          05
        </span>
      </div>
      <div className={s.list_item_right}>
        <div className={classnames(s.list_item_title, 'f-26 f-400')}>
          {t('wizard_step_5_start_investing')}
        </div>
        <div
          className={classnames(
            s.list_item_content,
            active && s.list_item_content_active,
            'f-16-24 f-400',
          )}
        >
          <div className={s.list_item_content_inner}>
            <div className="f-16 f-400">
              {t('wizard_step_5_congratulations_ready_to_invest')}
            </div>
            <div className="f-16 f-400" style={{ marginTop: '8px' }}>
              {fundraisingRunning ? (
                t('wizard_step_5_setting_adjustments_needed')
              ) : (
                <span>
                  <Trans
                    ns="dashboard"
                    i18nKey="wizard_step_5_the_round_starts"
                    values={{
                      roundStarts: roundStarts(fundraisingDates?.start_date),
                    }}
                    components={{
                      LinkLoanTerms: (
                        <MyLink href={APP_LINKS.helpLoanTerms()} underline />
                      ),
                    }}
                  />
                </span>
              )}
            </div>
            <RoundLink
              label={t('wizard_step_5_go_to_investing')}
              fillBackground
              fullWidth
              path={APP_LINKS.investing}
              className={s.button}
            />
          </div>
        </div>
      </div>
    </li>
  );
};
