import classnames from 'classnames';
import { RoundLink } from '@/components/UI/RoundLink';
import { RoundButton } from '@/components/UI/RoundButton';
import s from './index.module.scss';

const InfoModal = ({
  title,
  desc,
  onClick,
  onClickLabel,
  redirectPath,
  redirectLabel,
}) => {
  return (
    <div className={s.wrapper}>
      <div className={classnames(s.title, 'f-20 f-500')}>{title}</div>
      <div className={classnames(s.desc, 'f-14 f-400')}>{desc}</div>
      <div className={s.controls}>
        {onClick && (
          <RoundButton
            type="button"
            label={onClickLabel}
            onClick={onClick}
            fullWidth
            fillBackground
          />
        )}
        {redirectPath && (
          <RoundLink
            path={redirectPath}
            label={redirectLabel}
            fullWidth
            fillBackground
          />
        )}
      </div>
    </div>
  );
};

export default InfoModal;
