import { useEffect, useState } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import '../components/styles/default.scss';
import s from './index.module.scss';

export const KeySelectField = ({
  value = null,
  options = [],
  name,
  error,
  className,
  isSearchable,
  onChange,
  ...props
}) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (!value) {
      setSelected(null);
    } else {
      const current = selected?.value || null;
      if (value !== current) {
        const founded = options.find(t => t.value === value);
        founded && setSelected(founded);
      }
    }
  }, [value, selected]);

  const handleChange = option => {
    setSelected(option);
    onChange(option.value);
  };

  return (
    <div className={className}>
      <Select
        value={selected}
        onChange={handleChange}
        name={name}
        classNamePrefix="key_select"
        className={classnames(s.key_select, error && s.key_select_error)}
        options={options}
        placeholder=""
        isSearchable={isSearchable}
        aria-errormessage={error}
        {...props}
      />
      {error && <div className={s.error_message}>{error}</div>}
    </div>
  );
};
