import classnames from 'classnames';
import s from './index.module.scss';

export const OptionCountry = props => {
  const { innerProps, isSelected, data: country } = props;
  return (
    <div
      {...innerProps}
      className={classnames(s.country, isSelected && s.country_selected)}
    >
      <div className={s.country_flag}>
        <img src={country.flag} alt={country.label} />
      </div>
      <div className={s.country_name}>{country.label}</div>
    </div>
  );
};
