import classnames from 'classnames';
import PropTypes from 'prop-types';
import { MyLink } from '../../MyLink';
import './index.scss';

export const RoundLink = ({
  label,
  path,
  fillBackground,
  fullWidth,
  variant,
  style,
  className,
  ...other
}) => (
  <MyLink
    href={path}
    className={classnames(
      'round-link',
      'hov',
      fillBackground && 'round-link__filled',
      fullWidth && 'round-link__fullwidth',
      variant === 'black' && 'round-link__black',
      className,
    )}
    style={style}
    {...other}
  >
    <span className="round-link__label f-16 f-500">{label}</span>
  </MyLink>
);

RoundLink.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
};
