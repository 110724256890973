import axios from '@/axios-api';
import { APP_LINKS } from '@/helpers/links';
import { AppRoutes } from '@/routes';
import Pathes from '@/helpers/pathes';
import { getMessage, userObject } from '@/helpers/utils';
import { clearTempUserData } from '@/pages/AuthPage/utils.js';
import { decodeJWT, removeUserFromCookie } from '@/helpers/user';
import i18n from '@/i18n';
import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_TOKEN,
  SET_USER_LOCATION,
  UPDATE_USER,
} from './actionTypes';

const { t } = i18n;

const loginUserSuccess = payload => ({ type: LOGIN_USER.SUCCESS, payload });

export const setToken = token => ({
  type: SET_TOKEN,
  token,
});

// Submit login code
export const submitLoginCode = payload => {
  return dispatch => {
    return axios
      .post(Pathes.Auth.submitLoginCode, payload)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(
            loginUserSuccess({
              temp_token: null,
              ...decodeJWT(data),
            }),
          );
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(() => {
        return { success: false, error: t('kit:wrong_code_try_again') };
      });
  };
};

export const logoutUser = () => {
  return dispatch => {
    clearTempUserData();
    removeUserFromCookie();
    dispatch({ type: LOGOUT_USER });
    AppRoutes.navigate(APP_LINKS.auth);
  };
};

export const getUserLocation = () => {
  return async dispatch => {
    try {
      const response = await axios.get(Pathes.User.myLocation);
      if (response && response.status === 200) {
        const { data } = response;
        const location = {
          city: data.city,
          country: data.country_name,
          countryCode: data.country_code,
          postal: data.postal,
          state: data.state,
          ip: data.IPv4,
          lat: data.latitude,
          lon: data.longitude,
        };

        dispatch({ type: SET_USER_LOCATION, location });
      }
    } catch (e) {
      // do nothing
    }
  };
};

export const verifyToken = token => {
  return axios
    .post(Pathes.Auth.verifyToken, token)
    .then(r => r && r.data && r.data.token);
};

export const updateUser = userData => {
  return (dispatch, getState) => {
    const prevData = getState().userStore.user;
    if (prevData) {
      dispatch({
        type: UPDATE_USER,
        user: userObject({ ...prevData, ...userData }),
      });
    }
  };
};
