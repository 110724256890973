import classnames from 'classnames';
import IconNext from './assets/icon_arrow_right_30.svg?react';
import IconPrev from './assets/icon_arrow_left_30.svg?react';
import s from './index.module.scss';

export const SliderNavigation = ({
  onNext,
  onPrev,
  isBeginning = false,
  isEnd = false,
}) => (
  <div className={s.controls}>
    <button
      type="button"
      className={classnames(s.prev, isBeginning && 'disabled')}
      onClick={() => onPrev()}
    >
      <IconPrev />
    </button>
    <button
      type="button"
      className={classnames(s.next, isEnd && 'disabled')}
      onClick={() => onNext()}
    >
      <IconNext />
    </button>
  </div>
);
