import { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LOAN_GROUPS_IDS } from '@/helpers/constants';
import { APP_LINKS } from '@/helpers/links';
import { ArrowUp } from '@/components/UI/Icons';
import { prettyMoneyHybrid } from '@/helpers/utils';
import { MyLink } from '@/components/MyLink';
import './index.scss';

export const LoanBasket = ({ data }) => {
  const { t } = useTranslation('dashboard');
  const [show, showMenu] = useState(false);
  if (!data) {
    return null;
  }

  const { group, earnings, amount } = data;
  const on_hold = parseFloat(amount);
  const invested = parseFloat(data.invested);
  const isActive = !!(on_hold || invested);
  const disabled = !group.is_active;
  const link = APP_LINKS.helpLoanTermsGroup(
    LOAN_GROUPS_IDS[group.id]?.detailLink,
  );

  return (
    <div
      className={classnames(
        'loan_basket',
        show && 'loan_basket__active',
        !isActive && 'loan_basket__disabled',
      )}
    >
      <h3
        className="loan_basket__title f-26 f-500 hov"
        onClick={() => !disabled && showMenu(!show)}
      >
        {group.title} {!disabled && <ArrowUp />}
      </h3>

      {disabled ? (
        <div className="loan_basket__unavailable f-16 f-400">
          {t('loan_basket_unavailable', { title: group.title })}
        </div>
      ) : (
        <>
          <div className="loan_basket__extra">
            <div className="loan_basket__info">
              <div className="loan_basket__info-item">
                <span className="f-16">
                  {t('loan_basket_all_time_invested')}
                </span>
                <span className="f-16 f-500 tl">
                  {prettyMoneyHybrid(earnings.invested)}
                </span>
              </div>

              <div className="loan_basket__info-item">
                <span className="f-16">{t('loan_basket_all_time_earned')}</span>
                <span className="f-16 f-500 tl">
                  {prettyMoneyHybrid(earnings.earned)}
                </span>
              </div>
            </div>
          </div>

          <div className="loan_basket__invested">
            <div className="loan_basket__invested-left">
              <div className="loan_basket__invested-left1">
                <span className="f-16">{t('loan_basket_invested')}</span>
                <span className="f-26 f-500 tl">
                  {prettyMoneyHybrid(invested)}
                </span>
              </div>
              {!!on_hold && isActive && (
                <div className="loan_basket__invested-left2">
                  <span className="f-16">{t('loan_basket_allocated')}</span>
                  <span className="f-26 f-500 tl">
                    {prettyMoneyHybrid(on_hold)}
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {!disabled && (
        <MyLink href={link} className="loan_basket__link">
          {t('loan_basket_loan_terms', { title: group.title })}
        </MyLink>
      )}
    </div>
  );
};
