import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SimpleDropdown from '@/components/UI/SimpleDropdown';
import { getMiniFaq } from '@/store/actions/helpActions';
import { USER_STATUSES } from '@/helpers/constants';
import './index.scss';

export const MINIFAQ_SOURCES = {
  dashboard: 'Dashboard',
  investing: 'Investing',
  addCash: 'Add_cash',
  withdraw: 'Withdraw',
  loans: 'Loans',
  founders: 'Founders',
  investors: 'Investors',
  activity: 'Activity',
  profile: 'Profile',
  signUp: 'SignUp',
  affiliate: 'affiliate',
  bankDetails: 'Investor_bank_accounts',
};

const MiniFaqs = ({ source, step }) => {
  const dispatch = useDispatch();
  const pageQuestions = useSelector(state => state.helpStore.miniFaqs);
  const profile = useSelector(state => state.profileStore.profile.data);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMiniFaq(source, { step }));
  }, [step]);

  if (
    !pageQuestions?.faqs.length ||
    profile?.status === USER_STATUSES.suspended
  ) {
    return null;
  }

  return (
    <div className="mini-faqs">
      <h4 className="mini-faqs__title f-26 f-500">{t('mini_faqs_faq')}</h4>
      <div className="mini-faqs__list">
        {pageQuestions.faqs.map(item => (
          <SimpleDropdown
            key={item.id}
            data={item}
            label={item.faq.question}
            innerContentHTML={item.faq.answer}
          />
        ))}
      </div>
    </div>
  );
};

export default MiniFaqs;
