import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LOAN_GROUPS, LOAN_GROUPS_IDS } from '@/helpers/constants';
import Tooltip from '../Tooltip';
import { TERM_TOOLTIPS } from './tooltips';
import s from './desktop.module.scss';

export const DesktopTermView = () => {
  const { t } = useTranslation('investing');
  const { t: tLoanTerms } = useTranslation('loan_terms');
  const { t: tConstants } = useTranslation('constants');

  const LOAN_GROUPS_T = LOAN_GROUPS(tConstants);
  const TERM_TOOLTIPS_T = TERM_TOOLTIPS(tLoanTerms);

  return (
    <div className={s.list}>
      {[LOAN_GROUPS_T.conservative, LOAN_GROUPS_T.moderate].map(group => (
        <div key={group.id} className={s.item}>
          <h3 className={classnames(s.item_title, 'f-36 f-500')}>
            {group.full_name}
          </h3>
          <div className={s.item_info}>
            <div className={s.item_row}>
              <div className={classnames(s.item_row_left, s.item_header)}>
                <span>{t('inv_loan_terms_investor_earnings')}</span>
                {TERM_TOOLTIPS_T.earnings({ className: s.d_earning })}
              </div>
              <div
                className={s.item_row_right}
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                {group.investor_earnings}{' '}
                {group.id === LOAN_GROUPS_IDS.conservative.id && (
                  <Tooltip
                    position="bottom center"
                    renderContent={() => (
                      <div className={classnames(s.tooltip, 'f-16 f-400')}>
                        {t('inv_loan_terms_increased_income')}
                        <br />
                        <ul className="scr-circle-list">
                          <li>{t('inv_loan_terms_achieved_by_topping_up')}</li>
                          <li>{t('inv_loan_terms_automatically_applied')}</li>
                        </ul>
                        <br />
                        {t('inv_loan_terms_basic_income')}
                        <ul className="scr-circle-list">
                          <li>{t('inv_loan_terms_applied_if_not_met')}</li>
                        </ul>
                        <br />
                        {t('inv_loan_terms_returns_accrued')}
                        <br />
                        <br />
                        <i>{t('inv_loan_terms_balance_falls_below')}</i>
                      </div>
                    )}
                  />
                )}
              </div>
            </div>

            <div className={s.item_row}>
              <div className={classnames(s.item_row_left, s.item_header)}>
                <span>{t('inv_loan_terms_net_annual_return')}</span>
                {TERM_TOOLTIPS_T.net_annual_return({ className: s.d_return })}
              </div>
              <div className={s.item_row_right}>
                {group.target_annual_return}
              </div>
            </div>

            <div className={s.item_row}>
              <div className={classnames(s.item_row_left, s.item_header)}>
                <span>{t('inv_loan_terms_term')}</span>
                {TERM_TOOLTIPS_T.term({ className: s.d_term })}
              </div>
              <div className={s.item_row_right}>
                {t('inv_loan_terms_6_months')}
              </div>
            </div>

            <div className={s.item_row}>
              <div className={classnames(s.item_row_left, s.item_header)}>
                <span>{t('inv_loan_terms_repayments')}</span>
                {TERM_TOOLTIPS_T.repayments({ className: s.d_repayments })}
              </div>
              <div className={s.item_row_right}>{group.repayment_type}</div>
            </div>

            <div className={s.item_row}>
              <div className={classnames(s.item_row_left, s.item_header)}>
                <span>{t('inv_loan_terms_skin_in_the_game')}</span>
                {TERM_TOOLTIPS_T.skin({ className: s.d_skin })}
              </div>
              <div className={s.item_row_right}>{group.skin_in_the_game}</div>
            </div>

            <div className={s.item_row}>
              <div className={classnames(s.item_row_left, s.item_header)}>
                <span>{t('inv_loan_terms_protection')}</span>
                {TERM_TOOLTIPS_T.protection({
                  group: group.id,
                  className: s.d_protection,
                })}
              </div>
              <div className={s.item_row_right}>{group.protection}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
