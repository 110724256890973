import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { USER_STATUSES } from '@/helpers/constants';
import NotificationBlock from '@/containers/NotificationBlock';
import { NOTIFICATIONS as NOTIFICATIONS_T } from '@/components/Notification/list';
import { useGetMe } from '@/hooks/useGetMe';
import { BankDetailsTable } from './table';
import { BankDetailCreate } from './create';
import { BankDetailUpdate } from './edit';
import s from './index.module.scss';

const BankAccountsModule = () => {
  const { user } = useGetMe();
  const { t } = useTranslation();
  const NOTIFICATIONS = NOTIFICATIONS_T(t);
  const notifications = [];

  if (user?.status !== USER_STATUSES.verified) {
    notifications.push(
      NOTIFICATIONS.bank_accounts_available_to_verified_users(),
    );
  }

  return (
    <div className={s.wrapper}>
      <NotificationBlock
        className={s.notifications}
        notifications={notifications}
      />
      {user?.status === USER_STATUSES.verified && (
        <Routes>
          <Route path="new" element={<BankDetailCreate />} />
          <Route path=":id" element={<BankDetailUpdate />} />
          <Route path="/" element={<BankDetailsTable />} />
        </Routes>
      )}
    </div>
  );
};

export default BankAccountsModule;
