import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { MobileTermView } from './mobile';
import { DesktopTermView } from './desktop';
import s from './index.module.scss';

export const LoanTerms = ({ onCalculate }) => {
  const { t } = useTranslation('investing');
  const { innerWidth } = useSelector(state => state.commonStore);

  return (
    <section className={s.wrapper}>
      <div className={s.inner}>
        <h2 className="f-26 f-500">{t('investment_loan_terms')}</h2>
        <p className={classnames(s.desc, 'f-16 f-400')}>
          {t('all_loan_groups_available')}
        </p>
        {innerWidth < 1120 ? (
          <MobileTermView onCalculate={onCalculate} />
        ) : (
          <DesktopTermView onCalculate={onCalculate} />
        )}
      </div>
    </section>
  );
};
