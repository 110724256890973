import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import RadioButton from '@/components/UI/RadioButton';
import { RoundButton } from '@/components/UI/RoundButton';
import PriorityFoundersList from './priority';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import { setInvestmentStrategy } from '@/store/services/investingServices';
import { setInvestingStatus } from '@/store/actions/investActions';
import Dropdown from '@/components/Dropdown';
import { USER_STATUSES } from '@/helpers/constants';
import { isSubmittedVeriff } from '@/helpers/user';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import WarningBlock from '@/components/UI/WarningBlock';
import { MinInvestAmountWarning } from '@/components/Warnings/MinInvestAmountWarning';
import Analytics from '@/analyticsGA';
import './index.scss';

export const STRATEGIES = {
  standard: 'standard',
  priority: 'priority',
  not_selected: 'not_selected',
};

const InvSelectStrategy = ({ status, setProcessing, setStep, disabled }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('investing');
  const profile = useSelector(state => state.profileStore.profile.data);
  const { innerWidth } = useSelector(state => state.commonStore);
  const { veriffStatusCode } = useSelector(state => state.profileStore);

  const [state, setState] = useState({
    selectedStrategy:
      status.option === STRATEGIES.not_selected
        ? STRATEGIES.standard
        : status.option,
    selectedFounders: [],
  });

  useEffect(() => {
    Analytics.investingStrategyStep();
  }, []);

  const setStrategy = strategy => {
    setState(prevState => ({ ...prevState, selectedStrategy: strategy }));
  };

  const onSubmit = e => {
    e.preventDefault();
    setProcessing(true);
    const payload = {
      option: state.selectedStrategy,
      founders: state.selectedFounders,
    };
    setInvestmentStrategy(payload)
      .then(res => {
        if (res && res.success) {
          dispatch(setInvestingStatus(res.data));
          setStep(2);
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const { selectedStrategy } = state;

  return (
    <form className="inv-select-strategy" onSubmit={onSubmit}>
      <h1 className="f-42 f-500">{t('iss_select_an_investment_strategy')}</h1>
      {profile?.country_code === 'US' &&
        status?.min_investment_amount === 1000 && (
          <MinInvestAmountWarning className="inv-select-strategy__warning" />
        )}
      {profile && profile.status !== USER_STATUSES.verified && (
        <WarningBlock className="inv-select-strategy__warning" withBorder>
          {isSubmittedVeriff(veriffStatusCode) ? (
            <div className="f-16 f-400">
              {t('investing_available_only_verified_investors')}
            </div>
          ) : (
            <div className="f-16 f-400">
              {t('complete_verification_process_to_start_investing')}
              <MyLink href={APP_LINKS.dashboard} className="c-grey">
                {t('complete_verification')} &nbsp;→
              </MyLink>
            </div>
          )}
        </WarningBlock>
      )}

      <div className="inv-select-strategy__investment">
        <h2 className="f-26 f-500">{t('iss_how_would_you_like_to_invest')}</h2>
        <p className={classnames('f-16 f-400', disabled && 'disabledBlock')}>
          {t('iss_you_have_two_options')}
        </p>
        <div
          className={classnames(
            'inv-select-strategy__investment-group',
            disabled && 'disabledBlock',
          )}
        >
          <RadioButton
            className={classnames(
              'inv-select-strategy__investment-item',
              selectedStrategy === STRATEGIES.standard && 'active',
            )}
            checked={selectedStrategy === STRATEGIES.standard}
            disabled={disabled}
            onChange={() => setStrategy(STRATEGIES.standard)}
          >
            <h5 className="f-20 f-500">
              {t('iss_standard_allocation_recommended')}
            </h5>
            <p className="f-16 f-400">
              {t('iss_your_cash_is_allocated_proportionally')}
            </p>
          </RadioButton>

          <RadioButton
            className={classnames(
              'inv-select-strategy__investment-item',
              selectedStrategy === STRATEGIES.priority && 'active',
              !status.priority_strategy_enabled && 'disabled',
            )}
            checked={selectedStrategy === STRATEGIES.priority}
            disabled={disabled || !status.priority_strategy_enabled}
            onChange={() => setStrategy(STRATEGIES.priority)}
          >
            <h5 className="f-20 f-500">
              {t('iss_priority_allocation')}{' '}
              {!status.priority_strategy_enabled &&
                t('iss_currently_unavailable')}
            </h5>
            {!status.priority_strategy_enabled && (
              <p className="f-16 f-500 c-grey">
                {t('iss_priority_strategy_unavailable', {
                  participants: status.priority_required_batch_size,
                })}
              </p>
            )}
            <p className="f-16 f-400">
              {t('iss_your_cash_is_allocated_with_priority')}
            </p>
          </RadioButton>
        </div>

        {selectedStrategy === STRATEGIES.standard && (
          <Dropdown
            title={t('consumer_goods_brands_batch')}
            className={classnames(
              'inv-select-strategy__standard-founders',
              disabled && 'disabledBlock',
            )}
            disableToggle={disabled}
          >
            <ParticipatingStartupsList />
          </Dropdown>
        )}

        {selectedStrategy === STRATEGIES.priority && (
          <div className="inv-select-strategy__priority-founders">
            <PriorityFoundersList
              selectedStrategy={selectedStrategy}
              setSelections={selections =>
                setState(prevState => ({
                  ...prevState,
                  selectedFounders: selections,
                }))
              }
            />
          </div>
        )}

        <div className="inv-select-strategy__buttons">
          <RoundButton
            label={innerWidth < 600 ? t('back') : t('go_back')}
            type="button"
            onClick={() => {
              Analytics.investingBackButton();
              setStep(0);
            }}
            className="inv-select-strategy__buttons-back"
          />
          <RoundButton
            label={t('continue_to_the_next_step')}
            fullWidth
            fillBackground
            type="submit"
            onSubmit={onSubmit}
            disabled={disabled}
            className="inv-select-strategy__buttons-continue"
          />
        </div>
      </div>
    </form>
  );
};

export default InvSelectStrategy;
