import React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AvatarDesktop from './assets/desktop_avatars.svg?react';
import AvatarMobile from './assets/mobile_avatars.svg?react';
import { setModals } from '../../store/actions/commonActions';
import { MODAL_TYPES } from '../ModalProvider';
import s from './index.module.scss';

export const BonusInviteFriends = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('affiliate');

  const { innerWidth } = useSelector(state => state.commonStore);
  const { referralCode } = useSelector(state => state.referralStore);
  const isActiveReferralBanner = !!referralCode.data?.is_referral_banner_active;

  if (!isActiveReferralBanner) return null;

  const onClick = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.bonus_invite_friend,
      }),
    );
  };

  return (
    <div className={s.wrapper}>
      {innerWidth <= 500 ? <AvatarMobile /> : <AvatarDesktop />}
      {innerWidth <= 500 ? (
        <div className="f-16-24 f-500" style={{ marginTop: '16px' }}>
          {t('bonus_invite_friend_title')}{' '}
          <button type="button" onClick={onClick} className="underline f-400">
            {t('bonus_invite_friend_learn_more')}
          </button>
        </div>
      ) : (
        <>
          <div className={classnames(s.title, 'f-26-32 f-500')}>
            {t('bonus_invite_friend_title')}
          </div>
          <div className={classnames(s.desc, 'f-16')}>
            {t('bonus_invite_friend_description')}
          </div>
          <button type="button" onClick={onClick} className={s.learn}>
            {t('bonus_invite_friend_learn_more')}
          </button>
        </>
      )}
    </div>
  );
};
