import { APP_LINKS } from '@/helpers/links';
import AffiliateIcon from './assets/affiliate_icon.svg';

export const NAV_ROUTES =
  t =>
  ({ isActiveReferralBanner }) => [
    { label: t('dashboard'), path: APP_LINKS.dashboard },
    { label: t('investing'), path: APP_LINKS.investing },
    { label: t('cash'), path: APP_LINKS.cash },
    { label: t('loans'), path: APP_LINKS.loans },
    { label: t('brands_gallery'), path: APP_LINKS.brandsRoot },
    // { label: t('investors'), path: APP_LINKS.investors },
    { label: t('reports'), path: APP_LINKS.activity },
    {
      label: t('invite_friends'),
      path: APP_LINKS.affiliate,
      icon: isActiveReferralBanner && AffiliateIcon,
    },
    { label: t('rounds_history'), path: APP_LINKS.roundHistory },
    {
      label: t('investors_questions'),
      path: APP_LINKS.clientQuestions,
    },
    { label: t('blog'), path: APP_LINKS.blog },
  ];
