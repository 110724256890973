import React, { useEffect } from 'react';
import moment from 'moment/min/moment-with-locales';
import axios from '@/axios-api';
import Pathes from '@/helpers/pathes';
import { getQuery } from '@/helpers/utils';

export const useCheckPlannedPayments = () => {
  const [hasPlannedPayment, setHasPlannedPayment] = React.useState(false);

  useEffect(() => {
    const currentDay = moment().date();
    if (currentDay > 1 && currentDay < 10) getRepayments();
  }, []);

  const getRepayments = async () => {
    const res = await axios.get(
      Pathes.Loans.repaymentSchedule +
        getQuery({ page: 1, limit: 1, exclude_paid: 1 }),
    );
    const firstPayment = res.data?.list[0];
    if (firstPayment) {
      const paymentDay = moment(firstPayment.deadline).date();
      const paymentMonth = moment(firstPayment.deadline).month();
      const currentMonth = moment().month();
      if (paymentDay > 5 && currentMonth === paymentMonth) {
        setHasPlannedPayment(false);
      }
    }
  };

  return { hasPlannedPayment };
};
