import { ErrorBoundary } from 'react-error-boundary';
import { APP_LINKS } from '@/helpers/links';
import { AppProvider } from '@/app';
import { ErrorBoundaryFallback } from '@/components/ErrorBoundaryFallback';
import { AppLayout } from '@/layouts/AppLayout';
import { sentryCreateBrowserRouter } from '@/sentry';

import AuthPage from './pages/AuthPage';
import EmailVerificationStatusPage from './pages/EmailVerificationStatusPage';
import DevPage from './pages/DevPage';
import DashboardPage from './pages/DashboardPage';
import PageNotFound from './pages/PageNotFound';
import FundsPage from './pages/FundsPage';
import FundsAddPage from './pages/FundsAddPage';
import FundsWithdrawPage from './pages/FundsWithdrawPage';
import FundsAddResultPage from './pages/FundsAddResultPage';
import ProfilePage from './pages/ProfilePage';
import FundsBankAccountsPage from './pages/FundsBankDetailsPage';
import ReportsPage from './pages/ReportsPage';
import ActivityPage from './pages/ActivityPage';
import TaxReportPage from './pages/TaxReportPage';
import LoanTermsPage from './pages/LoanTermsPage';
import LoanTermsGroupDetailPage from './pages/LoanTermsGroupDetailPage';
import InvestingPage from './pages/InvestingPage';
import FounderDetailPage from './pages/FounderDetailPage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import RoundHistoryPage from './pages/RoundHistoryPage';
// import InvestorsPage from './pages/InvestorsPage';
import UnsubscribePage from './pages/UnsubscribePage';
import HelpPage from './pages/HelpPage';
import LoansPage from './pages/LoansPage';
import LoansLoanPage from './pages/LoansLoanPage';
import LoansSchedulePage from './pages/LoansSchedulePage';
import AffiliatePage from './pages/AffiliatePage';
import BrandsRootPage from './pages/BrandsRootPage';
import StartupsPage from './pages/StartupsPage';
import FoundersPage from './pages/FoundersPage';
import StartupDetailPage from './pages/StartupDetailPage';
import ClientQuestionsPage from './pages/ClientQuestionsPage';
import ComplaintsPage from './pages/ComplaintsPage';
import ComplaintAddPage from './pages/ComplaintAddPage';
import BlogPage from './pages/BlogPage';
import BlogDetailPage from './pages/BlogDetailPage';

const Routes = [
  {
    element: (
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <AppProvider />
      </ErrorBoundary>
    ),
    children: [
      {
        path: APP_LINKS.auth,
        element: <AuthPage />,
      },
      {
        path: APP_LINKS.signIn,
        element: <AuthPage />,
      },
      {
        path: APP_LINKS.emailVerificationStatus(),
        element: <EmailVerificationStatusPage />,
      },
      {
        path: '/unsubscribe/:token',
        element: <UnsubscribePage />,
      },
      {
        path: '/legal/terms-of-use/',
        element: <TermsOfUsePage />,
      },
      {
        path: APP_LINKS.root,
        element: <AppLayout />,
        children: [
          {
            path: APP_LINKS.dashboard,
            element: <DashboardPage />,
          },
          {
            path: APP_LINKS.investing,
            element: <InvestingPage />,
          },
          {
            path: APP_LINKS.roundHistory,
            element: <RoundHistoryPage />,
          },
          {
            path: APP_LINKS.affiliate,
            element: <AffiliatePage />,
          },
          {
            path: APP_LINKS.profile,
            element: <ProfilePage />,
          },
          {
            path: APP_LINKS.founderDetail(':id'),
            element: <FounderDetailPage />,
          },
          {
            path: APP_LINKS.startupDetail(':slug'),
            element: <StartupDetailPage />,
          },
          // {
          //   path: APP_LINKS.investors,
          //   element: InvestorsPage,
          //
          // },
          {
            path: APP_LINKS.cash,
            element: <FundsPage />,
            children: [
              {
                path: APP_LINKS.addFunds,
                element: <FundsAddPage />,
              },
              {
                path: APP_LINKS.addFundsResult,
                element: <FundsAddResultPage />,
              },
              {
                path: APP_LINKS.withdraw,
                element: <FundsWithdrawPage />,
              },
              {
                path: APP_LINKS.bankAccounts + '/*',
                element: <FundsBankAccountsPage />,
              },
            ],
          },
          {
            path: APP_LINKS.reports,
            element: <ReportsPage />,
            children: [
              {
                path: APP_LINKS.activity,
                element: <ActivityPage />,
              },
              {
                path: APP_LINKS.taxReports,
                element: <TaxReportPage />,
              },
            ],
          },
          {
            path: APP_LINKS.brandsRoot,
            element: <BrandsRootPage />,
            children: [
              {
                path: APP_LINKS.startups,
                element: <StartupsPage />,
              },
              {
                path: APP_LINKS.founders,
                element: <FoundersPage />,
              },
            ],
          },
          {
            path: APP_LINKS.helpLoanTerms(':version?'),
            element: <LoanTermsPage />,
          },
          {
            path: APP_LINKS.helpLoanTermsGroup(),
            element: <LoanTermsGroupDetailPage />,
          },
          {
            path: '/help/:slugLevelOne?/:slugLevelTwo?/:slugLevelThree?',
            element: <HelpPage />,
          },
          {
            path: APP_LINKS.help,
            element: <HelpPage />,
          },
          {
            path: APP_LINKS.loans,
            element: <LoansPage />,
            children: [
              {
                path: APP_LINKS.loansList,
                element: <LoansLoanPage />,
              },
              {
                path: APP_LINKS.loansSchedule,
                element: <LoansSchedulePage />,
              },
            ],
          },
          {
            path: APP_LINKS.clientQuestions,
            element: <ClientQuestionsPage />,
          },
          {
            path: APP_LINKS.complaints,
            element: <ComplaintsPage />,
          },
          {
            path: APP_LINKS.complaintCreate,
            element: <ComplaintAddPage />,
          },
          {
            path: APP_LINKS.blog,
            element: <BlogPage />,
          },
          {
            path: APP_LINKS.blogDetail(),
            element: <BlogDetailPage />,
          },
        ],
      },
      {
        path: '/dev',
        element: <DevPage />,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
];

export const AppRoutes = sentryCreateBrowserRouter(Routes);
