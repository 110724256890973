import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import Tooltip from '@/components/Tooltip';
import s from './index.module.scss';

export const LoanGroupCard = ({ group, className }) => {
  const { t } = useTranslation('loan_terms');
  return (
    <div className={classnames(s.card, className)}>
      <MyLink
        href={group.details_link}
        className={classnames(s.card__title, 'hov', 'f-26 f-500')}
      >
        {group.title}
      </MyLink>
      {group.is_active ? (
        <>
          <div className={s.card__block}>
            <div className="f-18 f-500">
              <span>{t('v2.loan_card.target_annual_return')}</span>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className={classnames(s.card__tooltip, 'f-16 f-400')}>
                    {t('v2.loan_card.target_annual_return_tooltip')}
                  </div>
                )}
              />
            </div>
            <p className="f-18 f-500 tl">{group.investor_annual_rate}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">{t('v2.loan_card.term')}</div>
            <p className="f-18 f-500 tl">{group.info_term}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">
              {t('v2.loan_card.flat_fee_6_months')}
            </div>
            <p className="f-18 f-500 tl">{group.flat_fee}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">
              {t('v2.loan_card.extra_flat_fee_beyond_6_months')}
            </div>
            <p className="f-18 f-500 tl">{group.extra_flat_fee}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">
              {t('v2.loan_card.downside_protection')}
            </div>
            <p className="f-18 f-500 tl">{group.downside_protection}</p>
          </div>

          <MyLink
            href={group.details_link}
            className={classnames(s.card__link_details, 'f-500')}
          >
            {t('v2.loan_card.see_details')}
          </MyLink>
        </>
      ) : (
        <p className={classnames(s.card__unavailable, 'f-18 f-500')}>
          {t('v2.loan_card.temporarily_unavailable', { title: group.title })}
        </p>
      )}
    </div>
  );
};
