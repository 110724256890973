import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment/min/moment-with-locales';
import { Trans, useTranslation } from 'react-i18next';
import { RoundButton } from '@/components/UI/RoundButton';
import { EarningsAndRepayments } from './schedules';
import { ACCOUNT_TYPES } from '@/helpers/constants';
import {
  getAllocatedRepaymentSchedule,
  getInvestingResults,
} from '@/store/actions/investActions';
import { DATE_FORMAT_DD_MMMM_YYYY } from '@/helpers/common';
import { isExpiredResetDate } from '@/helpers/date';
import { useGetAllocationStatus } from '@/hooks/useGetAllocationStatus';
import { prettyDate, prettyFloatMoney } from '../../helpers/utils';
import { STRATEGIES } from '../InvestingModule/InvSelectStrategy';
import { BonusAdditionalAmount } from './BonusAddIitionalAmount';
import { PlannedEarnings } from './earnings';
import './index.scss';

const InvestingSummary = ({
  status,
  onLoansEdit,
  onStrategyEdit,
  isReadyStep,
  className,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('investing');
  const {
    totalAllocated,
    availableCash,
    availableCashWithoutBonus,
    allocatedGroupA,
    bonusAmount,
  } = useGetAllocationStatus();
  const { fundraisingDates } = useSelector(state => state.dashboardStore);
  const { investingResult } = useSelector(state => state.investStore);
  const { preAllocateInfo } = useSelector(state => state.fundStore);

  const activeFundraisingId =
    fundraisingDates?.is_active && fundraisingDates?.id;

  const disabledAdd =
    allocatedGroupA === 0 && availableCashWithoutBonus <= bonusAmount;

  useEffect(() => {
    if (status.step === 5) {
      dispatch(getInvestingResults());
    }
  }, []);

  useEffect(() => {
    isReadyStep &&
      activeFundraisingId &&
      dispatch(getAllocatedRepaymentSchedule(activeFundraisingId));
  }, [isReadyStep, activeFundraisingId]);

  const isExpiredResetInvesting = isExpiredResetDate(status.editable_until);

  const isDisabled = status.step === 666;
  const isResult = status.step === 5;

  const hasAllocated = !!totalAllocated;

  const groups = [];

  isResult
    ? investingResult.data &&
      investingResult.data.groups
        .filter(group => group.is_active)
        .map(group =>
          groups.push({ title: group.title, amount: group.invested_in_group }),
        )
    : preAllocateInfo.data?.accounts
        .filter(account => account.type === ACCOUNT_TYPES.loan)
        .map(account =>
          groups.push({ title: account.group_title, amount: account.amount }),
        );

  return (
    <div
      className={classnames(
        'investing_summary',
        isDisabled && 'disabled',
        className,
      )}
    >
      <div className="investing_summary__content">
        <div className="investing_summary__strategy">
          <div className="investing_summary__strategy-header">
            <div className="investing_summary__strategy-approach">
              <p className="f-16">{t('summary_investment_strategy')}</p>
              <p className="f-26 f-500">
                {status.option === STRATEGIES.not_selected && <span>-</span>}
                {status.option === STRATEGIES.standard && (
                  <span>{t('summary_standard_strategy')}</span>
                )}
                {status.option === STRATEGIES.priority && (
                  <span>{t('summary_priority_strategy')}</span>
                )}
              </p>
              {status.option === STRATEGIES.priority && (
                <div className="investing_summary__strategy-priority f-16 f-400">
                  {t('summary_priority_strategy_description')}
                </div>
              )}
            </div>
            {onStrategyEdit &&
              status.step < 4 &&
              status.option !== STRATEGIES.not_selected && (
                <RoundButton
                  label={t('summary_edit')}
                  type="button"
                  disabled={isExpiredResetInvesting}
                  onClick={onStrategyEdit}
                  className="investing_summary__strategy-edit"
                />
              )}
          </div>
        </div>

        <div className="investing_summary__divider" />

        <div className="investing_summary__summary">
          <div className="investing_summary__baskets">
            {!!groups.length && (
              <div className="investing_summary__baskets-list">
                {groups.map((group, index) => (
                  <div className="investing_summary__baskets-item" key={index}>
                    <p className="f-16">
                      {t('summary_allocated_to', {
                        title: group.title.replace('Group', t('group')),
                      })}
                    </p>
                    <p className="f-26 f-500 tl">
                      {group.amount ? prettyFloatMoney(group.amount) : '—'}
                    </p>
                  </div>
                ))}
              </div>
            )}

            {isResult ? (
              <div className="investing_summary__result-header">
                <div className="investing_summary__result-invested">
                  <p className="f-16">{t('summary_invested')}</p>
                  <p className="f-42 f-500 tl">
                    {prettyFloatMoney(
                      investingResult.data &&
                        investingResult.data.total_invested,
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <div className="investing_summary__baskets-header">
                <div className="investing_summary__baskets-earmarked">
                  <p className="f-16">
                    {t('summary_total_allocated_for_investing')}
                  </p>
                  <p className="f-42 f-500 tl">
                    {prettyFloatMoney(totalAllocated)}
                  </p>
                </div>
                {onLoansEdit && status.step < 4 && hasAllocated && (
                  <RoundButton
                    type="button"
                    label={
                      isExpiredResetInvesting && isReadyStep
                        ? t('summary_add')
                        : t('summary_edit')
                    }
                    onClick={onLoansEdit}
                    disabled={
                      (isReadyStep &&
                        isExpiredResetInvesting &&
                        availableCash <= 0.1) ||
                      (isReadyStep && isExpiredResetInvesting && disabledAdd)
                    }
                    className="investing_summary__baskets-edit"
                  />
                )}
              </div>
            )}

            {isReadyStep && <div className="investing_summary__divider" />}

            {isReadyStep && <PlannedEarnings />}

            {isReadyStep && (
              <BonusAdditionalAmount className="investing_summary__additional_amount" />
            )}

            {isReadyStep && (
              <>
                <div className="investing_summary__description f-16 f-400">
                  {isExpiredResetInvesting ? (
                    <div style={{ marginTop: '10px' }}>
                      {t('summary_add_more_funds')}
                    </div>
                  ) : (
                    <div style={{ marginTop: '10px' }}>
                      <Trans
                        ns="investing"
                        i18nKey="summary_change_or_reset_investment_settings"
                        values={{
                          untilDate: prettyDate(
                            moment(status.editable_until),
                            DATE_FORMAT_DD_MMMM_YYYY,
                          ),
                        }}
                        components={{
                          span: <span className="f-500" />,
                        }}
                      />
                    </div>
                  )}
                  <div style={{ marginTop: '10px' }}>
                    {t('summary_loans_automatically_created')}
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    {t('summary_check_earnings_and_repayment_schedule')}
                  </div>
                </div>
                <EarningsAndRepayments />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestingSummary;
