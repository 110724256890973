import { useParams } from 'react-router-dom';
import { LoanTermsVersion01 } from './versions/v1';
import { LoanTermsVersion02 } from './versions/v2';
import { LoanTermsVersion03 } from './versions/v3';

const LoanTermsPage = () => {
  const { version } = useParams();

  switch (version) {
    case 'v1':
      return <LoanTermsVersion01 />;
    case 'v2':
      return <LoanTermsVersion02 />;
    case 'v3':
      return <LoanTermsVersion03 />;
    default:
      return <LoanTermsVersion03 />;
  }
};

export default LoanTermsPage;
