import { useTranslation } from 'react-i18next';
import { prettyFloatMoney } from '@/helpers/utils';
import { LoanCard } from '@/components/Cards/LoanCard';
import s from './index.module.scss';

export const LoansList = ({ data, onLoanOpen }) => {
  const { t } = useTranslation('loans');
  return (
    <div className={s.wrapper}>
      <div className={s.summary}>
        <div className={s.summary_row}>
          <div className="f-16 f-500">{t('total')}</div>
          <div className="f-18 f-500">
            {data.total_count === 1
              ? t('one_round')
              : t('multiple_rounds', { count: data.total_count })}
          </div>
        </div>

        <div className={s.summary_row}>
          <div className="f-16 f-500">{t('loans_amount')}</div>
          <div className="f-18 f-500">
            {prettyFloatMoney(data.total_amount)}
          </div>
        </div>

        <div className={s.summary_row}>
          <div className="f-16 f-500">{t('repayments')}</div>
          <div className="f-18 f-500">
            {prettyFloatMoney(data.total_repayments)}
          </div>
        </div>

        <div className={s.summary_row}>
          <div className="f-16 f-500">{t('your_earnings')}</div>
          <div className="f-18 f-500">
            + {prettyFloatMoney(data.total_earnings + data.total_accrued)}
          </div>
        </div>
      </div>

      <div className={s.list}>
        {data.list.map(round => (
          <LoanCard
            key={round.fundraising_name}
            round={round}
            className={s.list_item}
            onDetailsClick={() => onLoanOpen(round)}
          />
        ))}
      </div>
    </div>
  );
};
