import classnames from 'classnames';
import { Message } from '@/ui/Message';
import s from './index.module.scss';

export const RefferalCode = ({ code, className }) => {
  if (!code) {
    return null;
  }

  return (
    <div className={classnames(s.wrapper, className)}>
      <div className={s.code}>{code.id}</div>
      <Message
        type="success"
        title={`Your €${code.amount} referral code for new users has been successfully applied.`}
        description="If you don’t have an account yet, sign up and complete onboarding to
            claim your bonus!"
      />
    </div>
  );
};
