import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { prettyFloatMoney } from '@/helpers/utils';
import WarningBlock from '@/components/UI/WarningBlock';
import { RoundLink } from '@/components/UI/RoundLink';
import { APP_LINKS } from '@/helpers/links';
import s from './index.module.scss';

export const PaymentFailure = ({ amount }) => {
  const { t } = useTranslation('withdraw');
  return (
    <div className={s.wrapper}>
      <h1 className={classnames(s.title, 'f-26 f-500')}>
        {t('oops_something_went_wrong')}
      </h1>

      <WarningBlock withBorder className={s.message}>
        <div className="f-16 f-400">
          <Trans
            ns="withdraw"
            i18nKey="your_payment_of_was_failed_please_check_and_try_again"
            values={{ amount: prettyFloatMoney(amount) }}
            components={{ span: <span className="f-500" /> }}
          />
        </div>
      </WarningBlock>

      <div className={s.buttons}>
        <RoundLink
          path={APP_LINKS.dashboard}
          label={t('go_to_dashboard')}
          fillBackground
          className={s.button}
        />
        <RoundLink
          path={APP_LINKS.addFunds}
          label={t('add_cash')}
          className={s.button}
        />
      </div>
    </div>
  );
};
