import { useEffect } from 'react';
import classnames from 'classnames';
import './index.scss';

export const TrustpilotStars = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.src =
      'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;

    // Append script to the document
    document.body.appendChild(script);

    // Cleanup function (optional)
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {/* TrustBox widget */}
      <div
        className={classnames('trustpilot-widget', 'trs_stars')}
        data-locale="en-US"
        data-template-id="53aa8807dec7e10d38f59f32"
        data-businessunit-id="61faa1aa4e2b01025a9e808a"
        data-style-height="150px"
        data-style-width="100%"
        data-theme="dark"
      >
        <a
          href="https://www.trustpilot.com/review/scrambleup.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
      {/* End TrustBox widget */}
    </>
  );
};
