import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useGetProfile } from '@/query/queries/user/useGetProfile';
import { GET_USER_PROFILE } from '@/store/types/profileTypes';

export const useGetMe = () => {
  const token = useSelector(state => state.userStore.token);
  const { data: profile } = useGetProfile(token);

  // TODO remove once profile moved to query-hooks
  const dispatch = useDispatch();
  useEffect(() => {
    profile &&
      dispatch({
        type: GET_USER_PROFILE.SUCCESS,
        payload: profile,
      });
  }, [profile]);

  const user = useMemo(() => {
    if (!token) return null;
    const decodedData = jwtDecode(token.access_token);
    return decodedData.user;
  }, [token]);

  return { user: profile || user };
};
