import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { RoundLink } from '@/components/UI/RoundLink';
import { APP_LINKS } from '@/helpers/links';
import { setModals } from '@/store/actions/commonActions';
import { MODAL_TYPES } from '@/components/ModalProvider';
import s from './index.module.scss';

export const NoAvailableTopUpMethods = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('funds');

  const onClick = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.sepa_info_modal,
      }),
    );
  };

  return (
    <div className={s.wrapper}>
      <span className="f-16-24">
        <Trans
          ns="funds"
          i18nKey="unfortunately_no_available_top_up_methods"
          components={{
            button: (
              <button type="button" onClick={onClick} className="underline" />
            ),
          }}
        />
      </span>

      <div className={s.buttons}>
        <RoundLink
          path={APP_LINKS.bankAccountsCreate}
          label={t('add_a_bank_account')}
          fillBackground
        />
      </div>
    </div>
  );
};
