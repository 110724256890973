export const LANGUAGES = [
  {
    code: 'en',
    label: 'English',
    country: 'United Kingdom',
  },
  {
    code: 'es',
    label: 'Español',
    country: 'España',
  },
  {
    code: 'pt',
    label: 'Português',
    country: 'Portugal',
  },
  {
    code: 'el',
    label: 'Ελληνικά',
    country: 'Ελλάδα',
  },
  {
    code: 'nl',
    label: 'Dutch',
    country: 'Nederland',
  },
  {
    code: 'de',
    label: 'Deutsch',
    country: 'Deutschland',
  },
  {
    code: 'et',
    label: 'Eesti keel',
    country: 'Eesti',
  },
];
