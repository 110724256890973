import { useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BankRequisite } from '@/containers/AddFundsModule/bankRequisite';
import { prettyFloatMoney, scrollTop } from '@/helpers/utils';
import { useGetMe } from '@/hooks/useGetMe';
import s from './index.module.scss';

export const BankTransfer = ({ amount }) => {
  const { t } = useTranslation('funds');
  const { user } = useGetMe();

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <>
      <h1 className={classnames(s.bank_transfer__title, 'f-26 f-500')}>
        {t('bank_transfer.add_cash_to_your_account_via_bank_transfer')}
      </h1>
      <div className={s.bank_transfer__total}>
        <div
          className={classnames(s.bank_transfer__total_label, 'f-16-24 f-400')}
        >
          {t('bank_transfer.payment_amount')}
        </div>
        <div
          className={classnames(s.bank_transfer__total_amount, 'f-42 f-500')}
        >
          {prettyFloatMoney(amount)}
        </div>
      </div>

      <p className={classnames(s.bank_transfer__desc, 'f-16 f-400')}>
        {t('bank_transfer.transfer_cash_from_your_bank_account')}
      </p>

      <div className={s.bank_transfer__container}>
        <BankRequisite userID={user?.id} className={s.bank_transfer__req} />
      </div>
    </>
  );
};
