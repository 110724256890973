import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { TextInput } from '@/ui/TextInput';
import { RoundButton } from '@/ui/RoundButton';
import IconEmail from '@/assets/icons/icon_envelope.svg?react';
import s from '../index.module.scss';

const getValidationSchema = t =>
  Yup.object().shape({
    email: Yup.string()
      .strictEmail(t('invalid_email'))
      .max(50, t('invalid_email'))
      .required(t('email_is_required')),
  });

export const EmailForm = ({ onSubmit }) => {
  const { t } = useTranslation('auth');

  const { values, setFieldValue, errors, touched, isSubmitting, handleSubmit } =
    useFormik({
      validationSchema: getValidationSchema(t),
      onSubmit,
      initialValues: {
        email: '',
      },
    });

  return (
    <form className={s.form} onSubmit={handleSubmit}>
      <TextInput
        name="email"
        value={values.email}
        placeholder={t('email_form.email_address')}
        onChange={e => setFieldValue('email', e.target.value.trim())}
        iconLeft={<IconEmail />}
        error={errors.email && touched.email && errors.email}
      />
      <RoundButton
        type="submit"
        label={t('email_form.continue_with_email')}
        fillBackground
        className="hov"
        onSubmit={handleSubmit}
        disabled={isSubmitting}
      />
    </form>
  );
};
