import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Notify from '@/components/Notification';
import InvestorTypeForm from '@/components/Forms/InvestorTypeForm';
import { setInvestorType } from '@/store/services/userServices';
import { getUserProfile } from '@/store/actions/profileActions';
import s from './index.module.scss';

const InvestorTypeModal = ({ initialValues, onSave, onBack, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('modal');

  const onSubmit = async ({ investor_type }) => {
    if (!investor_type) {
      return Notify.error({ text: t('inv_type_modal.select_investor_type') });
    }

    try {
      const payload = { investor_type };
      const res = await setInvestorType(payload);

      if (res && res.success) {
        dispatch(getUserProfile());
        Notify.success({ text: res.data.message });
        onSave ? onSave(payload, res.data) : onClose();
      }
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <div className={s.wrapper}>
      <InvestorTypeForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={onClose}
        onBack={onBack}
      />
    </div>
  );
};

export default InvestorTypeModal;
