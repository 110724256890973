import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SimpleDropdown from '@/components/UI/SimpleDropdown';
import { Preloader } from '@/components/Preloader';
import { getHelp, setSelectedFAQ } from '@/store/actions/helpActions';
import './index.scss';

const HelpPage = () => {
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation('help');
  const slugPath = useMemo(
    () =>
      Object.keys(params)
        .map(key => params[key])
        .filter(i => i),
    [params],
  );
  const helpList = useSelector(state => state.helpStore.helpList);
  const selectedFAQ = useSelector(state => state.helpStore.selectedFAQ);
  const dispatch = useDispatch();

  const initHelp = async () => {
    if (!helpList.data) {
      await dispatch(getHelp());
    }
    if (slugPath.length) {
      dispatch(setSelectedFAQ(slugPath));
      scrollToSelectedSlug(slugPath[slugPath.length - 1]);
    }
  };

  useEffect(() => {
    initHelp();
  }, [location.key]);

  const scrollToSelectedSlug = identifier => {
    const el = document.getElementById(identifier);
    el &&
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'nearest',
      });
  };

  const { loading, data } = helpList;

  return (
    <div className="help-page">
      <div className="container-inner">
        <h1 className="help-page__title f-42 f-500">{t('help_page_title')}</h1>
        {loading && !data ? (
          <Preloader className="help-page__preloader" />
        ) : data ? (
          <div className="help-page__content">
            {helpList.data.map(item => (
              <section key={item.id}>
                <SimpleDropdown
                  data={item}
                  label={() => <h2 className="f-24 f-500">{item.name}</h2>}
                  isActive
                  disableIcon
                >
                  {item.children.map(child => (
                    <SimpleDropdown
                      data={child}
                      key={child.id}
                      label={child.name}
                      isActive={selectedFAQ.includes(child.slug)}
                    >
                      {child.description && (
                        <div
                          className="help-page__description unreset"
                          dangerouslySetInnerHTML={{
                            __html: child.description,
                          }}
                        />
                      )}
                      {child.faqs.map(faq => (
                        <SimpleDropdown
                          key={faq.id}
                          data={faq}
                          label={faq.question}
                          innerContentHTML={faq.answer}
                          isActive={selectedFAQ.includes(faq.slug)}
                        />
                      ))}
                    </SimpleDropdown>
                  ))}
                  {item.faqs.map(faq => (
                    <SimpleDropdown
                      key={faq.id}
                      data={faq}
                      label={faq.question}
                      innerContentHTML={faq.answer}
                      isActive={selectedFAQ.includes(faq.slug)}
                    />
                  ))}
                </SimpleDropdown>
              </section>
            ))}
          </div>
        ) : (
          <div className="help-page__error">{t('help_page_error')}</div>
        )}
      </div>
    </div>
  );
};

export default HelpPage;
