import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoansByFundraisingID } from '@/store/actions/loanActions';
import { IncreasedIncomeWarning } from '@/components/IncreasedIncomeWarning';
import { Preloader } from '@/components/Preloader';
import { Header } from './header';
import { Startups } from './startups';
import { LoanDetails } from './details';
import { FundsRecovery } from './recovery';
import { RepaymentSchedule } from './schedule';
import { RepaymentHistory } from './history';
import { FinanceAgreements } from './agreements';
import { LoanSecurity } from './security';
import { LoanEarningsSchedule } from './earnings';
import { Filter } from './filter';
import s from './index.module.scss';

const APRIL_ROUND_ID_2024 = 94;

export const LoanInfo = ({ info }) => {
  const dispatch = useDispatch();
  const [group, setGroup] = useState();
  const { fundraising_id } = info;
  const { loading } = useSelector(
    state => state.loanStore.loansByFundraisingID,
  );

  useEffect(() => {
    dispatch(getLoansByFundraisingID(fundraising_id));
  }, [fundraising_id]);

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <Header info={info} />
        {loading ? (
          <Preloader style={{ height: '30vh' }} />
        ) : (
          <>
            <Startups fundraisingID={fundraising_id} />
            <LoanDetails fundraisingID={fundraising_id} />
            {fundraising_id >= APRIL_ROUND_ID_2024 && (
              <LoanEarningsSchedule fundraisingID={fundraising_id} />
            )}
            <IncreasedIncomeWarning currentDate={info.start_date} />
            <FundsRecovery fundraisingID={fundraising_id} />
            <Filter group={group} onClick={group => setGroup(group)} />
            <RepaymentSchedule group={group} fundraisingID={fundraising_id} />
            <RepaymentHistory group={group} fundraisingID={fundraising_id} />
            <FinanceAgreements
              group={group}
              fundraisingID={fundraising_id}
              info={info}
            />
            <LoanSecurity fundraisingID={fundraising_id} />
          </>
        )}
      </div>
    </div>
  );
};
