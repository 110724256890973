import Cookies from 'js-cookie';
import config from '../config';

const COOKIE_NAME = 'refcodes';
const COOKIE_EXPIRE_DAYS = 10000000;
const COOKIE_PARENT_DOMAIN =
  config.release === 'development' ? 'localhost' : 'scrambleup.com';
const COOKIE_SETTINGS = {
  expires: COOKIE_EXPIRE_DAYS,
  domain: COOKIE_PARENT_DOMAIN,
};

export const getAllReferralCodesFromCookie = () => {
  let codes = [];
  try {
    const data = Cookies.get(COOKIE_NAME, {
      domain: COOKIE_PARENT_DOMAIN,
    });
    if (data) {
      codes = JSON.parse(data);
    }
    return codes;
  } catch (e) {
    console.error('Could not read cookie values');
  }
  return codes;
};

export const updateCookieReferralCodes = codes => {
  try {
    const payload = JSON.stringify(codes);
    Cookies.remove(COOKIE_NAME, { domain: COOKIE_PARENT_DOMAIN });
    Cookies.set(COOKIE_NAME, payload, COOKIE_SETTINGS);
    return true;
  } catch (e) {
    console.error(e.message);
    return false;
  }
};
