import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getRepaymentScheduleByFundraisingID } from '@/store/actions/loanActions';
import RepScheduleTable from '../../RepaymentScheduleModule/table';
import s from './index.module.scss';

export const RepaymentSchedule = ({ fundraisingID, group }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('loans');
  const { repaymentScheduleByFundraisingID } = useSelector(
    state => state.loanStore,
  );

  useEffect(() => {
    dispatch(getRepaymentScheduleByFundraisingID(fundraisingID, { group }));
  }, [fundraisingID, group]);

  const { data } = repaymentScheduleByFundraisingID;

  return (
    <div className={s.wrapper}>
      <h2 className="f-26 f-500">{t('loan_info.repayment_schedule')}</h2>
      {!!data?.list.length && data?.show_extend_disclaimer && (
        <div className={classnames(s.disclaimer, 'f-16 f-400')}>
          {t('loan_info.repayment_schedule_disclaimer')}
        </div>
      )}

      {data?.list.length ? (
        <RepScheduleTable data={data.list} />
      ) : (
        <div className={classnames(s.empty_message, 'f-16 f-400')}>
          {t('loan_info.no_repayments')}
        </div>
      )}
    </div>
  );
};
