import { APP_LINKS } from '@/helpers/links';
import { LOAN_GROUPS_IDS } from '@/helpers/constants';

export const TERMS = {
  [LOAN_GROUPS_IDS.conservative.id]: {
    is_active: true,
    id: 'conservative',
    title: 'Group A',
    investor_annual_rate: '12.4%',
    term: '6 months',
    flat_fee: 'up to 1% per month',
    flat_fee_repayment: 'At the end of the term',
    downside_protection: 'Triple-secured',
    details_link: APP_LINKS.helpLoanTermsGroup('v3-group-a'),

    seniority: 'Senior',
    annual_base_return: 6.0,
    principal_payment_start_month: 7,
    info_term: '6 months',
    success_investor_fee: 4.0,
    extra_flat_fee: '+ up to 4.4%',
  },
  [LOAN_GROUPS_IDS.moderate.id]: {
    is_active: true,
    id: 'moderate',
    title: 'Group B',
    investor_annual_rate: 'Up to 25%',
    term: '6 months',
    flat_fee: '9% for 6 months',
    flat_fee_repayment: 'At the end of the term',
    downside_protection: 'Single-secured',
    details_link: APP_LINKS.helpLoanTermsGroup('v3-group-b'),

    seniority: 'Junior',
    annual_base_return: 6.0,
    principal_payment_start_month: 12,
    info_term: '6 months',
    success_investor_fee: 17.33,
    extra_flat_fee: '+ up to 49%',
  },
  [LOAN_GROUPS_IDS.growth.id]: {
    is_active: false,
    id: 'growth',
    title: 'Group C',
    investor_annual_rate: '-',
    term: '-',
    flat_fee: '-',
    flat_fee_repayment: '-',
    downside_protection: '-',
    details_link: APP_LINKS.helpLoanTermsGroup('v3-group-c'),

    seniority: '-',
    annual_base_return: 6.0,
    principal_payment_start_month: 7,
    info_term: null,
    success_investor_fee: 26.0,
    extra_flat_fee: null,
  },
};
