import { isNumber, prettyFloatMoney } from '../../../helpers/utils';

export const TAX_REPORT_CELLS = t => ({
  account_value: {
    label: t('tax_report_cell_account_value'),
    value: ({ account_value }) =>
      isNumber(account_value) ? prettyFloatMoney(account_value) : '—',
  },
  invested_funds: {
    label: t('tax_report_cell_invested_funds'),
    value: ({ invested_funds }) =>
      isNumber(invested_funds) ? prettyFloatMoney(invested_funds) : '—',
  },
  opening_balance: {
    label: t('tax_report_cell_opening_balance'),
    value: ({ opening_balance }) =>
      isNumber(opening_balance) ? prettyFloatMoney(opening_balance) : '—',
  },
  top_up: {
    label: t('tax_report_cell_top_ups'),
    value: ({ top_up }) => (isNumber(top_up) ? prettyFloatMoney(top_up) : '—'),
  },
  withdrawals: {
    label: t('tax_report_cell_withdrawals'),
    value: ({ withdrawals }) =>
      isNumber(withdrawals) ? prettyFloatMoney(withdrawals) : '—',
  },
  received_payments: {
    label: t('tax_report_cell_received_payments'),
    value: ({ received_payments }) =>
      isNumber(received_payments) ? prettyFloatMoney(received_payments) : '—',
  },
  principal_repayment: {
    label: t('tax_report_cell_principal_repayment'),
    value: ({ principal_repayment }) =>
      isNumber(principal_repayment)
        ? prettyFloatMoney(principal_repayment)
        : '—',
  },
  interest_repayment: {
    label: t('tax_report_cell_interest_repayment'),
    value: ({ interest_repayment }) =>
      isNumber(interest_repayment) ? prettyFloatMoney(interest_repayment) : '—',
  },
  delayed_interest_repayment: {
    label: t('tax_report_cell_delayed_interest_repayment'),
    value: ({ delayed_interest_repayment }) =>
      isNumber(delayed_interest_repayment)
        ? prettyFloatMoney(delayed_interest_repayment)
        : '—',
  },
  campaign_bonuses: {
    label: t('tax_report_cell_campaign_bonuses'),
    value: ({ campaign_bonuses }) =>
      isNumber(campaign_bonuses) ? prettyFloatMoney(campaign_bonuses) : '—',
  },
  rewards: {
    label: t('tax_report_cell_rewards'),
    value: ({ rewards }) =>
      isNumber(rewards) ? prettyFloatMoney(rewards) : '—',
  },
  cancelled_bonuses: {
    label: t('tax_report_cell_cancelled_bonuses'),
    value: ({ cancelled_bonuses }) =>
      isNumber(cancelled_bonuses) ? prettyFloatMoney(cancelled_bonuses) : '—',
  },
  active_investments_at_the_start_date_of_the_activity_report: {
    label: t('tax_report_cell_active_investments_at_the_start_date_of_the_activity_report'),
    value: ({ active_investments_at_the_start_date_of_the_activity_report }) =>
      isNumber(active_investments_at_the_start_date_of_the_activity_report)
        ? prettyFloatMoney(
            active_investments_at_the_start_date_of_the_activity_report,
          )
        : '—',
  },
  active_investments_at_the_end_date_of_the_activity_report: {
    label: t('tax_report_cell_active_investments_at_the_end_date_of_the_activity_report'),
    value: ({ active_investments_at_the_end_date_of_the_activity_report }) =>
      isNumber(active_investments_at_the_end_date_of_the_activity_report)
        ? prettyFloatMoney(
            active_investments_at_the_end_date_of_the_activity_report,
          )
        : '—',
  },
  closing_balance: {
    label: t('tax_report_cell_closing_balance'),
    value: ({ closing_balance }) =>
      isNumber(closing_balance) ? prettyFloatMoney(closing_balance) : '—',
  },
});
