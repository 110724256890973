import axios from '@/axios-api';
import Pathes from '@/helpers/pathes';
import { getMessage } from '@/helpers/utils';
import Notify from '@/components/Notification';
import i18n from '@/i18n';

const { t } = i18n;

// Create complaint
export const createComplaint = payload => {
  return axios
    .post(Pathes.Complaint.complaints, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 201) {
        Notify.success({ text: t('kit:notify.complaint_submitted') });
        return { data, success: true, message };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

// Delete complaint
export const removeComplaint = id => {
  return axios
    .delete(Pathes.Complaint.complaint(id))
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};

export const getParticipatingFundraisingList = () => {
  return axios
    .get(Pathes.Complaint.participating_fundraising)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message);
    })
    .catch(e => ({ success: false, message: e.message }));
};
