import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Formik } from 'formik';
import { RoundButton } from '@/components/UI/RoundButton';
import { getActivityTypesList } from '@/store/actions/activityActions';
import { InputTextField } from '@/components/UI/InputTextField';
import Checkbox from '@/components/UI/Checkbox';
import { DatePicker } from '@/kit/form/DatePicker';
import ActivityTypeSelect from './select';
import './index.scss';

const ActivityFilters = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { activityTypes } = useSelector(state => state.activityStore);
  const { t } = useTranslation('reports');

  useEffect(() => {
    dispatch(getActivityTypesList());
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        startDate: null,
        endDate: null,
        types: [],
        groupResults: true,
        loanNumber: '',
      }}
    >
      {({ values, setFieldValue, handleChange, handleSubmit }) => (
        <form className="activity_filters" onSubmit={handleSubmit}>
          <div className="activity_filters__row">
            <DatePicker
              name="startDate"
              label={t('activity_filter_start_date')}
              value={values.startDate}
              onChange={date => setFieldValue('startDate', date)}
              onReset={() => setFieldValue('startDate', null)}
              className="activity_filters__date"
            />
            <DatePicker
              name="endDate"
              label={t('activity_filter_end_date')}
              value={values.endDate}
              onChange={date => setFieldValue('endDate', date)}
              onReset={() => setFieldValue('endDate', null)}
              className="activity_filters__date"
            />
            <InputTextField
              name="loanNumber"
              label={t('activity_filter_loan_number')}
              onChange={handleChange}
              onClear={() => setFieldValue('loanNumber', '')}
              value={values.loanNumber}
              className="activity_filters__input"
            />

            <ActivityTypeSelect
              label="Hello"
              placeholder={t('activity_filter_operation_type')}
              className="activity_filters__select"
              selected={[]}
              onChange={selected => setFieldValue('types', selected)}
              options={
                activityTypes
                  ? Object.keys(activityTypes).map(key => ({
                      value: key,
                      label: activityTypes[key],
                    }))
                  : []
              }
              noOptionsMessage={() => t('activify_filter_no_options')}
            />

            <RoundButton
              label={t('activity_button_search')}
              type="submit"
              fullWidth
              onSubmit={handleSubmit}
              className="activity_filters__submit"
            />
          </div>

          <div className="activity_filters__row_bottom">
            <Checkbox
              name="groupResults"
              isChecked={values.groupResults}
              onChange={e => {
                onSubmit({
                  ...values,
                  groupResults: !values.groupResults,
                });
                handleChange(e);
              }}
              className="activity_filters__group"
              render={() => (
                <span className={classnames('f-16')}>
                  {t('activity_group_results')}
                </span>
              )}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ActivityFilters;
