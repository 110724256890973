export const AUTH_USER_DATA_KEY = 'auth_user';
export const AUTH_TEMP_TOKEN_KEY = 'auth_tk';

export const getAuthData = () => {
  const token = localStorage.getItem(AUTH_TEMP_TOKEN_KEY) || null;
  const data = JSON.parse(localStorage.getItem(AUTH_USER_DATA_KEY)) || null;
  return {
    token,
    data,
  };
};

export const saveTempToken = token => {
  localStorage.setItem(AUTH_TEMP_TOKEN_KEY, token);
};

export const saveTempUserData = data => {
  localStorage.setItem(AUTH_USER_DATA_KEY, JSON.stringify(data));
};

export const clearTempUserData = () => {
  localStorage.removeItem(AUTH_TEMP_TOKEN_KEY);
  localStorage.removeItem(AUTH_USER_DATA_KEY);
};
