import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { DropdownBase } from '@/components/Dropdown/base';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { prettyFloatMoney } from '@/helpers/utils';
import { getIncreasedIncomeStatus } from '@/store/actions/investActions';
import { BANNER_STATUSES } from '@/helpers/constants';
import s from './index.module.scss';

export const IncreasedIncomeBanner = ({ className }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { increasedIncomeStatus } = useSelector(state => state.investStore);
  const { data: payload } = increasedIncomeStatus;

  useEffect(() => {
    dispatch(getIncreasedIncomeStatus());
  }, []);

  if (!payload?.status || location.pathname === APP_LINKS.affiliate)
    return null;

  return (
    <div className={classnames(s.wrapper, className)}>
      {payload.status === BANNER_STATUSES.get_increased_income && (
        <IncreasedIncome />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_save_type_a && (
        <BenefitingSaveA data={payload} />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_save_type_b && (
        <BenefitingSaveB data={payload} />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_save_type_c && (
        <BenefitingSaveC data={payload} />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_done_type_a && (
        <BenefitingDoneA data={payload} />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_done_type_b && (
        <BenefitingDoneB data={payload} />
      )}
      {payload.status === BANNER_STATUSES.keep_benefiting_done_type_c && (
        <BenefitingDoneC data={payload} />
      )}
    </div>
  );
};

const IncreasedIncome = () => {
  const { t } = useTranslation('affiliate');
  return (
    <DropdownBase
      render={({ open, setOpen }) => (
        <div className={s.income}>
          <div className="f-16-24 f-400">
            <Trans
              ns="affiliate"
              i18nKey="income_banner.income.title"
              components={{ span: <span className="f-500" /> }}
            />{' '}
            {!open && (
              <button
                type="button"
                onClick={() => setOpen(!open)}
                className="underline"
              >
                {t('income_banner.learn_more')}
              </button>
            )}
          </div>
          <div
            className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
            onClick={() => setOpen(false)}
          >
            <div
              style={{
                paddingTop: '15px',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <div>{t('income_banner.income.increased_income_details')}</div>
              <ul className="scr-circle-list">
                <li>{t('income_banner.income.message_1')}</li>
                <li>{t('income_banner.income.message_2')}</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    />
  );
};

const BenefitingDoneA = () => {
  const { t } = useTranslation('affiliate');
  return (
    <DropdownBase
      render={({ open, setOpen }) => (
        <div className={s.income}>
          <div className="f-16-24 f-400">
            <Trans
              ns="affiliate"
              i18nKey="income_banner.ben_done_a.all_set"
              components={{ span: <span className="f-500" /> }}
            />{' '}
            {!open && (
              <button
                type="button"
                onClick={() => setOpen(!open)}
                className="underline"
              >
                {t('income_banner.learn_more')}
              </button>
            )}
          </div>
          <div
            className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
            onClick={() => setOpen(false)}
          >
            <div style={{ paddingTop: '15px', whiteSpace: 'pre-line' }}>
              {t('income_banner.ben_done_a.success_requirements')}
            </div>
          </div>
        </div>
      )}
    />
  );
};

const BenefitingDoneB = () => (
  <div className={s.benefit_save}>
    <div className="f-16-24 f-400">
      <Trans
        ns="affiliate"
        i18nKey="income_banner.ben_done_b.congratulations_balance_above"
        components={{ div: <div className="f-500" /> }}
      />
    </div>
  </div>
);

const BenefitingDoneC = ({ data }) => (
  <div className={s.benefit_save}>
    <div className="f-16-24 f-400">
      <Trans
        ns="affiliate"
        i18nKey="income_banner.ben_done_c.current_balance"
        components={{ span: <span className="f-500" /> }}
        values={{
          amount: prettyFloatMoney(data.required_investments_to_10000),
        }}
      />
    </div>
  </div>
);

const BenefitingSaveA = ({ data }) => (
  <DropdownBase
    render={({ open, setOpen }) => (
      <div className={s.benefit_save}>
        <div className="f-16-24 f-400">
          <Trans
            ns="affiliate"
            i18nKey="income_banner.ben_save_a.save_your_income"
            components={{
              span: <span className="f-500" />,
              button: (
                <button
                  type="button"
                  onClick={() => setOpen(!open)}
                  className="underline"
                />
              ),
            }}
          />
          {!!(data.required_investments || data.required_monthly_top_ups) && (
            <ul className={s.benefit_save__list}>
              {!!data.required_monthly_top_ups && (
                <li>
                  <Trans
                    ns="affiliate"
                    i18nKey="income_banner.ben_save_a.message_1"
                    values={{
                      amount: prettyFloatMoney(data.required_monthly_top_ups),
                    }}
                    components={{
                      Link: <MyLink href={APP_LINKS.addFunds} underline />,
                    }}
                  />
                </li>
              )}
              {!!data.required_investments && (
                <li>
                  <Trans
                    ns="affiliate"
                    i18nKey="income_banner.ben_save_a.message_2"
                    values={{
                      amount: prettyFloatMoney(data.required_investments),
                    }}
                    components={{
                      Link: <MyLink href={APP_LINKS.investing} underline />,
                    }}
                  />
                </li>
              )}
            </ul>
          )}
        </div>
        <div
          className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
          onClick={() => setOpen(false)}
        >
          <div
            style={{
              paddingTop: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <div>
              <Trans
                ns="affiliate"
                i18nKey="income_banner.ben_save_a.income_accrued"
              />
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

const BenefitingSaveB = ({ data }) => {
  const { t } = useTranslation('affiliate');
  return (
    <DropdownBase
      render={({ open, setOpen }) => (
        <div className={s.benefit_save}>
          <div className="f-16-24 f-400">
            <Trans
              ns="affiliate"
              i18nKey="income_banner.ben_save_b.get_income"
              components={{
                span: <span className="f-500" />,
                button: (
                  <button
                    type="button"
                    onClick={() => setOpen(!open)}
                    className="underline"
                  />
                ),
              }}
            />
            {!!(data.required_investments || data.required_monthly_top_ups) && (
              <ul className={s.benefit_save__list}>
                {!!data.required_monthly_top_ups && (
                  <li>
                    <Trans
                      ns="affiliate"
                      i18nKey="income_banner.ben_save_b.message_1"
                      values={{
                        amount: prettyFloatMoney(data.required_monthly_top_ups),
                      }}
                      components={{
                        Link: <MyLink href={APP_LINKS.addFunds} underline />,
                      }}
                    />
                  </li>
                )}
                {!!data.required_investments && (
                  <li>
                    <Trans
                      ns="affiliate"
                      i18nKey="income_banner.ben_save_b.message_2"
                      values={{
                        amount: prettyFloatMoney(data.required_investments),
                      }}
                      components={{
                        Link: <MyLink href={APP_LINKS.investing} underline />,
                      }}
                    />
                  </li>
                )}
              </ul>
            )}
          </div>
          <div
            className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
            onClick={() => setOpen(false)}
          >
            <div
              style={{
                paddingTop: '15px',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <div style={{ whiteSpace: 'pre-line' }}>
                {t('income_banner.ben_save_b.increased_income_details')}
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

const BenefitingSaveC = ({ data }) => {
  const { t } = useTranslation('affiliate');
  return (
    <DropdownBase
      render={({ open, setOpen }) => (
        <div className={s.benefit_save}>
          <div className="f-16-24 f-400">
            <Trans
              ns="affiliate"
              i18nKey="income_banner.ben_save_c.save_your_income"
              components={{
                span: <span className="f-500" />,
                button: (
                  <button
                    type="button"
                    onClick={() => setOpen(!open)}
                    className="underline"
                  />
                ),
              }}
            />
            <ul className={s.benefit_save__list}>
              {!!data.required_monthly_top_ups && (
                <li>
                  <Trans
                    ns="affiliate"
                    i18nKey="income_banner.ben_save_c.message_1"
                    values={{
                      amount: prettyFloatMoney(data.required_monthly_top_ups),
                    }}
                    components={{
                      Link: <MyLink href={APP_LINKS.addFunds} underline />,
                    }}
                  />
                </li>
              )}
              {!!data.required_investments && (
                <li>
                  <Trans
                    ns="affiliate"
                    i18nKey="income_banner.ben_save_c.message_2"
                    values={{
                      amount: prettyFloatMoney(data.required_investments),
                    }}
                    components={{
                      Link: <MyLink href={APP_LINKS.investing} underline />,
                    }}
                  />
                </li>
              )}
              {!!data.required_investments_to_10000 && (
                <>
                  <span style={{ left: '-14px', position: 'relative' }}>
                    {t('income_banner.ben_save_c.or')}
                  </span>
                  <li>
                    <Trans
                      ns="affiliate"
                      i18nKey="income_banner.ben_save_c.message_3"
                      values={{
                        amount: prettyFloatMoney(
                          data.required_investments_to_10000,
                        ),
                      }}
                      components={{
                        span: (
                          <span className="f-500" style={{ color: 'red' }} />
                        ),
                        Link: <MyLink href={APP_LINKS.investing} underline />,
                      }}
                    />
                  </li>
                </>
              )}
            </ul>
          </div>
          <div
            className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
            onClick={() => setOpen(false)}
          >
            <div>{t('income_banner.ben_save_c.income_accrued_monthly')}</div>
            <ul className="scr-circle-list">
              <li>{t('income_banner.ben_save_c.circle_message_1')}</li>
              <li>{t('income_banner.ben_save_c.circle_message_2')}</li>
            </ul>
          </div>
        </div>
      )}
    />
  );
};
