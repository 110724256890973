import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LoanGroupCard } from './card';
import { LOAN_GROUPS_V1 } from './data';
import s from './index.module.scss';

export const LoanTermsVersion01 = () => {
  const { t } = useTranslation('loan_terms');
  return (
    <div className={s.page}>
      <div className="container-inner">
        <h1 className="f-42 f-500">{t('v1.loan_terms')}</h1>
        <p className={classnames(s.page__desc, 'f-20 f-500')}>
          {t('v1.all_loan_groups_available')}
        </p>
        <div className={s.page__cards}>
          {LOAN_GROUPS_V1.map(group => (
            <LoanGroupCard
              key={group.id}
              group={group}
              className={s.page__card}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
