import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import Tooltip from '@/components/Tooltip';
import { LOAN_GROUPS_IDS } from '@/helpers/constants';
import s from './index.module.scss';

export const LoanGroupCard = ({ group, className }) => {
  const { t } = useTranslation('loan_terms');
  return (
    <div className={classnames(s.card, className)}>
      <MyLink
        href={group.details_link}
        className={classnames(s.card__title, 'hov', 'f-26 f-500')}
      >
        {group.title}
      </MyLink>
      {group.is_active ? (
        <>
          <div className={s.card__block}>
            <div className="f-18 f-500">
              <span>{t('v3.loan_card.target_annual_return')}</span>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className={classnames(s.card__tooltip, 'f-16 f-400')}>
                    {t('v3.loan_card.target_annual_return_calculations')}
                  </div>
                )}
              />
            </div>
            <p className="f-18 f-500">{group.investor_annual_rate}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">{t('v3.loan_card.term')}</div>
            <p className="f-18 f-500 tl">{group.term}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">
              {group.id === 'conservative' ? (
                <span>{t('v3.loan_card.flat_fee')}</span>
              ) : (
                <span>{t('v3.loan_card.flat_fee_for_6_months')}</span>
              )}
            </div>
            <p style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                className="f-18 f-500"
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                {group.flat_fee}{' '}
                {group.id === LOAN_GROUPS_IDS.conservative.id && (
                  <Tooltip
                    position="bottom center"
                    renderContent={() => (
                      <p
                        className={classnames(s.card__tooltip, 'f-16 f-400')}
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        <Trans
                          ns="loan_terms"
                          i18nKey="v3.loan_card.increased_income"
                          components={{
                            ul: <ul className="scr-circle-list" />,
                            li: <li />,
                            i: <i />,
                          }}
                        />
                      </p>
                    )}
                  />
                )}
              </span>
              {group.id === LOAN_GROUPS_IDS.conservative.id && (
                <span className="f-14 f-500">
                  {t('v3.loan_card.on_the_outstanding_loan')}
                </span>
              )}
            </p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">
              {t('v3.loan_card.flat_fee_repayment')}
            </div>
            <p className="f-18 f-500 tl">{group.flat_fee_repayment}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">
              {t('v3.loan_card.downside_protection')}
            </div>
            <p className="f-18 f-500 tl">{group.downside_protection}</p>
          </div>

          <MyLink
            href={group.details_link}
            className={classnames(s.card__link_details, 'f-500')}
          >
            {t('v3.loan_card.see_details')}
          </MyLink>
        </>
      ) : (
        <p className={classnames(s.card__unavailable, 'f-18 f-500')}>
          {t('v3.loan_card.temporarily_unavailable', { title: group.title })}
        </p>
      )}
    </div>
  );
};
