import { useMutation } from '@tanstack/react-query';
import { authService } from '../../services/authService';

export const useSubmitEmailOtpCode = () => {
  return useMutation({
    mutationFn: payload =>
      authService.submitEmailOtp(payload).then(response => {
        const { status, data } = response;
        if (status === 200) {
          return { success: true, data };
        }
        return { error: data?.errors?.[0] };
      }),
  });
};
