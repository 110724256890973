import MiniFaqs, { MINIFAQ_SOURCES } from '@/containers/MiniFaqs';
import LoansModule from '@/containers/LoansModule';
import './index.scss';

const LoansLoanPage = () => (
  <div className="loans_loan_page">
    <LoansModule />
    <MiniFaqs source={MINIFAQ_SOURCES.loans} />
  </div>
);

export default LoansLoanPage;
