import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getQuestionnaireStatus } from '@/store/services/questionnaireService';
import { setModals } from '@/store/actions/commonActions';
import { Preloader } from '@/components/Preloader';
import { QuestionnaireForm } from './form';
import { QuestionnaireDisclaimer } from './disclaimer';
import s from './index.module.scss';

const QuestionnaireModal = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);

  useEffect(() => {
    updateStatus();
  }, []);

  useEffect(() => {
    if (status?.status === 'assessment_completed') {
      onSuccess && onSuccess();
      dispatch(setModals([]));
    }
  }, [status]);

  const updateStatus = () => {
    getQuestionnaireStatus().then(res => res.success && setStatus(res.data));
  };

  return (
    <div className={s.wrapper}>
      {!status && <Preloader className={s.preloader} />}
      {status?.status === null && (
        <QuestionnaireForm updateStatus={updateStatus} />
      )}
      {status?.status === 'questionnaire_completed' && (
        <QuestionnaireDisclaimer onSuccess={onSuccess} />
      )}
    </div>
  );
};

export default QuestionnaireModal;
