import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { ArrowUp } from '../Icons';
import { parseWithLinks } from '../../../helpers/utils';
import './index.scss';

const SimpleDropdown = ({
  label,
  isActive,
  children,
  innerContentHTML,
  data,
  disableIcon = false,
}) => {
  const [show, setShow] = useState(!!isActive);

  const text = useMemo(
    () => parseWithLinks(innerContentHTML),
    [innerContentHTML],
  );

  const onHandleShowMenu = () => {
    setShow(!show);
  };

  return (
    <div
      className={classnames('simple-dropdown', (isActive || show) && 'active')}
    >
      <div
        className="simple-dropdown__label"
        onClick={onHandleShowMenu}
        id={data.slug || data.id}
      >
        {!disableIcon && <ArrowUp style={{ minWidth: '14px' }} />}
        {typeof label === 'function' ? (
          label()
        ) : (
          <span className="f-18">{label}</span>
        )}
      </div>
      <div className="simple-dropdown__content">
        <div className="simple-dropdown__content-inner unreset f-16">
          {innerContentHTML ? text : children}
        </div>
      </div>
    </div>
  );
};

export default SimpleDropdown;
