import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLongNext } from '../UI/Icons';
import './index.scss';

const BackButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <button type="button" onClick={onClick} className="back-button">
      <ArrowLongNext />
      <span className="f-16 f-500">{t('buttons.back')}</span>
    </button>
  );
};

export default BackButton;
