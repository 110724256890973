import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_DD_MMM } from '@/helpers/common';
import { prettyFloatMoney } from '@/helpers/utils';
import Dropdown from '@/components/Dropdown';
import { getLoanPaymentHistoryByFundraisingID } from '@/store/actions/loanActions';
import s from './index.module.scss';

export const RepaymentHistory = ({ fundraisingID, group }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('loans');
  const { loanPaymentHistoryByFundraisingID } = useSelector(
    state => state.loanStore,
  );

  // TODO add pagination
  useEffect(() => {
    dispatch(
      getLoanPaymentHistoryByFundraisingID(fundraisingID, {
        page: 1,
        limit: 5000,
        group,
      }),
    );
  }, [fundraisingID, group]);

  const { data } = loanPaymentHistoryByFundraisingID;

  return (
    <div className={s.wrapper}>
      <Dropdown
        label={
          <h2 className="f-26 f-500">{t('loan_info.repayment_history')}</h2>
        }
      >
        {data?.list.length ? (
          <div className={s.table_wrapper}>
            <div className={s.table}>
              <div className={s.thead}>
                <div className={s.thead_col}>{t('loan_info.date')}</div>
                <div className={s.thead_col}>{t('loan_info.amount')}</div>
                <div className={s.thead_col}>{t('loan_info.status')}</div>
              </div>
              <div className={s.tbody}>
                {data.list.map((item, idx) => (
                  <div className={s.tbody_row} key={idx}>
                    <div className={s.tbody_col}>
                      {moment(item.created_at)
                        .format(DATE_FORMAT_DD_MMM)
                        .replace('.', '')}
                    </div>
                    <div className={s.tbody_col}>
                      {prettyFloatMoney(item.amount)}
                    </div>
                    <div className={classnames(s.tbody_col, s.description)}>
                      {item.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={classnames(s.empty_message, 'f-16')}>
            {t('loan_info.no_repayments_yet')}
          </div>
        )}
      </Dropdown>
    </div>
  );
};
