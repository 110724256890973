import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ProfileAvatarForm from '@/components/Forms/ProfileAvatarForm';
import { getFullname, getNameInitials } from '@/helpers/utils';
import { updateProfilePhoto } from '@/store/services/userServices';
import { getUserProfile } from '@/store/actions/profileActions';
import { cropAvatar } from '@/components/AvatarEdit';
import { uploadPhoto } from '@/store/services/commonServices';

const ProfileAvatarModal = ({ onClose }) => {
  const user = useSelector(state => state.profileStore.profile.data);
  const dispatch = useDispatch();
  const { t } = useTranslation('modal');

  const onSubmit = async (data, _, editorRef) => {
    try {
      if (data.file && editorRef) {
        const croppedAvatar = cropAvatar(editorRef);
        const response = await uploadPhoto(croppedAvatar);
        if (response && response.success) {
          const res = await updateProfilePhoto(response.data.id);
          if (res && res.success) {
            dispatch(getUserProfile());
            onClose();
          }
        }
      }
    } catch (e) {}
  };

  const onRemove = async () => {
    try {
      const res = await updateProfilePhoto(null);
      if (res && res.success) {
        dispatch(getUserProfile());
        onClose();
      }
    } catch (e) {}
  };

  if (!user) {
    return <div style={{ padding: '70px 50px' }}>{t('load_image_error')}</div>;
  }

  return (
    <ProfileAvatarForm
      photo={user.photo}
      fullname={getFullname(user.first_name, user.last_name)}
      nameInitials={getNameInitials(user.first_name, user.last_name)}
      onSubmit={onSubmit}
      onRemove={onRemove}
    />
  );
};

export default ProfileAvatarModal;
