import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Avatar } from '@/components/UI/Avatar';
import {
  getFullname,
  getNameInitials,
  prettyFloatMoney,
  shortenDigit,
} from '@/helpers/utils';
import { RoundButton } from '@/components/UI/RoundButton';
import { setModals } from '@/store/actions/commonActions';
import { MODAL_TYPES } from '../../ModalProvider';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import './index.scss';

const StartupCard = ({
  founders,
  company,
  fundraisingStatus,
  onSelect,
  isSelected,
  founderRedirect,
  received,
  active,
  className,
}) => {
  const innerWidth = useSelector(state => state.commonStore.innerWidth);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onCompanyClick = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.company_info,
        companyID: company.id,
      }),
    );
  };

  return (
    <div className={classnames('startup-card', className)}>
      <div className="startup-card__header dfc">
        <div className="startup-card__header-left" onClick={onCompanyClick}>
          <div className="startup-card__header-title f-18 underline">
            {company.name}
          </div>
          <div className="startup-card__header-left-fundraising">
            {received && (
              <div className="startup-card__header-received dfc f-12 f-400">
                <span style={{ color: '#00000040' }}>{t('startup_card.received')}:&nbsp;</span>
                <span>{prettyFloatMoney(received)}</span>
              </div>
            )}
            {fundraisingStatus && (
              <div className="startup-card__header-goal f-12 f-400">
                <span className="c-grey">{t('startup_card.goal')}:&nbsp;</span>
                <span>{shortenDigit(fundraisingStatus.goal)}</span>
              </div>
            )}
          </div>
        </div>
        {onSelect ? (
          <div className="startup-card__header-right">
            <RoundButton
              label={isSelected ? t('startup_card.selected') : t('startup_card.select')}
              type="button"
              fullWidth
              fillBackground={isSelected}
              onClick={onSelect}
              className="startup-card__select"
            />
          </div>
        ) : (
          <div
            className="startup-card__header-right-button f-16 f-500 hov"
            onClick={onCompanyClick}
          >
            {t('startup_card.learn_more')}
          </div>
        )}
      </div>
      <div className="startup-card__content">
        {founders?.map(founder => (
          <MyLink
            key={founder.id}
            href={founderRedirect || `${APP_LINKS.founderDetail(founder.id)}`}
            className={classnames('startup-card__founder')}
          >
            <div className="startup-card__founder-inner">
              <Avatar
                src={founder.photo && founder.photo.file}
                nameInitials={getNameInitials(
                  founder.first_name,
                  founder.last_name,
                )}
                alt={founder.first_name}
                className="startup-card__founder-avatar"
                active={active}
                size={innerWidth < 992 ? 80 : 122}
              />
              <div className="startup-card__founder-content">
                <h6 className="startup-card__founder-name f-18 f-500 tl">
                  {getFullname(founder.first_name, founder.last_name)}
                </h6>
                <div className="c-grey f-14 f-400 tl">
                  {founders.length === 1 ? t('startup_card.founder') : t('startup_card.co_founder')}
                </div>
                {founder.themes && !!founder.themes.length && (
                  <ul className="startup-card__founder-themes scr-circle-list f-14 f-400">
                    {founder.themes.map(theme => (
                      <li key={theme.id} className="tl">
                        {theme.title}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </MyLink>
        ))}
      </div>
    </div>
  );
};

export default StartupCard;
