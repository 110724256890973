const ENVIRONMENT = import.meta.env.VITE_ENV || 'production';
const RELEASE = import.meta.env.VITE_RELEASE || 'production';
const DEV_API_URL =
  import.meta.env.VITE_DEV_API_URL || 'http://localhost:8000';
const FACEBOOK_LINK = import.meta.env.VITE_FACEBOOK_LINK || '';
const LINKEDIN_LINK = import.meta.env.VITE_LINKEDIN_LINK || '';
const INSTAGRAM_LINK = import.meta.env.VITE_INSTAGRAM_LINK || '';
const API_URL = import.meta.env.VITE_API_URL || '';
const FACEBOOK_APP_ID = import.meta.env.VITE_FACEBOOK_APP_ID || '';
const GOOGLE_APP_ID = import.meta.env.VITE_GOOGLE_APP_ID || '';
const GOOGLE_RECAPTCHA_SITE_KEY_V2 =
  import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY_V2 || '';
const LINKEDIN_APP_ID = import.meta.env.VITE_LINKEDIN_APP_ID || '';
const APPLE_APP_ID = import.meta.env.VITE_APPLE_APP_ID || '';
const VERIFF_API_KEY = import.meta.env.VITE_VERIFF_PUBLISHABLE_KEY || '';
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || '';
const MAIN_PROMO_DOMAIN = import.meta.env.VITE_MAIN_PROMO_DOMAIN || '';
const INVESTOR_PROMO_DOMAIN = import.meta.env.VITE_INVESTOR_PROMO_DOMAIN || '';
const FOUNDER_PROMO_DOMAIN = import.meta.env.VITE_FOUNDER_PROMO_DOMAIN || '';
const LANDINGS_PROMO_DOMAIN = import.meta.env.VITE_LANDINGS_PROMO_DOMAIN || '';
const BLOG_DOMAIN = import.meta.env.VITE_BLOG_DOMAIN || '';
const VERIFF_HOST_URL = 'https://stationapi.veriff.com';
const PREFIX = '/api/';

const getApiURL = () => (ENVIRONMENT === 'development' ? DEV_API_URL : API_URL);

const config = {
  mainPromoDomain: MAIN_PROMO_DOMAIN,
  investorPromoDomain: INVESTOR_PROMO_DOMAIN,
  founderPromoDomain: FOUNDER_PROMO_DOMAIN,
  landingsPromoDomain: LANDINGS_PROMO_DOMAIN,
  blogDomain: BLOG_DOMAIN,
  facebookLink: FACEBOOK_LINK,
  linkedinLink: LINKEDIN_LINK,
  instagramLink: INSTAGRAM_LINK,
  baseURL: window.location.origin.toString(),
  apiURL: getApiURL() + PREFIX,
  facebookAppId: FACEBOOK_APP_ID,
  googleAppId: GOOGLE_APP_ID,
  linkedinId: LINKEDIN_APP_ID,
  appleAppId: APPLE_APP_ID,
  veriffApiKey: VERIFF_API_KEY,
  veriffHostURL: VERIFF_HOST_URL,
  googleReCaptchaSiteKeyV2: GOOGLE_RECAPTCHA_SITE_KEY_V2,
  sentryDSN: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: RELEASE,
};

export default config;
