import { useTranslation } from 'react-i18next';
import { TAX_REPORT_CELLS } from './cells';
import { CURRENCY } from '@/helpers/constants';
import s from './index.module.scss';

export const Table = ({ data }) => {
  const { t } = useTranslation('reports');

  const taxReportCells = TAX_REPORT_CELLS(t);
  const list = Object.keys(data).map(key => {
    return {
      label: taxReportCells[key].label,
      value: taxReportCells[key].value(data),
    };
  });

  return (
    <div className={s.activity_module__table_wrap}>
      <div className={s.activity_module__table_inner}>
        <div className={s.activity_module__table}>
          <div className={s.activity_module__thead}>
            <div className={s.activity_module__thead_col}>
              {t('tax_report_activity')}
            </div>
            <div className={s.activity_module__thead_col}>
              {t('tax_report_amount_with_currency', { currency: CURRENCY })}
            </div>
          </div>

          <div className={s.activity_module__tbody}>
            {list.map((item, index) => (
              <div
                className={s.activity_module__tbody_row}
                key={item.id || index}
              >
                <div
                  className={s.activity_module__tbody_col}
                  style={
                    [
                      t('tax_report_cell_principal_repayment'),
                      t('tax_report_cell_interest_repayment'),
                      t('tax_report_cell_delayed_interest_repayment'),
                      t('tax_report_cell_campaign_bonuses'),
                      t('tax_report_cell_rewards'),
                    ].includes(item.label)
                      ? {
                          fontStyle: 'italic',
                          paddingLeft: '30px',
                        }
                      : {}
                  }
                >
                  {item.label}
                </div>
                <div className={s.activity_module__tbody_col}>{item.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
