import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Formik } from 'formik';
import moment from 'moment/min/moment-with-locales';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { getLastRoute, removeLastRoute } from '@/helpers/utils';
import { CONTACT_EMAIL } from '@/helpers/constants';
import { APP_LINKS } from '@/helpers/links';
import { RoundButton } from '@/components/UI/RoundButton';
import { CountdownHour } from '@/components/CountdownHour';
import { OtpCodeInput } from '@/components/OtpCodeInput';
import { MyLink } from '@/components/MyLink';
import { submitLoginCode } from '@/store/actions/userActions';
import {
  getLoginCodeStatus,
  resendLoginCode,
} from '@/store/services/userServices';
import s from './index.module.scss';

const VALIDATION_SCHEMA = Yup.object({
  code: Yup.array(),
});

const SMS_LIMIT = 3;

export const StepPhoneOtp = () => {
  const mounted = useRef(true);
  const { t } = useTranslation('auth');
  const { token } = useSelector(state => state.userStore);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expireTime, setExpireTime] = useState(null);
  const [status, setStatus] = useState({
    count: 0,
    last_created_at: null,
    phone_number: '',
    next_channel: '',
  });

  useEffect(() => {
    if (token) {
      redirect();
    } else {
      getStatus();
    }
  }, [token]);

  useEffect(() => {
    if (status.last_created_at) {
      const currentTime = moment();
      const expireTime = moment(status.last_created_at).add(
        status.count >= SMS_LIMIT ? 10 : 1,
        'minutes',
      );

      mounted.current && currentTime.isBefore(expireTime)
        ? setExpireTime(expireTime.toISOString())
        : setExpireTime(null);
    } else {
      mounted.current && setExpireTime(null);
    }
  }, [status]);

  useEffect(() => {
    () => {
      mounted.current = false;
    };
  }, []);

  const getStatus = async () => {
    const res = await getLoginCodeStatus();
    res && res.success && mounted.current && setStatus(res.data);
  };

  const resendCode = async () => {
    const res = await resendLoginCode();
    if (res && res.success) {
      mounted.current && setStatus({ ...status, ...res.data.data });
    }
  };

  const onCounterFinish = () => {
    mounted.current && setExpireTime(null);
  };

  const onSubmit = async ({ code }, { setErrors }) => {
    const res = await dispatch(submitLoginCode({ code: code.join('') }));
    res?.success && redirect();

    if (res?.error) {
      setErrors({ code: res.error });
    }
  };

  const redirect = () => {
    const lastRoute = getLastRoute();
    navigate(lastRoute || APP_LINKS.dashboard);
    removeLastRoute();
  };

  const canResend = !(status.count >= SMS_LIMIT && !expireTime);
  const lastFourNumbers = status.phone_number.substring(
    status.phone_number.length - 4,
  );

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        code: [],
      }}
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => (
        <form className={classnames(s.wrapper)} onSubmit={handleSubmit}>
          <h1 className={classnames(s.title, 'f-500')}>
            {t('step_phone_otp.two_step_authentication')}
          </h1>

          {status.next_channel === 'whatsapp' && (
            <div className={classnames(s.desc, 'f-18-24 f-400')}>
              <Trans
                ns="auth"
                i18nKey="step_phone_otp.enter_verification_code_whatsapp"
                components={{
                  span: <span className="f-500" />,
                }}
                values={{ lastFourNumbers }}
              />
            </div>
          )}

          {status.next_channel === 'call' && (
            <div className={classnames(s.desc, 'f-18-24 f-400')}>
              <Trans
                ns="auth"
                i18nKey="step_phone_otp.enter_verification_code_call"
                components={{ span: <span className="f-500" /> }}
                values={{ lastFourNumbers }}
              />
            </div>
          )}

          {status.next_channel === 'sms' && (
            <div className={classnames(s.desc, 'f-18-24 f-400')}>
              <Trans
                ns="auth"
                i18nKey="step_phone_otp.enter_verification_code_sms"
                components={{ span: <span className="f-500" /> }}
                values={{ lastFourNumbers }}
              />
            </div>
          )}

          {canResend && (
            <div
              className={classnames(
                s.timer,
                status.next_channel,
                'f-18-24 f-400',
              )}
            >
              {status.count < SMS_LIMIT ? (
                <>
                  {t('step_phone_otp.did_not_receive_code')}{' '}
                  {expireTime ? (
                    <CountdownHour
                      endTime={expireTime}
                      onFinish={onCounterFinish}
                      label={time =>
                        t('step_phone_otp.resend_code_in', { time })
                      }
                      className={s.countdown}
                    />
                  ) : (
                    status.count < SMS_LIMIT && (
                      <button
                        type="button"
                        onClick={async () => {
                          await resendCode();
                          setFieldValue('code', []);
                        }}
                        className={classnames(s.button_resend, 'underline')}
                      >
                        {status.next_channel === 'whatsapp' && (
                          <span>{t('step_phone_otp.resend_to_whatsapp')}</span>
                        )}
                        {status.next_channel === 'call' && (
                          <span>{t('step_phone_otp.make_a_call')}</span>
                        )}
                        {status.next_channel === '' && (
                          <span>{t('step_phone_otp.resend')}</span>
                        )}
                      </button>
                    )
                  )}
                </>
              ) : (
                expireTime && (
                  <CountdownHour
                    endTime={expireTime}
                    onFinish={onCounterFinish}
                    label={time =>
                      t('step_phone_otp.code_expires_in', { time })
                    }
                    className={s.countdown}
                  />
                )
              )}
            </div>
          )}

          {status.count >= SMS_LIMIT && (
            <div className="f-18-24 f-400" style={{ marginTop: '8px' }}>
              <Trans
                ns="auth"
                i18nKey="step_phone_otp.technical_problems_contact_support"
                values={{ email: CONTACT_EMAIL }}
                components={{
                  link: <MyLink href={APP_LINKS.email} isExternal underline />,
                }}
              />
            </div>
          )}

          <OtpCodeInput
            values={values.code}
            className={s.input}
            error={errors.code && touched.code}
            onChange={value => setFieldValue('code', value.split(''))}
            onComplete={() => {
              handleSubmit();
            }}
          />

          {errors.code && touched.code && (
            <div className={classnames(s.error_message, 'f-16-24 f-400')}>
              {errors.code}
            </div>
          )}

          <div
            className={classnames(
              s.buttons,
              location.pathname === APP_LINKS.dashboard && s.buttons_row,
            )}
          >
            <RoundButton
              label={t('step_phone_otp.verify')}
              type="submit"
              onSubmit={handleSubmit}
              fillBackground
              fullWidth
              withArrow
              className={s.button_submit}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
