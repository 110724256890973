import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { BrandSlider } from './slider';
import s from './index.module.scss';

export const RoundDetailBrands = ({ brands }) => {
  const { t } = useTranslation('round_history');

  return (
    <div className={s.wrapper}>
      <div>{t('brands')}</div>
      {brands?.list.length ? (
        <BrandSlider brands={brands?.list || []} />
      ) : (
        <div className={classnames(s.empty, 'f-16-24')}>
          {t('no_brands_yet')}
        </div>
      )}
    </div>
  );
};
