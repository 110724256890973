import React from 'react';
import classnames from 'classnames';
import './index.scss';

const Checkbox = ({
  name,
  className,
  isChecked,
  render,
  error,
  onChange,
  disabled,
}) => (
  <label
    className={classnames(
      'checkbox',
      disabled && 'disabled',
      isChecked && 'checked',
      error && 'error',
      className,
    )}
    htmlFor={name}
  >
    {render && render()}
    <input
      id={name}
      name={name}
      type="checkbox"
      className="checkbox__input"
      disabled={disabled}
      checked={isChecked}
      onChange={onChange}
    />
    <span className="checkbox__checkmark" />
  </label>
);

export default Checkbox;
