import { useTranslation } from 'react-i18next';
import s from './index.module.scss';

const SepaInfoModal = () => {
  const { t } = useTranslation('modal');
  return (
    <div className={s.wrapper}>
      <h2 className="f-26-32 f-500" style={{ paddingRight: '20px' }}>
        {t('sepa_info_modal.what_is_sepa')}
      </h2>
      <p className="f-16-24" style={{ marginTop: '20px' }}>
        {t('sepa_info_modal.sepa_description')}
      </p>
    </div>
  );
};

export default SepaInfoModal;
