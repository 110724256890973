import { useMutation } from '@tanstack/react-query';
import { authService } from '../../services/authService';

export const useUserAuth = () => {
  return useMutation({
    mutationFn: payload =>
      authService.auth(payload).then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
      }),
  });
};
