import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getHappyFoundersLatestFR } from '@/store/services/investingServices';
import { StartupVerticalCard } from '@/components/Cards/StartupVerticalCard';
import IconHeart from '../../../assets/icons/icon_heart.svg?react';

const InvHappyStartups = () => {
  const { t } = useTranslation('investing');
  const [startups, setStartups] = useState(null);

  useEffect(() => {
    getHappyFoundersLatestFR({ page: 1, limit: 50 }).then(
      res => res && res.success && setStartups(res.data),
    );
  }, []);

  const isEmpty = !startups || !(startups && startups.list.length);

  return (
    <div className="inv_happy_startups">
      <h2 className="inv_happy_startups__title f-26 f-500">
        <span>
          {t('ir_thank_you_for_helping_these_brands_grow_their_future')}
        </span>
        <IconHeart />
      </h2>
      {isEmpty ? (
        <div className="inv_happy_startups__empty f-16 f-400">
          {t('no_brands')}
        </div>
      ) : (
        <div className="inv_happy_startups__list">
          {startups.list.map(startup => (
            <StartupVerticalCard key={startup.id} startup={startup} />
          ))}
        </div>
      )}
    </div>
  );
};

export default InvHappyStartups;
