import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { isVerifiedByAdmin } from '@/helpers/user';
import { APP_LINKS } from '@/helpers/links';
import { MyLink } from '@/components/MyLink';
import Icon from './assets/icon_wallet.svg';
import IconClose from './assets/icon_close.svg';
import s from './index.module.scss';

const LS_DISABLE_TOPUP_BANNER_KEY = 'topup_banner_disabled';

export const NewInvestorTopUpFeeBanner = ({ className }) => {
  const { t } = useTranslation('kit');
  const [show, setShow] = useState(true);
  const location = useLocation();
  const { data: user } = useSelector(state => state.profileStore.profile);
  const { data: status } = useSelector(
    state => state.investStore.investingStatus,
  );

  const onClose = () => {
    setShow(false);
    localStorage.setItem(LS_DISABLE_TOPUP_BANNER_KEY, '1');
  };

  const canView = isVerifiedByAdmin(user) && !status?.has_previously_invested;
  const disabledByUser = !!localStorage.getItem(LS_DISABLE_TOPUP_BANNER_KEY);

  if (
    !canView ||
    !show ||
    (disabledByUser && location.pathname === APP_LINKS.dashboard)
  )
    return null;

  return (
    <div className={classnames(s.wrapper, className)}>
      <div className={s.circle}>
        <img src={Icon} alt="icon" width={24} height={24} />
      </div>
      <div className={s.content}>
        <div className="f-20">
          {t('topup_fee_banner.no_fees_for_new_investors')}
        </div>
        <div className="f-16-24">
          {t('topup_fee_banner.top_up_your_account')}
        </div>
        {location.pathname !== APP_LINKS.addFunds && (
          <MyLink className="f-20" href={APP_LINKS.addFunds} underline>
            {t('topup_fee_banner.top_up_now')}
          </MyLink>
        )}
      </div>
      {location.pathname === APP_LINKS.dashboard && (
        <button type="button" className={s.close_btn} onClick={onClose}>
          <img src={IconClose} alt="icon" width={16} height={16} />
        </button>
      )}
    </div>
  );
};
