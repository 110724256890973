import { addMethod, string } from 'yup';
import { isStrictEmail, isStrongPassword } from './helpers/validation';

export function initYup() {
  // Strong Password Method
  addMethod(string, 'strongPassword', function (message) {
    return this.test('strong-password', message, value =>
      value != null ? isStrongPassword(value) : true,
    );
  });

  // Strict Email Method
  addMethod(string, 'strictEmail', function (message) {
    return this.test('strict-email', message, value => {
      if (!value) return true; // Allow undefined or null values
      return isStrictEmail(value);
    });
  });
}
