import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isExpiredResetDate, resetInvestingExpireDate } from '@/helpers/date';
import { useGetAllocationStatus } from '@/hooks/useGetAllocationStatus';
import { setModals } from '@/store/actions/commonActions';
import { MODAL_TYPES } from '@/components/ModalProvider';
import s from './index.module.scss';

export const ResetInvesting = ({ status, className, showAlways, onReset }) => {
  const { t } = useTranslation('investing');
  const { allocatedGroupA, allocatedGroupB } = useGetAllocationStatus();
  const dispatch = useDispatch();

  if (!status) return null;

  if (status.editable_until && isExpiredResetDate(status.editable_until))
    return null;

  if (!allocatedGroupA && !allocatedGroupB && !showAlways) return null;

  const showDate = status.has_accepted_necessary_terms;

  const handleReset = () => {
    status.step >= 3
      ? dispatch(
          setModals({
            type: MODAL_TYPES.confirm_modal,
            confirmLabel: t('reset'),
            onConfirm: () => {
              onReset();
              dispatch(setModals([]));
            },
            onCancel: () => dispatch(setModals([])),
            title: t('confirm_reset_title'),
            desc: t('confirm_reset_desc'),
          }),
        )
      : onReset();
  };

  return (
    <div className={classnames(s.wrapper, className)}>
      <div>
        <h2 className="f-26 f-500">{t('changed_your_mind')}</h2>
        <p className="f-16 f-400">
          {showDate
            ? t('decide_not_to_participate_with_date', {
                date: resetInvestingExpireDate(
                  status.editable_until,
                  status.end_date,
                ),
              })
            : t('decide_not_to_participate')}
        </p>
      </div>
      <button type="button" className="f-16 f-400 hov" onClick={handleReset}>
        {t('reset_investing')}
      </button>
    </div>
  );
};
