import { useRef } from 'react';
import { useOutsideClick } from '@/hooks/useOutsideClick';

export const OuterWrapper = ({ disabled, onClose, children }) => {
  const ref = useRef(null);
  useOutsideClick(ref, onClose, !disabled);
  return (
    <div className="modals-wrap__content" ref={ref}>
      {children}
    </div>
  );
};
