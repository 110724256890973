import { useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import s from './index.module.scss';

const BonusInviteFriendsModal = () => {
  const { t } = useTranslation('modal');
  return (
    <div className={s.modal}>
      <div className={s.content}>
        <div className="f-22 f-500" style={{ paddingRight: '37px' }}>
          {t('bonus_invite_modal.exclusive_promotion')}
        </div>

        <div>
          <div className="f-16-24 f-500">
            {t('bonus_invite_modal.how_it_works')}
          </div>
          <ul className={s.list}>
            <li>
              {t('bonus_invite_modal.standard_referral_reward')}{' '}
              <MyLink
                isExternal
                href={APP_LINKS.docAffiliateProgramScramble2024}
                underline
              >
                {t('bonus_invite_modal.learn_more')}
              </MyLink>
            </li>
            <li>
              {t('bonus_invite_modal.exclusive_july_bonus')}{' '}
              <MyLink
                isExternal
                href={APP_LINKS.docAffiliateProgramAdditional5Scramble2024}
                underline
              >
                {t('bonus_invite_modal.learn_more')}
              </MyLink>
            </li>
            <li>
              {t('bonus_invite_modal.friends_bonus')}{' '}
              <MyLink
                isExternal
                href={APP_LINKS.docScrambleFiveEuroProgram}
                underline
              >
                {t('bonus_invite_modal.learn_more')}
              </MyLink>
            </li>
          </ul>
        </div>
        <div className="f-16-24">{t('bonus_invite_modal.total_reward')}</div>
      </div>
    </div>
  );
};

export default BonusInviteFriendsModal;
