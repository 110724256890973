import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/min/moment-with-locales';
import * as qs from 'qs';
import { generateYearOptions } from '@/containers/TaxReportModule/utils';
import { DATE_REQUEST } from '@/helpers/common';
import axios from '../../../axios-api';
import { KeySelectField } from '../../../kit/form/select/KeySelect';
import IconDownload from '../../../assets/icons/icon_download_20.svg?react';
import s from './index.module.scss';

export const Filter = ({ year, onChange }) => {
  const { t } = useTranslation('reports');
  const [downloading, setDownloading] = useState(false);
  const options = useRef(generateYearOptions());

  const onDownload = async () => {
    window.scrollTo(0, 0);
    setDownloading(true);
    const datetime = moment().format(DATE_REQUEST);
    const downloadUrl = `activities/investors/tax_report/download/${
      year
        ? `?${qs.stringify(
            { year },
            {
              strictNullHandling: true,
              arrayFormat: 'comma',
              skipNulls: true,
            },
          )}`
        : ''
    }`;

    axios
      .get(downloadUrl)
      .then(res => {
        const options = {
          filename: `Scramble Tax Report - ${year}.pdf`,
        };
        html2pdf().set(options).from(res.data).save();
      })
      .finally(() => setTimeout(() => setDownloading(false), 1200));
  };

  return (
    <div className={s.filters}>
      <KeySelectField
        name="year"
        value={year}
        options={options.current}
        placeholder={t('tax_report_filter_year')}
        className={s.select}
        onChange={val => onChange(val)}
      />

      {year && (
        <button type="button" onClick={onDownload} className={s.download}>
          <span>
            {downloading ? (
              t('tax_report_downloading')
            ) : (
              <span
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                {t('tax_report_download_pdf')}
                <IconDownload style={{ minWidth: '20px' }} />
              </span>
            )}
          </span>
        </button>
      )}
    </div>
  );
};
