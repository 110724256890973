import classnames from 'classnames';
import { LOAN_GROUPS_IDS } from '@/helpers/constants';
import Tooltip from '@/components/Tooltip';
import Dropdown from '@/components/Dropdown';

import A_REGULAR_REPAYMENT from './assets/a_regular_repayment_scenario.svg';
import A_LAST_MONTH from './assets/a_last_month.svg';
import A_OPTION_1 from './assets/a_option_1.svg';
import A_OPTION_2 from './assets/a_option_2.svg';
import A_OPTION_3 from './assets/a_option_3.svg';
import B_REGULAR_REPAYMENT from './assets/b_regular_scenario.svg';
import B_OPTION_1 from './assets/b_option_1.svg';
import B_OPTION_2 from './assets/b_option_2.svg';

import B_OPTION_3 from './assets/b_option_3.svg';
import {
  EXAMPLE_A_DATA_12,
  EXAMPLE_A_DATA_18,
  EXAMPLE_A_DATA_24,
  EXAMPLE_B_DATA_12,
  EXAMPLE_B_DATA_18,
  EXAMPLE_B_DATA_24,
  GroupAExample,
  GroupBExample,
} from './tableData';
import { ExampleList } from './ExampleList';
import { ExampleTable } from './ExampleTable';
import s from './index.module.scss';

export const GROUP_DETAIL = t => ({
  [LOAN_GROUPS_IDS.conservative.id]: (data, innerWidth) => ({
    title: data ? data.conservative.title : t('v2.detail.group_a'),
    termsSection: (
      <section className={s.page__terms}>
        <h2 className="f-26-32 f-500">{t('v2.detail.loan_terms')}</h2>
        <div className={s.page__first_row}>
          <div className={s.page__annual_return}>
            <div className={s.page__annual_return_header}>
              <div
                className={classnames(
                  s.page__annual_return_title,
                  'f-16-24 f-400',
                )}
              >
                {t('v2.detail.target_annual_return')}
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className={classnames(s.tooltip, 'f-16 f-400')}>
                    {t('v2.detail.target_annual_return_calculations')}
                  </div>
                )}
              />
            </div>
            <div
              className={classnames(
                s.page__annual_return_value,
                'f-28-32 f-500',
              )}
            >
              {data ? data.conservative.investor_annual_rate : '-'}
            </div>
          </div>
        </div>
        <div className={s.page__second_row}>
          <div
            className={classnames(
              s.page__nominal_return_title,
              'f-16-24 f-400',
            )}
          >
            {t('v2.detail.nominal_return')}
          </div>
          <div className={s.page__nominal_return}>
            <div className={s.page__nominal_return_item}>
              <div
                className={classnames(
                  s.page__nominal_return_item_desc,
                  'f-16-24 f-400',
                )}
              >
                {t('v2.detail.standard_6_month_term')}
              </div>
              <div className={s.page__nominal_return_item_circle}>
                <div className="f-28-32 f-500">
                  {t('v2.detail.five_percent_flat_fee')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.paid_immediately_on_loan_disbursement')}
                </div>
              </div>
            </div>
            <div className={s.page__nominal_return_item}>
              <div
                className={classnames(
                  s.page__nominal_return_item_desc,
                  'f-16-24 f-400',
                )}
              >
                {t('v2.detail.if_funds_used_beyond_6_months')}
              </div>
              <div className={s.page__nominal_return_item_circle}>
                <div className="f-28-32 f-500">
                  {t('v2.detail.up_to_4_4_percent_extra_flat_fee')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.based_on_loan_repayment_timing')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    ),
    fundsUsageSection: (
      <section className={s.page__usage}>
        <h2 className={classnames(s.page__usage_title, 'f-26-32 f-500')}>
          {t('v2.detail.brands_selection_and_funds_usage')}
        </h2>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          {t('v2.detail.loans_issued_to_pre_selected_brands')}
        </p>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          {t('v2.detail.funds_used_for_working_capital')}
        </p>
      </section>
    ),
    fundsProtectionSection: (
      <section className={s.page__protection}>
        <h2 className="f-26-32 f-500">
          {t('v2.detail.investor_funds_protection')}
        </h2>
        <p className="f-16-24 f-400">
          {t('v2.detail.loans_of_group_a_senior_loans')}
        </p>

        <div className={s.page__protection_content}>
          {[
            {
              label: t('v2.detail.fee_payment_upfront'),
              content: (
                <div className="f-16-24 f-400">
                  {t('v2.detail.investors_start_receiving_repayments')}
                </div>
              ),
            },
            {
              label: t('v2.detail.co_founder_team_guarantees'),
              content: (
                <div className="f-16-24 f-400">
                  {t('v2.detail.each_co_founder_guarantees')}
                </div>
              ),
            },
            {
              label: t('v2.detail.first_loss_capital'),
              content: (
                <div className="f-16-24 f-400">
                  {t('v2.detail.investors_in_loans_b_contribute')}
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`0${idx + 1}`}
              disableToggle={innerWidth > 992}
              defaultShow
            >
              <div className={s.page__protection_dropdown}>{item.content}</div>
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className={s.page__repayments}>
        <h2 className="f-26-32 f-500">{t('v2.detail.loan_repayments')}</h2>
        <p className="f-16-24 f-400">
          {t('v2.detail.monthly_loan_repayments_transferred')}
        </p>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18-24 f-500">
            {t('v2.detail.regular_repayment_scenario')}
          </h4>
          <img
            src={A_REGULAR_REPAYMENT}
            alt={t('v2.detail.group_a')}
            style={{
              maxWidth: '500px',
              margin: '40px auto',
              display: 'block',
              width: '100%',
            }}
            className={s.page__repayments_image}
          />
          <ul className={s.page__repayments_list}>
            <li>
              <div className="f-16-24 f-500">
                {t('v2.detail.at_the_disbursement_moment')}
              </div>
              <div className="f-16-24 f-400">
                {t('v2.detail.right_at_the_end_of_the_round')}
              </div>
            </li>
            <li>
              <div className="f-16-24 f-500">
                {t('v2.detail.from_the_end_of_the_1st_month')}
              </div>
              <div className="f-16-24 f-400">
                {t('v2.detail.during_the_first_5_months')}
              </div>
            </li>
            <li>
              <div className="f-16-24 f-500">
                {t('v2.detail.at_the_end_of_the_6th_month')}
              </div>
              <div className="f-16-24 f-400">
                {t('v2.detail.at_the_end_of_the_6th_month_you_get')}
              </div>
            </li>
          </ul>

          <Dropdown
            label={
              <span className="f-18 f-500">
                {t('v2.detail.a_regular_repayment_schedule_example')}
              </span>
            }
            className={s.page__example}
            defaultShow
            disableToggle
          >
            <>
              <div className={s.page__example_summary}>
                <div className={s.page__example_summary_left}>
                  <div className="f-16">
                    <div>{t('v2.detail.loan_amount')}</div>
                    <div className="f-500">
                      <b>€1’000</b>
                    </div>
                  </div>
                  <div className="f-16">
                    <div>{t('v2.detail.origination_date')}</div>
                    <div className="f-500">
                      <b>05.01.2022</b>
                    </div>
                  </div>
                </div>
                <div className={s.page__example_summary_right}>
                  {/* <DownloadOptions */}
                  {/*   links={[ */}
                  {/*     { format: 'pdf', link: '#' }, */}
                  {/*     { format: 'xlsx', link: '#' }, */}
                  {/*     { format: 'csv', link: '#' }, */}
                  {/*   ]} */}
                  {/* /> */}
                </div>
              </div>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupAExample} />
              ) : (
                <ExampleTable data={GroupAExample} />
              )}
            </>
          </Dropdown>
        </div>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18 f-500">
            {t('v2.detail.a_startup_can_move_repayments')}
          </h4>
          <p className="f-16-24 f-400">
            {t('v2.detail.each_startup_can_move_a_monthly_repayment')}
          </p>
          <p className="f-16-24 f-400">
            {t('v2.detail.what_happens_if_a_startup_moves')}
          </p>
          <p className="f-16-24 f-400">
            {t('v2.detail.for_example_if_a_startup_skips')}
          </p>
          <img
            src={A_LAST_MONTH}
            alt={t('v2.detail.a_startup_can_move_repayments_to_the_last_month')}
            style={{
              maxWidth: '500px',
              margin: '0 auto',
              display: 'block',
              width: '100%',
            }}
          />
        </div>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18 f-500">
            {t('v2.detail.a_startup_can_extend_a_repayment_schedule')}
          </h4>
          <p className="f-16-24 f-400">
            {t('v2.detail.each_startup_can_extend_the_repayment_period')}
          </p>

          <Dropdown
            label={
              <span className="f-16 f-500">
                {t('v2.detail.option_1_a_startup_extends')}
              </span>
            }
            defaultShow={false}
            className={s.page__repayments_option}
          >
            <div
              className="f-16 f-400"
              style={{ marginTop: '10px', whiteSpace: 'pre-line' }}
            >
              {t('v2.detail.what_happens_if_a_startup_doesnt_pay_off')}
            </div>
            <img
              src={A_OPTION_1}
              alt={t('v2.detail.option_1')}
              className={s.page__repayments_image}
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
              }}
            />

            <ul className={s.page__repayments_list}>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.in_the_6th_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.you_will_receive_an_additional')}
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.from_7th_to_11th_months')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.then_from_7th_to_11th_months')}
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.in_the_12th_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.in_the_12th_month_you_get')}
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  {t('v2.detail.a_12_month_repayment_schedule_example')}
                </span>
              }
              defaultShow
              disableToggle
              className={s.page__repayments_dropdown}
            >
              <>
                <div className={s.page__example_summary}>
                  <div className={s.page__example_summary_left}>
                    <div className="f-16">
                      <div>{t('v2.detail.loan_amount')}</div>
                      <div className="f-500">
                        <b>€1’000</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>{t('v2.detail.origination_date')}</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className={s.page__example_summary_right}>
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className={s.page__example_data}>
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_A_DATA_12} />
                  ) : (
                    <ExampleTable data={EXAMPLE_A_DATA_12} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>

          <Dropdown
            label={
              <span className="f-16 f-500">
                {t('v2.detail.option_2_a_startup_extends')}
              </span>
            }
            defaultShow={false}
            className={s.page__repayments_option}
          >
            <div
              className="f-16 f-400"
              style={{ marginTop: '10px', whiteSpace: 'pre-line' }}
            >
              {t('v2.detail.what_happens_if_a_startup_doesnt_pay_off_12th')}
            </div>
            <img
              src={A_OPTION_2}
              alt={t('v2.detail.option_2')}
              className={s.page__repayments_image}
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
              }}
            />

            <ul className={s.page__repayments_list}>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.in_the_12th_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.you_will_receive_an_additional_12th')}
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.from_13th_to_17th_months')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.then_from_13th_to_17th_months')}
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.in_the_18th_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.in_the_18th_month_you_get')}
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  {t('v2.detail.an_18_month_repayment_schedule_example')}
                </span>
              }
              defaultShow
              disableToggle
              className={s.page__repayments_dropdown}
            >
              <>
                <div className={s.page__example_summary}>
                  <div className={s.page__example_summary_left}>
                    <div className="f-16">
                      <div>{t('v2.detail.loan_amount')}</div>
                      <div className="f-500">
                        <b>€1’000</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>{t('v2.detail.origination_date')}</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className={s.page__example_summary_right}>
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className={s.page__example_data}>
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_A_DATA_18} />
                  ) : (
                    <ExampleTable data={EXAMPLE_A_DATA_18} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>

          <Dropdown
            label={
              <span className="f-16 f-500">
                {t('v2.detail.option_3_a_startup_extends')}
              </span>
            }
            defaultShow={false}
            className={s.page__repayments_option}
          >
            <div
              className="f-16 f-400"
              style={{ marginTop: '10px', whiteSpace: 'pre-line' }}
            >
              {t('v2.detail.what_happens_if_a_startup_doesnt_pay_off_18th')}
            </div>
            <img
              src={A_OPTION_3}
              alt={t('v2.detail.option_3')}
              className={s.page__repayments_image}
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
              }}
            />

            <ul className={s.page__repayments_list}>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.in_the_18th_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.you_will_receive_an_8_5_percent')}
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.in_the_19th_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.you_get_4_7_percent')}
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.in_the_20th_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.in_the_20th_month_you_get')}
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  {t('v2.detail.a_24_month_repayment_schedule_example')}
                </span>
              }
              defaultShow
              disableToggle
              className={s.page__repayments_dropdown}
            >
              <>
                <div className={s.page__example_summary}>
                  <div className={s.page__example_summary_left}>
                    <div className="f-16">
                      <div>{t('v2.detail.loan_amount')}</div>
                      <div className="f-500">
                        <b>€1’000</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>{t('v2.detail.origination_date')}</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className={s.page__example_summary_right}>
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className={s.page__example_data}>
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_A_DATA_24} />
                  ) : (
                    <ExampleTable data={EXAMPLE_A_DATA_24} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>
        </div>
      </section>
    ),
  }),

  [LOAN_GROUPS_IDS.moderate.id]: (data, innerWidth) => ({
    title: data ? data.moderate.title : t('v2.detail.group_b'),
    termsSection: (
      <section className={s.page__terms}>
        <h2 className="f-26-32 f-500">{t('v2.detail.loan_terms')}</h2>
        <div className={s.page__first_row}>
          <div className={s.page__annual_return}>
            <div className={s.page__annual_return_header}>
              <div
                className={classnames(
                  s.page__annual_return_title,
                  'f-16-24 f-400',
                )}
              >
                {t('v2.detail.target_annual_return')}
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className={classnames(s.tooltip, 'f-16 f-400')}>
                    {t('v2.detail.target_annual_return_calculations')}
                  </div>
                )}
              />
            </div>
            <div
              className={classnames(
                s.page__annual_return_value,
                'f-28-32 f-500',
              )}
            >
              {t('v2.detail.up_to_30_percent')}
            </div>
          </div>
        </div>
        <div className={s.page__second_row}>
          <div
            className={classnames(
              s.page__nominal_return_title,
              'f-16-24 f-400',
            )}
          >
            {t('v2.detail.nominal_return')}
          </div>
          <div className={s.page__nominal_return}>
            <div className={s.page__nominal_return_item}>
              <div
                className={classnames(
                  s.page__nominal_return_item_desc,
                  'f-16-24 f-400',
                )}
              >
                {t('v2.detail.standard_6_month_term')}
              </div>
              <div className={s.page__nominal_return_item_circle}>
                <div className="f-28-32 f-500">
                  {t('v2.detail.nine_percent_flat_fee')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.paid_at_the_end_of_the_term')}
                </div>
              </div>
            </div>
            <div className={s.page__nominal_return_item}>
              <div
                className={classnames(
                  s.page__nominal_return_item_desc,
                  'f-16-24 f-400',
                )}
              >
                {t('v2.detail.if_funds_used_beyond_6_months')}
              </div>
              <div className={s.page__nominal_return_item_circle}>
                <div className="f-28-32 f-500">
                  {t('v2.detail.up_to_49_percent_extra_flat_fee')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.based_on_loan_repayment_timing')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    ),
    fundsUsageSection: (
      <section className={s.page__usage}>
        <h2 className={classnames(s.page__usage_title, 'f-26-32 f-500')}>
          {t('v2.detail.brands_selection_and_funds_usage')}
        </h2>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          {t('v2.detail.loans_issued_to_pre_selected_brands')}
        </p>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          {t('v2.detail.funds_used_for_working_capital')}
        </p>
      </section>
    ),
    fundsProtectionSection: (
      <section className={s.page__protection}>
        <h2 className="f-26-32 f-500">
          {t('v2.detail.investor_funds_protection')}
        </h2>
        <p className="f-16-24 f-400">
          {t('v2.detail.loans_of_group_b_junior_loans')}
        </p>

        <div className={s.page__protection_content}>
          {[
            {
              label: t('v2.detail.co_founder_team_guarantees'),
              content: (
                <div className="f-16-24 f-400">
                  {t('v2.detail.each_co_founder_guarantees')}
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`0${idx + 1}`}
              disableToggle={innerWidth > 992}
              defaultShow
            >
              <div className={s.page__protection_dropdown}>{item.content}</div>
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className={s.page__repayments}>
        <h2 className="f-26-32 f-500">{t('v2.detail.loan_repayments')}</h2>
        <p className="f-16-24 f-400">
          {t('v2.detail.monthly_loan_repayments_transferred')}
        </p>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18 f-400">
            {t('v2.detail.regular_repayment_scenario')}
          </h4>
          <img
            src={B_REGULAR_REPAYMENT}
            alt={t('v2.detail.group_b')}
            className={s.page__repayments_image}
            style={{
              maxWidth: '400px',
              margin: '30px auto 40px',
              display: 'block',
              width: '100%',
            }}
          />
          <ul className={s.page__repayments_list}>
            <li>
              <div className="f-16-24 f-500">
                {t('v2.detail.at_end_of_the_6th_month')}
              </div>
              <div className="f-16-24 f-400">
                {t('v2.detail.at_the_end_of_the_6th_month_you_get_100')}
              </div>
            </li>
          </ul>

          <Dropdown
            label={
              <span className="f-18 f-500">
                {t('v2.detail.a_regular_repayment_schedule_example')}
              </span>
            }
            className={s.page__example}
            defaultShow
            disableToggle
          >
            <>
              <div className={s.page__example_summary}>
                <div className={s.page__example_summary_left}>
                  <div className="f-16">
                    <div>{t('v2.detail.loan_amount')}</div>
                    <div className="f-500">
                      <b>€1’500</b>
                    </div>
                  </div>
                  <div className="f-16">
                    <div>{t('v2.detail.origination_date')}</div>
                    <div className="f-500">
                      <b>05.01.2022</b>
                    </div>
                  </div>
                </div>
                <div className={s.page__example_summary_right}>
                  {/* <DownloadOptions */}
                  {/*   links={[ */}
                  {/*     { format: 'pdf', link: '#' }, */}
                  {/*     { format: 'xlsx', link: '#' }, */}
                  {/*     { format: 'csv', link: '#' }, */}
                  {/*   ]} */}
                  {/* /> */}
                </div>
              </div>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupBExample} />
              ) : (
                <ExampleTable data={GroupBExample} />
              )}
            </>
          </Dropdown>
        </div>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18 f-500">
            {t('v2.detail.a_brand_can_move_repayments')}
          </h4>
          <p className="f-16-24 f-400">
            {t('v2.detail.each_brand_can_move_a_monthly_repayment')}
          </p>
        </div>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18 f-500">
            {t('v2.detail.a_brand_can_extend_a_repayment_schedule')}
          </h4>
          <p className="f-16-24 f-400">
            {t('v2.detail.each_brand_can_extend_the_repayment_period')}
          </p>

          <Dropdown
            label={
              <span className="f-16 f-500">
                {t('v2.detail.option_1_a_brand_extends')}
              </span>
            }
            defaultShow={false}
            className={s.page__repayments_option}
          >
            <div
              className="f-16 f-400"
              style={{ marginTop: '10px', whiteSpace: 'pre-line' }}
            >
              {t('v2.detail.what_happens_if_a_brand_doesnt_pay_off')}
            </div>
            <img
              src={B_OPTION_1}
              alt={t('v2.detail.option_1')}
              className={s.page__repayments_image}
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
                maxWidth: '700px',
              }}
            />

            <ul className={s.page__repayments_list}>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.in_the_6th_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.you_do_not_receive_repayments')}
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.in_the_12th_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.in_the_12th_month_you_get_100')}
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  {t('v2.detail.a_12_month_repayment_schedule_example')}
                </span>
              }
              defaultShow
              disableToggle
              className={s.page__repayments_dropdown}
            >
              <>
                <div className={s.page__example_summary}>
                  <div className={s.page__example_summary_left}>
                    <div className="f-16">
                      <div>{t('v2.detail.loan_amount')}</div>
                      <div className="f-500">
                        <b>€1’500</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>{t('v2.detail.origination_date')}</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className={s.page__example_summary_right}>
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className={s.page__example_data}>
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_B_DATA_12} />
                  ) : (
                    <ExampleTable data={EXAMPLE_B_DATA_12} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>

          <Dropdown
            label={
              <span className="f-16 f-500">
                {t('v2.detail.option_2_a_brand_extends')}
              </span>
            }
            defaultShow={false}
            className={s.page__repayments_option}
          >
            <div
              className="f-16 f-400"
              style={{ marginTop: '10px', whiteSpace: 'pre-line' }}
            >
              {t('v2.detail.what_happens_if_a_brand_doesnt_pay_off_12th')}
            </div>
            <img
              src={B_OPTION_2}
              alt={t('v2.detail.option_2')}
              className={s.page__repayments_image}
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
              }}
            />

            <ul className={s.page__repayments_list}>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.in_the_12th_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.you_do_not_receive_repayments')}
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.in_the_18th_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.in_the_18th_month_you_get_100')}
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  {t('v2.detail.an_18_month_repayment_schedule_example')}
                </span>
              }
              defaultShow
              disableToggle
              className={s.page__repayments_dropdown}
            >
              <>
                <div className={s.page__example_summary}>
                  <div className={s.page__example_summary_left}>
                    <div className="f-16">
                      <div>{t('v2.detail.loan_amount')}</div>
                      <div className="f-500">
                        <b>€1’500</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>{t('v2.detail.origination_date')}</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className={s.page__example_summary_right}>
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className={s.page__example_data}>
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_B_DATA_18} />
                  ) : (
                    <ExampleTable data={EXAMPLE_B_DATA_18} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>

          <Dropdown
            label={
              <span className="f-16 f-500">
                {t('v2.detail.option_3_a_brand_extends')}
              </span>
            }
            defaultShow={false}
            className={s.page__repayments_option}
          >
            <div
              className="f-16 f-400"
              style={{ marginTop: '10px', whiteSpace: 'pre-line' }}
            >
              {t('v2.detail.what_happens_if_a_brand_doesnt_pay_off_18th')}
            </div>
            <img
              src={B_OPTION_3}
              alt={t('v2.detail.option_3')}
              className={s.page__repayments_image}
              style={{
                margin: '30px auto 40px',
                display: 'block',
                width: '100%',
              }}
            />

            <ul className={s.page__repayments_list}>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.from_20th_to_23th_months')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.from_20th_to_23th_months_you_get')}
                </div>
              </li>
              <li>
                <div className="f-16-24 f-500">
                  {t('v2.detail.from_21th_to_24th_months')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v2.detail.from_21th_to_24th_months_you_get')}
                </div>
              </li>
            </ul>

            <Dropdown
              label={
                <span className="f-16 f-500">
                  {t('v2.detail.a_24_month_repayment_schedule_example')}
                </span>
              }
              defaultShow
              disableToggle
              className={s.page__repayments_dropdown}
            >
              <>
                <div className={s.page__example_summary}>
                  <div className={s.page__example_summary_left}>
                    <div className="f-16">
                      <div>{t('v2.detail.loan_amount')}</div>
                      <div className="f-500">
                        <b>€1’500</b>
                      </div>
                    </div>
                    <div className="f-16">
                      <div>{t('v2.detail.origination_date')}</div>
                      <div className="f-500">
                        <b>05.01.2022</b>
                      </div>
                    </div>
                  </div>
                  <div className={s.page__example_summary_right}>
                    {/* <DownloadOptions */}
                    {/*   links={[ */}
                    {/*     { format: 'pdf', link: '#' }, */}
                    {/*     { format: 'xlsx', link: '#' }, */}
                    {/*     { format: 'csv', link: '#' }, */}
                    {/*   ]} */}
                    {/* /> */}
                  </div>
                </div>
                <div className={s.page__example_data}>
                  {window.innerWidth < 992 ? (
                    <ExampleList data={EXAMPLE_B_DATA_24} />
                  ) : (
                    <ExampleTable data={EXAMPLE_B_DATA_24} />
                  )}
                </div>
              </>
            </Dropdown>
          </Dropdown>
        </div>
      </section>
    ),
  }),
});
