import { useEffect } from 'react';
import moment from 'moment/min/moment-with-locales';
import { RoundButton } from '@/components/UI/RoundButton';
import { sendErrorMessage } from '@/store/services/loggingService';

export const ErrorBoundaryFallback = props => {
  useEffect(() => {
    sendError();
  }, [props]);

  const sendError = async () => {
    const message = props?.error.message;
    await sendErrorMessage({
      user: null,
      message,
      date: moment().format('dddd, MMMM Do YYYY, h:mm:ss a'),
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          margin: 'auto',
          textAlign: 'center',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <h2 className="f-26-32">Ooops, something went wrong :( </h2>
        <div>
          <RoundButton
            label="Refresh"
            fillBackground
            onClick={() => window.location.assign(window.location.origin)}
          />
        </div>
      </div>
    </div>
  );
};
