import { useDispatch, useSelector } from 'react-redux';
import ProfileContactsForm from '@/components/Forms/ProfileContactsForm';
import { updateProfileContacts } from '@/store/services/userServices';
import { getUserProfile } from '@/store/actions/profileActions';

const ProfilePersonalModal = ({ onClose, preSaved, onOutsideClick }) => {
  const user = useSelector(state => state.profileStore.profile.data);
  const userLocation = useSelector(state => state.userStore.userLocation);
  const dispatch = useDispatch();

  const onSubmit = values => {
    const payload = {
      address: values.address,
      city: values.city,
      country: values.country,
      country_code: values.country_code,
      postal_code: values.postal_code,
      phone: values.phone,
      citizenship: values.citizenship,
      citizenship_code: values.citizenship_code,
      origin_of_funds: values.origin_of_funds,
      monthly_investment: values.planned_monthly_investment,
    };

    updateProfileContacts(payload).then(res => {
      if (res && res.success) {
        dispatch(getUserProfile());
        onClose();
      }
    });
  };

  const handleOutsideClick = values => {
    onOutsideClick && onOutsideClick(values);
    onClose();
  };

  return (
    <ProfileContactsForm
      user={user}
      location={userLocation}
      onSubmit={onSubmit}
      onCancel={onClose}
      preSaved={preSaved}
      onOutsideClick={handleOutsideClick}
    />
  );
};

export default ProfilePersonalModal;
