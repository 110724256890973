import classnames from 'classnames';
import { Trans } from 'react-i18next';
import { LOAN_GROUPS_IDS } from '@/helpers/constants';
import Tooltip from '@/components/Tooltip';
import Dropdown from '@/components/Dropdown';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { ExampleList } from './ExampleList';
import { ExampleTable } from './ExampleTable';
import { IncreasedIncomeWarning } from './WarningIncome';
import { GroupAExample, GroupAExampleFor1, GroupBExample } from './tableData';
import s from './index.module.scss';

export const GROUP_DETAIL = t => ({
  [LOAN_GROUPS_IDS.conservative.id]: () => ({
    title: t('v3.detail.group_a'),
    termsSection: (
      <section className={s.page__terms}>
        <h2 className="f-26-32 f-500">{t('v3.detail.loan_terms')}</h2>
        <div className={s.page__first_row}>
          <div className={s.page__annual_return}>
            <div className={s.page__annual_return_header}>
              <div
                className={classnames(
                  s.page__annual_return_title,
                  'f-16-24 f-400',
                )}
              >
                {t('v3.detail.target_annual_return')}
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className={classnames(s.tooltip, 'f-16 f-400')}>
                    {t('v3.detail.target_annual_return_calculations')}
                  </div>
                )}
              />
            </div>
            <div
              className={classnames(
                s.page__annual_return_value,
                'f-28-32 f-500',
              )}
            >
              {t('v3.detail.up_to_12_4_percent')}
            </div>
          </div>
        </div>
        <div className={s.page__second_row}>
          <div
            className={classnames(
              s.page__nominal_return_title,
              'f-16-24 f-400',
            )}
          >
            {t('v3.detail.nominal_return')}
          </div>
          <div className={s.page__nominal_return}>
            <div className={s.page__nominal_return_item}>
              <div
                className={classnames(
                  s.page__nominal_return_item_desc,
                  'f-16-24 f-400',
                )}
              >
                {t('v3.detail.for_standard_6_month_term')}
              </div>
              <div className={s.page__nominal_return_item_circle}>
                <div className="f-28-32 f-500">
                  {t('v3.detail.up_to_1_percent_per_month')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v3.detail.on_outstanding_loan_principal')}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className={s.page__repayments_list} style={{ marginTop: '30px' }}>
          <li>
            <div className="f-16-24 f-400">
              {t('v3.detail.increased_income')}
              <ul>
                <li style={{ marginBottom: 0 }}>
                  {t('v3.detail.achieved_by_topping_up')}
                </li>
                <li>{t('v3.detail.automatically_applied')}</li>
              </ul>
            </div>
          </li>
          <li>
            <div className="f-16-24 f-400">
              {t('v3.detail.basic_income')}
              <ul>
                <li style={{ marginBottom: 0 }}>
                  {t('v3.detail.applied_if_conditions_not_met')}
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="f-16-24 f-400">
              {t('v3.detail.returns_accrued_monthly')}
            </div>
          </li>
        </ul>
        <div style={{ marginTop: '20px' }}>
          <i>{t('v3.detail.balance_falls_below')}</i>
        </div>
      </section>
    ),
    fundsUsageSection: (
      <section className={s.page__usage}>
        <h2 className={classnames(s.page__usage_title, 'f-26-32 f-500')}>
          {t('v3.detail.brands_selection_and_funds_usage')}
        </h2>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          {t('v3.detail.loans_issued_to_brands')}
        </p>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          {t('v3.detail.funds_used_by_brands')}
        </p>
      </section>
    ),
    fundsProtectionSection: (
      <section className={s.page__protection}>
        <h2 className="f-26-32 f-500">
          {t('v3.detail.investor_funds_protection')}
        </h2>
        <p className="f-16-24 f-400">{t('v3.detail.loans_of_group_a')}</p>
        <div className={s.page__protection_content}>
          {[
            {
              label: t('v3.detail.regular_monthly_repayments'),
              content: (
                <div className="f-16-24 f-400">
                  {t('v3.detail.investors_start_receiving_repayments')}
                </div>
              ),
            },
            {
              label: t('v3.detail.co_founder_team_guarantees'),
              content: (
                <div className="f-16-24 f-400">
                  {t('v3.detail.each_co_founder_guarantees')}
                </div>
              ),
            },
            {
              label: t('v3.detail.first_loss_capital'),
              content: (
                <div className="f-16-24 f-400">
                  {t('v3.detail.investors_in_loans_b_contribute')}
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`0${idx + 1}`}
              disableToggle
              defaultShow
            >
              <div className={s.page__protection_dropdown}>{item.content}</div>
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className={s.page__repayments}>
        <h2 className="f-26-32 f-500">{t('v3.detail.loan_repayments')}</h2>
        <p className="f-16-24 f-400">
          {t('v3.detail.monthly_loan_repayments')}
        </p>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18-24 f-500">
            {t('v3.detail.regular_repayment_scenario')}
          </h4>
          <ul className={s.page__repayments_list}>
            <li>
              <div className="f-16-24 f-500">
                {t('v3.detail.from_end_of_1st_month')}
              </div>
              <div className="f-16-24 f-400">
                {t('v3.detail.during_first_5_months')}
              </div>
            </li>
            <li>
              <div className="f-16-24 f-500">
                {t('v3.detail.at_end_of_6th_month')}
              </div>
              <div className="f-16-24 f-400">
                {t('v3.detail.at_end_of_6th_month_get_remaining')}
              </div>
            </li>
          </ul>
          <Dropdown
            label={
              <span className="f-18 f-500">
                {t('v3.detail.regular_repayment_schedule_example')}
              </span>
            }
            className={s.page__example}
            defaultShow
            disableToggle
          >
            <>
              <div className={s.page__example_summary}>
                <div className={s.page__example_summary_left}>
                  <div className="f-16">
                    <div>{t('v3.detail.loan_amount')}</div>
                    <div className="f-500">
                      <b>€1’000</b>
                    </div>
                  </div>
                  <div className="f-16">
                    <div>{t('v3.detail.origination_date')}</div>
                    <div className="f-500">
                      <b>15.01.2024</b>
                    </div>
                  </div>
                </div>
                <div className={s.page__example_summary_right}></div>
              </div>
              <div className="f-16-24 f-400" style={{ marginTop: '20px' }}>
                {t('v3.detail.since_amount_of_monthly_repayments')}
              </div>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupAExample} />
              ) : (
                <ExampleTable data={GroupAExample} />
              )}
            </>
          </Dropdown>

          <IncreasedIncomeWarning
            amount={12.77}
            className={s.page__example_warning}
          />

          <Dropdown
            label={
              <span className="f-18 f-400 underline">
                {t('v3.detail.check_repayment_schedule_example')}
              </span>
            }
            className={s.page__example}
            defaultShow={false}
          >
            <>
              <div className="f-18 f-500" style={{ marginTop: '40px' }}>
                {t('v3.detail.regular_repayment_schedule_example_1_percent')}
              </div>
              <div className={s.page__example_summary}>
                <div className={s.page__example_summary_left}>
                  <div className="f-16">
                    <div>{t('v3.detail.loan_amount')}</div>
                    <div className="f-500">
                      <b>€1’000</b>
                    </div>
                  </div>
                  <div className="f-16">
                    <div>{t('v3.detail.origination_date')}</div>
                    <div className="f-500">
                      <b>15.01.2024</b>
                    </div>
                  </div>
                </div>
                <div className={s.page__example_summary_right}></div>
              </div>
              <div className="f-16-24 f-400" style={{ marginTop: '20px' }}>
                {t('v3.detail.since_amount_of_monthly_repayments')}
              </div>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupAExampleFor1} />
              ) : (
                <ExampleTable data={GroupAExampleFor1} />
              )}
            </>
          </Dropdown>
        </div>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18 f-500">
            {t('v3.detail.brand_can_move_repayments')}
          </h4>
          <p className="f-16-24 f-400">{t('v3.detail.each_brand_can_move')}</p>
          <p className="f-16-24 f-400">
            {t('v3.detail.what_happens_if_startup_moves')}
          </p>
          <p className="f-16-24 f-400">
            {t('v3.detail.for_example_if_startup_skips')}
          </p>
        </div>
      </section>
    ),
    agreementSection: (
      <section className={s.page__agreement}>
        <h2 className="f-24 f-500">{t('v3.detail.loan_agreements')}</h2>
        <p className="f-16 f-400">
          <Trans
            ns="loan_terms"
            i18nKey="v3.detail.when_you_set_up_investing_a"
            components={{ link: <MyLink href={APP_LINKS.loans} /> }}
          />
        </p>
      </section>
    ),
  }),
  [LOAN_GROUPS_IDS.moderate.id]: (data, innerWidth) => ({
    title: t('v3.detail.group_b'),
    termsSection: (
      <section className={s.page__terms}>
        <h2 className="f-26-32 f-500">{t('v3.detail.loan_terms')}</h2>
        <div className={s.page__first_row}>
          <div className={s.page__annual_return}>
            <div className={s.page__annual_return_header}>
              <div
                className={classnames(
                  s.page__annual_return_title,
                  'f-16-24 f-400',
                )}
              >
                {t('v3.detail.target_annual_return')}
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className={classnames(s.tooltip, 'f-16 f-400')}>
                    {t('v3.detail.target_annual_return_calculations')}
                  </div>
                )}
              />
            </div>
            <div
              className={classnames(
                s.page__annual_return_value,
                'f-28-32 f-500',
              )}
            >
              {t('v3.detail.up_to_25_percent')}
            </div>
          </div>
        </div>
        <div className={s.page__second_row}>
          <div
            className={classnames(
              s.page__nominal_return_title,
              'f-16-24 f-400',
            )}
          >
            {t('v3.detail.nominal_return')}
          </div>
          <div className={s.page__nominal_return}>
            <div className={s.page__nominal_return_item}>
              <div
                className={classnames(
                  s.page__nominal_return_item_desc,
                  'f-16-24 f-400',
                )}
              >
                {t('v3.detail.for_standard_6_month_term')}
              </div>
              <div className={s.page__nominal_return_item_circle}>
                <div className="f-28-32 f-500">
                  {t('v3.detail.9_percent_flat_fee')}
                </div>
                <div className="f-16-24 f-400">
                  {t('v3.detail.paid_at_end_of_term')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    ),
    fundsUsageSection: (
      <section className={s.page__usage}>
        <h2 className={classnames(s.page__usage_title, 'f-26-32 f-500')}>
          {t('v3.detail.brands_selection_and_funds_usage')}
        </h2>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          {t('v3.detail.loans_issued_to_brands')}
        </p>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          {t('v3.detail.funds_used_by_brands')}
        </p>
      </section>
    ),
    fundsProtectionSection: (
      <section className={s.page__protection}>
        <h2 className="f-26-32 f-500">
          {t('v3.detail.investor_funds_protection')}
        </h2>
        <p className="f-16-24 f-400">{t('v3.detail.loans_of_group_b')}</p>
        <div className={s.page__protection_content}>
          {[
            {
              label: t('v3.detail.co_founder_team_guarantees'),
              content: (
                <div className="f-16-24 f-400">
                  {t('v3.detail.each_co_founder_guarantees')}
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`0${idx + 1}`}
              disableToggle={innerWidth > 992}
              defaultShow
            >
              <div className={s.page__protection_dropdown}>{item.content}</div>
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className={s.page__repayments}>
        <h2 className="f-26-32 f-500">{t('v3.detail.loan_repayments')}</h2>
        <p className="f-16-24 f-400">
          {t('v3.detail.monthly_loan_repayments')}
        </p>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18 f-400">
            {t('v3.detail.regular_repayment_scenario')}
          </h4>
          <ul className={s.page__repayments_list}>
            <li>
              <div className="f-16-24 f-500">
                {t('v3.detail.at_end_of_6th_month')}
              </div>
              <div className="f-16-24 f-400">
                {t('v3.detail.at_end_of_6th_month_get_100_percent')}
              </div>
            </li>
          </ul>

          <Dropdown
            label={
              <span className="f-18 f-500">
                {t('v3.detail.regular_repayment_schedule_example')}
              </span>
            }
            className={s.page__example}
            defaultShow
            disableToggle
          >
            <>
              <div className={s.page__example_summary}>
                <div className={s.page__example_summary_left}>
                  <div className="f-16">
                    <div>{t('v3.detail.loan_amount')}</div>
                    <div className="f-500">
                      <b>€1’500</b>
                    </div>
                  </div>
                  <div className="f-16">
                    <div>{t('v3.detail.origination_date')}</div>
                    <div className="f-500">
                      <b>15.01.2024</b>
                    </div>
                  </div>
                </div>
                <div className={s.page__example_summary_right} />
              </div>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupBExample} />
              ) : (
                <ExampleTable data={GroupBExample} />
              )}
            </>
          </Dropdown>
        </div>
      </section>
    ),
    agreementSection: (
      <section className={s.page__agreement}>
        <h2 className="f-24 f-500">{t('v3.detail.loan_agreements')}</h2>
        <p className="f-16 f-400">
          <Trans
            ns="loan_terms"
            i18nKey="v3.detail.when_you_set_up_investing_b"
            components={{ link: <MyLink href={APP_LINKS.loans} /> }}
          />
        </p>
      </section>
    ),
  }),
});
