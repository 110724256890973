import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Preloader } from '@/components/Preloader';
import { getReferralsList } from '@/store/actions/referralActions';
import ReferralTable from './ReferralTable';
import ReferralList from './ReferralList';
import './index.scss';

export const ReferralFriendStatus = ({status}) => {
  const { t } = useTranslation('affiliate');
  return {
    registered: t('friend_status.registered'),
    invested: t('friend_status.invested'),
    invested_less: t('friend_status.invested_less'),
  }[status];
};

const ReferralModule = () => {
  const dispatch = useDispatch();
  const { referralsList } = useSelector(state => state.referralStore);
  const { innerWidth } = useSelector(state => state.commonStore);
  const [pageSettings, setPageSettings] = useState({
    page: 1,
    limit: 10,
  });
  const { t } = useTranslation('affiliate');

  useEffect(() => {
    dispatch(getReferralsList(pageSettings));
  }, []);

  const getNext = () => {
    const { page } = pageSettings;
    const nextPage = page + 1;

    dispatch(
      getReferralsList(
        {
          ...pageSettings,
          page: nextPage,
        },
        true,
      ),
    );

    setPageSettings(prevState => ({ ...prevState, page: nextPage }));
  };

  const { page } = pageSettings;
  const { data, loading } = referralsList;
  const hasReferrals = data?.total_count > 0;

  return (
    <div className="referrals_module">
      {!hasReferrals && !loading && (
        <div className="referrals_module__empty f-16">
          {t('no_referrals_yet')}
        </div>
      )}

      {page === 1 && loading ? (
        <Preloader className="referrals_module__preloader" />
      ) : (
        hasReferrals &&
        (innerWidth < 992 ? (
          <ReferralList data={data} />
        ) : (
          <ReferralTable data={data} />
        ))
      )}

      {hasReferrals && data?.total_pages > page && (
        <div style={{ textAlign: 'center' }}>
          <button
            type="button"
            className="referrals_module__more f-24 f-500"
            onClick={getNext}
          >
            {t('load_more')}
          </button>
        </div>
      )}
    </div>
  );
};

export default ReferralModule;
