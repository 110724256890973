import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGetAllocationStatus } from '@/hooks/useGetAllocationStatus';
import { LOAN_GROUPS, LOAN_GROUPS_IDS } from '@/helpers/constants';
import { Table } from './table';
import s from './index.module.scss';

export const EarningSchedule = () => {
  const { t } = useTranslation('investing');
  const { t: tConstants } = useTranslation('constants');
  const [tab, setTab] = useState('conservative');

  const { data } = useSelector(
    state => state.investStore.allocatedRepaymentSchedule,
  );

  const { allocatedGroupA, allocatedGroupB } = useGetAllocationStatus();

  useEffect(() => {
    if (allocatedGroupA) {
      setTab(LOAN_GROUPS_IDS.conservative.id);
    } else if (allocatedGroupB) {
      setTab(LOAN_GROUPS_IDS.moderate.id);
    }
  }, [allocatedGroupA, allocatedGroupB]);

  if (!data || !tab) {
    return null;
  }

  const LOAN_GROUPS_T = LOAN_GROUPS(tConstants);
  return (
    <div className={s.wrapper}>
      <div className="f-16-24">
        {t('summary_here_you_can_see_your_planed_monthly_accrued_income')}
      </div>
      <div className={s.schedule} style={{ marginTop: '10px' }}>
        <div className={classnames(s.tabs, 'f-20 f-400')}>
          {!!allocatedGroupA && (
            <div
              className={classnames(
                s.tab,
                tab === LOAN_GROUPS_IDS.conservative.id && 'active',
              )}
              onClick={() => setTab(LOAN_GROUPS_IDS.conservative.id)}
            >
              {LOAN_GROUPS_T.conservative.full_name}
            </div>
          )}
          {!!allocatedGroupB && (
            <div
              className={classnames(
                s.tab,
                tab === LOAN_GROUPS_IDS.moderate.id && 'active',
              )}
              onClick={() => setTab(LOAN_GROUPS_IDS.moderate.id)}
            >
              {LOAN_GROUPS_T.moderate.full_name}
            </div>
          )}
        </div>
        <Table
          tab={tab}
          data={data}
          allocatedAmount={
            tab === LOAN_GROUPS_IDS.conservative.id
              ? allocatedGroupA
              : tab === LOAN_GROUPS_IDS.moderate.id
                ? allocatedGroupB
                : 0
          }
        />
      </div>
    </div>
  );
};
