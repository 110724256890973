import { Tooltip as OriginalTooltip } from 'react-tooltip';
import Icon from './assets/icon_grey_tooltip.svg?react';
import 'react-tooltip/dist/react-tooltip.css';
import s from './index.module.scss';

export const Tooltip = ({ id, place = 'top', content, children, props }) => (
  <div
    data-tooltip-id={id}
    data-tooltip-content={content}
    style={{ display: children ? 'inline-block' : 'flex' }}
    className={s.wrapper}
  >
    {children || <Icon style={{ minWidth: '20px' }} />}
    <OriginalTooltip id={id} place={place} content={content} {...props} />
  </div>
);
