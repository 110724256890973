import classnames from 'classnames';
import s from './index.module.scss';

export const RadioButton = ({ children, className, ...radioProps }) => (
  <label className={classnames(s.radio_button, className)}>
    <RadioInput {...radioProps} />
    {children}
  </label>
);

export const RadioInput = ({ ...radioProps }) => (
  <span className={classnames(s.radio_input, 'radio_input')}>
    <input type="radio" {...radioProps} />
    <span className={s.radio_input__checkmark} />
  </span>
);
