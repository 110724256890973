import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VERIFF_CODES } from '@/helpers/constants';
import { WarningIcon } from '@/components/UI/Icons';
import { RoundButton } from '@/components/UI/RoundButton';
import { STEPS } from '../index';
import s from '../index.module.scss';

export const VerifyIdentityStep = ({ passed, onVerifyIdentity }) => {
  const { t } = useTranslation('dashboard');
  const { veriffStatusCode } = useSelector(state => state.profileStore);
  const active = passed.includes(STEPS.verify_identity);

  return (
    <li
      id={`vpw_${STEPS.verify_identity}`}
      className={classnames(s.list_item, active && s.list_item_active)}
    >
      <div className={s.list_item_left}>
        <span
          className={classnames(
            s.circle,
            active && s.circle_active,
            'f-26 f-400',
          )}
        >
          02
        </span>
      </div>
      <div className={s.list_item_right}>
        <div className={classnames(s.list_item_verify, 'f-26 f-400')}>
          <span className={s.list_item_title}>
            {t('wizard_step_2_verify_your_identity')}
          </span>
        </div>

        {veriffStatusCode !== VERIFF_CODES.verified && (
          <div
            className={classnames(
              s.list_item_content,
              active && s.list_item_content_active,
              'f-16-24 f-400',
            )}
          >
            <div className={s.list_item_content_inner}>
              {VERIFF_CODES.submitted === veriffStatusCode ? (
                <div className="f-16 f-400">
                  {t('wizard_step_2_identity_being_verified')}
                </div>
              ) : (
                veriffStatusCode !== null && (
                  <>
                    {[VERIFF_CODES.notVerified, VERIFF_CODES.expired].includes(
                      veriffStatusCode,
                    ) && (
                      <div className={s.veriff}>
                        <WarningIcon />
                        <span className="f-16 f-500">
                          {t('wizard_step_2_verification_unsuccessful')}
                        </span>
                      </div>
                    )}

                    {[VERIFF_CODES.new, VERIFF_CODES.started].includes(
                      veriffStatusCode,
                    ) && (
                      <div className="f-16 f-400">
                        {t('wizard_step_2_fill_information')}
                      </div>
                    )}

                    {VERIFF_CODES.reSubmitRequest === veriffStatusCode ? (
                      <div className="f-16 f-400" style={{ marginTop: '8px' }}>
                        {t('wizard_step_2_verification_not_successful')}
                      </div>
                    ) : (
                      <div className="f-16 f-400" style={{ marginTop: '8px' }}>
                        {t('wizard_step_2_provide_information')}
                      </div>
                    )}

                    <div className={s.veriff_buttons}>
                      <RoundButton
                        label={t('wizard_step_2_verify_identity')}
                        fillBackground
                        fullWidth
                        onClick={onVerifyIdentity}
                        className={s.veriff_button_identity}
                      />
                    </div>
                  </>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </li>
  );
};
