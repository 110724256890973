import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import Tooltip from '@/components/Tooltip';
import s from './index.module.scss';

export const LoanGroupCard = ({ group, className }) => {
  const { t } = useTranslation('loan_terms');
  return (
    <div className={classnames(s.card, className)}>
      <MyLink href={group.link} className="card__title hov f-26 f-500">
        {group.title}
      </MyLink>
      {group.isActive ? (
        <>
          <div className={s.card__block}>
            <div className="f-18 f-500">
              <span>{t('v1.loan_card.target_annual_return')}</span>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className={classnames(s.card__tooltip, 'f-16 f-400')}>
                    {t('v1.loan_card.target_annual_return_tooltip')}
                  </div>
                )}
              />
            </div>
            <p className="f-18 f-500 tl">{group.rate}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">{t('v1.loan_card.term')}</div>
            <p className="f-18 f-500 tl">{group.repaymentPeriod}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">
              {t('v1.loan_card.downside_protection')}
            </div>
            <p className="f-18 f-500 tl">{group.downsideProtection}</p>
          </div>

          <MyLink
            href={group.link}
            className={classnames(s.card__link_details, 'f-500')}
          >
            {t('v1.loan_card.see_details')}
          </MyLink>
        </>
      ) : (
        <p className={classnames(s.card__unavailable, 'f-18 f-500')}>
          {t('v1.loan_card.temporarily_unavailable', { title: group.title })}
        </p>
      )}
    </div>
  );
};
