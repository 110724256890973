import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Logo from '@/assets/images/logo_white.svg?react';
import { MyLink } from '@/components/MyLink';
import MenuUser from '@/components/MenuUser';
import { APP_LINKS } from '@/helpers/links';
import { NAV_ROUTES } from '../routes';
import './index.scss';

export const DesktopNavigation = () => {
  const { referralCode } = useSelector(state => state.referralStore);
  const isActiveReferralBanner = !!referralCode.data?.is_referral_banner_active;
  const { t } = useTranslation('navigation');

  return (
    <div className="desktop_navigation__container">
      <div className="desktop_navigation__top">
        <MyLink href={APP_LINKS.dashboard} className="desktop_navigation__logo">
          <Logo />
        </MyLink>
        <nav className="desktop_navigation">
          {NAV_ROUTES(t)({ isActiveReferralBanner }).map(route => (
            <NavLink
              key={route.label}
              to={route.path}
              className={({ isActive }) =>
                `desktop_navigation__item f-16 f-400 ${isActive ? 'desktop_navigation__item_active' : ''}`
              }
            >
              {route.label} {route.icon && <img src={route.icon} alt="icon" />}
            </NavLink>
          ))}
        </nav>
      </div>
      <MenuUser className="desktop_navigation__user" />
    </div>
  );
};
