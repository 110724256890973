import classnames from 'classnames';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import config from '@/config';
import { RoundButton } from '@/ui/RoundButton';
import IconGoogle from './assets/icon_google.svg';
import s from './index.module.scss';

export const GoogleButton = props => (
  <GoogleOAuthProvider clientId={config.googleAppId}>
    <Button {...props} />
  </GoogleOAuthProvider>
);

const Button = props => {
  const { label, onGoogleResponseSuccess, onGoogleResponseFailure } = props;

  const login = useGoogleLogin({
    onSuccess: onGoogleResponseSuccess,
    onError: onGoogleResponseFailure,
  });

  return (
    <RoundButton
      className={classnames(s.button, 'hov')}
      onClick={() => login()}
    >
      <img src={IconGoogle} width={24} height={24} alt="Google" />
      <span className="f-16-26 f-400">{label}</span>
    </RoundButton>
  );
};
