import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getInactiveStartups } from '@/store/actions/startupActions';
import { Preloader } from '@/components/Preloader';
import { StartupCard } from '@/components/Cards/StartupCard/v3';
import { APP_LINKS } from '../../helpers/links';
import s from './index.module.scss';

export const StartupsAll = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('brands');

  const startups = useSelector(state => state.startupStore.inactiveStartups);
  const innerWidth = useSelector(state => state.commonStore.innerWidth);

  useEffect(() => {
    !startups && getStartups();
  }, []);

  const getStartups = () => {
    dispatch(getInactiveStartups()).finally(() => setLoading(false));
  };

  const hasStartups = !!startups?.list.length;

  return (
    <div className={s.startups_all}>
      <h2 className="f-26 f-500">
        {t('startups.other.title')}{' '}
        {startups?.total_count && `(${startups.total_count})`}
      </h2>
      <p className={classnames(s.startups_all__desc, 'f-16 f-400')}>
        {t('startups.other.description')}
      </p>
      {loading && !hasStartups ? (
        <Preloader className={s.startups_all__preloader} />
      ) : hasStartups ? (
        <InfiniteScroll
          dataLength={Number(startups?.list.length) || 0}
          next={getStartups}
          hasMore={
            !startups || (startups && startups.page < startups.total_pages)
          }
          scrollableTarget={innerWidth >= 1380 && 'main'}
          scrollThreshold={0.5}
          className={s.startups_all__list}
          loader={null}
        >
          {startups.list.map(startup => (
            <StartupCard
              key={startup.id}
              startup={startup}
              href={APP_LINKS.startupDetail(startup.slug)}
              className={s.startups_all__card}
              hideFR
            />
          ))}
        </InfiniteScroll>
      ) : (
        <div className={s.startups_all__empty}>
          <span
            className={classnames(s.startups_all__empty_title, 'f-16 f-400')}
          >
            {t('startups.other.no_brands')}
          </span>
        </div>
      )}
    </div>
  );
};
