import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Analytics from '@/analyticsGA';
import { getInvestingStatus } from '@/store/actions/investActions';
import { getBalance } from '@/store/actions/dashboardActions';
import { getUserProfile } from '@/store/actions/profileActions';
import { Preloader } from '@/components/Preloader';
import MiniFaqs, { MINIFAQ_SOURCES } from '../MiniFaqs';
import { NewUsersView } from './NewUsers';
import { InvestedUsersView } from './InvestedUsers';
import { USER_STATUSES } from '@/helpers/constants';
import { SuspendedUsersView } from './SuspendedUsers';
import s from './index.module.scss';

const DashboardModule = () => {
  const dispatch = useDispatch();
  const [pageLoaded, setPageLoaded] = useState(false);
  const { data: status } = useSelector(
    state => state.investStore.investingStatus,
  );
  const { data: profile } = useSelector(state => state.profileStore.profile);

  useEffect(() => {
    Promise.all([
      dispatch(getUserProfile()),
      dispatch(getInvestingStatus()),
      dispatch(getBalance()),
    ]).finally(() => setPageLoaded(true));
    Analytics.pageNavigation('Dashboard');
  }, []);

  if (!pageLoaded || !profile || !status) {
    return (
      <div className={s.dashboard_module}>
        <div className="container-inner">
          <Preloader style={{ height: '50vh' }} />
        </div>
      </div>
    );
  }

  const isActiveFR = !!(status && status.step >= 0 && status.step < 4);

  const hasInvestedBefore = !!status?.has_previously_invested;
  const isSuspended = profile?.status === USER_STATUSES.suspended;

  const content = isSuspended ? (
    <SuspendedUsersView />
  ) : hasInvestedBefore ? (
    <InvestedUsersView isActiveFR={isActiveFR} />
  ) : (
    <NewUsersView isActiveFR={isActiveFR} />
  );

  return (
    <div className={s.dashboard_module}>
      <div className="container-inner">
        {content}
        <MiniFaqs source={MINIFAQ_SOURCES.dashboard} />
      </div>
    </div>
  );
};

export default DashboardModule;
