import Pathes from '@/helpers/pathes';
import axios from '@/axios-api';

export const authService = {
  auth: payload => axios.post(Pathes.Auth.auth, payload),
  google: payload => axios.post(Pathes.Auth.google, payload),
  apple: payload => axios.post(Pathes.Auth.apple, payload),
  emailOtpStatus: () => axios.get(Pathes.Auth.emailOtpStatus),
  submitEmailOtp: payload => axios.post(Pathes.Auth.emailOtpSubmit, payload),
  useResendEmailOtpCode: () => axios.post(Pathes.Auth.emailOtpResend),
};
