import { Tooltip } from 'react-tippy';
import { useTranslation } from 'react-i18next';
import { Tippy } from '@/kit/Tippy';
import { useCalculateEarnings } from '@/hooks/useCalculateEarnings';
import { BonusFirstInvestHint } from '@/components/BonusFirstInvestHint';
import { InfoIcon } from '@/components/UI/Icons';
import { MyLink } from '@/components/MyLink';
import { prettyFloatMoney } from '@/helpers/utils';

export const PlannedEarnings = () => {
  const { t } = useTranslation('investing');
  const { plannedEarning, apr } = useCalculateEarnings();

  return (
    <div className="investing_summary__earnings">
      <div className="investing_summary__earnings_item">
        <div className="f-16">
          {t('summary_planned_earnings')}{' '}
          <Tippy
            render={(ref, open, setOpen) => (
              <Tooltip
                theme="light"
                trigger="click"
                arrow
                open={open}
                interactive
                html={
                  <div
                    ref={ref}
                    className="f-16 f-400"
                    style={{ maxWidth: '350px' }}
                  >
                    {t('summary_return_group_a')}
                    <div style={{ marginTop: '10px' }} />
                    {t('summary_return_group_b')}
                    <div style={{ marginTop: '10px' }} />
                    {t('summary_more_details')}{' '}
                    <MyLink isExternal href="/help/loan-terms" underline>
                      {t('summary_loan_terms')}
                    </MyLink>
                  </div>
                }
              >
                <InfoIcon
                  style={{
                    position: 'relative',
                    top: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                />
              </Tooltip>
            )}
          />
        </div>
        <div className="f-42 f-500 tl">
          {prettyFloatMoney(plannedEarning, { roundUpper: true })}
        </div>
      </div>
      <div className="investing_summary__earnings_item">
        <BonusFirstInvestHint className="investing_summary__earnings_bonus" />
      </div>
      <div className="investing_summary__earnings_item">
        <div className="f-16">
          {t('summary_planned_apr')}{' '}
          <Tippy
            render={(ref, open, setOpen) => (
              <Tooltip
                theme="light"
                trigger="click"
                arrow
                open={open}
                interactive
                html={
                  <div
                    ref={ref}
                    className="f-16 f-400"
                    style={{ maxWidth: '350px' }}
                  >
                    {t('summary_apr_note')}
                  </div>
                }
              >
                <InfoIcon
                  style={{
                    position: 'relative',
                    top: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                />
              </Tooltip>
            )}
          />
        </div>
        <div className="f-42 f-500 tl">
          <span>{apr}</span>%
        </div>
      </div>
    </div>
  );
};
