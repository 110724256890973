import { useTranslation } from 'react-i18next';
import Image from './image.svg?react';
import { RoundLink } from '@/components/UI/RoundLink';
import { APP_LINKS } from '@/helpers/links';
import s from './index.module.scss';

const BonusInviteFriendsInvestingModal = () => {
  const { t } = useTranslation('modal');
  return (
    <div className={s.modal}>
      <div className={s.content}>
        <div className={s.image}>
          <Image />
        </div>
        <div className="f-22 f-500">
          {t('bonus_invite_investing.more_friends_more_rewards')}
        </div>

        <div className="f-16-24">
          {t('bonus_invite_investing.invite_friends_to_invest')}
        </div>

        <RoundLink
          fillBackground
          path={APP_LINKS.affiliate}
          label={t('bonus_invite_investing.invite_friends')}
        />
      </div>
    </div>
  );
};

export default BonusInviteFriendsInvestingModal;
